import { createTheme } from '@material-ui/core'

export const themeGlobal = createTheme({
  palette: {
    background: {
      // default: "#1e2834"
    },
    primary: {
      main: "#007bff",
    },
    secondary: {
      main: "#EB5757"
    },
    instaPMBlue: "#00AF91"
  },
  props: {
    MuiButtonBase: {
      // The default props to change
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
}); 