import { makeStyles} from '@material-ui/core/styles';
import { useSelector } from 'react-redux'
import Button from '@material-ui/core/Button';
import React, { useMemo } from 'react';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
  	regular: {
		background: props=>props.color,
		borderRadius: 16,
		border: 0,
		color: 'white',
		height: 55,
		width: "100%",
		fontWeight: 600,
		fontSize: 16,
		textAlign: "center",
		textTransform: 'capitalize',
		whiteSpace: "nowrap",
		"&:hover": {
			//you want this to be the same as the backgroundColor above
			backgroundColor: props=>props.color
		}
  	},
  	outline: {
		borderRadius: 16,
		border: "0.2rem solid",
		borderColor: props=>props.color,
		color: props=>props.color,
		height: 55,
		width: "100%",
		fontWeight: 600,
		fontSize: 16,
		textAlign: "center",
		textTransform: 'capitalize',
		whiteSpace: "nowrap"
  	} 
});

export default function PMButton({children, type, disabled, loading, loadingText="", outlined, red, isFormButton=false, isNotAllowed=false, hidden=false, ...props}){
	const editState = useSelector((state) => state.forms.editState)

	const style = useMemo(() => {
    	let color = '#007bff'
    	if (red){
      		color = '#EB5757';
    	}
    	return {color: color};
  	}, [red]);
  
	const { regular, outline } = useStyles(style);

	if(hidden) {
		return <></>
	}

    return (
        <Button style={{cursor: isNotAllowed ? "not-allowed !important" : ""}} type={type} disabled={isFormButton ? (disabled || editState.isDisabled) : disabled} {...props} onClick={!loading ? props.onClick : () => {}} className={outlined ? `${outline} ${props.className}` : `${regular} ${props.className}`} >
            {loading ? (
				<>
            		<CircularProgress style={{'color': outlined ? style.color : 'white'}} size={24} />
					{loadingText}
				</>
            ) : (
            	children
            )}
        </Button>
    )
}
