import * as React from 'react';
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    red: {
        color: '#E74C3C',
    },
    yellow: {
        color: '#FFBF00',
    },
    green: {
        color: '#27AE60',
    },
});


export default function CircularProgressWithLabel({ value, ...props }){

    const classes = useStyles();

    let color = classes.green; // Default color (Green)
    if (value < 50) {
        color = classes.red; // Red
    } else if (value >= 50 && value <= 70) {
        color = classes.yellow; // Yellow
    }

    return (
        <Box>
            <Box
                sx={{
                    display: "inline-flex",
                    transform: "rotate(-90deg)",
                }}
            >
                <CircularProgress
                    className={color}
                    thickness={4}
                    value={value/2}
                    variant="determinate"
                />
            </Box>
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div">
                    {`${Math.round(value)}`}
                    <br/>
                    <Typography style={{ fontSize: '0.8em', color: 'gray', opacity: 0.6 }}>
                        Readability
                    </Typography>
                </Typography>
            </Box>
        </Box>
    );
}
