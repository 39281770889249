import _ from "lodash"

export const isEmpty = (text) => {
    return text === null || text === undefined || text.trim() === ""
}

export const UNAUTHENTICATED_ROUTES = [
    '/login',
    '/forgot-password',
    '/reset-password',
]

export const DATE_MASK = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
export const RMLC_DATE_MASK = [/\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
export const CONTROL_NUMBER_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
export const DIN_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]

export const updatePasswordFieldConditions = (conditions, field_type, new_password, confirm_new_password, current_password) => {
    let currentState = Object.assign({}, conditions);
    const field_conditions = _.pickBy(conditions, (condition) => {return condition.field.includes(field_type)})        
    Object.keys(field_conditions).map((condition) => {
        currentState[condition] = {
            ...currentState[condition],
            state: currentState[condition].validator(new_password, confirm_new_password, current_password),
        }
        return true
    })
    return currentState
}

export const getPasswordFieldCoditions = (conditions, fields) => {
    return _.pickBy(conditions, (condition) => {return _.difference(condition.field, fields).length === 0})
}

export const DOCUMENT_LOCK_STATES = {
    CURRENT_USER_SESSION: 0,
    CURRENT_USER_OPERATION: 1,
    ANOTHER_SESSION_OR_OPERATION: 2,
    UNLOCKED: 3,
}

export const FORM_ACCESS = {
    VIEWER: { name: "Viewer", code: 1 },
    EDITOR: { name: "Editor", code: 2 },
    MANAGER: { name: "Manager", code: 3 },
}

export const VIEW_TYPE_STATES = {
    EDITOR: 0,
    ARCHIVE: 1,
    COMPARISON: 2,
}

export const PROVINCE_ABBREVIATIONS = [
    {
        province: 'Alberta',
        abbreviation: 'AB',
    },
    {
        province: 'British Columbia',
        abbreviation: 'BC',
    },
    {
        province: 'Manitoba',
        abbreviation: 'MB',
    },
    {
        province: 'New Brunswick',
        abbreviation: 'NB',
    },
    {
        province: 'Newfoundland and Labrador',
        abbreviation: 'NL',
    },
    {
        province: 'Northwest Territories',
        abbreviation: 'NT',
    },
    {
        province: 'Nova Scotia',
        abbreviation: 'NS',
    },
    {
        province: 'Nunavut',
        abbreviation: 'NU',
    },
    {
        province: 'Ontario',
        abbreviation: 'ON',
    },
    {
        province: 'Prince Edward Island',
        abbreviation: 'PE',
    },
    {
        province: 'Quebec',
        abbreviation: 'QC',
    },
    {
        province: 'Saskatchewan',
        abbreviation: 'SK',
    },
    {
        province: 'Yukon',
        abbreviation: 'YT',
    },
]

export const passwordConditions = {
    CURRENT_PASSWORD: {
        key: 0,
        message: "Current Password must not be empty",
        state: false,
        field: ["CURRENT_PASSWORD"],
        validator: (new_password, confirm_new_password, current_password) => {
            return current_password && current_password.trim() !== ""
        },
    },
    LENGTH: {
        key: 1,
        message: "New Password must be a minimum of 8 characters",
        state: false,
        field: ["NEW_PASSWORD"],
        validator: (new_password, confirm_new_password, current_password) => {
            return /.*\S{8}.*/.test(new_password)
        },
    },
    NUMBER: {
        key: 2,
        message: "New Password must contain at least 1 number",
        state: false,
        field: ["NEW_PASSWORD"],
        validator: (new_password, confirm_new_password, current_password) => {
            return /.*[0-9].*/.test(new_password)
        },
    },
    SPECIAL_CHARACTER: {
        key: 3,
        message: "New Password must contain at least 1 special character",
        state: false,
        field: ["NEW_PASSWORD"],
        validator: (new_password, confirm_new_password, current_password) => {
            return /.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ].*/.test(new_password)
        },
    },
    UPPERCASE: {
        key: 4,
        message: "New Password must contain at least 1 uppercase letter",
        state: false,
        field: ["NEW_PASSWORD"],
        validator: (new_password, confirm_new_password, current_password) => {
            return /.*[A-Z].*/.test(new_password)
        },
    },
    LOWERCASE: {
        key: 5,
        message: "New Password must contain at least 1 lowercase letter",
        state: false,
        field: ["NEW_PASSWORD"],
        validator: (new_password, confirm_new_password, current_password) => {
            return /.*[a-z].*/.test(new_password)
        },
    },
    DIFFERENT_THAN_CURRENT: {
        key: 6,
        message: "New Password must be different than Current password",
        state: false,
        field: ["CURRENT_PASSWORD", "NEW_PASSWORD"],
        validator: (new_password, confirm_new_password, current_password) => {
            if(new_password === null || current_password === null) {
                return false
            }
            return current_password !== new_password
        },
    },
    CONFIRM_MATCHES: {
        key: 7,
        message: "New Password must match Confirm Password",
        state: false,
        field: ["NEW_PASSWORD", "CONFIRM_PASSWORD"],
        validator: (new_password, confirm_new_password, current_password) => {
            if(new_password == null || confirm_new_password == null) {
                return false
            }
            if(new_password.trim() !== "" && confirm_new_password.trim() !== "") {
                return new_password === confirm_new_password
            }
        }
    },
}