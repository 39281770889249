import React, { Fragment, useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown from '../../formLabels/Dropdown'
import ProductInformation from '../formSections/ProductInformation'
import ProductCharacteristics from '../formSections/ProductCharacteristics'
import RegulatoryStatus from '../formSections/RegulatoryStatus'
import Ingredients from '../formSections/Ingredients'
import Packaging from '../formSections/Packaging'
import { makeStyles} from '@material-ui/core/styles';

import { Box, Grid, Paper} from '@material-ui/core';
import PMButton from "../../common/Button";
import { updateFormField } from '../../../actions/forms'
import { productModel } from "../../../models/Product"

import _ from "lodash"

const useStyles = makeStyles({
    veritcalText: {
        alignSelf: "center"
    },
    paper: {
        padding: "2rem",
        margin: "2rem 0rem",
        borderRadius: "25px"
    }
});

export default function ProductDetails({ context }){
    const dispatch = useDispatch()
    const form = useSelector((state) => state.forms.form)
    const updating = useSelector((state) => state.forms.updating)
    const classes = useStyles();
    const [products, setProducts] = useState([]);
    const [currentProductIndex, setCurrentProductIndex] = useState(0)
    let formInput = context ? form && form.formDataFrench : form && form.formDataEnglish 

    useEffect(() => {
        setProducts(formInput.products)
    }, [formInput])

    const handleUpdateField = (name, content) => {
        _.set(formInput, name, content)
        dispatch(updateFormField(name, content, form._id, context))
    }

    const handleCreateNewProduct = () => {
        let product = productModel
        formInput.products.push(product)
        setCurrentProductIndex(formInput.products.length - 1)
        dispatch(updateFormField("products", formInput.products, form._id, context))
    }

    const handleRemoveProduct = () => {
        if (formInput.products.length === 0) {
            return
        }
        formInput.products.splice(currentProductIndex, 1)
        setCurrentProductIndex(formInput.products.length === 0 ? 0 : formInput.products.length - 1)
        dispatch(updateFormField("products", formInput.products, form._id, context))
    }

    return(
        <Fragment>
            <Paper className={classes.paper} elevation={4}>
                <Grid container>
                    <Grid xs={10} item className={classes.veritcalText}>
                        {products.length > 0 ? (
                            <Box>
                                <h6>
                                    Select a Product
                                </h6>
                                <Dropdown currentIndex = {currentProductIndex} setCurrentIndex = {setCurrentProductIndex} list ={products} />
                            </Box>
                        ) : (
                            <Box>
                                <h6>
                                    No Products Exist. Click Add To Create One
                                </h6>
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        <Box display="flex">
                            <Box width="100%" mr={2}>
                                <PMButton isFormButton={true} disabled={updating} onClick={()=>{handleCreateNewProduct()}}>
                                    Add
                                </PMButton>
                            </Box>
                            <Box width="100%">
                                <PMButton isFormButton={true} disabled={updating} onClick={()=>{handleRemoveProduct()}} red>
                                    Delete
                                </PMButton>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
            {
                products.length > 0 && (
                    <Fragment>
                        <ProductInformation
                            context = {context}
                            currentProductIndex={currentProductIndex}
                            handleUpdateField = {handleUpdateField}
                        />
                        <Ingredients
                            context={context}
                            currentProductIndex={currentProductIndex} 
                            handleUpdateField = {handleUpdateField}
                        />
                        <ProductCharacteristics
                            context = {context}
                            currentProductIndex={currentProductIndex}
                            handleUpdateField = {handleUpdateField}
                        /> 
                        <Packaging
                            context = {context}
                            currentProductIndex={currentProductIndex}
                        />
                        <RegulatoryStatus
                            context = {context}
                            currentProductIndex={currentProductIndex}
                            handleUpdateField = {handleUpdateField}
                        />
                    </Fragment>
                )
            }
        </Fragment>
    )
}
