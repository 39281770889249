import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// MUI Theme
import { ThemeProvider } from '@material-ui/styles';
import {themeGlobal} from './assets/globalTheme'

// Redux
import { Provider } from 'react-redux'
import store from './store'

import InstaPMApp from './InstaPMApp';

const App = () => {
  	return (
		<ThemeProvider theme={themeGlobal}>
			<div id='snackbarhelper'></div>
			<Provider store={store}>
				<InstaPMApp/>
			</Provider>
		</ThemeProvider>
	)
}

export default App;
