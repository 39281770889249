import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { connect } from 'react-redux'
import {englishLabels, frenchLabels} from "../../formLabels/RecentMajorLabelChangesFormLabels"

import { makeStyles} from '@material-ui/core/styles';
import { Box, Grid, Paper } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import RemoveIcon from '@material-ui/icons/Remove';

import { updateFormField } from '../../../actions/forms'
import RMLCEditor from '../formComponents/RMLCEditor'
import { EmptyRMLC, RMLCTemplate } from '../../../models/Form';

const useStyles = makeStyles({
    veritcalText: {
        alignSelf: "center"
    },
    paper: {
        padding: "2rem",
        margin: "2rem 0rem",
        borderRadius: "25px"
    },
    rightAlign: {
        textAlign: "right"
    }
});

const RecentMajorLabelChanges = ({ context }) => {
    const EmptyRMLCTemplate = context ? EmptyRMLC.FRENCH : EmptyRMLC.ENGLISH
    const dispatch = useDispatch()
    const form = useSelector((state) => state.forms.form)
    const editState = useSelector((state) => state.forms.editState)
    let formInput = context ? form && form.formDataFrench : form && form.formDataEnglish 
    const updating = useSelector((state) => state.forms.updating)
    const [labels, setLabels] = useState({})
    const [RMLCExists, setRMLCExists] = useState(formInput.recentMajorLabelChanges !== EmptyRMLCTemplate && formInput.recentMajorLabelChanges.trim() !== '')
    const classes = useStyles();
    const placeHolder = context ? 'Aucune au moment de l’autorisation la plus récente.' : 'No Recent Major Label Changes at time of the most recent authorization.'
    
    useEffect(() => {
        setLabels(context ? frenchLabels : englishLabels)
    }, [context])

    const handleRMLCToggle = (newValue) => {
        if (newValue === false){
            formInput.recentMajorLabelChanges = EmptyRMLCTemplate
            dispatch(updateFormField("recentMajorLabelChanges", EmptyRMLCTemplate, form._id, context))
        } else {
            formInput.recentMajorLabelChanges = RMLCTemplate
            dispatch(updateFormField("recentMajorLabelChanges", RMLCTemplate, form._id, context))
        }
        setRMLCExists(newValue)
    }

    return (
        <Box>
            <Paper className={classes.paper} elevation={4}>
                <Grid container>
                    <Grid xs={8} item className={classes.veritcalText}>
                        <Box>
                            <h6>{labels.title}</h6>
                        </Box>
                    </Grid>
                    <Grid className={classes.rightAlign} xs={4} item>
                        <ToggleButton
                            value={true}
                            disabled={updating || editState.isDisabled}
                            selected={RMLCExists}
                            onChange={() => {
                                handleRMLCToggle(!RMLCExists);
                            }}
                        >
                            {RMLCExists ? (
                                <Box>
                                    <RemoveIcon />
                                    Remove RMLC Section
                                </Box>
                            ) : (
                                <Box>
                                    <PlaylistAddIcon />
                                    Add RMLC Section
                                </Box>
                            )}
                        </ToggleButton>
                    </Grid>
                </Grid>
                <Grid>
                    {RMLCExists ? (
                        <Box mt={2} key={`recentMajorLabelChanges-${context}`}>
                            <RMLCEditor
                                language={context}
                                initialValue={formInput.recentMajorLabelChanges}
                                name={"recentMajorLabelChanges"}
                            />
                        </Box>
                    ) : (
                        <Box mt={2}>
                            {placeHolder}
                        </Box>
                    )}
                </Grid>
            </Paper>
        </Box>
    )
}

export default connect(null, null)(RecentMajorLabelChanges)