import { Box, Typography } from '@material-ui/core';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
const descriptionTheme = createTheme({
    typography: {
        fontFamily: "Playfair Display, serif",
        fontStyle: "normal",
    }
});

const useStyles = makeStyles(() => ({
    rightContainer: {
        padding: "5rem",
        display: "flex",
        alignItems: "center",
        height: "100vh",
        width: "100%"
    },
    paper: {
        width: "100%",
        display: "flex"
    },
    left: {
        height: "100%",
        width: "40%",
        position: "fixed",
        overflow: "hidden",
        left: 0,
        background: "linear-gradient(166.32deg, #007bff 7.32%, #001c96 93.55%)",        
    }, 
    leftMobile: {
        height: "100%",
        width: "100%",
        position: "fixed",
        top: "0",
        background: "linear-gradient(166.32deg, #007bff 7.32%, #001c96 93.55%)",        
    }, 
    right: {
        height: "100%",
        width: "60%",
        position: "fixed",
        overflow: "hidden",
        paddingTop: "20px",
        right: 0,
    },
    centered: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center"
    },
    description: {
        color: "white",
        // float: "left",
        fontWeight: 800,
        fontSize: 36,
        fontStyle: "normal",
        marginBottom: "1%",
    },
    subDescription: {
        color: "white",
        fontSize: "18px",
        fontWeight: 400,
    },
    descriptionBox: {
        textAlign: "left",
        width: '75%'
    },
    dots1: {
        top: 0,
        position: "absolute",
        right: 0,
    },
    dots2: {
        position: "absolute",
        paddingLeft: "13%",
        display: "flex",
        alignItems: "center",
        height: "106%"
    },
}));

const NotAvailableMobile = () => {
	const classes = useStyles();

    return (
        <div className={classes.leftMobile}>
            <Box className={classes.dots1}>
                {/* <img src={Dots1} alt="Design Icons" /> */}
            </Box>
            <Box className={classes.dots2}>
                {/* <img src={Dots2} alt="Design Icons2" /> */}
            </Box>
            <div className={`${classes.centered} ${classes.descriptionBox}`}>
                <ThemeProvider theme={descriptionTheme}>
                    <Box mt={2} mb={1}>
                        <Typography className={classes.description}>
                            Warning!
                        </Typography>
                    </Box>
                </ThemeProvider>
                <Typography className={classes.subDescription}>
                    The InstaPM Portal is not optimized for a mobile experience. Please view this page on a desktop web browser.
                </Typography>
            </div>
        </div>
    )
}

export default NotAvailableMobile;