import React, { useEffect, useState } from 'react'
import TextField from '../formComponents/TextField'
import AutocompleteField from '../formComponents/AutoComplete'
import ProductCharacteristicStack from './productCharacteristicStack'
import {englishLabels, frenchLabels} from "../../formLabels/ProductCharacteristicsFormLabels"
import { makeStyles} from '@material-ui/core/styles';
import { Grid, Paper} from '@material-ui/core';

const useStyles = makeStyles({
    button: {
        width: "25%",
    },
    buttonGroupStyling: {
        "& .MuiButtonGroup-groupedContainedPrimary" : {
            backgroundColor: "#007bff",
        }
    },
    veritcalText: {
        alignSelf: "center"
    },
    paper: {
        padding: "2rem",
        margin: "2rem 0rem",
        borderRadius: "25px"
    }
});

export default function ProductCharacteristics({ currentProductIndex, context, handleUpdateField }){
    const [labels, setLabels] = useState({})
    const classes = useStyles();

    useEffect(() => {
        setLabels(context ? frenchLabels : englishLabels)
    }, [context])

    return(
        <Paper className={classes.paper} elevation={4}>
            <h6>{labels.title}</h6>

                <Paper className={classes.paper} elevation={4}>
                    <h6>{labels.productType}</h6>
                    <AutocompleteField 
                        showLabel={false} 
                        context={context} 
                        codeSystem="2.16.840.1.113883.2.20.6.53" 
                        updateFunction={handleUpdateField} 
                        label = {labels.productType} 
                        placeholder = {labels.productType} 
                        name = {`products[${currentProductIndex}].productType`}
                    />
                </Paper>
                <Paper className={classes.paper} elevation={4}>
                    <ProductCharacteristicStack
                        updateFunction={handleUpdateField} 
                        currentProductIndex={currentProductIndex}
                        isOriginalText={true}
                        context={context}
                        codeSystem="2.16.840.1.113883.2.20.6.24"
                        label = {labels.colour}
                        placeholder = {labels.colour}
                        name="colours"
                    />
                </Paper>
                <Paper className={classes.paper} elevation={4}>
                    <ProductCharacteristicStack
                        updateFunction={handleUpdateField} 
                        currentProductIndex={currentProductIndex}
                        isOriginalText={true}
                        context={context}
                        codeSystem="2.16.840.1.113883.2.20.6.25"
                        label = {labels.shape}
                        placeholder = {labels.shape}
                        name="shapes"
                    />
                </Paper>
                <Paper className={classes.paper} elevation={4}>
                    <ProductCharacteristicStack
                        updateFunction={handleUpdateField} 
                        currentProductIndex={currentProductIndex}
                        isOriginalText={true}
                        context={context}
                        codeSystem="2.16.840.1.113883.2.20.6.15"
                        label = {labels.size} 
                        placeholder = {labels.size}
                        name="sizes"
                        isValueUnitPair={true}
                    />
                </Paper>
                <Paper className={classes.paper} elevation={4}>
                    <h6>{labels.score}</h6>
                    <AutocompleteField 
                        showLabel={false} 
                        context={context} 
                        codeSystem="2.16.840.1.113883.2.20.6.4" 
                        updateFunction={handleUpdateField} 
                        label = {labels.score} 
                        placeholder = {labels.score} 
                        name = {`products[${currentProductIndex}].score`}
                    />
                </Paper>
                <Paper className={classes.paper} elevation={4}>
                    <h6>{labels.imprint}</h6>
                    <TextField 
                        showLabel={false} 
                        updateFunction={handleUpdateField} 
                        label = {labels.imprint} 
                        placeholder = {labels.imprint} 
                        name = {`products[${currentProductIndex}].imprint`}
                    />
                </Paper>
                <Paper  className={classes.paper} elevation={4}>
                    <ProductCharacteristicStack
                        updateFunction={handleUpdateField} 
                        currentProductIndex={currentProductIndex}
                        isOriginalText={true}
                        context={context}
                        codeSystem="2.16.840.1.113883.2.20.6.26"
                        label = {labels.flavour}
                        placeholder = {labels.flavour}
                        name="flavours"
                    />
                </Paper>
                <Paper  className={classes.paper} elevation={4}>
                    <ProductCharacteristicStack
                        updateFunction={handleUpdateField} 
                        currentProductIndex={currentProductIndex}
                        isOriginalText={false}
                        context={context}
                        codeSystem="2.16.840.1.113883.2.20.6.5"
                        label = {labels.pharmaceuticalStandard}
                        placeholder = {labels.pharmaceuticalStandard}
                        name="pharmaceuticalStandards"
                    />
                </Paper>
                <Paper  className={classes.paper} elevation={4}>
                    <ProductCharacteristicStack
                        updateFunction={handleUpdateField} 
                        currentProductIndex={currentProductIndex}
                        isOriginalText={false}
                        context={context}
                        codeSystem="2.16.840.1.113883.2.20.6.2"
                        label = {labels.schedule}
                        placeholder = {labels.schedule}
                        name="schedules"
                    />
                </Paper>
                <Paper  className={classes.paper} elevation={4}>
                    <ProductCharacteristicStack
                        updateFunction={handleUpdateField} 
                        currentProductIndex={currentProductIndex}
                        isOriginalText={false}
                        context={context}
                        codeSystem="2.16.840.1.113883.2.20.6.6"
                        label = {labels.therapeuticClass}
                        placeholder = {labels.therapeuticClass}
                        name="therapeuticClasses"
                    />
                </Paper>
        </Paper>
    )
}
