import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { generate, downloadDOCX, downloadSubmission, getDownloadStatus } from '../../actions/forms';

import { Box, Grid, Paper, Stepper, Step, StepLabel, Typography, Tooltip } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';

import AutorenewIcon from '@material-ui/icons/Autorenew';
import { makeStyles } from '@material-ui/core/styles';
import axios from "../../utils/customAxios"
import PMButton from '../common/Button';
import moment from 'moment';
import useInterval from '../../utils/Interval';

import { onClickHTML, hasFailed, hasFailedValidation, isLoading, isDownloadable, genTooltipTitle, genSubmissionTooltipTitle } from "../../utils/downloadUtilFunctions"

const useStyles = makeStyles(() => ({
    paper: {
        borderRadius: "25px",
        padding: 15
    },
    disabledPaper: {
        backgroundColor: "lightgray",
        borderRadius: "25px",
        padding: 15
    },
    iconButton: {
    },
    icon: {
        marginRight: "0.5rem"
    },
    iconGrid: {
        display: "flex",
        justifyContent: "flex-end",
        alignSelf: "center",
        "& .MuiButton-startIcon": {
            margin: 0,
            padding: 0,
        }
    },
    itemGrid: {
        display: "flex",
        alignItems: "center",
    },
    spinIcon: {
        animation: "spin 4s linear infinite"
    },
    errorBox: {
        borderRadius: "25px",
        backgroundColor: "#F68D8E",
        padding: "1rem",
    },
    radio: {
        '&$checked': {
            color: '#007bff'
        }
    },
    checked: {}
}));

const submissionSteps = ['Validating User Input', 'Generating XML', 'Generating Word Document', 'Generating Submission Zip', 'Rendering Quick Preview', 'Validating against Health Canada V2.1 XML schema'];

export default function DownloadDialog({ context, isDownloadDialogOpen }) {
    const dispatch = useDispatch()
    const classes = useStyles();
    const language = context === false ? 'ENGLISH' : 'FRENCH';

    const form = useSelector((state) => state.forms.form)
    const formUpdating = useSelector((state) => state.forms.updating)
    const downloadEnglishStatus = useSelector((state) => state.forms.downloadEnglishStatus)
    const downloadFrenchStatus = useSelector((state) => state.forms.downloadFrenchStatus)
    const accessLevel = useSelector((state) => state.forms.accessLevel)
    const isDemo = useSelector((state) => state.demo.isDemo)

    const [htmlUrl, setHtmlUrl] = useState("")
    const [xmlUrl, setXmlUrl] = useState("")
    const [submissionUrl, setSubmissionUrl] = useState("")
    const [docxUrl, setDocxUrl] = useState("")
    const [lastGenDate, setLastGenDate] = useState(null)
    const [pollDownload, setPollDownload] = useState(false)

    const [activeStep, setActiveStep] = useState(-1)
    const [errorStep, setErrorStep] = useState(-1)
    const [isErrorValidating, setIsErrorValidating] = useState(false)
    const [blockGen, setBlockGen] = useState(false)

    const [isForHealthCanada, setIsForHealthCanada] = useState(true)

    const handleSubmissionDownload = () => {
        if (isDemo) {
            return
        }
        dispatch(downloadSubmission(form._id, form.name, language))
    }

    const handleDocxDownload = () => {
        if (isDemo) {
            return
        }
        dispatch(downloadDOCX(form._id, form.name, language, "SUBMISSION"))
    }

    useInterval(() => {
        dispatch(getDownloadStatus(form._id, "ENGLISH"))
        dispatch(getDownloadStatus(form._id, "FRENCH"))
    }, pollDownload ? 2000 : null)

    const setData = () => {
        var downloadStatus = {}
        if(language === "ENGLISH") {
            downloadStatus = {
                html: downloadEnglishStatus?.html?.ENGLISH.url,
                xml: downloadEnglishStatus?.xml?.ENGLISH.url,
                xmlValidation: downloadEnglishStatus?.xml_ENGLISH_validation,
                docx: downloadEnglishStatus?.docx?.ENGLISH.url,
                sub: downloadEnglishStatus?.submission?.ENGLISH.url,
                lastGenDate: downloadEnglishStatus?.submission?.ENGLISH.lastModified,
                createdDate: downloadEnglishStatus?.createdDate,
            }
        } else {
            downloadStatus = {
                html: downloadFrenchStatus?.html?.FRENCH.url,
                xml: downloadFrenchStatus?.xml?.FRENCH.url,
                xmlValidation: downloadFrenchStatus?.xml_FRENCH_validation,
                docx: downloadFrenchStatus?.docx?.FRENCH.url,
                sub: downloadFrenchStatus?.submission?.FRENCH.url,
                lastGenDate: downloadFrenchStatus?.submission?.FRENCH.lastModified,
                createdDate: downloadFrenchStatus?.createdDate,
            }
        }

        setHtmlUrl(downloadStatus.html)
        setXmlUrl(downloadStatus.xml)
        setDocxUrl(downloadStatus.docx)
        setSubmissionUrl(downloadStatus.sub)
        setLastGenDate(downloadStatus.lastGenDate)
        
        genStep(downloadStatus.html, downloadStatus.xml, downloadStatus.xmlValidation, downloadStatus.docx, downloadStatus.sub)
        refreshGenStatus(downloadStatus.html, downloadStatus.xml, downloadStatus.docx, downloadStatus.sub, downloadStatus.xmlValidation, downloadStatus.createdDate)
    }

    // Poll for download status
    useEffect(() => {
        setIsErrorValidating(false)

        if (isDownloadDialogOpen) {
            dispatch(getDownloadStatus(form._id, "ENGLISH"))
            dispatch(getDownloadStatus(form._id, "FRENCH"))
            setPollDownload(true)
        } else {
            setPollDownload(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDownloadDialogOpen, dispatch, form._id, language])

    useEffect(() => {
        setData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [downloadEnglishStatus, downloadFrenchStatus, isDownloadDialogOpen, language])


    const genStep = (HTML, XML, XMLValidation, DOCX, SUBMISSION) => {
        if (!isDownloadable(XML)) {
            if(hasFailedValidation(XML)){
                return setStep(0, 0)
            }

            if(hasFailed(XML)){
                return setStep(1, 1)
            }

            if(isLoading(XML)){
                return setStep(1, -1)
            }
        }

        if (isDownloadable(XML) && !isDownloadable(DOCX) && !isDownloadable(SUBMISSION) && !isDownloadable(HTML)) {
            if(hasFailed(DOCX)){
                return setStep(2, 2)
            }

            if(isLoading(DOCX)){
                return setStep(2, -1)
            }
        }

        if (isDownloadable(XML) && isDownloadable(DOCX) && !isDownloadable(SUBMISSION) && !isDownloadable(HTML)) {
            if(hasFailed(SUBMISSION)){
                return setStep(3, 3)
            }

            if(isLoading(SUBMISSION)){
                return setStep(3, -1)
            }
        }

        if (isDownloadable(XML) && isDownloadable(DOCX) && isDownloadable(SUBMISSION) && !isDownloadable(HTML)) {
            if(hasFailed(HTML)){
                return setStep(4, 4)
            }

            if(isLoading(HTML)){
                return setStep(4, -1)
            }
        }

        if (isDownloadable(XML) && isDownloadable(DOCX) && isDownloadable(SUBMISSION) && isDownloadable(HTML)) {
            if(hasFailed(XMLValidation)){
                return setStep(5, 5)
            }

            if(isLoading(XMLValidation)){
                return setStep(5, -1)
            }
        }

        return setStep(6, -1)
    }

    const setStep = (activeStep, errorStep) => {
        setActiveStep(activeStep)

        if (errorStep >= 0) {
            setErrorStep(errorStep)
        } else {
            setErrorStep(-1)
        }

        if (errorStep > activeStep && activeStep !== -1) {
            setErrorStep(-1)
        }
    }

    const refreshGenStatus = (html, xml, docx, sub, validation, cDate) => {
        // If any of them are loading and none of them failed
        if ((isLoading(html) || isLoading(xml) || isLoading(docx) || isLoading(sub) || isLoading(validation)) && !(hasFailed(html) || hasFailed(xml) || hasFailed(docx) || hasFailed(sub) || hasFailed(validation))) {
            const currentTime = moment();
            const createdDate = moment(cDate);
            const difference = currentTime.diff(createdDate, 'minutes');

            if (difference < 3) {
                setBlockGen(true)
                return
            }
        }

        setBlockGen(false)
    }

    const handleGenerate = () => {
        setActiveStep(-1)
        setErrorStep(-1)
        setBlockGen(true)
        dispatch(generate(form._id, language, isForHealthCanada))
    }

    const genSubmissionButtons = () => {
        return (
            <Box display="flex">
                <Tooltip title={genTooltipTitle(htmlUrl)}>
                    <Box className='click-dhpp-button' mr={2}>
                        <PMButton
                            outlined
                            onClick={() => onClickHTML(htmlUrl)}
                            disabled={!isDownloadable(htmlUrl)}
                            startIcon={
                                (isLoading(htmlUrl) || isLoading(docxUrl)) ? (
                                    <>
                                        <AutorenewIcon className={classes.spinIcon} />
                                        <style>{`
                                                    @keyframes spin {
                                                        0% { transform: rotate(0deg); }
                                                        100% { transform: rotate(360deg); }
                                                    }
                                                `}</style>
                                    </>
                                ) : (
                                    <VisibilityIcon className={classes.iconButton} />
                                )
                            }
                        />
                    </Box>
                </Tooltip>
                <Tooltip title={genTooltipTitle(docxUrl, isDemo)}>
                    <Box mr={2}>
                        <PMButton
                            outlined
                            onClick={() => handleDocxDownload()}
                            isNotAllowed={isDemo}
                            disabled={!isDownloadable(docxUrl)}
                            startIcon={
                                isLoading(docxUrl) && !(hasFailed(docxUrl)) ? (
                                    <>
                                        <AutorenewIcon className={classes.spinIcon} />
                                        <style>{`
                                            @keyframes spin {
                                                0% { transform: rotate(0deg); }
                                                100% { transform: rotate(360deg); }
                                            }
                                        `}</style>
                                    </>
                                ) : (
                                    <DescriptionIcon className={classes.iconButton} />
                                )
                            }
                        />
                    </Box>
                </Tooltip>
                <Tooltip title={genSubmissionTooltipTitle(submissionUrl, xmlUrl, docxUrl, isDemo)}>
                    <Box>
                        <PMButton
                            outlined
                            isNotAllowed={isDemo}
                            onClick={() => handleSubmissionDownload()}
                            disabled={!isDownloadable(submissionUrl) || (hasFailed(xmlUrl) || hasFailed(docxUrl))}
                            startIcon={
                                isLoading(submissionUrl) && !(hasFailed(xmlUrl) || hasFailed(docxUrl)) ? (
                                    <>
                                        <AutorenewIcon className={classes.spinIcon} />
                                        <style>{`
                                            @keyframes spin {
                                                0% { transform: rotate(0deg); }
                                                100% { transform: rotate(360deg); }
                                            }
                                        `}</style>
                                    </>
                                ) : (
                                    <CloudDownloadIcon className={classes.iconButton} />
                                )
                            }
                        />
                    </Box>
                </Tooltip>
            </Box>
        )
    }

    const stepper = (steps) => {
        return (
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel error={errorStep === index}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        )
    }

    const genGenerateTooltipTitle = () => {
        if (blockGen) {
            return "Export is still loading, come back shortly after to generate again"
        }
        return "Click to generate"
    }

    return (
        <Paper variant="outlined" className={classes.paper}>
            <Grid container>
                <Grid item xs={5} >
                    <Tooltip title={genGenerateTooltipTitle()}>
                        <Box className='click-generate-button'>
                            <PMButton
                                onClick={() => handleGenerate()}
                                disabled={blockGen}
                            >
                                Generate
                            </PMButton>
                        </Box>
                    </Tooltip>
                </Grid>
                <Grid className={classes.iconGrid} item xs={7}>
                    <Box display="flex">
                        {genSubmissionButtons()}
                    </Box>
                </Grid>
                <Grid className={classes.itemGrid} item xs={12}>
                    <Box width="100%" mt={2} ml={1} pr={10}>
                        <Typography variant='caption'>
                            {isDownloadable(submissionUrl) ? (
                                `Last Generated: ${moment(lastGenDate).fromNow()}`
                            ) : (
                                isLoading(submissionUrl) ? (
                                    "Preparing Your Submission..."
                                ) : (
                                    "Failed to Generate Your Submission"
                                )
                            )}
                        </Typography>
                    </Box>

                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid className={classes.itemGrid} item xs={12}>
                            {isErrorValidating && (
                                <Box className={classes.errorBox}>
                                    <Typography>
                                        There are validation errors with your Product Monograph. Please correct them and try again.
                                    </Typography>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {stepper(submissionSteps)}
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}
