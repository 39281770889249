export const englishLabels = {
    title: "PATIENT MEDICATION INFORMATION",
    brandName: "BRAND NAME",
    properName: "PROPER NAME",
    readThisForSafeAndEffectiveUseOfYourMedicine: 'READ THIS FOR SAFE AND EFFECTIVE USE OF YOUR MEDICINE',
    whatIsBrandNameUsedFor: 'What is [Brand name] used for?',
    howDoesBrandNameWork: 'How does [Brand name] work?',
    whatAreTheIngredientsInBrandName: 'What are the ingredients in [Brand name]?',
    brandNameDosageForms: '[Brand name] comes in the following dosage forms:',
    doNotUseBrandNameIf: 'Do not use [Brand name] if:',
    healthConditionsOrProblems: 'To help avoid side effects and ensure proper use, talk to your healthcare professional before you take [Brand name]. Talk about any health conditions or problems you may have, including if you:',
    otherWarningsYouShouldKnowAbout: 'Other warnings you should know about:',
    theFollowingMayInteractWithBrandName: 'The following may interact with [Brand name]:',
    howToTakeBrandName: 'How to take [Brand name]:',
    usualDose: 'Usual dose:',
    overDose: 'Overdose:',
    missedDosePMI: 'Missed Dose:',
    possibleSideEffects: 'What are possible side effects from using [Brand name]?',
    storage: 'Storage:',
    moreInformation: 'If you want more information about [Brand name]:',
}

export const frenchLabels = {
    title: "RENSEIGNEMENTS POUR LE PATIENT SUR LE MÉDICAMENT",
    brandName: "MARQUE NOMINATIVE",
    properName: "NOM PROPRE",
    readThisForSafeAndEffectiveUseOfYourMedicine: 'LISEZ CE DOCUMENT POUR UNE UTILISATION SÉCURITAIRE ET EFFICACE DE VOTRE MÉDICAMENT',
    whatIsBrandNameUsedFor: 'Pourquoi utilise-t-on [Nom commercial]?',
    howDoesBrandNameWork: 'Comment [Nom commercial] agit-il?',
    whatAreTheIngredientsInBrandName: 'Quels sont les ingrédients de [Nom commercial]?',
    brandNameDosageForms: '[Nom commercial] se présente sous la ou les formes pharmaceutiques suivantes:',
    doNotUseBrandNameIf: "N'utilisez pas [nom commercial] dans les cas suivants :",
    healthConditionsOrProblems: "Consultez votre professionnel de la santé avant d'utiliser [Nom commercial], afin d'assurer le bon usage du médicament et réduire la possibilité d'effets indésirables. Informez votre professionnel de la santé de votre état actuel ainsi que vos problèmes de santé, notamment:",
    otherWarningsYouShouldKnowAbout: 'Autres mises en garde',
    theFollowingMayInteractWithBrandName: 'Les produits suivants pourraient interagir avec [Nom commercial]:',
    howToTakeBrandName: 'Comment utiliser [Nom commercial]?',
    usualDose: 'Dose habituelle',
    overDose: 'Surdose',
    missedDosePMI: 'Dose oubliée:',
    possibleSideEffects: 'Quels sont les effets secondaires qui pourraient être associés à [Nom commercial]?',
    storage: 'Conservation',
    moreInformation: 'Pour en savoir plus sur [Nom commercial]:',
}