import axios from 'axios';
import { logout, refreshToken } from '../actions/auth';
import { VERSION_UPDATE_REQUIRED } from '../actions/types';
import store from '../store'
import { error } from "./SnackBar"
import { UNAUTHENTICATED_ROUTES } from './utilFunctions';

const BASE_URL = process.env.REACT_APP_ENVMNT === 'production' ? 'https://api.instapm.ca' : process.env.REACT_APP_ENVMNT === 'staging' ? '' :  'http://localhost:5000';

const getExpirationDate = (jwtToken) => {
    if (!jwtToken) {
        return null;
    }
    const jwt = JSON.parse(atob(jwtToken.split('.')[1]));
    // multiply by 1000 to convert seconds into milliseconds
    return (jwt && jwt.exp && jwt.exp * 1000) || null;
};

const isExpiring = (exp) => {
    if (!exp) {
        return false;
    }

    // refreshWindow is the window (before token expiry) to trigger a token refresh
    // i.e any time 10 minutes (in milliseconds) before token expiry, refresh the token
    const refreshWindow = 10 * 60 * 1000    // 10 minutes * 60 seconds/minute * 1000 milliseconds/second

    // exp is the token expiry time in milliseconds, Date.now() is the current time
    // exp - Date.now() is the how long until the token expires
    return (exp - Date.now()) <= refreshWindow;
};

// Check if current datetime is greater (past) the token expiry time
const isExpired = (exp) => {
    if (!exp) {
        return false;
    }
    return Date.now() >= exp       
}

const isLastModifiedCall = (url) => {
    return url ? url.match(/\/api\/forms\/lastModified\?/g) : false
}

const customAxios = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
    }
});

const cancelSource = axios.CancelToken.source();

customAxios.interceptors.request.use((config) => {
    if (isExpired(getExpirationDate(localStorage.token)) ){
        error("Expired session - Please login again")
        store.dispatch(logout())
        cancelSource.cancel('Expired User Session')
        return null;
    }
    if(localStorage.token) {
        config.headers['x-auth-token'] = localStorage.token;
    } else {
        config.headers['x-auth-token'] = null;
    }
    
    if (sessionStorage.sessionID){
        config.headers['x-sessionID'] = sessionStorage.sessionID;
    } else {
        config.headers['x-sessionID'] = null;
    }

    return config;
})

customAxios.interceptors.response.use(
    (response) => {
        // Check if server version is newer than current client version
        const pathName = window.location.pathname
        if (!UNAUTHENTICATED_ROUTES.includes(pathName) && (parseFloat(response.headers['x-version']) > parseFloat(process.env.REACT_APP_VERSION))) {
            store.dispatch({
                type: VERSION_UPDATE_REQUIRED,
                payload: true,
            })
        };

        if ( isExpiring(getExpirationDate(localStorage.token)) && !isLastModifiedCall(response?.config?.url)) {
            store.dispatch(refreshToken(localStorage.refreshToken))
        }
        return response;
    },
    (err) => {
        if (err.response.status === 401) {
            error("Expired session - Please login again")
            store.dispatch(logout())
            return
        }
        return Promise.reject(err);
    }
);

export default customAxios;
