import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useIdleTimer } from 'react-idle-timer'

import { SET_ACTIVE, SET_EXPIRED, SET_EXPIRING, SESSION_TIMEOUT } from '../actions/types';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import SessionTimoutDialog from './SessionTimeoutDialog';

import { createSession, invalidateSession } from "../actions/auth"

export const SESSION_STATUS = {
    UNAUTHORIZED: -1,
    ACTIVE: 0,
    EXPIRING: 1,
    EXPIRED: 2,
}

export const NON_EXPIRABLE_SESSION_PATHS = [
    '/',
    '/login',
    '/forgot-password',
    '/reset-password',
]

export const SESSION_TIMEOUT_WARNING_TIME = 1000 * 60 * 15;      // 1000 millisecond per second  * 60 seconds per minute * 15 minutes
export const SESSION_EXPIRY_FROM_WARNING_TIME = 1000 * 60 * 2;    

export default function SessionProvider({ children }){

    const dispatch = useDispatch()
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
    const sessionStatus = useSelector((state) => state.session.sessionStatus)
    const sessionLoading = useSelector((state) => state.auth.sessionLoading)

    // Make request to create session
	useEffect( () => {
		if(isAuthenticated){
		    dispatch(createSession())
		}
  	}, [isAuthenticated])

    // Function fired when session is ABOUT to expire
    // When promptTimeout is set, this function is called after the user becomes idle. This is useful for displaying a confirm prompt. If the prompt timeout is reached, onIdle is then called.
    const onPrompt = () => {
        if (NON_EXPIRABLE_SESSION_PATHS.includes(window.location.pathname)) {
            return;
        }
        dispatch({
            type: SET_EXPIRING,
        })
    }
    
    // Function fired when session timeout has been reached
    // Function to call when user is idle.
    const onIdle = () => {
        if (NON_EXPIRABLE_SESSION_PATHS.includes(window.location.pathname)) {
            return;
        }
        dispatch({type: SET_EXPIRED})
        dispatch(invalidateSession())
        pause();
    }
    
    // Function fired when an action was detected.
    // Function to call on user activity.
    const onAction = () => {
        if (sessionStatus === SESSION_STATUS.EXPIRED) {
            return;
        }
        dispatch({
            type: SET_ACTIVE
        });
    }

    const { activate, pause, getRemainingTime, } = useIdleTimer({
        onPrompt: onPrompt,
        onIdle: onIdle,
        onAction,
        timeout: SESSION_TIMEOUT_WARNING_TIME,
        promptTimeout: SESSION_EXPIRY_FROM_WARNING_TIME,
    })

    useEffect(() => {
        if (sessionStatus === SESSION_STATUS.ACTIVE) {
            activate();
        }
    }, [sessionStatus, activate])

    return (
        <Box>
            <SessionTimoutDialog
                getRemainingTime={getRemainingTime}
            />
            {sessionLoading === true && isAuthenticated ? (
                <Box alignItems="center" height="100vh" display="flex" flexDirection="column" justifyContent="center">
                    <CircularProgress />
                    <Box mt={2}>
                        <Typography variant='h4'>
                            InstaPM
                        </Typography>
                    </Box>
                </Box>
            ) : (
                {...children}
            )}
        </Box>
    )
};