import { SET_UNAUTHORIZED, SET_ACTIVE, SET_EXPIRING, SET_EXPIRED } from '../actions/types'
import { SESSION_STATUS } from '../utils/SessionProvider';

const initialState = {
    sessionStatus: SESSION_STATUS.ACTIVE
}

export default function sessionReducer( state = initialState, action) {

    const { type } = action;
    
    switch(type) {
        case SET_UNAUTHORIZED:
            return {
                ...state,
                sessionStatus: SESSION_STATUS.UNAUTHORIZED
            }
        case SET_ACTIVE:
            return {
                ...state,
                sessionStatus: SESSION_STATUS.ACTIVE
            }
        case SET_EXPIRING:
            return {
                ...state,
                sessionStatus: SESSION_STATUS.EXPIRING
            }
        case SET_EXPIRED:
            return {
                ...state,
                sessionStatus: SESSION_STATUS.EXPIRED
            }
        default:
            return state
    }
}