import { Box } from '@material-ui/core';

export const englishTooltipLabels = {
    accessControl:
        <Box>
            There are three levels of access for a PM form, from lowest to highest:
            <ol>
                <li><b>Viewer:</b> can view and download, but cannot make any changes to the PM. When a new PM is created, everyone in the organization but the PM creator gets a "Viewer" access by default.</li>
                <li><b>Editor:</b> can view, edit and download, but cannot manage the PM settings. It is recommended that the creator (the first Manager) gives "Editor" access to collaborators.</li>
                <li><b>Manager:</b> can view, edit, download, and manage PM settings (such as controlling access). The PM creator becomes the first manager. A PM form needs to have at least one "Manager".</li>
            </ol>
        </Box>
}

export const frenchTooltipLabels = {
    accessControl:
        <Box>
            Il existe trois niveaux d'accès pour un formulaire MP, du plus bas au plus élevé :
            <ol>
                <li><b>Lecteur :</b> peut afficher et télécharger, mais ne peut apporter aucune modification au PM. Lorsqu'un nouveau MP est créé, tous les membres de l'organisation, à l'exception du créateur du MP, obtiennent un accès "Lecteur" par défaut.</li>
                <li><b>Éditeur :</b> peut afficher, modifier et télécharger, mais ne peut pas gérer les paramètres PM. Il est recommandé que le créateur (le premier gestionnaire) accorde l'accès "Éditeur" aux collaborateurs.</li>
                <li><b>Gestionnaire :</b> peut afficher, modifier, télécharger et gérer les paramètres PM (tels que le contrôle d'accès). Le créateur de MP devient le premier Gestionnaire. Un formulaire MP doit avoir au moins un "Gestionnaire".</li>
            </ol>
        </Box>
}