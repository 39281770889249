import {
	DECREMENT_DEMO_STEP,
    DEMO_LOADING,
	INCREMENT_DEMO_STEP,
	SET_DEMO,
	SET_DEMO_STEP,
	UPDATE_DEMO_STATE,
} from "../actions/types";

const DEMO_FORM_PAGE_PATHNAME_REGEX = /^\/demo\/.+/;

const isDemoSignupPage = (window.location.pathname) === "/demo/" || (window.location.pathname) === "/demo"
const isDemoFormPage = DEMO_FORM_PAGE_PATHNAME_REGEX.test(window.location.pathname)

const initialState = {
	loading: null,
	isDemoSignupPage: isDemoSignupPage,
	isDemoFormPage: isDemoFormPage,
	isDemo: isDemoSignupPage || isDemoFormPage,
	isDemoComplete: !Boolean(localStorage.getItem('isDemoComplete') === "false"),
	currentDemoStep: 0,
};

export default function demoReducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case DEMO_LOADING:
			return {
				...state,
                loading: payload,
				isDemoComplete: false,
			};
		case SET_DEMO:
			localStorage.setItem('isDemoComplete', false)
			return {
				...state,
				isDemo: true,
			}
		case SET_DEMO_STEP:
			return {
				...state,
				currentDemoStep: payload
			}
		case INCREMENT_DEMO_STEP:
			if (state.currentDemoStep === 15) {
				return state
			}
			localStorage.setItem('isDemoComplete', state.currentDemoStep === 14)
			return {
				...state,
				currentDemoStep: state.currentDemoStep + 1
			}
		case DECREMENT_DEMO_STEP:
			return {
				...state,
				currentDemoStep: state.currentDemoStep - 1
			}
		case UPDATE_DEMO_STATE:
			return {
				...state,
				isDemo: payload._isDemo,
				isDemoSignupPage: payload._isDemoSignupPage,
				isDemoFormPage: payload._isDemoFormPage,
				currentDemoStep: payload._isDemoSignupPage ? 0 : state.currentDemoStep,
			}
		default:
			return state;
	}
}

