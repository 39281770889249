
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChatBot from '../chatbot/index';
const useStyles = makeStyles((theme) => ({
    page: {
        margin: "4%"
    },
    formPage: {
        margin: "4% 4% 0% 2%"
    }
}));

const PrivateRoute = ({ component: Component, auth: { isAuthenticated }, viewType, showChatBot, ...rest }) => {
    const classes = useStyles()
    return (
        <Box className={rest.path === "/form/:id" ? classes.formPage : classes.page}>
            <Route
                {...rest}
                render={
                    props => isAuthenticated ? (
                        <>
                            <Component {...props} viewType={viewType} />
                            { showChatBot && <ChatBot /> }
                        </>
                    ) : (
                        <Redirect to="/login" />
                    )
                }
            />
        </Box>
    )
};

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);