import { DOCUMENT_LOCK_STATES, FORM_ACCESS, VIEW_TYPE_STATES } from "./utilFunctions"

// DocLock Format
// {
//     canEdit: boolean;
//     owningUserId: any;
//     isOwnedByOperation: any;
//     operationName: any;
// }
export default class EditState {
    constructor(accessLevel, documentLock, viewType, userID) {
        this.accessLevel = accessLevel
        this.documentLock = documentLock
        this.viewType = viewType
        this.userID = userID
    }

    documentLockState() {        
        if (this.documentLock.canEdit) {
            // If the document is editable and the owning user ID is not empty
            // that means the lock is held by the current user. If no user ID,
            // then the document is unlocked
            if (this.documentLock.owningUserId) {
                return DOCUMENT_LOCK_STATES.CURRENT_USER_SESSION
            } else {
                return DOCUMENT_LOCK_STATES.UNLOCKED
            }
        }

        // check operation logic
        if (this.documentLock.isOwnedByOperation && this.documentLock.owningUserId === this.userID){  
            return DOCUMENT_LOCK_STATES.CURRENT_USER_OPERATION
        }
        
        // default case
        return DOCUMENT_LOCK_STATES.ANOTHER_SESSION_OR_OPERATION
    }

    isDocumentEditable() {
        return (this.viewType === VIEW_TYPE_STATES.EDITOR
            && this.documentLockState() === DOCUMENT_LOCK_STATES.CURRENT_USER_SESSION
            && this.accessLevel.code >= FORM_ACCESS.EDITOR.code)
    }

    _isDocumentUnlocked() {
        return this.documentLockState() === DOCUMENT_LOCK_STATES.UNLOCKED
    }

    get hasEditAccess() {
        return this.accessLevel.code >= FORM_ACCESS.EDITOR.code
    }

    get isEditable(){
        // User can edit if the following conditions are met
        // - On editor view 
        // - Current session (not an operation) owns the document lock
        // - User is at least an Editor
        return this.isDocumentEditable()
    }

    get isDocumentUnlocked() {
        return this._isDocumentUnlocked()
    }

    get operationName() {
        return this.documentLock.operationName
    }

    get userOwnsOperation() {
        return this.documentLockState() === DOCUMENT_LOCK_STATES.CURRENT_USER_OPERATION
    }

    get isDocumentLocked() {
        return !this.isDocumentEditable() && !this._isDocumentUnlocked()
    }

    get isDisabled() {
        return !this.isDocumentEditable()
    }

    get canTryGetDocumentLock() {
        return this.documentLockState() === DOCUMENT_LOCK_STATES.UNLOCKED
            && this.viewType === VIEW_TYPE_STATES.EDITOR
            && this.accessLevel.code >= FORM_ACCESS.EDITOR.code
    }

    get isEditor() {
        return this.viewType === VIEW_TYPE_STATES.EDITOR
    }

    get isArchive() {
        return  this.viewType === VIEW_TYPE_STATES.ARCHIVE
    }

    get isComparison() {
        return  this.viewType === VIEW_TYPE_STATES.COMPARISON
    }

}