import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { resetPassword } from '../../actions/auth'
import { AUTH_RESET } from '../../actions/types';
import PMButton from '../common/Button'
import OtpInput from 'react-otp-input';

import { Box, Grid, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import PasswordValidator from '../common/PasswordValidator';
import { getPasswordFieldCoditions, passwordConditions, updatePasswordFieldConditions } from "../../utils/utilFunctions";
import { warning } from '../../utils/SnackBar';

const useStyles = makeStyles(() => ({
    button: {
        width: "100%"
    },
    textField: {
        width: "100%"
    },
    formLabel: {
        float: "left",
        margin: "2% 0% 2% 0",
        fontSize: 18
    },
    forgotPassword: {
        '&:hover': {
            color: "#007bff",
            cursor: "pointer",
            textDecoration: "underline",
        },
    },
    formHeader: {
        float: "left",
        fontWeight: 800,
        fontSize: 36,
        fontStyle: "normal",
        marginBottom: "1%",
    },
}));


export default function ResetPassword({email}) {
    const dispatch = useDispatch()
    const loading = useSelector((state) => state.auth.loading)
    const classes = useStyles()
    let history = useHistory()

    const [code, setCode] = useState()
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [conditions, setConditions] = useState(getPasswordFieldCoditions(passwordConditions, ["NEW_PASSWORD", "CONFIRM_PASSWORD"]))

    const handleSetPassword = (e) => {
        setConditions(updatePasswordFieldConditions(conditions, "NEW_PASSWORD", e.target.value, confirmPassword, null))
        setPassword(e.target.value)
    }

    const handleSetConfirmPassword = (e) => {
        setConditions(updatePasswordFieldConditions(conditions, "CONFIRM_PASSWORD", password, e.target.value, null))
        setConfirmPassword(e.target.value)
    }

    const handleBackToLogin = async e => {
        dispatch({
            type: AUTH_RESET,
            payload: null
        })
        history.push('/login')
    }

    const onSubmit = async e => {
        e.preventDefault();
        if(Object.values(conditions).every((condition) => condition.state)) {
            dispatch(resetPassword( email, password, confirmPassword, code))
        } else {
            warning("All conditions must be met before resetting password.")
        }
    }

    return(
        <Box>
            <form>
                <Box display="flex" flexDirection="column">
                    <Typography className ={classes.formHeader}>
                        Reset Your Password
                    </Typography>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box>
                                <Typography className ={classes.formLabel}>
                                    If an account with that email exists, you will shortly get a verification code in your email inbox. Enter your new password and the code to reset your password.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid container xs={12}>
                            <Grid item xs={6}>
                                <Grid item xs={12}>
                                    <Typography className ={classes.formLabel}>
                                        New Password
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box mb={1}>                                
                                        <TextField
                                            type = 'password'
                                            variant='outlined'
                                            placeholder = 'Enter New Password'
                                            name = 'password'
                                            value = {password}
                                            onChange = {(e) => {handleSetPassword(e)}}
                                            className={classes.textField}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className ={classes.formLabel}>
                                        Confirm New Password
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box>
                                        <TextField
                                            type = 'password'
                                            variant='outlined'
                                            placeholder = 'Confirm New Password'
                                            name = 'confirmPassword'
                                            value = {confirmPassword}
                                            onChange = {(e) => {handleSetConfirmPassword(e)}}
                                            className={classes.textField}
                                        />
                                    </Box>
                                </Grid>
                                <Box mt={2} display="flex" flexDirection="column">
                                    <Grid item xs={12}>
                                        <Typography className ={classes.formLabel}>
                                            Verification Code
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box>
                                            <OtpInput
                                                inputStyle="OTP-inputStyleReduced"
                                                errorStyle="OTP-error"
                                                value={code}
                                                onChange = {(otp) => setCode(otp)}
                                                numInputs={6}
                                                separator={<span></span>}
                                                isInputNum={true}
                                                className={classes.textField}
                                            />
                                        </Box>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <PasswordValidator
                                    conditions={conditions}
                                    fields={['NEW_PASSWORD', "CONFIRM_PASSWORD"]}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Box mt={3}>
                            <PMButton
                                type="submit"
                                loading={loading}
                                disabled={loading}
                                onClick={(e) => {handleBackToLogin(e)}}
                                className={classes.button}
                                outlined
                            >
                                Back to Login
                            </PMButton>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box mt={3}>
                            <PMButton
                                type="submit"
                                loading={loading}
                                disabled={loading}
                                onClick={(e) => {onSubmit(e)}}
                                className={classes.button}
                            >
                                Reset Password
                            </PMButton>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}
