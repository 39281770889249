import { Box, Grid, Paper, Typography, TextField } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useState } from "react";
import PMButton from '../common/Button';
import { useDispatch, useSelector } from 'react-redux'
import { changePassword } from "../../actions/auth";
import PasswordValidator from "../common/PasswordValidator";
import { warning } from "../../utils/SnackBar";
import { passwordConditions, updatePasswordFieldConditions } from "../../utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
    header: {
        paddingTop: "5%",
        fontWeight: 600,
    },
    subHeader: {
        fontSize: 20
    },
    root: {
        display: "flex",
        flexDirection: "column",
        paddingBottom: "5%"
    },
    paper: {
      marginTop: theme.spacing(5),
      textAlign: 'center',
      borderRadius: "20px",
      width: "90%",
      margin: "0 auto",
      padding: "5% 5% 0 5%"
    },
    textField: {
        padding: theme.spacing(2)
    },
    profileField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    input: {
        fontSize: 17
    },
    button: {
        borderRadius: "30px",
        maxWidth: "80%",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center"
    },
    select: {
        '&:before': {
            borderColor: "white",
            border: 'none'
        },
        '&:after': {
            borderColor: "white",
            border: 'none'
        },
        '&:hover': {
            borderColor: "white",
            border: 'none'
        },
        color: 'white',
        border: 'none',
        width: "100%",
    },
  }));


const ChangePassword = () => {

    const classes = useStyles();
    const dispatch = useDispatch()

    const loading = useSelector((state) => state.auth.loading)

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [conditions, setConditions] = useState(passwordConditions)

    const handleSetCurrentPassword = (e) => {
        setConditions(updatePasswordFieldConditions(conditions, "CURRENT_PASSWORD", newPassword, confirmNewPassword, e.target.value))
        setCurrentPassword(e.target.value)
    }

    const handleSetNewPassword = (e) => {
        setConditions(updatePasswordFieldConditions(conditions, "NEW_PASSWORD", e.target.value, confirmNewPassword, currentPassword))
        setNewPassword(e.target.value)
    }

    const handleSetConfirmPassword = (e) => {
        setConditions(updatePasswordFieldConditions(conditions, "CONFIRM_PASSWORD", newPassword, e.target.value, currentPassword))
        setConfirmNewPassword(e.target.value)
    }

    const handleChangePassword = (e) => {
        if(Object.values(conditions).every((condition) => condition.state)) {
            dispatch(changePassword(currentPassword, newPassword, confirmNewPassword))
            setCurrentPassword("")
            setNewPassword("")
            setConfirmNewPassword("")
            setConditions(passwordConditions)
        } else {
            warning("All conditions must be met before changing password.")
        }
    }

    return (
        <Grid direction="column" container>
            <Paper className={classes.paper}>
                <Typography align = "center" className = {classes.subHeader} variant="h6" gutterBottom>
                    Change Your Password
                </Typography>
            <Box className={classes.root}>
                <TextField 
                    className = {classes.profileField} 
                    InputLabelProps= {{className: classes.input}}
                    InputProps={{className: classes.input}} 
                    type = "password"
                    variant = "outlined"
                    label="Current Password"
                    value={currentPassword}
                    onChange={(e) => {handleSetCurrentPassword(e)}}
                />
                <TextField 
                    className = {classes.profileField}
                    InputLabelProps= {{className: classes.input}}
                    InputProps={{className: classes.input}}
                    type = "password"
                    variant = "outlined"
                    label="New Password"
                    value={newPassword}
                    onChange={(e) => {handleSetNewPassword(e)}}
                />
                <TextField
                    className = {classes.profileField}
                    InputLabelProps= {{className: classes.input}}
                    InputProps={{className: classes.input}}
                    type = "password"
                    variant = "outlined"
                    label="Confirm New Password"
                    value={confirmNewPassword}
                    onChange={(e) => {handleSetConfirmPassword(e)}}
                />
                {(currentPassword !== "" || newPassword !== "" || confirmNewPassword !== "") && (
                    <PasswordValidator
                        conditions={conditions}
                    />
                )}
                <Box className={classes.buttonContainer}>
                    <PMButton loading={loading} disabled={loading} className={classes.button} type="submit" onClick={handleChangePassword}>
                        Change Password
                    </PMButton>
                </Box>
            </Box>
            </Paper>
        </Grid>
    );
}

export default ChangePassword;