import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@material-ui/core';
import { RESET_LOGOUT } from '../actions/types';

export default function LogoutProvider({ children }){
    const dispatch = useDispatch()
    const logout = useSelector((state) => state.auth.logout)
    let history = useHistory()

    useEffect(() => {
        if(logout === true) {
            dispatch({
                type: RESET_LOGOUT,
                payload: false
            })
            history.push("/login")
        }
    }, [logout])

    return (
        <Box>
            {children}
        </Box>
    )
};