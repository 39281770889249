import React from 'react'
import { useDispatch } from 'react-redux'

import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, List, ListItem, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { setIsReportViewOpen } from '../../actions/forms';

const useStyles = makeStyles({
    iconbutton: {
        color: "#007bff",
        padding: 0,
        minHeight: 0,
        minWidth: 0,
    },
    container: {
        borderRadius: "15px"
    },
    header: {
        alignSelf: "center"
    },
    warningIcon: {
        color: "#FFBF00"
    },
    errorIcon: {
        color: "#E74C3C"
    },
    successIcon: {
        color: "#27AE60"
    }
});

export default function UploadReport({ report }) {
    const dispatch = useDispatch()
    const classes = useStyles();

    return (
        <>
            <Box display="flex" mb={2}>
                <IconButton onClick={() => { dispatch(setIsReportViewOpen(false)) }} color='primary'>
                    <ArrowBackIosIcon />
                </IconButton>
                <Typography variant="h6" className={classes.header}>
                    Upload Report
                </Typography>
            </Box>
            <Box className={classes.container}>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Box width="100%" display="flex" justifyContent="space-between">
                            <Box display="flex">
                                <Box mr={1}>
                                    <ErrorIcon className={classes.errorIcon} />
                                </Box>
                                <Typography className={classes.header}>
                                    Missing Sections
                                </Typography>
                            </Box>
                            <Box>
                                <Tooltip
                                    title="A list of sections in the 2020 Master Template that could not be found in your Word Document"
                                >
                                    <IconButton className={classes.iconbutton}>
                                        <InfoOutlinedIcon className={classes.infoicon} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{ overflow: 'auto', maxHeight: '200px', width: "100%" }}>
                            <List>
                                {report && report.missing_sections.length > 0 ? (
                                    report.missing_sections.map((section, index) => (
                                        <ListItem key={index}>
                                            <Typography>{section}</Typography>
                                        </ListItem>
                                    ))
                                ) : (
                                    <ListItem>
                                        <Typography>There were no missing sections detected while uploading your document.</Typography>
                                    </ListItem>
                                )}
                            </List>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Box width="100%" display="flex" justifyContent="space-between">
                            <Box display="flex">
                                <Box mr={1}>
                                    <WarningIcon className={classes.warningIcon} />
                                </Box>
                                <Typography className={classes.header}>
                                    Unmatched Sections
                                </Typography>
                            </Box>
                            <Box>
                                <Tooltip
                                    title="A list of sections in your Word Document that are not a valid 2020 Master Template sections, and could not be placed"
                                >
                                    <IconButton className={classes.iconbutton}>
                                        <InfoOutlinedIcon className={classes.infoicon} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{ overflow: 'auto', maxHeight: '200px', width: "100%" }}>
                            <List>
                                {report && report.unmatched.length > 0 ? (
                                    report.unmatched.map((section, index) => (
                                        <ListItem key={index}>
                                            <Typography>{section}</Typography>
                                        </ListItem>
                                    ))
                                ) : (
                                    <ListItem>
                                        <Typography>There were no umatched sections detected while uploading your document.</Typography>
                                    </ListItem>
                                )}
                            </List>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Box width="100%" display="flex" justifyContent="space-between">
                            <Box display="flex">
                                <Box mr={1}>
                                    <CheckCircleIcon className={classes.successIcon} />
                                </Box>
                                <Typography className={classes.header}>
                                    Matched Sections
                                </Typography>
                            </Box>
                            <Box>
                                <Tooltip
                                    title="A list of sections in your Word Document that were successfully matched to a 2020 Master Template section"
                                >
                                    <IconButton className={classes.iconbutton}>
                                        <InfoOutlinedIcon className={classes.infoicon} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{ overflow: 'auto', maxHeight: '200px', width: "100%" }}>
                            <List>
                                {report && report.matched.map((section) => {
                                    return (
                                        <ListItem>
                                            <Typography>
                                                {section.section_in_word}
                                            </Typography>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    )
}