import React, { useState } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'

const Dropdown = ({style, name, currentIndex, onOptionClick, setCurrentIndex, list}) => {

    const [menuOpen, setMenuOpen] = useState(false)

    // Handle dropdown blur
    const handleClickOutside = () => {
        setMenuOpen(false)
    }

    // Toggle dropdown view
    const toggleList = () => {
        setMenuOpen(!menuOpen)
    }

    // Handle dropdown item click
    const dropdownItemClick = index  => {
        setCurrentIndex(index);
        onOptionClick && onOptionClick(index, name);
        toggleList();
    }

    const getDropdownText = (index) => {
        return (list[index] && list[index].brandName !== "") ? `Product ${index + 1} - ${list[index].brandName}` : `Product ${index + 1}`
    }

    return (
        <div style={style} tabIndex="0" onBlur={() => handleClickOutside()} className="dd-wrapper">
            <div className="dd-header" onClick={() => toggleList()}>
                <div className="dd-header-title">
                    {getDropdownText(currentIndex)}
                </div>
                {menuOpen
                    ? <FontAwesome name="angle-up" size="2x"/>
                    : <FontAwesome name="angle-down" size="2x"/>
                }
            </div>
            {menuOpen && <ul className="dd-list">
                {list.map((item, index) => (
                    <li key ={index} onClick = {() => {dropdownItemClick(index)}} className="dd-list-item" >{getDropdownText(index)}</li>
                ))}
            </ul>}
        </div>
    )
}

export default connect(null, null)(Dropdown)