import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider, withSnackbar } from 'notistack';

const toast = (msg, variant) => {
    const Display = withSnackbar(({ message, enqueueSnackbar }) => {
        enqueueSnackbar(message, { variant });
        return null;
    });
    const mountPoint = document.getElementById('snackbarhelper');
    ReactDOM.render(
        <SnackbarProvider maxSnack={3} anchorOrigin={{horizontal: 'right', vertical: 'top'}}>
            <Display message={msg} variant={variant} />
        </SnackbarProvider>,
        mountPoint
    )
}

export const success = (message) => {
    toast(message, 'success');  
}

export const warning = (message) => {
    toast(message, 'warning');
}

export const info = (message) => {
    toast(message, 'info');
}

export const error = (message) => {
    toast(message, 'error');  
}
