import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { mfa } from '../../actions/auth'
import PMButton from '../common/Button'
import OtpInput from 'react-otp-input';

import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    button: {
        width: "100%"
    },
    textField: {
        width: "100%"
    },
    formLabel: {
        float: "left",
        margin: "2% 0% 2% 0",
        fontSize: 18
    },
    forgotPassword: {
        '&:hover': {
            color: "#007bff",
            cursor: "pointer",
            textDecoration: "underline",
        },
    },
    formHeader: {
        float: "left",
        fontWeight: 800,
        fontSize: 36,
        fontStyle: "normal",
        marginBottom: "1%",
    },
}));


export default function MFA({email}) {
    const dispatch = useDispatch()
    const loading = useSelector((state) => state.auth.loading)
    const classes = useStyles()

    const [code, setCode] = useState()

    const onSubmit = async e => {
        e.preventDefault();
        dispatch(mfa( email, code))
    }


    return(
        <Box>
            <form>
                <Box display="flex" flexDirection="column">
                    <Typography className ={classes.formHeader}>
                        Two Factor Authentication
                    </Typography>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box>
                                <Typography className ={classes.formLabel}>
                                    Enter the SMS code sent to your phone
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box margin="0 auto">
                                <OtpInput
                                    inputStyle="OTP-inputStyle"
                                    errorStyle="OTP-error"
                                    value={code}
                                    onChange = {(otp) => setCode(otp)}
                                    numInputs={6}
                                    separator={<span></span>}
                                    isInputNum={true}
                                    className={classes.textField}
                                    shouldAutoFocus
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={3}>
                    <PMButton
                        type="submit"
                        loading={loading}
                        disabled={loading}
                        onClick={(e) => {onSubmit(e)}}
                        className={classes.button}
                    >
                        Login
                    </PMButton>
                </Box>
            </form>
        </Box>
    )
}
