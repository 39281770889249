import { SECTION_INDEXES } from "./SectionIndexes";

export const FormSections = [
    {
        englishName: 'Title Page',
        frenchName: 'Page - Titre',
        index: SECTION_INDEXES.TITLE_PAGE,
        children: []
    },
    {
        englishName: 'Product Details',
        frenchName: 'Détails Du Produit',
        index: SECTION_INDEXES.PRODUCT_DETAILS,
        children: [
            {
                englishName: 'Company Details',
                frenchName: "Détails De L'enterprise",
                index: SECTION_INDEXES.COMPANY_DETAILS,
                children: []
            },
            {
                englishName: 'Products',
                frenchName: 'Produits',
                index: SECTION_INDEXES.PRODUCTS,
                children: []
            }
        ]
    },
    {
        englishName: 'Recent Major Label Changes',
        frenchName: 'Modifications Importantes Apportées Récemment À La Monographie',
        index: SECTION_INDEXES.RECENT_MAJOR_LABEL_CHANGES,
        children: []
    },
    {
        englishName: 'Part I: Health Professional Information',
        frenchName: 'Partie I: Renseignements Pour Le Professionnel De La Santé',
        index: SECTION_INDEXES.PART_ONE,
        children: [
            {
                englishName: '1 Indications',
                frenchName: '1 Indications',
                index: SECTION_INDEXES.INDICATIONS,
                children: [
                    {
                        englishName: '1.1 Pediatrics',
                        frenchName: '1.1 Pédiatrie',
                        index: SECTION_INDEXES.PEDIATRICS,
                        children: []
                    },
                    {
                        englishName: '1.2 Geriatrics',
                        frenchName: '1.2 Gériatrie',
                        index: SECTION_INDEXES.GERIATRICS,
                        children: []
                    }
                ]
            },
            {
                englishName: '2 Contraindications',
                frenchName: '2 Contre-Indications',
                index: SECTION_INDEXES.CONTRAINDICATIONS,
                children: []
            },
            {
                englishName: '3 Serious Warnings And Precautions Box',
                frenchName: '3 Encadré Mises En Garde Et Précautions Importantes',
                index: SECTION_INDEXES.SERIOUS_WARNINGS_AND_PRECAUTIONS_BOX,
                children: []
            },
            {
                englishName: '4 Dosage And Administration',
                frenchName: '4 Posologie Et Administration',
                index: SECTION_INDEXES.DOSAGE_AND_ADMINISTRATION,
                children: [
                    {
                        englishName: '4.1 Dosing Considerations',
                        frenchName: '4.1 Considérations Posologiques',
                        index: SECTION_INDEXES.DOSING_CONSIDERATIONS,
                        children: []
                    },
                    {
                        englishName: '4.2 Recommended Dose And Dosage Adjustments',
                        frenchName: '4.2 Posologie Recommandée Et Ajustement Posologique',
                        index: SECTION_INDEXES.RECOMMENDED_DOSE_AND_DOSAGE_ADJUSTMENTS,
                        children: []
                    },
                    {
                        englishName: '4.3 Reconstitution',
                        frenchName: '4.3 Reconstitution',
                        index: SECTION_INDEXES.RECONSTITUITION,
                        children: []
                    },
                    {
                        englishName: '4.4 Administration',
                        frenchName: '4.4 Administration',
                        index: SECTION_INDEXES.ADMINISTRATION,
                        children: []
                    },
                    {
                        englishName: '4.5 Missed Dose',
                        frenchName: '4.5 Dose Oubliée',
                        index: SECTION_INDEXES.MISSED_DOSE,
                        children: []
                    },
                    {
                        englishName: '4.6 Image Aquisition And Interpretation',
                        frenchName: "4.6 Acquisition Et Interpretation D'image",
                        index: SECTION_INDEXES.IMAGE_ACQUISITION_AND_INTERPRETATION,
                        children: []
                    },
                    {
                        englishName: '4.7 Instructions For Preparation And Use',
                        frenchName: "4.7 Instructions Pour La Préparation Et L'utilisation",
                        index: SECTION_INDEXES.INSTRUCTIONS_FOR_PREPARATION_AND_USE,
                        children: []
                    },
                    {
                        englishName: '4.8 Radiation Dosimetry',
                        frenchName: '4.8 Dosimétrie Des Rayonnements',
                        index: SECTION_INDEXES.RADIATION_DOSIMETRY,
                        children: []
                    }
                ]
            },
            {
                englishName: '5 Overdosage',
                frenchName: '5 Surdosage',
                index: SECTION_INDEXES.OVERDOSAGE,
                children: []
            },
            {
                englishName: '6 Dosage Forms, Strengths, Composition, And Packaging',
                frenchName: '6 Formes Pharmaceutiques, Teneurs, Composition Et Conditionnement',
                index: SECTION_INDEXES.DOSAGE_FORMS_STRENGTHS_COMPOSITION_AND_PACKAGING,
                children: [
                    {
                        englishName: '6.1 Physical Characteristics',
                        frenchName: '6.1 Caractéristiques Physiques',
                        index: SECTION_INDEXES.PHYSICAL_CHARACTERISTICS,
                        children: []
                    },
                    {
                        englishName: '6.2 External Radiation',
                        frenchName: '6.2 Irradiation Externe',
                        index: SECTION_INDEXES.EXTERNAL_RADIATION,
                        children: []
                    }
                ]
            },
            {
                englishName: '7 Warnings And Precautions',
                frenchName: '7 Mises En Garde Et Précautions',
                index: SECTION_INDEXES.WARNINGS_AND_PRECAUTIONS,
                children: [
                    {
                        englishName: '7.1 Special Populations',
                        frenchName: '7.1 Populations Particulières',
                        index: SECTION_INDEXES.SPECIAL_POPULATIONS,
                        children: [
                            {
                                englishName: '7.1.1 Pregnant Women',
                                frenchName: '7.1.1 Femmes Enceintes',
                                index: SECTION_INDEXES.PREGNANT_WOMEN,
                                children: []
                            },
                            {
                                englishName: '7.1.2 Breast-Feeding',
                                frenchName: '7.1.2 Femmes Qui Allaitent',
                                index: SECTION_INDEXES.BREAST_FEEDING,
                                children: []
                            },
                            {
                                englishName: '7.1.3 Pediatrics',
                                frenchName: '7.1.3 Enfants Et Adolescents',
                                index: SECTION_INDEXES.PEDIATRICS_WARNINGS_AND_PRECAUTIONS,
                                children: []
                            },
                            {
                                englishName: '7.1.4 Geriatrics',
                                frenchName: '7.1.4 Personnes Âgées',
                                index: SECTION_INDEXES.GERIATRICS_WARNINGS_AND_PRECAUTIONS,
                                children: []
                            }
                        ]
                    }
                ]
            },
            {
                englishName: '8 Adverse Reactions',
                frenchName: '8 Effets Indésirables',
                index: SECTION_INDEXES.ADVERSE_REACTIONS,
                children: [
                    {
                        englishName: '8.1 Adverse Reaction Overview',
                        frenchName: '8.1 Aperçu Des Effets Indésirables',
                        index: SECTION_INDEXES.ADVERSE_REACTIONS_OVERVIEW,
                        children: []
                    },
                    {
                        englishName: '8.2 Clinical Trial Adverse Reactions',
                        frenchName: '8.2 Effets Indésirables Observés Au Cours Des Études Cliniques',
                        index: SECTION_INDEXES.CLINICAL_TRIAL_ADVERSE_REACTIONS,
                        children: [
                            {
                                englishName: '8.2.1 Clinical Trial Adverse Reactions (Pediatrics)',
                                frenchName: '8.2.1 Effets Indésirables Observés Au Cours Des Études Cliniques – Enfants Et Adolescents',
                                index: SECTION_INDEXES.CLINICAL_TRIAL_ADVERSE_REACTIONS_PEDIATRICS,
                                children: []
                            }
                        ]
                    },
                    {
                        englishName: '8.3 Less Common Clinical Trial Adverse Reactions',
                        frenchName: '8.3 Effets Indésirables Peu Fréquents Observés Au Cours Des Études Cliniques',
                        index: SECTION_INDEXES.LESS_COMMON_CLINICAL_TRIAL_ADVERSE_REACTIONS,
                        children: [
                            {
                                englishName: '8.3.1 Less Common Clinical Trial Adverse Reactions (Pediatrics)',
                                frenchName: '8.3.1 Effets Indésirables Peu Fréquents Observés Au Cours Des Études Cliniques – Enfants Et Adolescents',
                                index: SECTION_INDEXES.LESS_COMMON_CLINICAL_TRIAL_ADVERSE_REACTIONS_PEDIATRICS,
                                children: []
                            }
                        ]
                    },
                    {
                        englishName: '8.4 Abnormal Laboratory Findings: Hematologic, Clinical Chemistry And Other Quantitative Data',
                        frenchName: '8.4 Résultats Anormaux Aux Examens De Laboratoire : Données Hématologiques, Données Biochimiques Et Autres Données Quantitatives',
                        index: SECTION_INDEXES.ABNORMAL_LABORATORY_FINDINGS_HEMATOLOGIC,
                        children: []
                    },
                    {
                        englishName: '8.5 Post-Market Adverse Reactions',
                        frenchName: '8.5 Effets Indésirables Observés Après La Commercialisation',
                        index: SECTION_INDEXES.POST_MARKET_ADVERSE_REACTIONS,
                        children: []
                    }
                ]
            },
            {
                englishName: '9 Drug Interactions',
                frenchName: '9 Interactions Médicamenteuses',
                index: SECTION_INDEXES.DRUG_INTERACTIONS,
                children: [
                    {
                        englishName: '9.1 Serious Drug Interactions',
                        frenchName: '9.1 Interactions Médicamenteuses Graves',
                        index: SECTION_INDEXES.SERIOUS_DRUG_INTERACTIONS,
                        children: []
                    },
                    {
                        englishName: '9.2 Drug Interactions Overview',
                        frenchName: '9.2 Aperçu Des Interactions Médicamenteuses',
                        index: SECTION_INDEXES.DRUG_INTERACTIONS_OVERVIEW,
                        children: []
                    },
                    {
                        englishName: '9.3 Drug-Behavioural Interactions',
                        frenchName: '9.3 Interactions Médicament-Comportement',
                        index: SECTION_INDEXES.DRUG_BEHAVIOURAL_INTERACTIONS,
                        children: []
                    },
                    {
                        englishName: '9.4 Drug-Drug Interactions',
                        frenchName: '9.4 Interactions Médicament-Médicament',
                        index: SECTION_INDEXES.DRUG_DRUG_INTERACTIONS,
                        children: []
                    },
                    {
                        englishName: '9.5 Drug-Food Interactions',
                        frenchName: '9.5 Interactions Médicament-Aliment',
                        index: SECTION_INDEXES.DRUG_FOOD_INTERACTIONS,
                        children: []
                    },
                    {
                        englishName: '9.6 Drug-Herb Interactions',
                        frenchName: '9.6 Interactions Médicament-Plante Médicinale',
                        index: SECTION_INDEXES.DRUG_HERB_INTERACTIONS,
                        children: []
                    },
                    {
                        englishName: '9.7 Drug-Laboratory Test Interactions',
                        frenchName: '9.7 Interactions Médicament-Examens De Laboratoire',
                        index: SECTION_INDEXES.DRUG_LABORATORY_TEST_INTERACTIONS,
                        children: []
                    }
                ]
            },
            {
                englishName: '10 Clinical Pharmacology',
                frenchName: '10 Pharmacologie Clinique',
                index: SECTION_INDEXES.ACTION_AND_CLINICAL_PHARMACOLOGY,
                children: [
                    {
                        englishName: '10.1 Mechanism Of Action',
                        frenchName: "10.1 Mode D'action",
                        index: SECTION_INDEXES.MECHANISM_OF_ACTION,
                        children: []
                    },
                    {
                        englishName: '10.2 Pharmacodynamics',
                        frenchName: '10.2 Pharmacodynamie',
                        index: SECTION_INDEXES.PHARMACODYNAMICS,
                        children: []
                    },
                    {
                        englishName: '10.3 Pharmacokinetics',
                        frenchName: '10.3 Pharmacocinétique',
                        index: SECTION_INDEXES.PHARMACOKINETICS,
                        children: []
                    }
                ]
            },
            {
                englishName: '11 Storage, Stability, And Disposal',
                frenchName: '11 Conservation, Stabilité Et Mise Au Rebuts',
                index: SECTION_INDEXES.STORAGE_STABILITY_AND_DISPOSAL,
                children: []
            },
            {
                englishName: '12 Special Handling Instructions',
                frenchName: '12 Particularités De Manipulation Du Produit',
                index: SECTION_INDEXES.SPECIAL_HANDLING_INSTRUCTIONS,
                children: []
            }
        ]
    },
    {
        englishName: 'Part II: Scientific Information',
        frenchName: 'Partie II: Renseignements Scientifiques',
        index: SECTION_INDEXES.PART_TWO,
        children: [
            {
                englishName: '13 Pharmaceutical Information',
                frenchName: '13 Information Pharmaceutiques',
                index: SECTION_INDEXES.PHARMACEUTICAL_INFORMATION,
                children: []
            },
            {
                englishName: '14 Clinical Trials',
                frenchName: '14 Études Cliniques',
                index: SECTION_INDEXES.CLINICAL_TRIALS,
                children: [
                    {
                        englishName: '14.1 Clinical Trials By Indication',
                        frenchName: '14.1 Études Cliniques Par Indication',
                        index: SECTION_INDEXES.CLINICAL_TRIALS_BY_INDICATIONS,
                        children: []
                    },
                    {
                        englishName: '14.2 Comparative Bioavailabilty Studies',
                        frenchName: '14.2 Études De Biodisponibilité Comparatives',
                        index: SECTION_INDEXES.COMPARATIVE_BIOAVAILABILTY_STUDIES,
                        children: []
                    },
                    {
                        englishName: '14.3 Immunogenicity',
                        frenchName: '14.3 Immunogénicité',
                        index: SECTION_INDEXES.IMMUNOGENICITY,
                        children: []
                    },
                    {
                        englishName: '14.4 Clinical Trials - Reference Biologic Drug',
                        frenchName: '14.4 Études Cliniques - Médicament Biologique De Référence',
                        index: SECTION_INDEXES.CLINICAL_TRIALS_REFERENCE_BIOLOGIC_DRUG,
                        children: []
                    }
                ]
            },
            {
                englishName: '15 Microbiology',
                frenchName: '15 Microbiologie',
                index: SECTION_INDEXES.MICROBIOLOGY,
                children: []
            },
            {
                englishName: '16 Non-Clinical Toxicology',
                frenchName: '16 Toxicologie Non Clinique',
                index: SECTION_INDEXES.NON_CLINICAL_TOXICOLOGY,
                children: [
                    {
                        englishName: '16.1 Comparative Non-Clinical Pharmacology And Toxicology',
                        frenchName: '16.1 Pharmacologie Et Toxicologie Non Cliniques Comparatives',
                        index: SECTION_INDEXES.COMPARATIVE_NON_CLINICAL_PHARMACOLOGY_AND_TOXICOLOGY,
                        children: [
                            {
                                englishName: '16.1.1 Comparative Non-Clinical Pharmacodynamics',
                                frenchName: '16.1.1 Pharmacodynamie Non Clinique Comparative',
                                index: SECTION_INDEXES.COMPARATIVE_NON_CLINICAL_PHARMACODYNAMICS,
                                children: []
                            },
                            {
                                englishName: '16.1.2 Comparative Toxicology',
                                frenchName: '16.1.2 Toxicologie Comparative',
                                index: SECTION_INDEXES.COMPARATIVE_TOXICOLOGY,
                                children: []
                            }
                        ]
                    }
                ]
            },
            {
                englishName: '17 Supporting Product Monographs',
                frenchName: '17 Monographies De Référence',
                index: SECTION_INDEXES.SUPPORTING_PRODUCT_MONOGRAPHS,
                children: []
            }
        ]
    },
    {
        englishName: 'Patient Medical Information',
        frenchName: 'Renseignements Destinés Aux Patient·E·S',
        index: SECTION_INDEXES.PART_THREE,
        children: [
            {
                englishName: 'Brand Name',
                frenchName: 'Marque Nominative',
                index: SECTION_INDEXES.BRAND_NAME,
                children: []
            },
            {
                englishName: 'Proper Name',
                frenchName: 'Nom Propre',
                index: SECTION_INDEXES.PROPER_NAME,
                children: []
            },
            {
                englishName: 'Read This For Safe And Effective Use Of Your Medicine',
                frenchName: 'Lisez Ce Document Pour Une Utilisation Sécuritaire Et Efficace De Votre Médicament',
                index: SECTION_INDEXES.READ_THIS_FOR_SAFE_AND_EFFECTIVE_USE_OF_YOUR_MEDICINE,
                children: []
            },
            {
                englishName: 'What Is [Brand Name] Used For?',
                frenchName: 'Pourquoi Utilise-T-On [Nom Commercial]?',
                index: SECTION_INDEXES.WHAT_IS_BRAND_NAME_USED_FOR,
                children: []
            },
            {
                englishName: 'How Does [Brand Name] Work?',
                frenchName: "Comment [Nom Commercial] Agit-Il?'",
                index: SECTION_INDEXES.HOW_DOES_BRAND_NAME_WORK,
                children: []
            },
            {
                englishName: 'What Are The Ingredients In [Brand Name]?',
                frenchName: 'Quels Sont Les Ingrédients De [Nom Commercial]?',
                index: SECTION_INDEXES.WHAT_ARE_THE_INGREDIENTS_IN_BRAND_NAME,
                children: []
            },
            {
                englishName: '[Brand Name] Comes In The Following Dosage Forms:',
                frenchName: '[Nom Commercial] Se Présente Sous La Ou Les Formes Pharmaceutiques Suivantes :',
                index: SECTION_INDEXES.BRAND_NAME_DOSAGE_FORMS,
                children: []
            },
            {
                englishName: 'Do Not Use [Brand Name] If:',
                frenchName: "N'utilisez Pas [Nom Commercial] Dans Les Cas Suivants :",
                index: SECTION_INDEXES.DO_NOT_USE_BRAND_NAME_IF,
                children: []
            },
            {
                englishName: 'To Help Avoid Side Effects And Ensure Proper Use, Talk To Your Healthcare Professional Before You Take [Brand Name]. Talk About Any Health Conditions Or Problems You May Have, Including If You:',
                frenchName: "Consultez Votre Professionnel De La Santé Avant D'utiliser [Nom Commercial], Afin D'assurer L'utilisation Adéquate Du Médicament Et D'aider À Éviter Les Effets Secondaires. Informez Votre Professionnel De La Santé De Votre État Actuel Et De Vos Problèmes De Santé, Notamment :",
                index: SECTION_INDEXES.HEALTH_CONDITIONS_OR_PROBLEMS,
                children: []
            },
            {
                englishName: 'Other Warnings You Should Know About:',
                frenchName: 'Autres Mises En Garde',
                index: SECTION_INDEXES.OTHER_WARNINGS_YOU_SHOULD_KNOW_ABOUT,
                children: []
            },
            {
                englishName: 'The Following May Interact With [Brand Name]:',
                frenchName: 'Les Produits Suivants Pourraient Interagir Avec [Nom Commercial] :',
                index: SECTION_INDEXES.THE_FOLLOWING_MAY_INTERACT_WITH_BRAND_NAME,
                children: []
            },
            {
                englishName: 'How To Take [Brand Name]:',
                frenchName: 'Comment Utiliser [Nom Commercial]?',
                index: SECTION_INDEXES.HOW_TO_TAKE_BRAND_NAME,
                children: []
            },
            {
                englishName: 'Usual Dose:',
                frenchName: 'Dose Habituelle :',
                index: SECTION_INDEXES.USUAL_DOSE,
                children: []
            },
            {
                englishName: 'Overdose:',
                frenchName: 'Surdose :',
                index: SECTION_INDEXES.OVER_DOSE,
                children: []
            },
            {
                englishName: 'Missed Dose:',
                frenchName: 'Dose Oubliée :',
                index: SECTION_INDEXES.MISSED_DOSE_PMI,
                children: []
            },
            {
                englishName: 'What Are Possible Side Effects From Using [Brand Name]?',
                frenchName: 'Quels Sont Les Effets Secondaires Qui Pourraient Être Associés À [Nom Commercial]?',
                index: SECTION_INDEXES.POSSIBLE_SIDE_EFFECTS,
                children: []
            },
            {
                englishName: 'Storage:',
                frenchName: 'Conservation',
                index: SECTION_INDEXES.STORAGE,
                children: []
            },
            {
                englishName: 'If You Want More Information About [Brand Name]:',
                frenchName: 'Pour En Savoir Plus Sur [Nom Commercial] :',
                index: SECTION_INDEXES.MORE_INFORMATION,
                children: []
            }
        ]
    },
    {
        englishName: 'Biosimilar Biologic Drug',
        frenchName: 'Médicaments Biologiques Biosimilaires',
        index: SECTION_INDEXES.BIOSIMILAR_BIOLOGIC_DRUG,
        children: []
    },
    {
        englishName: 'Footnotes',
        frenchName: 'Notes De Bas De Page',
        index: SECTION_INDEXES.FOOTNOTES,
        children: []
    }
]

const getNumericPrefixMatch = (name) => {
    const numericPartMatch = name.match(/^\d+(\.\d+)*/);
    return numericPartMatch ? numericPartMatch[0] : null;
};

const normalizeSectionName = (name) => {
    return name.toLowerCase().replace(/[^\w\d\s]/g, '').replace(/ +/g, '-');
};

export const getSectionUrlName = (sectionName) => {
    const numericPart = getNumericPrefixMatch(sectionName);
    return numericPart ? numericPart : normalizeSectionName(sectionName);
};