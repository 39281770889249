import React, { Fragment, useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TextField from '../formComponents/TextField'
import TextFieldArray from '../formComponents/TextFieldArray'
import {englishLabels, frenchLabels} from "../../formLabels/ProductDetailsFormLabels"
import { makeStyles} from '@material-ui/core/styles';

import { Box, Grid, Paper} from '@material-ui/core';
import PMButton from "../../common/Button";
import AutocompleteField from '../formComponents/AutoComplete'
import { englishTooltipLabels, frenchTooltipLabels } from "../../tooltipLabels/ProductDetailsTooltipLabels"
import TooltipComponent from '../formComponents/TooltipComponent'

import { contactInfoModel } from '../../../models/Form'
import { updateFormField } from '../../../actions/forms'
import { info } from '../../../utils/SnackBar'
import _ from "lodash"

const useStyles = makeStyles({
    veritcalText: {
        alignSelf: "center"
    },
    paper: {
        padding: "2rem",
        margin: "2rem 0rem",
        borderRadius: "25px"
    }
});

export default function CompanyDetails({ context }){

    const dispatch = useDispatch()
    const form = useSelector((state) => state.forms.form)
    const updating = useSelector((state) => state.forms.updating)
    const classes = useStyles()
    const [labels, setLabels] = useState({});
    const [tooltipLabels, setTooltipLabels] = useState({});
    let formInput = context ? form && form.formDataFrench : form && form.formDataEnglish 

    useEffect(() => {
        setLabels(context ? frenchLabels : englishLabels)
        setTooltipLabels(context ? frenchTooltipLabels : englishTooltipLabels)
    }, [context])

    const handleCreateFormLevelListObj = (name, freeForm = false) => {
        formInput[name].push(contactInfoModel(freeForm))
        dispatch(updateFormField(name, formInput[name], form._id, context))
    }

    const handleDeleteFormLevelListObj = (name, index, freeForm = false) => {
        formInput[name].pop(index)
        if (name === "titlePageCompanyInformations" && formInput[name].length === 0) {
            info("Product Monograph must have at least one company contact")
            formInput[name].push(contactInfoModel(freeForm))
        }
        dispatch(updateFormField(name, formInput[name], form._id, context))
    }

    const handleUpdateField = (name, content) => {
        _.set(formInput, name, content)
        dispatch(updateFormField(name, content, form._id, context))
    }

    return(
        <Fragment>
            <Paper className={classes.paper} elevation={4}>
                <h6>
                    <Box>
                        {labels.marketAuthorizationHolder} 
                        <TooltipComponent label={tooltipLabels.marketAuthorizationHolder} />
                    </Box>
                </h6>
                <AutocompleteField 
                    codeSystem="2.16.840.1.113883.2.20.6.31" 
                    context={context} 
                    updateFunction = {handleUpdateField}
                    label = {labels.name} 
                    placeholder = {labels.name} 
                    name = {"marketAuthorizationHolderName"}
                />
                <TextField 
                    updateFunction = {handleUpdateField} 
                    label = {labels.streetAddress} 
                    placeholder = {labels.streetAddress} 
                    name = {"marketAuthorizationHolderStreetName"}
                />
                <TextFieldArray 
                    context={context} 
                    updateFunction = {handleUpdateField} 
                    columnStyling = {3} 
                    label = {[labels.city, labels.state, labels.postalCode, labels.country]} 
                    placeholder = {[labels.city, labels.state, labels.postalCode, labels.country]} 
                    fieldTypes={[0, 0, 0, 1]} 
                    codeSystems={[null,null,null, "2.16.840.1.113883.2.20.6.17"]} 
                    name = {['marketAuthorizationHolderCity', 'marketAuthorizationHolderState', 'marketAuthorizationHolderPostalCode', 'marketAuthorizationHolderCountry']}
                />
                <TextFieldArray 
                    updateFunction = {handleUpdateField} 
                    columnStyling = {4} 
                    label={[
                        <Box>
                            {labels.telephone}
                            <TooltipComponent label={tooltipLabels.telephone} />
                        </Box>,
                        <Box>
                            {labels.email}
                            <TooltipComponent label={tooltipLabels.email} />
                        </Box>,
                        <Box>
                            {labels.website}
                            <TooltipComponent label={tooltipLabels.website} />
                        </Box>
                    ]}
                    placeholder = {[labels.telephone, labels.email, labels.website]} 
                    name = {['marketAuthorizationHolderTelephone', 'marketAuthorizationHolderEmail', 'marketAuthorizationHolderWebsite']}
                />
            </Paper>
            <Paper className={classes.paper} elevation={4}>
                <Grid container>
                    <Grid xs={10} item className={classes.veritcalText}>
                        <Box width="100%" alignSelf="center">
                            <h6>
                                <Box>
                                    {labels.canadianImporterDistributor}
                                    <TooltipComponent label={tooltipLabels.canadianImporterDistributor} />
                                </Box>
                            </h6>
                        </Box>
                    </Grid>
                    <Grid xs={2} item>
                        <Box display="flex">
                            <Box width="100%" mr={2}>
                                <PMButton isFormButton={true} disabled={updating} onClick={()=>{handleCreateFormLevelListObj("importerDistributors")}}>
                                    Add
                                </PMButton>
                            </Box>
                            <Box width="100%">
                                <PMButton isFormButton={true} disabled={updating} onClick={()=>{handleDeleteFormLevelListObj("importerDistributors", formInput.importerDistributors.length - 1)}} red>
                                    Delete
                                </PMButton>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                {formInput.importerDistributors.map((importerDistributor, index) => {
                    return (
                        <Paper className={classes.paper} elevation={4}>
                            <AutocompleteField 
                                codeSystem="2.16.840.1.113883.2.20.6.31" 
                                context={context} 
                                updateFunction = {handleUpdateField}  
                                label = {labels.name} 
                                placeholder = {labels.name} 
                                name = {`importerDistributors[${index}].name`}
                            />
                            <TextField 
                                updateFunction = {handleUpdateField}  
                                label = {labels.streetAddress} 
                                placeholder = {labels.streetAddress} 
                                name = {`importerDistributors[${index}].streetName`}
                            />
                            <TextFieldArray 
                                context={context} 
                                updateFunction = {handleUpdateField}  
                                inputProps={ [{},{},{pattern: "[A-Za-z][0-9][A-Za-z][ -]?[0-9][A-Za-z][0-9]"},{}]} 
                                columnStyling = {3} 
                                label = {[labels.city, labels.province, labels.postalCode, labels.country]} 
                                placeholder = {[labels.city, labels.province, labels.postalCode, labels.country]} 
                                fieldTypes={[0, 2, 0, 1]} 
                                codeSystems={[null,null,null, "2.16.840.1.113883.2.20.6.17"]} 
                                name = {[`importerDistributors[${index}].city`, `importerDistributors[${index}].state`, `importerDistributors[${index}].postalCode`, `importerDistributors[${index}].country`]}
                            />
                            <TextFieldArray 
                                updateFunction = {handleUpdateField}  
                                columnStyling = {4} 
                                label={[
                                    <Box>
                                        {labels.telephone}
                                        <TooltipComponent label={tooltipLabels.telephone}/>
                                    </Box>,
                                    <Box>
                                        {labels.email}
                                        <TooltipComponent label={tooltipLabels.email}/>
                                    </Box>,
                                    <Box>
                                        {labels.website}
                                        <TooltipComponent label={tooltipLabels.website}/>
                                    </Box>
                                ]}
                                placeholder = {[labels.telephone, labels.email, labels.website]} 
                                name = {[`importerDistributors[${index}].telephone`, `importerDistributors[${index}].email`, `importerDistributors[${index}].website`]}
                            />
                        </Paper>
                    )
                })}
            </Paper>
        </Fragment>
    )
}
