import React, { Fragment, useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate';
import { getForms, createForm } from '../../actions/forms'
import { Box, Grid, Typography, IconButton, TextField, InputAdornment } from '@material-ui/core';
import PMButton from '../common/Button'
import CancelIcon from '@material-ui/icons/Cancel';
import debounce from 'lodash.debounce';


import FormTable from "./FormTable"
import CreateForm from './CreateForm'


const Dashboard = ({ createForm, getForms, forms: { creationID, creationLoading, forms, formCount, loading, error } }) => {

    const [redirect, setRedirect] = useState(0)
    const [createPopupOpen, setCreatePopupOpen] = useState(false)
    const [createError, setCreateError] = useState("")
    const [page, setPage] = useState(1)
    const [orderBy, setOrderBy] = useState("lastModifiedDate")
    const [order, setOrder] = useState("-1")
    const [searchTerm, setSearchTerm] = useState('');
    const totalPages = Math.ceil(formCount / 8)

    const [searchLoading, setsearchLoading] = useState(false)

    useEffect(() => {
        document.title = "Dashboard"
    }, [])

    useEffect(() => {
        getForms(page, orderBy, order)
    }, [getForms, page, order, orderBy]);

    const handlePageSelection = (data) => {
        setPage(data.selected + 1)
    }

    const createNewForm = async (name) => {
        const creationStatus = await createForm(name)

        if (creationStatus === false) {
            setCreateError("A Product Monograph with that name already exists!")
            return;
        }
        setCreateError("")
        setCreatePopupOpen(false)
        setTimeout(() => { setRedirect(true) }, 1000)
    }

    const handleClose = () => {
        setCreateError("")
        setCreatePopupOpen(false)
    }

    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setsearchLoading(true);
                await getForms(page, orderBy, order, searchTerm);
            } catch (error) {
                setCreateError('Error fetching data');
            } finally {
                setsearchLoading(false);
            }
        };

        const debouncedFetchData = debounce(fetchData, 300); 
        debouncedFetchData();
        return () => {
            debouncedFetchData.cancel();
        };
    }, [page, orderBy, order, searchTerm, getForms]);


    return loading ? (
        <>
        </>
    ) : (
        redirect ? (
            <Redirect to={`/form/${creationID}?section=title-page&isEnglish=true`} />
        ) : (
            <Fragment>
                <Grid container alignItems="center" justifyContent='space-between'>
                    <Grid item xs={4}>
                        <Typography variant='h4'>
                            Product Monographs
                        </Typography>
                    </Grid>

                    <Grid item xs={4} container justifyContent="flex-end">
                        <Box display="flex" alignItems="center">
                            <PMButton onClick={() => { setCreatePopupOpen(true); }} variant="contained" height="100%">
                                Create New
                            </PMButton>
                            <CreateForm loading={creationLoading} open={createPopupOpen} handleFormCreate={createNewForm} handleClose={handleClose} createError={createError} setCreateError={setCreateError} />

                        </Box>
                    </Grid>
                </Grid>
                <Grid container xs={3} style={{ marginTop: '10px', marginBottom: '10px' }}>

                    <div style={{ width: 400, display: 'inline-block' }}>
                        <TextField
                            id="search-bar"
                            className="text"
                            onInput={handleSearchChange}
                            placeholder="Search..."
                            size="small"
                            value={searchTerm}
                            fullWidth={true}
                            style={{ backgroundColor: 'white', border: '3px solid #007BFF', paddingTop: '5px', paddingLeft: '10px', paddingRight: '5px', borderRadius: '22px', maxWidth: '100%' }}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: searchTerm ? (
                                    <InputAdornment disableTypography position="end">
                                        <IconButton onClick={() => { setSearchTerm(''); getForms(page, orderBy, order) }} style={{ borderRadius: '0 20px 20px 0', paddingRight: 0 }} type="submit" aria-label="search">

                                            <CancelIcon style={{ fill: '#007BFF', fontSize: '24px', paddingBottom: '4px' }} />
                                        </IconButton>
                                    </InputAdornment>
                                ) : (null)
                            }}
                        />
                    </div>

                </Grid>

                <FormTable
                    searchLoading={searchLoading}
                    forms={forms}
                    loading={loading}
                    order={order}
                    setOrder={setOrder}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                />
                <div className='pagination'>
                    <ReactPaginate
                        previousLabel={'<<'}
                        nextLabel={'>>'}
                        breakLabel={'...'}
                        pageCount={totalPages}
                        forcePage={page - 1}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageSelection}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        containerClassName={'pagination'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </div>
            </Fragment>
        )
    )
}

Dashboard.propTypes = {
    getForms: PropTypes.func.isRequired,
    forms: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    forms: state.forms,
    error: state.error
})

export default connect(mapStateToProps, { getForms, createForm })(Dashboard)