import { Box } from '@material-ui/core';

export const englishTooltipLabels = {
    pharmaceuticalInformation:
        <Box>
            <b>Drug substance</b>
            <br />
            Proper name: [text]
            <br />
            Chemical name: [text]
            <br />
            Molecular formula and molecular mass: [text]
            <br />
            Structural formula: [image]
            <br />
            Physicochemical properties: [text]
            <br />
            Pharmaceutical standard: [for biologics] [text]
            <br />
            <br />
            <b>Product characteristics</b>
            <br />
            [text]
            <br />
            For radiopharmaceuticals, provide detailed information or a lengthier description of product characteristics that are in addition to those mentioned under 6.1 Physical characteristics.
            <br />
            <br />
            For biologics, this subsection should describe the method of manufacture. Sponsors are not expected to supply proprietary information, but they must provide enough detail to provide health professionals with an understanding of how the product is prepared.
            <br />
            <br />
            <b>Viral inactivation</b>
            <br />
            [text]
            <br />
            For products derived from plasma, detail the viral reduction steps. Include information on selection criteria for donors, if applicable.
        </Box>,
    clinicalTrialsByIndications:
        <Box>
            For biosimilars, include the following or similar statement for comparative trials:
            <br />
            <ul>
                <li>
                    <i>
                        - Clinical studies conducted to support similarity between [Biosimilar brand name] and the reference biologic drug included:

                    </i>
                </li>
                <li>
                    - [text] [Provide a general description of study 1, for example, a randomized comparative bioavailability study performed in healthy volunteers.]
                </li>
                <li>
                    - [text] [Provide a general description of study 2, for example, a double-blind, randomized, comparative safety and efficacy study performed in patients with moderate to severe rheumatoid arthritis.]

                </li>
            </ul>

            <b>Table [#] - Summary of patient demographics for clinical trials in [specific indication]</b>
            <table >
                <thead>
                    <tr>
                        <th>Study #</th>
                        <th>Study design</th>
                        <th>Dosage, route of administration and duration</th>
                        <th>Study subjects (n)</th>
                        <th>Mean age (Range)</th>
                        <th>Sex</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                </tbody>
            </table>
            [Provide a brief narrative describing the demographic characteristics of the study population:]
            <br />
            [text]
        </Box>,
    comparativeBioavailabiltyStudies:
        <Box>
            For biosimilars, there should be no claims of bioequivalence or clinical equivalence between the biosimilar and the reference biologic drug. For biosimilar submissions that include only comparative bioavailability studies, leave this section blank and include the following statement:
            <br />
            <ul>
                <li>
                    <i>
                        See 14.3 Comparative bioavailability studies.
                    </i>
                </li>
            </ul>
            <b>Table [#] - Results of study [#] in [specific indication]</b>
            <table >
                <thead>
                    <tr>
                        <th>Primary Endpoints</th>
                        <th>Associated value and statistical significance for Drug at specific dosages</th>
                        <th>Associated value and statistical significance for Placebo or active control</th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>

                    </tr>
                </tbody>
            </table>
        </Box>,
    immunogenicity:
        <Box>
            [text]
            <br />
            Provide a narrative outlining the design of the bioequivalence study. The values in the table should be based on the measured data from the study. No potency correction should be applied.
            <br />
            <br />
            For biosimilars, comparative pharmacokinetic (PK) studies should be conducted to rule out differences in PK characteristics between the biosimilar and the reference biologic drug. For clinical studies conducted to support similarity between a biosimilar and the reference biologic drug, there may be cases where a pharmacodynamic (PD) marker may be used in lieu of clinical endpoints or as additional support for similarity. If this is the case, include a brief narrative describing the study and a tabulation of the PD results including the appropriate statistical analyses.
            <br />
            <br />
            <b>[Table for single dose studies:]</b>
            <table >
                <thead>
                    <tr>
                        <th>
                            Analyte Name
                            (__ x __ mg)
                            From measured data
                            Geometric Mean
                            Arithmetic Mean (CV %)
                        </th>
                    </tr>
                    <tr>
                        <th>Parameter</th>
                        <th>Test</th>
                        <th>Reference</th>
                        <th>% Ratio of Geometric Means</th>
                        <th>Confidence Interval</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> AUCT (units)</td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> AUCI (units) </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> CMAX (units) </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> TMAX (h) </td>
                        <td> - </td>
                        <td> - </td>
                        <td> Not applicable </td>
                        <td> Not applicable </td>
                    </tr>
                    <tr>
                        <td> T½ (h) </td>
                        <td> - </td>
                        <td> - </td>
                        <td> Not applicable	</td>
                        <td> Not applicable	</td>
                    </tr>
                </tbody>
            </table>

            <b>[Table for multiple dose studies:]</b>
            <table >
                <thead>
                    <tr>
                        <th>
                            Analyte Name
                            (__ x __ mg)
                            From measured data
                            Geometric Mean
                            Arithmetic Mean (CV %)
                        </th>
                    </tr>
                    <tr>
                        <th>Parameter</th>
                        <th>Test</th>
                        <th>Reference</th>
                        <th>% Ratio of Geometric Means</th>
                        <th>Confidence Interval</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> AUCtau (units)</td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> CMAX (units) </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> CMIN (h) </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> TMAX (h) </td>
                        <td> - </td>
                        <td> - </td>
                        <td> Not applicable	</td>
                        <td> Not applicable	</td>
                    </tr>
                </tbody>
            </table>
        </Box>,
    clinicalTrialsReferenceBiologicDrug:
        <Box>
            [text]
            <br />
            For vaccines, include information on efficacy by class of individuals, to recognize differences in immunogenicity.
            <br />
            <br />
            For biosimilars, include comparative immunogenicity results, if applicable, with a brief narrative describing the testing strategy for anti-drug antibodies (ADA) and the overall incidence of treatment emergent or treatment enhanced confirmed binding antibodies.
            <br />
            <br />
            The following or similar statements may be included:
            <br />
            <ul>
                <li>
                    <i>
                        Comparing the incidences of antibodies between studies or between products may be misleading due to differences in the types, sensitivities and/or specificities of the assays employed.
                    </i>
                </li>
                or
                <li>
                    <i>
                        As with all therapeutic proteins, there is a potential for immunogenicity. The detection of antibody formation is highly dependent on the sensitivity and specificity of the assay.
                    </i>
                </li>
            </ul>

        </Box>,
    microbiology:
        <Box>
            [text]
            <br />
            [table]
            <br />

            For drugs with no antimicrobial properties, include the following statement:
            <br />
            <ul>
                <li>
                    <i>
                        No microbiological information is required for this drug product.

                    </i>
                </li>
            </ul>
        </Box>,
    nonClinicalToxicology:
        <Box>
            Only use a table if presentation will be more concise. The following or similar statements should be included where applicable:
            <ul>
                <li>
                    <i>
                        - No long-term animal studies have been performed to evaluate carcinogenic or mutagenic potential or whether [Brand name] affects fertility in males or females.
                    </i>
                </li>
                <li>
                    <i>
                        - As with other radiopharmaceuticals which distribute intracellularly, there may be increased risk of chromosome damage from Auger electrons if nuclear uptake occurs.
                    </i>
                </li>
            </ul>
            For biologics, this section should confirm if long-term studies have been done to evaluate immunogenicity.
            <br />
            <br />
            For biosimilars, include toxicology information that appears in the reference biologic drug product monograph. The reference biologic drug brand name should be changed to the proper name (INN). Data that relates only to indications that will not be authorized for the biosimilar should not be included.
            <br />
            <br />

            <b>General Toxicology:</b> [text]
            <br />

            <b>Carcinogenicity: </b> [text]
            <br />

            <b>Genotoxicity:</b> [text]
            <br />

            <b>Reproductive and Developmental Toxicology:</b> [text]
            <br />

            <b>Special Toxicology: </b> [text]
            <br />
            <b>Juvenile Toxicity: </b> [text]
        </Box>,
    comparativeNonClinicalPharmacologyAndToxicology:
        <Box>
            For biosimilars Only.
        </Box>,
    comparativeNonClinicalPharmacodynamics:
        <Box>
            For biosimilars Only.
            <br />
            <b>In vitro studies</b>
            <br />
            [provide a narrative and/or table]
            <br />
            [text]
            <br />
            [table]
        </Box>,
    comparativeToxicology:
        <Box>
            For biosimilars Only.
            <br />
            [provide a narrative and/or table]
            <br />
            [text]
            <br />
            [table]
        </Box>,
    supportingProductMonographs:
        <Box>
            [numbered list:]
            <br />
            <br />

            [Brand name] [dosage form, strength], submission control [number], Product Monograph, [Sponsor]. [(MON DD, YYYY)]
            <br />
            <br />
            List only Health Canada authorized product monographs that were supportive in the development of the product monograph (e.g., Canadian Reference Product for a generic, or Reference Biologic Drug for a biosimilar biologic drug), combination product, or subsequent entry product.
            <br />
            <br />
            Where there are no such supporting product monographs, this section, including heading, should be omitted.
        </Box>,
}

export const frenchTooltipLabels = {
    pharmaceuticalInformation:
        <Box>
            <b>Substance pharmaceutique</b>
            <br />
            Nom propre : [texte]
            <br />
            Nom chimique : [texte]
            <br />
            Formule moléculaire et masse moléculaire : [texte]
            <br />
            Formule de structure : [image]
            <br />
            Propriétés physicochimiques : [texte]
            <br />
            Norme pharmaceutique : [pour les produits biologiques] [texte]
            <br />
            <br />
            <b>Caractéristiques du produit :</b>
            <br />
            [texte]
            <br />
            Pour les produits radiopharmaceutiques, fournir des renseignements détaillés ou une description plus longue des caractéristiques du produit qui s’ajoutent à celles mentionnées au point 6.1 Caractéristiques physiques.
            <br />
            <br />
            Pour les produits biologiques, cette sous-section doit fournir des renseignements qui décrivent la méthode de fabrication du produit. Les promoteurs ne sont pas tenus de fournir de renseignements exclusifs, mais ils doivent fournir suffisamment d’information aux professionnels de la santé pour leur permettre de comprendre la façon dont le produit est préparé.
            <br />
            <br />
            <b>Inactivation virale</b>
            <br />
            [texte]
            <br />
            Dans le cas des produits dérivés du plasma, les traitements de réduction du titre viral doivent être détaillés. Indiquer des informations sur les critères de sélection des donateurs, le cas échéant.
        </Box>,
    clinicalTrialsByIndications:
        <Box>
            Pour les biosimilaires, inclure l’énoncé suivant ou un énoncé similaire pour les essais comparatifs :

            <br />
            <ul>
                <li>
                    <i>
                        - Les études cliniques qui ont servi à appuyer la similarité entre la [Marque nominative du biosimilaire] et le médicament biologique de référence comprennent :

                    </i>
                </li>
                <li>
                    - [texte] [Inclure une description générale de l’étude 1, par exemple, une étude de biodisponibilité comparative randomisée auprès des volontaires en bonne santé.]
                </li>
                <li>
                    - [texte] [Inclure une description générale de l’étude 2, par exemple, une étude comparative randomisée menée à double insu sur l’innocuité et l’efficacité auprès de patients atteints d’arthrite rhumatoïde modérée à sévère.]

                </li>
            </ul>

            <b>Tableau [nº] – Résumé des caractéristiques démographiques des patients dans les essais cliniques pour une [indication particulière]</b>
            <table >
                <thead>
                    <tr>
                        <th>Nº d’étude</th>
                        <th>Conception de l’étude</th>
                        <th>Posologie, voie d’administration et durée</th>
                        <th>Nombre de sujets (n)</th>
                        <th>Âge moyen (Tranche)</th>
                        <th>Sexe</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                </tbody>
            </table>
            [Fournir une courte description des caractéristiques démographiques de la population de l’étude :]
            <br />
            [texte]
        </Box>,
    comparativeBioavailabiltyStudies:
        <Box>
            Pour les biosimilaires, il ne doit pas y avoir d’allégations de bioéquivalence ou d’équivalence clinique entre le biosimilaire et le médicament biologique de référence. Pour les présentations de biosimilaires qui ne comprennent que des études comparatives de biodisponibilité, laisser cette section vide et ajouter l’énoncé suivant :
            <br />
            <ul>
                <li>
                    <i>
                        Voir 14.3 Études biodisponibilité comparatives.
                    </i>
                </li>
            </ul>
            <b>Tableau [nº] – Résultats de l’étude [nº] pour une indication particulière</b>
            <table >
                <thead>
                    <tr>
                        <th>Paramètre(s) primaire(s)</th>
                        <th>Valeur associée et signification statistique pour le médicament à des doses particulières</th>
                        <th>Valeur associée et signification statistique pour le placebo ou le comparateur actif</th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>

                    </tr>
                </tbody>
            </table>
        </Box>,
    immunogenicity:
        <Box>
            [texte]
            <br />
            Fournir une description de la conception de l’étude de bioéquivalence. Les valeurs du tableau doivent reposer sur les données mesurées dans l’étude. Aucune correction de la puissance ne doit être appliquée.

            <br />
            <br />
            Pour les biosimilaires, des études pharmacocinétiques comparatives doivent être menées afin d’exclure les différences de caractéristiques pharmacocinétiques entre le biosimilaire et le médicament biologique de référence. Pour les études cliniques menées pour étayer la similarité entre un biosimilaire et le médicament biologique de référence, il peut y avoir des cas où un marqueur pharmacodynamique peut être utilisé à la place des critères cliniques ou comme appui supplémentaire à la similarité. Si c’est le cas, inclure une brève description de l’étude et un tableau des résultats relatifs à la pharmacodynamique comprenant les analyses statistiques appropriées.
            <br />
            <br />
            <b>[Tableau pour les études à dose unique :]</b>
            <table >
                <thead>
                    <tr>
                        <th>
                            Nom de l’analyte
                            (__ x __ mg)
                            À partir des données mesurées
                            Moyenne géométrique
                            Moyenne arithmétique (% de CV)
                        </th>
                    </tr>
                    <tr>
                        <th>Paramètre</th>
                        <th>Test</th>
                        <th>Référence</th>
                        <th>Rapport de moyenne géométriques (%)</th>
                        <th>Intervalle de confiance</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> ASCT (unités)</td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> ASCI (unités) </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> CMAX (unités) </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> TMAX (h) </td>
                        <td> - </td>
                        <td> - </td>
                        <td> Sans objet </td>
                        <td> Sans objet </td>
                    </tr>
                    <tr>
                        <td> T½ (h) </td>
                        <td> - </td>
                        <td> - </td>
                        <td> Sans objet	</td>
                        <td> Sans objet	</td>
                    </tr>
                </tbody>
            </table>

            <b>[Tableau pour les études à doses multiples :]</b>
            <table >
                <thead>
                    <tr>
                        <th>
                            Nom de l’analyte
                            (__ x __ mg)
                            À partir des données mesurées
                            Moyenne géométrique
                            Moyenne arithmétique (% de CV)
                        </th>
                    </tr>
                    <tr>
                        <th>Paramètre</th>
                        <th>Test</th>
                        <th>Référence</th>
                        <th>Rapport de moyenne géométriques (%)</th>
                        <th>Intervalle de confiance</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> ASCtau (unités)</td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> CMAX (unités) </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> CMIN (h) </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> TMAX (h) </td>
                        <td> - </td>
                        <td> - </td>
                        <td> Sans objet	</td>
                        <td> Sans objet	</td>
                    </tr>
                </tbody>
            </table>
        </Box>,
    clinicalTrialsReferenceBiologicDrug:
        <Box>
            [texte]
            <br />
            Pour les vaccins, inclure des informations sur l’efficacité par catégories de personnes, afin de reconnaître les différences d’immunogénicité.
            <br />
            <br />
            Pour les biosimilaires, inclure les résultats comparatifs relatifs à l’immunogénicité, s’il y a lieu, et une brève description de la stratégie de test des anticorps anti-médicaments (AAM) et de l’incidence globale des anticorps liants confirmés, apparus au cours du traitement ou renforcés par le traitement.
            <br />
            <br />
            Les énoncés suivants peuvent être utilisés :
            <br />
            <ul>
                <li>
                    <i>
                        La comparaison de l’incidence des anticorps dans les essais décrits ci-dessous avec l’incidence des anticorps dans d’autres essais ou avec d’autres produits [nom du médicament] peut être trompeuse.
                    </i>
                </li>
                ou
                <li>
                    <i>
                        Comme avec toutes les protéines thérapeutiques, il existe un risque d’immunogénicité. La détection de la formation d’anticorps dépend fortement de la sensibilité et de la spécificité de l’analyse.
                    </i>
                </li>
            </ul>

        </Box>,
    microbiology:
        <Box>
            [texte]
            <br />
            [tableau]
            <br />

            Pour les médicaments sans propriétés antimicrobiennes, inclure la déclaration suivante :

            <br />
            <ul>
                <li>
                    <i>
                        Aucune information microbiologique n’est requise pour ce produit pharmaceutique.
                    </i>
                </li>
            </ul>
        </Box>,
    nonClinicalToxicology:
        <Box>
            Le tableau n’est à utiliser que si la présentation en ressort plus concise. L’énoncé suivant ou un énoncé semblable peut être inclus :
            <ul>
                <li>
                    <i>
                        - Aucune étude à long terme n’a été menée chez les animaux en vue d’évaluer le potentiel carcinogène ou mutagène ou de déterminer si [Marque nominative] a des répercussions sur la fertilité chez les mâles ou les femelles.
                    </i>
                </li>
                <li>
                    <i>
                        - Comme pour les autres produits radiopharmaceutiques qui sont soumis à une distribution intracellulaire, il peut y avoir une augmentation des risques d’accident chromosomique attribuables aux électrons Auger si ces derniers sont captés par le noyau.
                    </i>
                </li>
            </ul>
            <br />
            Pour les produits biologiques, cette section doit confirmer si des études à long terme ont été réalisées pour évaluer l’immunogénicité.
            <br />
            <br />
            Pour les biosimilaires, inclure les renseignements toxicologiques qui figurent dans la monographie du médicament biologique de référence. La marque nominative du médicament biologique de référence doit être remplacée par le nom propre (dénomination commune internationale [DCI]). Les données qui concernent uniquement des indications qui ne seront pas autorisées pour le biosimilaire ne doivent pas être incluses.
            <br />
            <br />

            <b>Toxicologie générale :</b> [texte]
            <br />

            <b>Cancérogénicité : </b> [texte]
            <br />

            <b>Génotoxicité :</b> [texte]
            <br />

            <b>Toxicologie pour la reproduction et le développement :</b> [texte]
            <br />

            <b>Toxicologie particulière : </b> [texte]
            <br />
            <b>Toxicité juvénile : </b> [texte]
        </Box>,

    comparativeNonClinicalPharmacologyAndToxicology:
        <Box>
            Pour les biosimilaires uniquement.
        </Box>,
    comparativeNonClinicalPharmacodynamics:
        <Box>
            Pour les biosimilaires uniquement.
            <br />
            <b>Études in vitro</b>
            <br />
            [fournir une description et/ou un tableau]
            <br />
            [texte]
            <br />
            [tableau]
        </Box>,
    comparativeToxicology:
        <Box>
            Pour les biosimilaires uniquement.
            <br />
            [fournir une description et/ou un tableau]
            <br />
            [texte]
            <br />
            [tableau]
        </Box>,
    supportingProductMonographs:
        <Box>
            [liste numérotée :]
            <br />
            <br />

            [Marque nominative] [forme posologique, concentration], [numéro de contrôle de la présentation], Monographie de produit, [promoteur]. [(AAAA, MM, JJ)]
            <br />
            <br />
            Indiquer seulement les monographies de produit autorisées par Santé Canada qui ont servi à l’élaboration de la monographie de produit (p. ex. produit de référence canadien pour un produit générique ou médicament biologique de référence pour un biosimilaire), du produit d’association ou du produit de commercialisation subséquente.
            <br />
            <br />
            En l’absence d’une monographie de produit de soutien, cette section, y compris le titre, peut être omise.
        </Box>,
}