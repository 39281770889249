import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";

import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SESSION_STATUS } from './SessionProvider';
import PMButton from '../components/common/Button';
import { SET_UNAUTHORIZED } from '../actions/types';
import { invalidateSession } from '../actions/auth';

const useStyles = makeStyles( () => ({
}));

export default function SessionTimoutDialog({getRemainingTime}) {

    const classes = useStyles();
    const dispatch = useDispatch()
    const sessionStatus = useSelector((state) => state.session.sessionStatus)
    let history = useHistory()

    const [timeLeft, setTimeLeft] = useState(Math.ceil(getRemainingTime()/1000));

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(Math.ceil(getRemainingTime()/1000));
        }, 1000);
    });

    const onSessionExpiredCta = () => {
        dispatch(invalidateSession())
        dispatch({
            type: SET_UNAUTHORIZED
        })
        history.push("/login");
    }

    return(
        <Dialog
            open={sessionStatus >= SESSION_STATUS.EXPIRING}
            PaperProps={{
                style: {
                    width: '50vw',
                    borderRadius: '25px',
                    padding: '20px',
                },
            }}
        >
            <DialogTitle className={classes.dialogTitle}>
                <Box alignItems="center" display="flex" justifyContent="space-between">
                    <Typography className={classes.dialogTitleText}>
                        {sessionStatus === SESSION_STATUS.EXPIRING && 
                            "Your Session Will Expire Soon!"
                        }
                        {sessionStatus === SESSION_STATUS.EXPIRED && (
                            "Your Session Has Expired!"
                        )}
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box>
                    {sessionStatus === SESSION_STATUS.EXPIRING && 
                        <Box>
                            <Typography className={classes.dialogText}>
                                Your session will expire in <span style={{color:'red'}}>{timeLeft} seconds</span> due to inactivity.
                            </Typography>
                            <br/>
                            <Typography className={classes.dialogText}>
                                If you are still working, click anywhere to stay signed in.
                            </Typography> 
                            <br/>
                            <Typography className={classes.dialogText}>
                                For your security, you will need to login once your session has expired.
                            </Typography> 
                        </Box>
                    }
                    {sessionStatus === SESSION_STATUS.EXPIRED &&
                        <Box>
                            <Typography className={classes.dialogText}>
                                Due to inactivity, you session has expired. You will need to log in again to continue.
                            </Typography>
                        </Box>
                    }
                </Box>

                {sessionStatus === SESSION_STATUS.EXPIRED && (
                    <Box mt={2}>
                        <PMButton onClick={() => {onSessionExpiredCta()}}>
                            Login Again
                        </PMButton>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    )
}
