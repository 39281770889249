import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Table, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Tooltip } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';

import PMButton from '../common/Button';
import { validateForm } from '../../actions/forms';
import { useHistory } from "react-router-dom";
import { INCREMENT_DEMO_STEP } from '../../actions/types';

const useStyles = makeStyles(() => ({
    paper: {
        minWidth: "1200px"
    },
    loadingButton: {
        backgroundColor: "gray",
        opacity: "0.6",
    },
    errorButton: {
        backgroundColor: "red",
        opacity: "0.6",
    },
    passCell: {
        backgroundColor: "#5cb85c",
    },
    warningCell: {
        backgroundColor: "#f0ad4e",
    },
    errorCell: {
        backgroundColor: "#bb2124",
    },
    table: {
        '& .MuiTableCell-root': {
            border: "1px solid black",
            textAlign: "center"
        }
    },
    header: {
        backgroundColor: "#D3D3D3",
        color: "black",
    },
    icon: {
        color: "white",
        cursor: 'pointer'
    }
}));

export default function ValidationDialog({ context, isValidationDialogOpen, setIsValidationDialogOpen }) {

    const classes = useStyles();
    const dispatch = useDispatch()
    let history = useHistory()
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });
    const form = useSelector((state) => state.forms.form)
    const formUpdating = useSelector((state) => state.forms.updating)
    const validationResults = useSelector((state) => state.forms.validationResults)

    useEffect(() => {
        if (isValidationDialogOpen && !formUpdating) {
            dispatch(validateForm(form._id, context))
        }
    }, [isValidationDialogOpen, formUpdating, context, form._id, dispatch])

    const handleSectionSwitch = (urlSectionName) => {
        setIsValidationDialogOpen(false)
        history.push(`/form/${form._id}?section=${urlSectionName}&isEnglish=${params.isEnglish.toString()}`)
    }

    const handleValidationDialogClose = (e) => {
        e.preventDefault()

        setIsValidationDialogOpen(false)
        dispatch({
            type: INCREMENT_DEMO_STEP,
        })
    }

    return (
        <Dialog
            open={isValidationDialogOpen}
            keepMounted
            scroll="paper"
            maxWidth="md"
        >
            <DialogTitle>
                Validation Results
                <Box mt={2}>
                    <Typography>
                        We ran your input against <a target="_blank" rel="noreferrer" href="https://www.canada.ca/en/health-canada/services/drugs-health-products/public-involvement-consultations/drug-products/structured-product-monograph/validation.html">Health Canada's XML validation rules</a> and these are the results.
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent className={'validation-report'} dividers={true}>
                <TableContainer component={Paper} >
                    <Table className={classes.table}>
                        <colgroup>
                            <col style={{ width: '5%' }} />
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '60%' }} />
                            <col style={{ width: '25%' }} />
                        </colgroup>
                        <TableHead>
                            <TableRow className={classes.header}>
                                <TableCell>
                                    <Typography>
                                        Status
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        Rule ID
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        Requirement
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    Failed Sections
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {formUpdating ? (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={3}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {validationResults.errors && validationResults.errors.map((row) => {
                                    return (
                                        <TableRow
                                            key={row.ruleId}
                                        >
                                            <TableCell label="Icon" className={classes.errorCell}>
                                                <ErrorIcon
                                                    className={classes.icon}
                                                />
                                            </TableCell>
                                            <TableCell>{row.ruleId}</TableCell>
                                            <TableCell>{row.message}</TableCell>
                                            <TableCell>
                                                {row.failedSections && row.failedSections.map((section) => (
                                                    <React.Fragment key={section.urlName}>
                                                        <div>
                                                            <Typography
                                                                component="span"
                                                                style={{ color: 'rgb(5, 99, 193)', cursor: 'pointer', textDecoration: 'underline', fontSize: "14px" }}
                                                                onClick={() => handleSectionSwitch(section.urlName)}
                                                            >
                                                                {section.name || section.urlName}
                                                            </Typography>
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                {validationResults.warnings && validationResults.warnings.map((row) => (
                                    <TableRow
                                        key={row.ruleId}
                                    >
                                        <TableCell label="Icon" className={classes.warningCell}>
                                            <WarningIcon className={classes.icon} />
                                        </TableCell>
                                        <TableCell>{row.ruleId}</TableCell>
                                        <TableCell>{row.message}</TableCell>
                                        <TableCell>
                                            {row.failedSections && row.failedSections.map((section) => (
                                                <React.Fragment key={section.urlName}>
                                                    <div>
                                                        <Typography
                                                            component="span"
                                                            style={{ color: 'rgb(5, 99, 193)', cursor: 'pointer', textDecoration: 'underline', fontSize: "14px" }}
                                                            onClick={() => handleSectionSwitch(section.urlName)}
                                                        >
                                                            {section.name || section.urlName}
                                                        </Typography>
                                                    </div>
                                                </React.Fragment>
                                            ))}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {validationResults.passed && validationResults.passed.map((row) => (
                                    <TableRow
                                        key={row.ruleId}
                                    >
                                        <TableCell className={classes.passCell}>
                                            <CheckIcon fontSize="medium" className={classes.icon} />
                                        </TableCell>
                                        <TableCell>{row.ruleId}</TableCell>
                                        <TableCell>{row.message}</TableCell>
                                        <TableCell style={{ background: '#e3e3e3' }}>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <PMButton className="close-validation" onClick={(e) => handleValidationDialogClose(e)}>Close</PMButton>
            </DialogActions>
        </Dialog>
    )
}
