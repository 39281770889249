import React, { useEffect, useState} from 'react'
import { connect } from 'react-redux'
import TextField from '../formComponents/TextField'
import {englishLabels, frenchLabels} from "../../formLabels/RegulatoryStatusLabels"
import AutocompleteField from '../formComponents/AutoComplete'
import { makeStyles} from '@material-ui/core/styles';
import { Paper} from '@material-ui/core';
import { CONTROL_NUMBER_MASK, DATE_MASK } from '../../../utils/utilFunctions'

const useStyles = makeStyles({
    veritcalText: {
        alignSelf: "center"
    },
    paper: {
        padding: "2rem",
        margin: "2rem 0rem",
        borderRadius: "25px"
    }
});

const RegulatoryStatus = ({ currentProductIndex, context, handleUpdateField }) => {

    const [labels, setLabels] = useState({})
    const classes = useStyles();

    useEffect(() => {
        setLabels(context ? frenchLabels : englishLabels)
    }, [context])

    return(
        <Paper className={classes.paper} elevation={4}>
            <h6>{labels.title}</h6>
                <AutocompleteField 
                    context={context} 
                    codeSystem="2.16.840.1.113883.2.20.6.37" 
                    updateFunction = {handleUpdateField} 
                    label = {labels.regulatoryActivityType} 
                    placeholder = {labels.regulatoryActivityType} 
                    name = {`products[${currentProductIndex}].regulatoryActivityType`}
                />
                <TextField 
                    updateFunction = {handleUpdateField} 
                    label = {labels.regulatoryControlNumber} 
                    placeholder = {labels.regulatoryControlNumber} 
                    name = {`products[${currentProductIndex}].regulatoryControlNumber`}
                    inputMask={CONTROL_NUMBER_MASK}
                />
                <TextField 
                    updateFunction = {handleUpdateField} 
                    label = {labels.dateOfApproval} 
                    placeholder = {labels.datePlaceholder} 
                    name = {`products[${currentProductIndex}].dateOfApproval`}
                    inputMask={DATE_MASK}
                />
                <TextField 
                    updateFunction = {handleUpdateField} 
                    label = {labels.dateOfCancellation} 
                    placeholder = {labels.datePlaceholder} 
                    name = {`products[${currentProductIndex}].dateOfCancellation`}
                    inputMask={DATE_MASK}
                />
        </Paper>
    )
}

export default connect(null, null)(RegulatoryStatus)