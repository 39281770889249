import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Grid, Typography, Chip, Tooltip, IconButton, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FolderIcon from '@material-ui/icons/Folder';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import SettingsIcon from '@material-ui/icons/Settings';

import moment from 'moment';
import PMButton from '../common/Button';

import ValidationDialog from './ValidationDialog';
import DownloadDialog from './DownloadDialog';
import PreviewDialog from './PreviewDialog';
import VersionHistoryDialog from './VersionHistoryDialog';
import FormManagementDialog from './FormManagementDialog';
import { getLastModifiedInfo, getAccessLevel, getFormAccessControlInfo, getUsersInUserCompanyInfo, getDocumentLock, checkDocumentLock, releaseDocumentLock, setIsUploadDialogOpen } from '../../actions/forms';
import { incrementDemoStepWithDelay } from '../../actions/demo';
import { FORM_ACCESS } from '../../utils/utilFunctions';
import useInterval from '../../utils/Interval';
import TooltipComponent from './formComponents/TooltipComponent'
import UploadDialog from './UploadDialog';
import { INCREMENT_DEMO_STEP } from '../../actions/types';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import HistoryIcon from '@material-ui/icons/History';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles(() => ({
    archiveChip: {
        marginLeft: "0.5rem"
    },
    actionButtonRow: {
        display: "flex",
        borderRadius: "25px"
    },
    actionItems: {
        display: "flex",
        justifyContent: "right"
    }
}));


export default function FormHeader({ context }) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    const form = useSelector((state) => state.forms.form)
    const currentDemoStep = useSelector((state) => state.demo.currentDemoStep)
    const isDemoComplete = useSelector((state) => state.demo.isDemoComplete)
    const isDemo = useSelector((state) => state.demo.isDemo)
    const editState = useSelector((state) => state.forms.editState)
    const lastModifiedInfo = useSelector((state) => state.forms.lastModifiedInfo)
    const accessLevel = useSelector((state) => state.forms.accessLevel)
    const formAccessControlInfo = useSelector((state) => state.forms.formAccessControlInfo)
    const formLoading = useSelector((state) => state.forms.loading)
    const usersInUserCompanyInfo = useSelector((state) => state.forms.usersInUserCompanyInfo)

    const [isValidationDialogOpen, setIsValidationDialogOpen] = useState(false)
    const [isDownloadDialogOpen, setIsDownloadDialogOpen] = useState(false)
    const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false)
    const [isVersionHistoryDialogOpen, setIsVersionHistoryDialogOpen] = useState(false)
    const [isFormManagementDialogOpen, setIsFormManagementDialogOpen] = useState(false)

    const [pollingEnabled, setPollingEnabled] = useState(false)

    // Disable Action Buttons (except Validation and Download) while demo is ongoing
    const actionButtonDisabledDuringDemo = isDemo && currentDemoStep > 0 && !isDemoComplete 

    const handleReleaseDocumentLock = (e) => {
        dispatch(releaseDocumentLock(form._id, context))
    }

    const handleGetDocumentLock = (e) => {
        dispatch(getDocumentLock(form._id, context))
    }

    const getDocumentInformation = () => {
        dispatch(getAccessLevel(form._id))
        dispatch(checkDocumentLock(form._id, context))
        dispatch(getLastModifiedInfo(form._id))
        dispatch(getFormAccessControlInfo(form._id))
    }

    const handleValidationDialogOpen = (e) => {
        e.preventDefault()
        setIsValidationDialogOpen(true)
        
        dispatch(incrementDemoStepWithDelay())

    }

    const handleDownloadDialogOpen = (e) => {
        e.preventDefault()
        setIsDownloadDialogOpen(true)

        dispatch({
            type: INCREMENT_DEMO_STEP,
        })
    }

    useInterval(() => {
        getDocumentInformation()
    }, pollingEnabled ? 5000 : null)

    const getFormManagersInfo = (formAccessControlInfo, usersInUserCompanyInfo) => {
        var managerUsernames = Object.entries(formAccessControlInfo).filter(([_, accessLevel]) => accessLevel.code === FORM_ACCESS.MANAGER.code).map((x) => x[0]);
        var managersInfo = usersInUserCompanyInfo?.users?.filter((x) => {
            return managerUsernames.includes(x.username);
        })
        if (managersInfo === null || managersInfo === undefined) {
            return []
        }
        return managersInfo;
    };
    const formManagers = useMemo(() => {
        return getFormManagersInfo(formAccessControlInfo, usersInUserCompanyInfo)
    }, [formAccessControlInfo, usersInUserCompanyInfo]);

    // Get inital document edit state and enable polling
    useEffect(() => {
        dispatch(getUsersInUserCompanyInfo(form._id))
    }, [form._id, dispatch])

    useEffect(() => {
        setPollingEnabled(editState.isEditor)
    }, [editState])

    const formatUserInfo = (userObj) => {
        return `${userObj.given_name} ${userObj.family_name} (${userObj.email})`
    }

    const editButton = () => {
        if (!editState.hasEditAccess || editState.isArchive || editState.isComparison) {
            return (
                <PMButton
                    disabled
                >
                    <EditIcon />
                    Edit PM
                </PMButton>
            )
        }

        if (editState.isDocumentUnlocked) {
            return (
                <PMButton
                    loading={formLoading}
                    onClick={() => { handleGetDocumentLock() }}
                >
                    <EditIcon />
                    Edit PM
                </PMButton>
            )
        }

        if (editState.isEditable) {
            return (
                <PMButton
                    red
                    outlined
                    loading={formLoading}
                    onClick={() => { handleReleaseDocumentLock() }}
                >
                    <LockIcon />
                    Disable Edit
                </PMButton>
            )
        } else {
            if (editState.userOwnsOperation && editState.operationName === "Upload Word") {
                return (
                    <Tooltip title="A Word document for this PM is currently benig uploaded. Please try again later.">
                        <span>
                            <PMButton
                                red
                                disabled
                            >
                                <LockIcon />
                                Word Doc Uploading...
                            </PMButton>
                        </span>
                    </Tooltip>
                )
            }
            return (
                <Tooltip title="This PM is currently being edited by another user or operation. Please try again later.">
                    <span>
                        <PMButton
                            red
                            disabled
                        >
                            <LockIcon />
                            Edit PM
                        </PMButton>
                    </span>
                </Tooltip>
            )
        }
    }


    var actionButtons = () => {
        return (
            <Paper className={`${classes.actionButtonRow} action-buttons`} elevation={3}>
                <Box>
                    <Tooltip
                        title="Upload"
                        disableFocusListener={actionButtonDisabledDuringDemo}
                        disableHoverListener={actionButtonDisabledDuringDemo}
                        disableTouchListener={actionButtonDisabledDuringDemo}
                    >
                        <span>
                            <IconButton
                                color="primary"
                                onClick={() => dispatch(setIsUploadDialogOpen(true))}
                                className={classes.button}
                                disabled={editState.isDisabled || actionButtonDisabledDuringDemo}
                            >
                                <CloudUploadIcon fontSize='large' />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <UploadDialog
                    context={context}
                />
                <Box>
                    <Tooltip
                        title="Version History"
                        disableFocusListener={actionButtonDisabledDuringDemo}
                        disableHoverListener={actionButtonDisabledDuringDemo}
                        disableTouchListener={actionButtonDisabledDuringDemo}
                    >
                        <span>
                            <IconButton
                                color="primary"
                                onClick={() => setIsVersionHistoryDialogOpen(true)}
                                className={classes.button}
                                disabled={editState.isDisabled || actionButtonDisabledDuringDemo}
                            >
                                <HistoryIcon fontSize='large' />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <VersionHistoryDialog
                    id={form._id ?? null}
                    context={context}
                    isVersionHistoryDialogOpen={isVersionHistoryDialogOpen}
                    setIsVersionHistoryDialogOpen={setIsVersionHistoryDialogOpen}
                />
                <Box className="validate-button">
                    <Tooltip title="Validation">
                        <span>
                            <IconButton
                                color="primary"
                                onClick={(e) => handleValidationDialogOpen(e)}
                                className={classes.button}
                                disabled={editState.isDisabled}
                            >
                                <AssignmentTurnedInIcon fontSize='large' />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <ValidationDialog
                    context={context}
                    isValidationDialogOpen={isValidationDialogOpen}
                    setIsValidationDialogOpen={setIsValidationDialogOpen}
                />
                <Box className="step-ten">
                    <Tooltip
                        title="Preview"
                        disableFocusListener={actionButtonDisabledDuringDemo}
                        disableHoverListener={actionButtonDisabledDuringDemo}
                        disableTouchListener={actionButtonDisabledDuringDemo}
                    >
                        <span>
                            <IconButton
                                color="primary"
                                onClick={() => setIsPreviewDialogOpen(true)}
                                disabled={editState.isArchive || editState.isComparison || actionButtonDisabledDuringDemo}
                                className={classes.button}
                            >
                                <VisibilityIcon fontSize='large' />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <PreviewDialog
                    context={context}
                    id={form._id ?? null}
                    isPreviewDialogOpen={isPreviewDialogOpen}
                    setIsPreviewDialogOpen={setIsPreviewDialogOpen}
                />
                <Box className='click-export-menu-button'>
                    <Tooltip title="Download">
                        <span>
                            <IconButton
                                color="primary"
                                onClick={(e) => handleDownloadDialogOpen(e)}
                                disabled={editState.isArchive || editState.isComparison}
                                className={classes.button}
                            >
                                <CloudDownloadIcon fontSize='large' />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <DownloadDialog
                    context={context}
                    id={form._id ?? null}
                    isDownloadDialogOpen={isDownloadDialogOpen}
                    setIsDownloadDialogOpen={setIsDownloadDialogOpen}
                />
            </Paper>
        )
    };

    var formNameArea = (showSettings) => {
        return (
            <Grid className="author-view" item xs={9}>
                <Typography variant="h4">
                    {editState.isArchive ? (
                        <Box display="flex">
                            {`${form.name} - v${params.majorVersionNumber}.${params.minorVersionNumber}`}
                            <Box ml={2}>
                                <Chip
                                    style={{ backgroundColor: "#808080", color: "white" }}
                                    label={
                                        <Box>
                                            <FolderIcon />
                                            <span className={classes.archiveChip}>
                                                Archived
                                            </span>
                                        </Box>
                                    }
                                />
                            </Box>
                        </Box>
                    ) :
                        editState.isComparison ? (
                            <Box>
                                {form.name}
                            </Box>
                        ) : (
                            <Box>
                                {showSettings ? (
                                    <Grid>
                                        {form.name}  <SettingsIcon color="primary" fontSize="Medium" onClick={() => setIsFormManagementDialogOpen(true)}></SettingsIcon>
                                        <FormManagementDialog
                                            context={context}
                                            isFormManagementDialogOpen={isFormManagementDialogOpen}
                                            setIsFormManagementDialogOpen={setIsFormManagementDialogOpen}
                                        />
                                    </Grid>
                                ) : (<Box>{form.name}</Box>)}
                            </Box>
                        )}
                </Typography>
            </Grid>
        )
    };


    if (accessLevel.code === FORM_ACCESS.VIEWER.code) {
        var stringManagers;
        if (formManagers.length === 0) {
            stringManagers = <Box>Unable to retrieve managers info. Please try again later</Box>
        }
        else if (formManagers.length === 1) {
            stringManagers = <Box>The manager of this form is: <br />{formatUserInfo(formManagers[0])} </Box>;
        }
        else {
            stringManagers = <Box>The managers of this form are: <Box>{formManagers.map(item => { return (<Box>{formatUserInfo(item)}</Box>) })}</Box></Box>
        }

        return (
            <Grid container spacing={4}>
                {formNameArea(false)}
                <Grid item xs={3}>
                    {editButton()}
                </Grid>
                <Grid item xs={8}>
                    <Grid display="flex" alignItems="center" container>
                        <Box justifyContent='flex-start'>
                            You are in read-only mode. To gain edit access contact a manager of this form.
                            <TooltipComponent label={stringManagers} />
                        </Box>
                    </Grid>
                </Grid>
                <Grid className={classes.actionItems} item xs={4}>
                    {actionButtons()}
                </Grid>
            </Grid>
        );
    } else {
        var showSettings = accessLevel.code === FORM_ACCESS.MANAGER.code ? true : false;
        return (
            <Grid container spacing={4}>
                {formNameArea(showSettings)}
                <Grid item xs={3}>
                    <Box className='edit-button'>
                        {editButton()}
                    </Box>
                </Grid>
                <Grid item xs={8}>
                    <Grid display="flex" alignItems="center" container>
                        {lastModifiedInfo !== null &&
                            <Box display="inline-block">
                                <Tooltip disableHoverListener={editState.isComparison} title={moment(lastModifiedInfo.lastModifiedDate).format('lll')}>
                                    <Typography className={classes.lastEdited} variant="h6">
                                        {editState.isArchive ? (
                                            <Box>
                                                Archive Created On {moment(lastModifiedInfo.lastModifiedDate).format('LL')} by {lastModifiedInfo.lastModifiedBy.name}
                                            </Box>
                                        ) :
                                            editState.isComparison ? (
                                                <Box>
                                                    Comparing v{params.majorVersionNumberA}.{params.minorVersionNumberA} to v{params.majorVersionNumberB}.{params.minorVersionNumberB}
                                                </Box>
                                            ) : (
                                                <Box>
                                                    Last edit was {moment(lastModifiedInfo.lastModifiedDate).fromNow()} by {lastModifiedInfo.lastModifiedBy.name}
                                                </Box>
                                            )}
                                    </Typography>
                                </Tooltip>
                            </Box>
                        }
                    </Grid>
                </Grid>
                <Grid className={classes.actionItems} item xs={4}>
                    {actionButtons()}
                </Grid>
            </Grid>
        )
    }
}