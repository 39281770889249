import { useState, useEffect } from 'react';
import { useLocation, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux'

import Logo from '../../assets/InstaPMwText.svg'

import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import MFA from "./MFA"
import InitalSetup from './InitialSetup';
import ResetPassword from './ResetPassword';
import PasswordReset from './PasswordReset';
import NotAvailableMobile from '../common/NotAvailableMobile';
const descriptionTheme = createTheme({
    typography: {
        fontFamily: "Playfair Display, serif",
        fontStyle: "normal",
    }
});

const useStyles = makeStyles(() => ({
    rightContainer: {
        padding: "5rem",
        display: "flex",
        alignItems: "center",
        height: "100vh",
        width: "100%"
    },
    paper: {
        width: "100%",
        display: "flex"
    },
    left: {
        height: "100%",
        width: "40%",
        position: "fixed",
        overflow: "hidden",
        left: 0,
        background: "linear-gradient(166.32deg, #007bff 7.32%, #001c96 93.55%)",        
    }, 
    leftMobile: {
        height: "100%",
        width: "100%",
        position: "fixed",
        top: "0",
        background: "linear-gradient(166.32deg, #007bff 7.32%, #001c96 93.55%)",        
    }, 
    right: {
        height: "100%",
        width: "60%",
        position: "fixed",
        overflow: "hidden",
        paddingTop: "20px",
        right: 0,
    },
    centered: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center"
    },
    description: {
        color: "white",
        // float: "left",
        fontWeight: 800,
        fontSize: 36,
        fontStyle: "normal",
        marginBottom: "1%",
    },
    subDescription: {
        color: "white",
        fontSize: "18px",
        fontWeight: 400,
    },
    descriptionBox: {
        textAlign: "left",
        width: '75%'
    },
    dots1: {
        top: 0,
        position: "absolute",
        right: 0,
    },
    dots2: {
        position: "absolute",
        paddingLeft: "13%",
        display: "flex",
        alignItems: "center",
        height: "106%"
    },
}));

const renderMapping = (pathName, authStep, email, setEmail, password, setPassword) => {

    const authStepMapping = {
        1: <MFA email={email}/>,
        2: <InitalSetup email={email}/>,
        3: <ResetPassword email={email}/>,
        4: <PasswordReset />
    }
    const mapping = {
        "/login": <Login email={email} setEmail={setEmail} password={password} setPassword={setPassword}/>,
        "/forgot-password": <ForgotPassword email={email} setEmail={setEmail}/>,
    }

    if (authStep !== null) {
        return authStepMapping[authStep]
    }
    return mapping[pathName]
}

const LoginRouter = () => {
	const classes = useStyles();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
    const authenticationStep = useSelector((state) => state.auth.authenticationStep)

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const isMobile = useMediaQuery(theme => theme?.breakpoints.down('sm'));
    const pathname = useLocation().pathname

    // TODO: Investigate if removing this block has any effect on login router flow
    // useEffect(() => {
    //     if(pathname === "/login" && !localStorage.token) {
    //         dispatch({
    //             type: AUTH_RESET,
    //             payload: null
    //         })
    //     }
    // }, [pathname, dispatch])

    const renderContent = () => {
        return renderMapping(pathname, authenticationStep, email, setEmail, password, setPassword)
    }

    useEffect(() =>{
        document.title = "InstaPM"
    }, [])

    // Redirect if logged in
    if (isAuthenticated) {
        return <Redirect to = '/dashboard' />
    }

    if (isMobile) {
        return <NotAvailableMobile />
    }

    return (
        <>
            <div className={classes.left}>
                <Box className={classes.dots1}>
                    {/* <img src={Dots1} alt="Design Icons" /> */}
                </Box>
                <Box className={classes.dots2}>
                    {/* <img src={Dots2} alt="Design Icons2" /> */}
                </Box>
                <div className={`${classes.centered} ${classes.descriptionBox}`}>
                    <Box width="250px">
                        <img src={Logo} alt="Logo" />
                    </Box>
                    <Box mt={2}>
                        <Typography className={classes.subDescription}>
                            The modern Product Monograph platform
                        </Typography>
                    </Box>
                </div>
            </div>
            <div className={classes.right}>
                <div className={classes.rightContainer}>
                    <Box className={classes.paper}>
                        <Grid container>
                            <Box className={classes.paper} display="flex" flexDirection="column">
                                <Grid item xs={12}>
                                    <Box width="100%">
                                        { renderContent() }
                                    </Box>
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>
                </div>
            </div>
        </>
    );
}

export default LoginRouter;