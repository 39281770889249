import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import PMButton from '../common/Button';
import { onboardUser } from '../../actions/auth';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: "2rem",
        margin: "2rem 0rem",
        borderRadius: "25px"
    }
}));

export default function Admin() {
    const dispatch = useDispatch()
    const classes = useStyles();

    const userOnboarding = useSelector((state) => state.auth.userOnboarding)

    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [accessLevel, setAccessLevel] = useState('');
    
    // Handle form submission
    const handleSubmit = () => {
        dispatch(onboardUser(email, phoneNumber, firstName, lastName, companyName, accessLevel))

        setEmail('');
        setPhoneNumber('');
        setFirstName('');
        setLastName('');
        setCompanyName('');
        setAccessLevel('');
    };

    useEffect(() =>{
        document.title = "Admin Dashboard"
    }, [])

    return (
        <Box>
            <Box className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Box>
                            <Paper className={classes.paper} elevation={4}>
                                <Box mb={2}>
                                    <Typography variant='h5'>
                                        Onboard a User
                                    </Typography>
                                </Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Email"
                                            variant="outlined"
                                            fullWidth
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Phone Number"
                                            placeholder='+14161234567'
                                            variant="outlined"
                                            fullWidth
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="First Name"
                                            variant="outlined"
                                            fullWidth
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Last Name"
                                            variant="outlined"
                                            fullWidth
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Company Name"
                                            variant="outlined"
                                            fullWidth
                                            value={companyName}
                                            onChange={(e) => setCompanyName(e.target.value)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Access Level"
                                            variant="outlined"
                                            fullWidth
                                            type="number"
                                            value={accessLevel}
                                            onChange={(e) => setAccessLevel(e.target.value)}
                                            required
                                        />
                                    </Grid>
                                    <PMButton disabled={userOnboarding} loading={userOnboarding} onClick={() => {handleSubmit()}} type="submit" variant="contained" color="primary">
                                        Onboard User
                                    </PMButton>
                                </Grid>
                            </Paper>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
