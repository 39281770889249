import React, { useState } from "react";
import { Box, Typography, Grid, TextField, Fade, useMediaQuery} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { loadDemo } from "../../actions/demo";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PMButton from "../common/Button";
import Logo from "../../assets/InstaPMwText.svg";

const useStyles = makeStyles(() => ({
	rightContainer: {
		padding: "5rem",
		display: "flex",
		alignItems: "center",
		height: "100vh",
		width: "100%",
	},
	rightContainerMobile: {
		padding: "6rem 2rem 6rem 2rem",
		display: "flex",
		alignItems: "center",
	},
	paper: {
		width: "100%",
		display: "flex",
	},
	left: {
		height: "100%",
		width: "40%",
		position: "fixed",
		overflow: "hidden",
		left: 0,
		background: "linear-gradient(166.32deg, #007bff 7.32%, #001c96 93.55%)",
	},
	top: {
		width: "100%",
		background: "linear-gradient(166.32deg, #007bff 7.32%, #001c96 93.55%)",
	},
	leftMobile: {
		height: "100%",
		width: "100%",
		position: "fixed",
		top: "0",
		background: "linear-gradient(166.32deg, #007bff 7.32%, #001c96 93.55%)",
	},
	right: {
		height: "100%",
		width: "60%",
		position: "fixed",
		overflow: "hidden",
		paddingTop: "20px",
		right: 0,
	},
	centered: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		textAlign: "center",
	},
	description: {
		color: "white",
		// float: "left",
		fontWeight: 800,
		fontSize: 36,
		fontStyle: "normal",
		marginBottom: "1%",
	},
	subDescription: {
		color: "white",
		fontSize: "18px",
		fontWeight: 400,
	},
	descriptionBox: {
		textAlign: "left",
		width: "75%",
	},
	logoBox: {
		textAlign: "center",
	},
	button: {
		width: "100%",
	},
	textField: {
		width: "100%",
	},
	formLabel: {
		float: "left",
		margin: "2% 0% 2% 0",
		fontSize: 18,
	},
	forgotPassword: {
		"&:hover": {
			color: "#007bff",
			cursor: "pointer",
			textDecoration: "underline",
		},
	},
	formHeader: {
		float: "left",
		fontWeight: 800,
		fontSize: 36,
		fontStyle: "normal",
		marginBottom: "1%",
		// marginTop: "4%"
	},
}));

export default function Demo({}) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [email, setEmail] = useState("");
	let history = useHistory();

	const isMobile = useMediaQuery(theme => theme?.breakpoints.down('sm'));
	const loading = useSelector((state) => state.demo.loading);

	const onSubmit = (e) => {
		e.preventDefault();
		dispatch(loadDemo(email, history, isMobile));
	};

	const demoPageHeader = () => {
		if (isMobile) {
			return (
				<Grid item xs={12}>
					<Box p={5} className={classes.top}>
						<Box className={classes.logoBox}>
							<Box width="275px">
								<img src={Logo} alt="Logo" />
							</Box>
						</Box>
					</Box>
				</Grid>
			)
		} else {
			return (
				<div className={classes.left}>
					<div
						className={`${classes.centered} ${classes.descriptionBox}`}
					>
						<Box width="250px">
							<img src={Logo} alt="Logo" />
						</Box>
						<Box mt={2}>
							<Typography className={classes.subDescription}>
								The modern Product Monograph platform
							</Typography>
						</Box>
					</div>
				</div>
			)
		}
	}

	const demoPageForm = () => {
		const formContent = (
			<Box className={classes.paper}>
				<Grid container>
					<Box
						className={classes.paper}
						display="flex"
						flexDirection="column"
					>
						<Grid item xs={12}>
							<Box width="100%">
								<Box>
									<form>
										<Box
											display="flex"
											flexDirection="column"
										>
											{!loading && (
												<Fade in={!loading}>
													<Typography
														className={classes.formHeader}
													>
														Get Started With Your Demo
													</Typography>
												</Fade>
											)}
											{loading && (
												<Fade in={loading}>
													<Typography
														className={classes.formHeader}
													>
														Creating Your Demo Environment...
													</Typography>
												</Fade>
											)}
											<Box>
												<Grid item xs={12}>
													<Typography
														className={
															classes.formLabel
														}
													>
														Email
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<TextField
														type="text"
														variant="outlined"
														placeholder="Enter Email Here"
														name="email"
														value={email}
														onChange={(e) =>
															setEmail(
																e.target
																	.value
															)
														}
														required
														className={
															classes.textField
														}
													/>
												</Grid>
											</Box>
										</Box>
										<Box mt={3}>
											<PMButton
												type="submit"
												loading={loading}
												loadingText={"This could take a few seconds"}
												disabled={loading}
												onClick={(e) => {
													onSubmit(e);
												}}
												className={
													classes.button
												}
											>
												Start Demo
											</PMButton>
										</Box>
									</form>
								</Box>{" "}
							</Box>
						</Grid>
					</Box>
				</Grid>
			</Box>
		)

		if (!isMobile) {
			return (
				<div className={classes.right}>
					<div className={classes.rightContainer}>
						{formContent}
					</div>
				</div>
			)
		} else {
			return (
				<Box className={classes.rightContainerMobile}>
					{formContent}
				</Box>
			)
		}
	}

	return (
		<>
			{demoPageHeader()}
			{demoPageForm()}
		</>
	);
}
