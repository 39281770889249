import axios from '../utils/customAxios'
import { USER_LOADED, AUTH_ERROR, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, AUTH_INITIATED, AUTH_LOADING, AUTH_LOADED, REFRESH_TOKEN_SUCCESS, REFRESH_LOADING, SESSION_CREATION, SESSION_TIMEOUT, USER_ONBOARDING } from './types'

import { success, error } from '../utils/SnackBar'
import { isEmpty } from '../utils/utilFunctions'

let refreshMutexLock = false

export const onboardUser = (email, phoneNumber, firstName, lastName, companyName, accessLevel) => async dispatch => {
    dispatch({
        type: USER_ONBOARDING,
        payload: true,
    })

    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify( { 
            email,
            phoneNumber,
            firstName,
            lastName,
            companyName,
            accessLevel
        })

        await axios.post('/api/auth/onboardUser', body, config)
        
        dispatch({
            type: USER_ONBOARDING,
            payload: false,
        })

        success("User onboarded successfully!")
    } catch(err) {
        dispatch({
            type: AUTH_ERROR
        })
        error("Something went wrong with user onboarding")
    }
}

// Load a User
export const loadUser = () => async dispatch => {
    if(!localStorage.token) {
        dispatch({
            type: AUTH_ERROR
        })
        error("Something went wrong with user authentication")
        return
    }

    dispatch({
        type: AUTH_LOADING,
        payload: true
    })

    try{
        const res = await axios.get('/api/auth')

        dispatch({
            type: USER_LOADED,
            payload: res.data
        })
    } catch(err) {
        dispatch({
            type: AUTH_ERROR
        })
        error("Something went wrong with user authentication")
    }
}

// Create a sessionID for a user
export const createSession = () => async dispatch => {
    dispatch({
        type: AUTH_LOADING,
        payload: true
    })

    try{
        const res = await axios.post('/api/auth/session')

        dispatch({
            type: SESSION_CREATION,
            payload: res.data.sessionID
        })
    } catch(err) {
        dispatch({
            type: AUTH_ERROR
        })
    }
}

// Invalidate a sessionID for a user
export const invalidateSession = () => async dispatch => {
    dispatch({
        type: AUTH_LOADING,
        payload: true
    })

    try {
        await axios.post('/api/forms/tryReleaseAllDocs')
    } catch (err) {
        error("There was an error releasing your locked documents.")
    }

    try{
        await axios.delete('/api/auth/session')

        dispatch({
            type: SESSION_TIMEOUT,
        })
    } catch(err) {
        dispatch({
            type: AUTH_ERROR
        })
    }
}

// Log user in
export const login = (email, password) => async dispatch => {

    dispatch({
        type: AUTH_LOADING,
        payload: true
    })

    if (isEmpty(email) || isEmpty(password)) {
        error("Both email and password fields need to be filled.")
        dispatch({
            type:LOGIN_FAIL
        })
        return;
    }

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify( { email, password })

    try {
        const res = await axios.post('/api/auth/login', body, config)

        if(res.data.token) {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data
            })
        } else {
            dispatch({
                type: AUTH_INITIATED,
                payload: res.data.status
            })
        }
    } catch (err) {
        error(err.response.data.message || "Failed to login")
        dispatch({
            type:LOGIN_FAIL
        })
    }
}

export const mfa = (email, code) => async dispatch => {

    dispatch({
        type: AUTH_LOADING,
        payload: true
    })

    if(isEmpty(email)) {
        error("Something went wrong. Please refresh and try again.")
        dispatch({
            type:LOGIN_FAIL
        })
        return;
    }

    if (isEmpty(code)) {
        error("The 2FA code must be entered")
        dispatch({
            type: AUTH_LOADED,
            payload: false
        })
        return;
    }

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify( { email, code })

    try {
        const res = await axios.post('/api/auth/mfa', body, config)

        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: AUTH_LOADED,
            payload: false
        })
        error(err.response.data.message || "Failed to login.")
    }
}

export const accountSetup = (email, password, confirmPassword) => async dispatch => {

    dispatch({
        type: AUTH_LOADING,
        payload: true
    })

    if(isEmpty(email)) {
        error("Something went wrong. Please refresh and try again.")
        dispatch({
            type:LOGIN_FAIL
        })
        return;
    }

    if (isEmpty(password) || isEmpty(confirmPassword)) {
        dispatch({
            type: AUTH_LOADED,
            payload: false
        })
        error("All password fields must be filled out")
        return
    }

    if (password !== confirmPassword) {
        dispatch({
            type: AUTH_LOADED,
            payload: false
        })
        error("Password and Confirm Password fields do not match")
        return
    }

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify( { email, password })

    try {
        const res = await axios.post('/api/auth/account-setup', body, config)

        if(res.data.token) {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data
            })
        } else {
            dispatch({
                type: AUTH_INITIATED,
                payload: res.data.status
            })
        }
    } catch (err) {
        dispatch({
            type: AUTH_LOADED,
            payload: false
        })
        error(err.response.data.message || "Failed to set up account.")
    }
}

export const forgotPassword = (email) => async dispatch => {

    dispatch({
        type: AUTH_LOADING,
        payload: true
    })

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    if (isEmpty(email)) {
        dispatch({
            type: AUTH_LOADED,
            payload: false
        })
        error("Email field cannot be empty")
        return
    }

    const body = JSON.stringify( { email })

    try {
        const res = await axios.post('/api/auth/forgot-password', body, config)

        dispatch({
            type: AUTH_INITIATED,
            payload: res.data.status
        })
    } catch (err) {
        dispatch({
            type: AUTH_LOADED,
            payload: false
        })
        error(err.response.data.message || "Failed to send forgot password email")
    }
}

export const resetPassword = (email, password, confirmPassword, code) => async dispatch => {

    dispatch({
        type: AUTH_LOADING,
        payload: true
    })

    if(isEmpty(email)) {
        error("Something went wrong. Please refresh and try again.")
        dispatch({
            type:LOGIN_FAIL
        })
        return;
    }

    if (isEmpty(password) || isEmpty(confirmPassword)) {
        dispatch({
            type: AUTH_LOADED,
            payload: false
        })
        error("All password fields must be filled out")
        return
    }

    if (password !== confirmPassword) {
        dispatch({
            type: AUTH_LOADED,
            payload: false
        })
        error("Password and Confirm Password fields do not match")
        return
    }

    if (isEmpty(code)) {
        error("The verification code must be entered")
        dispatch({
            type: AUTH_LOADED,
            payload: false
        })
        return;
    }

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify( { email, password, code })

    try {
        const res = await axios.post('/api/auth/reset-password', body, config)

        dispatch({
            type: AUTH_INITIATED,
            payload: res.data.status
        })
    } catch (err) {
        dispatch({
            type: AUTH_LOADED,
            payload: false
        })
        error(err.response.data.message || "Failed to reset password")
    }
}

export const changePassword = (oldPassword, newPassword, confirmNewPassword) => async dispatch => {

    dispatch({
        type: AUTH_LOADING,
        payload: true
    })

    if (isEmpty(oldPassword) || isEmpty(newPassword) || isEmpty(confirmNewPassword)) {
        dispatch({
            type: AUTH_LOADED,
            payload: false
        })
        error("All password fields must be filled out")
        return
    }

    if (newPassword !== confirmNewPassword) {
        dispatch({
            type: AUTH_LOADED,
            payload: false
        })
        error("New Password and Confirm New Password do not match")
        return
    }

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify( { oldPassword, newPassword })

    try {
        await axios.post('/api/auth/change-password', body, config)
        success("Password changed successfully!")
        dispatch({
            type: AUTH_LOADED,
            payload: false   
        })
    } catch (err) {
        dispatch({
            type: AUTH_LOADED,
            payload: false
        })
        error(err.response.data.message || "Failed to change password")
    }
}

export const refreshToken = (refreshToken) => async dispatch => {

    if(refreshMutexLock){
        return
    }

    dispatch({
        type: REFRESH_LOADING,
    })

    refreshMutexLock = true

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify( { refreshToken })

    try {
        const res = await axios.post('/api/auth/refresh-token', body, config)
        dispatch({
            type: REFRESH_TOKEN_SUCCESS,
            payload: res.data
        })
        refreshMutexLock = false
    } catch (err) {
        refreshMutexLock = false
        dispatch(logout())
        error(err.response.data.message || "Something went wrong. Please login again.")
    }
}

// Log user out
export const logout = () => async dispatch => {
    try {
        if(localStorage.getItem('token') && sessionStorage.getItem("sessionID")){
            await axios.post('/api/forms/tryReleaseAllDocs')
        }
        dispatch({type: LOGOUT})
    } catch (err) {
        error("There was an error trying to logout.")
    }
}

