import { Box } from '@material-ui/core';

export const englishTooltipLabels = {
    marketAuthorizationHolder:
        <Box>The Market Authorization Holder (MAH) is the legal entity that holds the Notice of Compliance and the Drug Identification Number (DIN).</Box>,
    canadianImporterDistributor:
        <Box>When the Market Authorization Holder is not located in Canada, the importer and/or distributor is required.</Box>,
    telephone:
        <Box>
            Phone numbers must be written with the prefix (+) sign. The country code, area code and phone number should be separated by a hyphen (no spaces).
            <br />
            Example: <b>+1-416-123-4567</b>
        </Box>,
    email:
        <Box>Email addresses must be written in the local-part@domain format.
            <br />
            Example: <b>example@company.com</b>
        </Box>,
    website:
        <Box>Web site addresses are written with the prefix ’http://' or 'https://' followed by the website.</Box>,
}

export const frenchTooltipLabels = {
    marketAuthorizationHolder:
        <Box>Le titulaire de l'autorisation de mise en marché (TAMM) est l'entité juridique qui détient l'avis de conformité et le numéro d'identification du médicament (DIN).</Box>,
    canadianImporterDistributor:
        <Box>Lorsque le titulaire de l'autorisation de mise en marché n'est pas situé au Canada, l'importateur et/ou le distributeur est requis.</Box>,
    telephone:
        <Box>Les numéros de téléphone doivent être écrits avec le préfixe (+). L'indicatif du pays, l'indicatif régional et le numéro de téléphone doivent être séparés par un trait d'union (sans espace).
            <br />
            Exemple: <b>+1-416-123-4567</b>
        </Box>,
    email:
        <Box>Les adresses e-mail doivent être écrites au format local-partie@domaine
            <br />
            Exemple: <b>example@company.com</b>
        </Box>,
    website:
        <Box>Les adresses de sites Web sont écrites avec le préfixe 'http://' ou 'https://' suivi du site Web.</Box>,
}