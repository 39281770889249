// Auth Actions
export const APP_VERSION_NUMBER = 'APP_VERSION_NUMBER'
export const VERSION_UPDATE_REQUIRED = 'VERSION_UPDATE_REQUIRED'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS'
export const REFRESH_LOADING = 'REFRESH_LOADING'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const USER_LOADED = 'USER_LOADED'
export const UPDATE_USER = 'UPDATE_USER'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_RESET = "AUTH_RESET"
export const AUTH_INITIATED = "AUTH_INITIATED"
export const AUTH_LOADING = "AUTH_LOADING"
export const AUTH_LOADED = "AUTH_LOADED"
export const LOGOUT = 'LOGOUT'
export const RESET_LOGOUT = 'RESET_LOGOUT'
export const SESSION_CREATION = "SESSION_CREATION"
export const SESSION_TIMEOUT = "SESSION_TIMEOUT"
export const USER_ONBOARDING = "USER_ONBOARDING"

// Form Actions
export const CREATE_FORM = "CREATE_FORM"
export const FORM_CREATED = "FORM_CREATED"
export const GET_FORMS = "GET_FORMS"
export const FILTER_FORMS = "FILTER_FORMS"
export const GET_FORM = "GET_FORM"
export const UPDATE_FORM = "UPDATE_FORM"
export const UPDATE_FORM_FIELD = "UPDATE_FORM_FIELD"
export const ARCHIVE_FORMS = "ARCHIVE_FORMS"
export const ARCHIVE_FORM_ID = "ARCHIVE_FORM_ID"
export const RESTORE_FORM = "RESTORE_FORM"
export const FORM_ERROR = "FORM_ERROR"
export const CLEAR_FORM = "CLEAR_FORM"
export const DOWNLOAD_FORM = "DOWNLOAD_FORM"
export const FORM_LOADING = "FORM_LOADING"
export const FORM_UPDATING = "FORM_UPDATING"
export const GET_FORM_VALIDATION = "GET_FORM_VALIDATION"
export const FORM_LOADED = "FORM_LOADED"
export const PREVIEW_DOWNLOAD_ENGLISH_STATUS = "PREVIEW_DOWNLOAD_ENGLISH_STATUS"
export const PREVIEW_DOWNLOAD_FRENCH_STATUS = "PREVIEW_DOWNLOAD_FRENCH_STATUS"
export const DOWNLOAD_ENGLISH_STATUS = "DOWNLOAD_ENGLISH_STATUS"
export const DOWNLOAD_FRENCH_STATUS = "DOWNLOAD_FRENCH_STATUS"
export const UPLOAD_ENGLISH_STATUS = "UPLOAD_ENGLISH_STATUS"
export const UPLOAD_FRENCH_STATUS = "UPLOAD_FRENCH_STATUS"
export const UPDATE_FORM_LAST_MODIFIED_INFO = "UPDATE_FORM_LAST_MODIFIED_INFO"
export const EDIT_STATE = "EDIT_STATE"
export const GET_VERSION_NUMBERS = "GET_VERSION_NUMBERS"
export const GET_ACCESS_LEVEL = "GET_ACCESS_LEVEL"
export const GET_USERS_USER_COMPANY_INFO = "GET_USERS_USER_COMPANY_INFO"
export const GET_FORM_ACCESS_CONTROL = "GET_FORM_ACCESS_CONTROL"
export const ARCHIVE_FORM = "ARCHIVE_FORM"
export const PMI_SCORE = "PMI_SCORE"
export const PMI_SCORE_FAILURE = "PMI_SCORE_FAILURE"
export const PMI_SUGGESTION_LOADING = "PMI_SUGGESTION_LOADING"
export const PMI_SUGGESTION_COMPLETE = "PMI_SUGGESTION_COMPLETE"
export const PMI_SUGGESTION_STREAMING = "PMI_SUGGESTION_STREAMING"
export const PMI_SUGGESTION_ERROR = "PMI_SUGGESTION_ERROR"
export const SET_CURRENT_SECTION_INDEX = "SET_CURRENT_SECTION_INDEX"
export const SET_UPLOAD_DIALOG_OPEN_STATUS = "SET_UPLOAD_DIALOG_OPEN_STATUS"
export const SET_UPLOAD_REPORT_OPEN_STATUS = "SET_UPLOAD_REPORT_OPEN_STATUS"

// Document Lock
export const CHECK_DOCUMENT_LOCK = "CHECK_DOCUMENT_LOCK"

// Session Status
export const SET_UNAUTHORIZED = "SET_UNAUTHORIZED"
export const SET_ACTIVE = "SET_ACTIVE"
export const SET_EXPIRING = "SET_EXPIRING"
export const SET_EXPIRED = "SET_EXPIRED"

// Chatbot
export const CHAT_LOADING = "CHAT_LOADING"
export const CHAT_STREAMING = "CHAT_STREAMING"

// Demo
export const DECREMENT_DEMO_STEP = "DECREMENT_DEMO_STEP"
export const DEMO_LOADING = "DEMO_LOADING"
export const SET_DEMO = "SET_DEMO"
export const SET_DEMO_STEP = "SET_DEMO_STEP"
export const UPDATE_DEMO_STATE = "UPDATE_DEMO_STATE"
export const INCREMENT_DEMO_STEP = "INCREMENT_DEMO_STEP"