import React, { useEffect, useState, useRef } from 'react';
import SendIcon from '@material-ui/icons/Send';
import { Box, IconButton, Paper, makeStyles, InputBase, Typography } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { queryChatbot } from '../../actions/chatbot';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../../assets/InstaPM.svg'
import { ThreeDots } from 'react-loader-spinner'

const useStyles = makeStyles({
    chatContainer: {
        zIndex: 1000,
		position: "fixed",
		bottom: 20,
		right: 50,
    },
	chatWindow: {
		position: "absolute",
		display: "flex",
		flexDirection: "column",
		bottom: "60px",
		right: 0,
		width: "350px",
		backgroundColor: "#fff",
		borderRadius: "15px"
	},
	chatHeaderContainer: {
		height: "60px",
		backgroundColor: "#007bff",
		justifyContent: "space-between",
		color: "white",
		borderTopRightRadius: "15px",
		borderTopLeftRadius: "15px"
	},
	chatHeaderTitle: {
		alignSelf: "flex-end",
		fontWeight: "bold",
        fontFamily: "'Roboto', sans-serif", 
	},
	messageBox: {
		display: "flex",
		flexDirection: "column",
		height: "20rem",
		overflowY: "scroll",
		paddingTop: "1rem",
	},
	userMessage: {
		alignSelf: "flex-end",
		maxWidth: "80%",
		marginBottom: "10px",
		marginRight: "10px",
		padding: "10px",
		fontSize: "14px",
		backgroundColor: "#007bff",
		color: "white",
		borderRadius: "15px"
	},
	chatbotMessage: {
		alignSelf: "flex-start",
		maxWidth: "80%",
		marginBottom: "10px",
		marginLeft: "10px",
		padding: "10px",
		fontSize: "14px",
		backgroundColor: "#f7f7f7",
		color: "black",
		borderRadius: "15px"
	},
	textbox: {
		paddingLeft: "10px",
		fontSize: "15px",
	},
	sendIcon: {
		color: "#007bff",
	}
});

export default function ChatBot(){
	const dispatch = useDispatch();
	const classes = useStyles();
	const lastMessageRef = useRef(null);
	const chatHistory = useSelector((state) => state.chatbot.chatHistory)

	const [isOpen, setIsOpen] = useState(false);
	const [input, setInput] = useState("");
	const [toggleInput, setToggleInput] = useState(false);

	const toggleChat = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		if (lastMessageRef.current) {
		  lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	  }, [chatHistory]);

  	const handleUserInput = async () => {
		if (input.trim() === '') {
			return
		}

		setToggleInput(true)

		try {
			dispatch(queryChatbot(input, chatHistory));
		} catch (error) {
			console.log(error.message);
		} finally {
			setInput("")
			setToggleInput(false)
		}
  	};

	const handleKeyDown = (event) => {
    	if (event.key === 'Enter') {
    		handleUserInput();
    	}
  	};

	return (
		<Box className={classes.chatContainer}>
			<Box onClick={toggleChat} width="3.5rem" style={{ cursor: 'pointer' }}>
				<img src={Logo} />
			</Box>
			{isOpen && (
				<Paper elevation={20} className={classes.chatWindow}>
					<Box padding={2} display="flex" className={classes.chatHeaderContainer}>
						<Box display="flex">
							<Box mr={1} onClick={toggleChat} width="2.5rem" style={{ marginTop: '-0.22rem' }}>
								<img src={Logo} />
							</Box>
							<Typography className={classes.chatHeaderTitle}>
								InstaGPT
							</Typography>
							<Box ml={0.5}>
								<Typography variant="caption" color="white">
									Beta
								</Typography>
							</Box>
						</Box>
						<Box display="flex">
							<Box style={{ cursor: 'pointer' }}>
								<CloseRoundedIcon onClick={() => { toggleChat(!isOpen) }} />
							</Box>
						</Box>
					</Box>
					<Box className={classes.messageBox}>
						{ chatHistory.length > 0 ? (
							chatHistory.map((messageTransaction) => (
								<>
									<Box className={classes.userMessage}>
										{messageTransaction.human}
									</Box>
									{ messageTransaction.ai ? (
										<Box className={classes.chatbotMessage}>
											{messageTransaction.ai}
										</Box>
									):(
										<Box ml={2}>
											<ThreeDots
												height="50"
												width="50"
												color="#007bff"
											/>
										</Box>
									)}
								</>
							))
						) : (
							<Box p={2} style={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
								<Typography>
									Welcome to InstaGPT. Ask me anything about Health Canada's Product Monograph Guidance
								</Typography>
							</Box>
						)}
						<Box ref={lastMessageRef}></Box>
					</Box>
					<Box width="100%" display="flex" justifyContent="space-between">
						<Box width="100%">
							<InputBase
								fullWidth
								disabled={toggleInput}
								onKeyDown={handleKeyDown}
								value={input}
								onChange={(e) => {setInput(e.target.value)}}
								type="text"
								placeholder="Message InstaGPT..."
								className={classes.textbox}
								endAdornment={
									<IconButton className={classes.sendIcon} size='sm' onClick={() => {handleUserInput()}}>
										<SendIcon />
									</IconButton>
								}
							/>
						</Box>
					</Box>
				</Paper>
			)}
		</Box>
	);
};
