import React from 'react'
import { useSelector } from 'react-redux'
import { Field } from 'formik';
import { Box } from "@material-ui/core"
import MaskedInput from "react-text-mask";
import parse from 'html-react-parser';
import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    comparisonField: {
        backgroundColor: "#e9ecef",
		padding: "0.75rem",
		minHeight: "3rem",
		borderRadius: "25px"
    },
});

export default function TextField({ width, columnStyling, updateFunction, label, name, placeholder, type=null, inputMask = null, showLabel=true }){
    const classes = useStyles();
	const editState = useSelector((state) => state.forms.editState)

	const textFieldUpdate = (name, value, isDirty) => {
		if(isDirty) {
			updateFunction(name, value)
		}
	}

	const genFieldValue = (field) => {
        try {
            return (field.value).toString()
        } catch {
            return ""
        }
    }

    return(
		<Box key={name} className={columnStyling ? `form-group col-md-${columnStyling}`: "form-group"} style={{width: width ? width : null}}>
			{ showLabel && (
				<label htmlFor={name}>{label}</label>
			)}
			<Field 
				name={name}
			>
				{({
					field,
					form: { dirty },
				}) => (
					editState.isComparison ? (
						<Box className={classes.comparisonField}>
							{parse(genFieldValue(field))}
						</Box>
					) : (
						inputMask != null ? (
							<Box>
								<MaskedInput
									{...field}
									mask={inputMask}
									type={type ?? "text"}
									placeholder = {placeholder}
									className={`form-control`}
									disabled={editState.isDisabled}
									onBlur={(e) => {textFieldUpdate(name, e.target.value, dirty)}}
								/>
							</Box>
						) : (
							<Box>
								<input 
									{...field}
									type={type ?? "text"}
									placeholder = {placeholder}
									className={`form-control`}
									disabled={editState.isDisabled}
									onBlur={(e) => {textFieldUpdate(name, e.target.value, dirty)}}
								/>
							</Box>
						)
					)
				)}
           </Field>
		</Box>
    )
}