import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress, Typography, IconButton } from '@material-ui/core';
import CircularProgressWithLabel from '../formComponents/CircularProgressWithLabel';
import { getPMISectionSuggestions } from '../../../actions/forms';
import ReactMarkdown from 'react-markdown'
import BlurOnIcon from '@material-ui/icons/BlurOn';
import PMButton from '../../common/Button';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../../assets/InstaPM.svg'
const useStyles = makeStyles({
    chatHeaderTitle: {
		alignSelf: "flex-end",
        fontWeight: "bold",
		fontSize: '1.2rem',
        fontFamily: "'Roboto', sans-serif",
    },
});

export default function PartThreeSuggestionPopup({editorContent, isEnglish}) {
	const dispatch = useDispatch()
	const [open, setOpen] = React.useState(false);
    const pmiScore = useSelector((state) => state.forms.pmiScore)
    const pmiSuggestion = useSelector((state) => state.forms.pmiSuggestion)
    const pmiSuggestionLoading = useSelector((state) => state.forms.pmiSuggestionLoading)
    const pmiSuggestionStreaming = useSelector((state) => state.forms.pmiSuggestionStreaming)
	const classes = useStyles();

	const handleClickOpen = () => {
	   setOpen(true);
	};
   
	const handleClose = () => {
	   setOpen(false);
	};

	useEffect(() => {
		if(open === false || pmiSuggestion !== null || pmiSuggestionLoading || pmiScore >= 70) {
			return
		}
		dispatch(getPMISectionSuggestions(editorContent, isEnglish))
	}, [open, pmiSuggestion, pmiSuggestionLoading, pmiScore])

	const genSuggestion = () => {
		if (pmiSuggestionLoading) {
			return (
				<Box textAlign="center">
					<CircularProgress />
				</Box>
			)
		}
		
		return pmiScore >= 70 ? (
				<Box>
					<Typography>
						It looks like your text is already at a Grade 6 to 8 reading level. No suggestions on our end!
					</Typography>
				</Box>
		) : (
			<ReactMarkdown>
				{pmiSuggestion}
			</ReactMarkdown>
		)
	}

	return (
		<Box>
			<Box mr={2}>
				<Button onClick={handleClickOpen}>
					<CircularProgressWithLabel value={pmiScore} />
				</Button>
			</Box>
			<Dialog
				open={open}
				onClose={handleClose}
				keepMounted
				scroll="paper"
				maxWidth="sm"
				PaperProps={{
					style: {
						width: '50vw',
						borderRadius: '25px',
						padding: '20px',
					},
				}}
			>
				<DialogTitle>
					<Box display="flex" flexDirection="row" justifyContent="space-between">
					<Box display="flex">
							<Box mr={1} width="2.5rem" style={{ marginTop: '-0.22rem' }}>
								<img src={Logo} />
							</Box>
                    <Box display="flex" mb={2} alignItems="baseline">
                        <Typography className={classes.chatHeaderTitle}>
                            InstaGPT
                        </Typography>
                        <Box ml={0.5}>
                            <Typography variant="caption" color="white">
                                Beta
                            </Typography>
                        </Box>
                    </Box>
						</Box>
						<Box>
							<IconButton onClick={handleClose} color='secondary'>
								<CancelIcon/>
							</IconButton>
						</Box>
					</Box>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{ genSuggestion() }
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Box display="flex" flexDirection="column">
						{pmiSuggestion && (
							<PMButton disabled={pmiSuggestionStreaming} outlined onClick={() => {dispatch(getPMISectionSuggestions(editorContent, isEnglish))}}>
								<AutorenewIcon />
								Regenerate Suggestions
							</PMButton>
						)}
						<Box mt={1}>
							<Typography style={{ fontSize: '0.8em', color: 'gray', opacity: 0.6 }}>
								Suggestions are intended solely to improve the readability of text and should be used as guidelines for enhancing the content.
							</Typography>
						</Box>
					</Box>
				</DialogActions>
			</Dialog>
		</Box>
	);
}
