import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link, Redirect } from 'react-router-dom'

const Logout = ({ isAuthenticated }) => {

    const [redirect, setRedirect] = useState(0);

    useEffect( () => {
        setTimeout( () => {setRedirect(true)} ,5000)
    }, [redirect])


    return isAuthenticated ? (
            <Redirect to = '/dashboard' />
        ) : redirect ? (
            <Redirect to = '/' /> 
        ) : (
            <Fragment>
                <h1 className = "large text-primary">
                    <i className="fas fa-sign-out-alt"></i>
                    &nbsp; Signed Out
                </h1>
                <h3 className = 'logout-text'> 
                    You have been successfully signed out from your account. You must log in to regain access to your account.
                </h3>
                <p className = 'logout-text'> 
                    Click <Link className='link' to ='/'>here</Link> to return back to the Landing page. Click <Link className='link' to='/login'>here</Link> to return back to the Login page.
                </p>
            </Fragment>
        )
}

Logout.propTypes = {
    isAuthenticated: PropTypes.bool,
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps, {})(Logout)