import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { makeStyles} from '@material-ui/core/styles';
import { Box, IconButton, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import PMButton from "../../common/Button";

import { updateFormField } from "../../../actions/forms"

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import LinkingDialog from './LinkingDialog';

const useStyles = makeStyles({
    button: {
        width: "50%",
    },
    veritcalText: {
        alignSelf: "center"
    },
    paper: {
        padding: "2rem",
        margin: "2rem 0rem",
        borderRadius: "25px"
    }
});


export default function Linking({ context }){
    const dispatch = useDispatch()
    const form = useSelector((state) => state.forms.form)
    const [isOpen, setIsOpen] = useState(false)
    const classes = useStyles();

    let formInput = context ? form && form.formDataFrench : form && form.formDataEnglish 

    const handleDelete = (index) => {
        formInput.footnotes.splice(index, 1)
        dispatch(updateFormField("footnotes", formInput.footnotes, form._id, context))
    }

    return(
        <Box mt={4}>
            <Paper className={classes.paper} elevation={4}>
                <Grid container>
                    <Grid xs={10} item className={classes.veritcalText}>
                        <Box>
                            <h6>Footnotes</h6>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box display="flex">
                            <Box width="100%" mr={2}>
                                <PMButton isFormButton={true} onClick={() => {setIsOpen(true)}}>
                                    Add
                                </PMButton>
                            </Box>
                        </Box>
                        <LinkingDialog 
                            isOpen = {isOpen}
                            setIsOpen = {setIsOpen}
                            context={context}
                        />
                    </Grid>
                </Grid>
                <Box mt={4}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow className={classes.header}>
                                    <TableCell>
                                        <Typography>
                                            Display Name
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            Footnote Text
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {formInput.footnotes.map((row, index) => (
                                <TableRow
                                    key={index}
                                >
                                    <TableCell>{row.title}</TableCell>
                                    <TableCell>{row.footnote_text}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => {handleDelete(index)}} color="secondary">
                                            <DeleteForeverIcon />    
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Paper>
        </Box>
    )
}
