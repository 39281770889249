import TitlePage from "../formSections/TitlePage";
import ProductDetails from '../formSections/ProductDetails';
import RecentMajorLabelChanges from "../formSections/RecentMajorLabelChanges";
import BiosimilarBiologicDrug from "../formSections/BiosimilarBiologicDrug";
import Linking from "../formSections/Linking";
import PartThree from "../formSections/PartThree";
import PartTwo from "../formSections/PartTwo";
import PartOne from "../formSections/PartOne";
import CompanyDetails from "../formSections/CompanyDetails";
import { SECTION_INDEXES } from './SectionIndexes';

export const FormSectionRouter = (index) => {
    switch(index) {
        case SECTION_INDEXES.TITLE_PAGE:
            return TitlePage
        case SECTION_INDEXES.PRODUCT_DETAILS:
            return null
        case SECTION_INDEXES.COMPANY_DETAILS:
            return CompanyDetails
        case SECTION_INDEXES.PRODUCTS:
            return ProductDetails
        case SECTION_INDEXES.RECENT_MAJOR_LABEL_CHANGES:
            return RecentMajorLabelChanges
        case SECTION_INDEXES.PART_ONE:
        case SECTION_INDEXES.INDICATIONS:
        case SECTION_INDEXES.PEDIATRICS:
        case SECTION_INDEXES.GERIATRICS:
        case SECTION_INDEXES.CONTRAINDICATIONS:
        case SECTION_INDEXES.SERIOUS_WARNINGS_AND_PRECAUTIONS_BOX:
        case SECTION_INDEXES.DOSAGE_AND_ADMINISTRATION:
        case SECTION_INDEXES.DOSING_CONSIDERATIONS:
        case SECTION_INDEXES.RECOMMENDED_DOSE_AND_DOSAGE_ADJUSTMENTS:
        case SECTION_INDEXES.RECONSTITUITION:
        case SECTION_INDEXES.ADMINISTRATION:
        case SECTION_INDEXES.MISSED_DOSE:
        case SECTION_INDEXES.IMAGE_ACQUISITION_AND_INTERPRETATION:
        case SECTION_INDEXES.INSTRUCTIONS_FOR_PREPARATION_AND_USE:
        case SECTION_INDEXES.RADIATION_DOSIMETRY:
        case SECTION_INDEXES.OVERDOSAGE:
        case SECTION_INDEXES.DOSAGE_FORMS_STRENGTHS_COMPOSITION_AND_PACKAGING:
        case SECTION_INDEXES.PHYSICAL_CHARACTERISTICS:
        case SECTION_INDEXES.EXTERNAL_RADIATION:
        case SECTION_INDEXES.WARNINGS_AND_PRECAUTIONS:
        case SECTION_INDEXES.SPECIAL_POPULATIONS:
        case SECTION_INDEXES.PREGNANT_WOMEN:
        case SECTION_INDEXES.BREAST_FEEDING:
        case SECTION_INDEXES.PEDIATRICS_WARNINGS_AND_PRECAUTIONS:
        case SECTION_INDEXES.GERIATRICS_WARNINGS_AND_PRECAUTIONS:
        case SECTION_INDEXES.ADVERSE_REACTIONS:
        case SECTION_INDEXES.ADVERSE_REACTIONS_OVERVIEW:
        case SECTION_INDEXES.CLINICAL_TRIAL_ADVERSE_REACTIONS:
        case SECTION_INDEXES.CLINICAL_TRIAL_ADVERSE_REACTIONS_PEDIATRICS:
        case SECTION_INDEXES.LESS_COMMON_CLINICAL_TRIAL_ADVERSE_REACTIONS:
        case SECTION_INDEXES.LESS_COMMON_CLINICAL_TRIAL_ADVERSE_REACTIONS_PEDIATRICS:
        case SECTION_INDEXES.ABNORMAL_LABORATORY_FINDINGS_HEMATOLOGIC:
        case SECTION_INDEXES.POST_MARKET_ADVERSE_REACTIONS:
        case SECTION_INDEXES.DRUG_INTERACTIONS:
        case SECTION_INDEXES.SERIOUS_DRUG_INTERACTIONS:
        case SECTION_INDEXES.DRUG_INTERACTIONS_OVERVIEW:
        case SECTION_INDEXES.DRUG_BEHAVIOURAL_INTERACTIONS:
        case SECTION_INDEXES.DRUG_DRUG_INTERACTIONS:
        case SECTION_INDEXES.DRUG_FOOD_INTERACTIONS:
        case SECTION_INDEXES.DRUG_HERB_INTERACTIONS:
        case SECTION_INDEXES.DRUG_LABORATORY_TEST_INTERACTIONS:
        case SECTION_INDEXES.ACTION_AND_CLINICAL_PHARMACOLOGY:
        case SECTION_INDEXES.MECHANISM_OF_ACTION:
        case SECTION_INDEXES.PHARMACODYNAMICS:
        case SECTION_INDEXES.PHARMACOKINETICS:
        case SECTION_INDEXES.STORAGE_STABILITY_AND_DISPOSAL:
        case SECTION_INDEXES.SPECIAL_HANDLING_INSTRUCTIONS:
            return PartOne
        case SECTION_INDEXES.PART_TWO:
        case SECTION_INDEXES.PHARMACEUTICAL_INFORMATION:
        case SECTION_INDEXES.CLINICAL_TRIALS:
        case SECTION_INDEXES.CLINICAL_TRIALS_BY_INDICATIONS:
        case SECTION_INDEXES.COMPARATIVE_BIOAVAILABILTY_STUDIES:
        case SECTION_INDEXES.IMMUNOGENICITY:
        case SECTION_INDEXES.CLINICAL_TRIALS_REFERENCE_BIOLOGIC_DRUG:
        case SECTION_INDEXES.MICROBIOLOGY:
        case SECTION_INDEXES.NON_CLINICAL_TOXICOLOGY:
        case SECTION_INDEXES.COMPARATIVE_NON_CLINICAL_PHARMACOLOGY_AND_TOXICOLOGY:
        case SECTION_INDEXES.COMPARATIVE_NON_CLINICAL_PHARMACODYNAMICS:
        case SECTION_INDEXES.COMPARATIVE_TOXICOLOGY:
        case SECTION_INDEXES.SUPPORTING_PRODUCT_MONOGRAPHS:
            return PartTwo
        case SECTION_INDEXES.PART_THREE:
        case SECTION_INDEXES.BRAND_NAME:
        case SECTION_INDEXES.PROPER_NAME:
        case SECTION_INDEXES.READ_THIS_FOR_SAFE_AND_EFFECTIVE_USE_OF_YOUR_MEDICINE:
        case SECTION_INDEXES.WHAT_IS_BRAND_NAME_USED_FOR:
        case SECTION_INDEXES.HOW_DOES_BRAND_NAME_WORK:
        case SECTION_INDEXES.WHAT_ARE_THE_INGREDIENTS_IN_BRAND_NAME:
        case SECTION_INDEXES.BRAND_NAME_DOSAGE_FORMS:
        case SECTION_INDEXES.DO_NOT_USE_BRAND_NAME_IF:
        case SECTION_INDEXES.HEALTH_CONDITIONS_OR_PROBLEMS:
        case SECTION_INDEXES.OTHER_WARNINGS_YOU_SHOULD_KNOW_ABOUT:
        case SECTION_INDEXES.THE_FOLLOWING_MAY_INTERACT_WITH_BRAND_NAME:
        case SECTION_INDEXES.HOW_TO_TAKE_BRAND_NAME:
        case SECTION_INDEXES.USUAL_DOSE:
        case SECTION_INDEXES.OVER_DOSE:
        case SECTION_INDEXES.MISSED_DOSE_PMI:
        case SECTION_INDEXES.POSSIBLE_SIDE_EFFECTS:
        case SECTION_INDEXES.STORAGE:
        case SECTION_INDEXES.MORE_INFORMATION:
            return PartThree
        case SECTION_INDEXES.BIOSIMILAR_BIOLOGIC_DRUG:
            return BiosimilarBiologicDrug
        case SECTION_INDEXES.FOOTNOTES:
            return Linking
        default:
            return null
    }
}