export const englishLabels = {
    title: "REGULATORY STATUS",
    regulatoryActivityType: "Regulatory Activity Type*",
    regulatoryControlNumber: "Control Number*",
    dateOfApproval: "Date of Approval",
    dateOfCancellation: "Date of Cancellation",
    datePlaceholder: "MM/DD/YYYY",
}

export const frenchLabels = {
    title: "STATUT RÉGLEMENTAIRE",
    regulatoryActivityType: "Type D'activité Réglementaire*",
    regulatoryControlNumber: "Numéro De Contrôle De La Présentation*",
    dateOfApproval: "Date D'approbation",
    dateOfCancellation: "Date D'annulation",
    datePlaceholder: "MM/DD/YYYY",
}
