export const SECTION_INDEXES = {
    TITLE_PAGE: 0,
    PRODUCT_DETAILS: 1,
    COMPANY_DETAILS: 2,
    PRODUCTS: 3,
    RECENT_MAJOR_LABEL_CHANGES: 4,
    PART_ONE: 5,
    INDICATIONS: 6,
    PEDIATRICS: 7,
    GERIATRICS: 8,
    CONTRAINDICATIONS: 9,
    SERIOUS_WARNINGS_AND_PRECAUTIONS_BOX: 10,
    DOSAGE_AND_ADMINISTRATION: 11,
    DOSING_CONSIDERATIONS: 12,
    RECOMMENDED_DOSE_AND_DOSAGE_ADJUSTMENTS: 13,
    RECONSTITUITION: 14,
    ADMINISTRATION: 15,
    MISSED_DOSE: 16,
    IMAGE_ACQUISITION_AND_INTERPRETATION: 17,
    INSTRUCTIONS_FOR_PREPARATION_AND_USE: 18,
    RADIATION_DOSIMETRY: 19,
    OVERDOSAGE: 20,
    DOSAGE_FORMS_STRENGTHS_COMPOSITION_AND_PACKAGING: 21,
    PHYSICAL_CHARACTERISTICS: 22,
    EXTERNAL_RADIATION: 23,
    WARNINGS_AND_PRECAUTIONS: 24,
    SPECIAL_POPULATIONS: 25,
    PREGNANT_WOMEN: 26,
    BREAST_FEEDING: 27,
    PEDIATRICS_WARNINGS_AND_PRECAUTIONS: 28,
    GERIATRICS_WARNINGS_AND_PRECAUTIONS: 29,
    ADVERSE_REACTIONS: 30,
    ADVERSE_REACTIONS_OVERVIEW: 31,
    CLINICAL_TRIAL_ADVERSE_REACTIONS: 32,
    CLINICAL_TRIAL_ADVERSE_REACTIONS_PEDIATRICS: 33,
    LESS_COMMON_CLINICAL_TRIAL_ADVERSE_REACTIONS: 34,
    LESS_COMMON_CLINICAL_TRIAL_ADVERSE_REACTIONS_PEDIATRICS: 35,
    ABNORMAL_LABORATORY_FINDINGS_HEMATOLOGIC: 36,
    POST_MARKET_ADVERSE_REACTIONS: 37,
    DRUG_INTERACTIONS: 38,
    SERIOUS_DRUG_INTERACTIONS: 39,
    DRUG_INTERACTIONS_OVERVIEW: 40,
    DRUG_BEHAVIOURAL_INTERACTIONS: 41,
    DRUG_DRUG_INTERACTIONS: 42,
    DRUG_FOOD_INTERACTIONS: 43,
    DRUG_HERB_INTERACTIONS: 44,
    DRUG_LABORATORY_TEST_INTERACTIONS: 45,
    ACTION_AND_CLINICAL_PHARMACOLOGY: 46,
    MECHANISM_OF_ACTION: 47,
    PHARMACODYNAMICS: 48,
    PHARMACOKINETICS: 49,
    STORAGE_STABILITY_AND_DISPOSAL: 50,
    SPECIAL_HANDLING_INSTRUCTIONS: 51,
    PART_TWO: 52,
    PHARMACEUTICAL_INFORMATION: 53,
    CLINICAL_TRIALS: 54,
    CLINICAL_TRIALS_BY_INDICATIONS: 55,
    COMPARATIVE_BIOAVAILABILTY_STUDIES: 56,
    IMMUNOGENICITY: 57,
    CLINICAL_TRIALS_REFERENCE_BIOLOGIC_DRUG: 58,
    MICROBIOLOGY: 59,
    NON_CLINICAL_TOXICOLOGY: 60,
    COMPARATIVE_NON_CLINICAL_PHARMACOLOGY_AND_TOXICOLOGY: 61,
    COMPARATIVE_NON_CLINICAL_PHARMACODYNAMICS: 62,
    COMPARATIVE_TOXICOLOGY: 63,
    SUPPORTING_PRODUCT_MONOGRAPHS: 64,
    PART_THREE: 65,
    BRAND_NAME: 66,
    READ_THIS_FOR_SAFE_AND_EFFECTIVE_USE_OF_YOUR_MEDICINE: 67,
    WHAT_IS_BRAND_NAME_USED_FOR: 68,
    HOW_DOES_BRAND_NAME_WORK: 69,
    WHAT_ARE_THE_INGREDIENTS_IN_BRAND_NAME: 70,
    BRAND_NAME_DOSAGE_FORMS: 71,
    DO_NOT_USE_BRAND_NAME_IF: 72,
    HEALTH_CONDITIONS_OR_PROBLEMS: 73,
    OTHER_WARNINGS_YOU_SHOULD_KNOW_ABOUT: 74,
    THE_FOLLOWING_MAY_INTERACT_WITH_BRAND_NAME: 75,
    HOW_TO_TAKE_BRAND_NAME: 76,
    USUAL_DOSE: 77,
    OVER_DOSE: 78,
    MISSED_DOSE_PMI: 79,
    POSSIBLE_SIDE_EFFECTS: 80,
    STORAGE: 81,
    MORE_INFORMATION: 82,
    BIOSIMILAR_BIOLOGIC_DRUG: 83,
    FOOTNOTES: 84,
    PROPER_NAME: 85
}

export const EXPAND_TREES = {
    [SECTION_INDEXES.TITLE_PAGE]: ['0'],
    [SECTION_INDEXES.PRODUCT_DETAILS]: ['1'],
    [SECTION_INDEXES.COMPANY_DETAILS]: ['1', '2'],
    [SECTION_INDEXES.PRODUCTS]: ['1', '3'],
    [SECTION_INDEXES.RECENT_MAJOR_LABEL_CHANGES]: ['4'],
    [SECTION_INDEXES.PART_ONE]: ['5'],
    [SECTION_INDEXES.INDICATIONS]: ['5', '6'],
    [SECTION_INDEXES.PEDIATRICS]: ['5', '6', '7'],
    [SECTION_INDEXES.GERIATRICS]: ['5', '6', '8'],
    [SECTION_INDEXES.CONTRAINDICATIONS]: ['5', '9'],
    [SECTION_INDEXES.SERIOUS_WARNINGS_AND_PRECAUTIONS_BOX]: ['5', '10'],
    [SECTION_INDEXES.DOSAGE_AND_ADMINISTRATION]: ['5', '11'],
    [SECTION_INDEXES.DOSING_CONSIDERATIONS]: ['5', '11', '12'],
    [SECTION_INDEXES.RECOMMENDED_DOSE_AND_DOSAGE_ADJUSTMENTS]: ['5', '11', '13'],
    [SECTION_INDEXES.RECONSTITUITION]: ['5', '11', '14'],
    [SECTION_INDEXES.ADMINISTRATION]: ['5', '11', '15'],
    [SECTION_INDEXES.MISSED_DOSE]: ['5', '11', '16'],
    [SECTION_INDEXES.IMAGE_ACQUISITION_AND_INTERPRETATION]: ['5', '11', '17'],
    [SECTION_INDEXES.INSTRUCTIONS_FOR_PREPARATION_AND_USE]: ['5', '11', '18'],
    [SECTION_INDEXES.RADIATION_DOSIMETRY]: ['5', '11', '19'],
    [SECTION_INDEXES.OVERDOSAGE]: ['5', '20'],
    [SECTION_INDEXES.DOSAGE_FORMS_STRENGTHS_COMPOSITION_AND_PACKAGING]: ['5', '21'],
    [SECTION_INDEXES.PHYSICAL_CHARACTERISTICS]: ['5', '21', '22'],
    [SECTION_INDEXES.EXTERNAL_RADIATION]: ['5', '21', '23'],
    [SECTION_INDEXES.WARNINGS_AND_PRECAUTIONS]: ['5', '24'],
    [SECTION_INDEXES.SPECIAL_POPULATIONS]: ['5', '24', '25'],
    [SECTION_INDEXES.PREGNANT_WOMEN]: ['5', '24', '25', '26'],
    [SECTION_INDEXES.BREAST_FEEDING]: ['5', '24', '25', '27'],
    [SECTION_INDEXES.PEDIATRICS_WARNINGS_AND_PRECAUTIONS]: ['5', '24', '25', '28'],
    [SECTION_INDEXES.GERIATRICS_WARNINGS_AND_PRECAUTIONS]: ['5', '24', '25', '29'],
    [SECTION_INDEXES.ADVERSE_REACTIONS]: ['5', '30'],
    [SECTION_INDEXES.ADVERSE_REACTIONS_OVERVIEW]: ['5', '30', '31'],
    [SECTION_INDEXES.CLINICAL_TRIAL_ADVERSE_REACTIONS]: ['5', '30', '32'],
    [SECTION_INDEXES.CLINICAL_TRIAL_ADVERSE_REACTIONS_PEDIATRICS]: ['5', '30', '32', '33'],
    [SECTION_INDEXES.LESS_COMMON_CLINICAL_TRIAL_ADVERSE_REACTIONS]: ['5', '30', '34'],
    [SECTION_INDEXES.LESS_COMMON_CLINICAL_TRIAL_ADVERSE_REACTIONS_PEDIATRICS]: ['5', '30', '34', '35'],
    [SECTION_INDEXES.ABNORMAL_LABORATORY_FINDINGS_HEMATOLOGIC]: ['5', '30', '36'],
    [SECTION_INDEXES.POST_MARKET_ADVERSE_REACTIONS]: ['5', '30', '37'],
    [SECTION_INDEXES.DRUG_INTERACTIONS]: ['5', '38'],
    [SECTION_INDEXES.SERIOUS_DRUG_INTERACTIONS]: ['5', '38', '39'],
    [SECTION_INDEXES.DRUG_INTERACTIONS_OVERVIEW]: ['5', '38', '40'],
    [SECTION_INDEXES.DRUG_BEHAVIOURAL_INTERACTIONS]: ['5', '38', '41'],
    [SECTION_INDEXES.DRUG_DRUG_INTERACTIONS]: ['5', '38', '42'],
    [SECTION_INDEXES.DRUG_FOOD_INTERACTIONS]: ['5', '38', '43'],
    [SECTION_INDEXES.DRUG_HERB_INTERACTIONS]: ['5', '38', '44'],
    [SECTION_INDEXES.DRUG_LABORATORY_TEST_INTERACTIONS]: ['5', '45'],
    [SECTION_INDEXES.ACTION_AND_CLINICAL_PHARMACOLOGY]: ['5', '46'],
    [SECTION_INDEXES.MECHANISM_OF_ACTION]: ['5', '46', '47'],
    [SECTION_INDEXES.PHARMACODYNAMICS]: ['5', '46', '48'],
    [SECTION_INDEXES.PHARMACOKINETICS]: ['5', '46', '49'],
    [SECTION_INDEXES.STORAGE_STABILITY_AND_DISPOSAL]: ['5', '50'],
    [SECTION_INDEXES.SPECIAL_HANDLING_INSTRUCTIONS]: ['5', '51'],
    [SECTION_INDEXES.PART_TWO]: ['52'],
    [SECTION_INDEXES.PHARMACEUTICAL_INFORMATION]: ['52', '53'],
    [SECTION_INDEXES.CLINICAL_TRIALS]: ['52', '54'],
    [SECTION_INDEXES.CLINICAL_TRIALS_BY_INDICATIONS]: ['52', '54', '55'],
    [SECTION_INDEXES.COMPARATIVE_BIOAVAILABILTY_STUDIES]: ['52', '54', '56'],
    [SECTION_INDEXES.IMMUNOGENICITY]: ['52', '54', '57'],
    [SECTION_INDEXES.CLINICAL_TRIALS_REFERENCE_BIOLOGIC_DRUG]: ['52', '54', '58'],
    [SECTION_INDEXES.MICROBIOLOGY]: ['52', '59'],
    [SECTION_INDEXES.NON_CLINICAL_TOXICOLOGY]: ['52', '60'],
    [SECTION_INDEXES.COMPARATIVE_NON_CLINICAL_PHARMACOLOGY_AND_TOXICOLOGY]: ['52', '60', '61'],
    [SECTION_INDEXES.COMPARATIVE_NON_CLINICAL_PHARMACODYNAMICS]: ['52', '60', '61', '62'],
    [SECTION_INDEXES.COMPARATIVE_TOXICOLOGY]: ['52', '60', '61', '63'],
    [SECTION_INDEXES.SUPPORTING_PRODUCT_MONOGRAPHS]: ['52', '64'],
    [SECTION_INDEXES.PART_THREE]: ['65'],
    [SECTION_INDEXES.BRAND_NAME]: ['65', '66'],
    [SECTION_INDEXES.PROPER_NAME]: ['65', '85'],
    [SECTION_INDEXES.READ_THIS_FOR_SAFE_AND_EFFECTIVE_USE_OF_YOUR_MEDICINE]: ['65', '67'],
    [SECTION_INDEXES.WHAT_IS_BRAND_NAME_USED_FOR]: ['65', '68'],
    [SECTION_INDEXES.HOW_DOES_BRAND_NAME_WORK]: ['65', '69'],
    [SECTION_INDEXES.WHAT_ARE_THE_INGREDIENTS_IN_BRAND_NAME]: ['65', '70'],
    [SECTION_INDEXES.BRAND_NAME_DOSAGE_FORMS]: ['65', '71'],
    [SECTION_INDEXES.DO_NOT_USE_BRAND_NAME_IF]: ['65', '72'],
    [SECTION_INDEXES.HEALTH_CONDITIONS_OR_PROBLEMS]: ['65', '73'],
    [SECTION_INDEXES.OTHER_WARNINGS_YOU_SHOULD_KNOW_ABOUT]: ['65', '74'],
    [SECTION_INDEXES.THE_FOLLOWING_MAY_INTERACT_WITH_BRAND_NAME]: ['65', '75'],
    [SECTION_INDEXES.HOW_TO_TAKE_BRAND_NAME]: ['65', '76'],
    [SECTION_INDEXES.USUAL_DOSE]: ['65', '77'],
    [SECTION_INDEXES.OVER_DOSE]: ['65', '78'],
    [SECTION_INDEXES.MISSED_DOSE_PMI]: ['65', '79'],
    [SECTION_INDEXES.POSSIBLE_SIDE_EFFECTS]: ['65', '80'],
    [SECTION_INDEXES.STORAGE]: ['65', '81'],
    [SECTION_INDEXES.MORE_INFORMATION]: ['65', '82'],
    [SECTION_INDEXES.BIOSIMILAR_BIOLOGIC_DRUG]: ['83'],
    [SECTION_INDEXES.FOOTNOTES]: ['84'],
}
