import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector } from 'react-redux'
import throttle from 'lodash/throttle';
import axios from '../../../utils/customAxios'
import _ from "lodash"
import { Box, TextField } from "@material-ui/core"
import { makeStyles} from '@material-ui/core/styles';
import parse from 'html-react-parser';
import { Field } from 'formik';

const useStyles = makeStyles((theme) => ({
    autoComplete: (props) => ({
		backgroundColor: props.disabled ? "#e9ecef !important" : "",
        "& .MuiOutlinedInput-notchedOutline": {
            top: 0,
        },
    }),
    comparisonField: {
        backgroundColor: "#e9ecef",
		padding: "0.75rem",
		minHeight: "3rem",
		borderRadius: "25px"
    }
}));

export default function AutocompleteField({ width, codeSystem, context, columnStyling, updateFunction, label, name, showLabel=true}){
    
    const form = useSelector((state) => state.forms.form)
    const editState = useSelector((state) => state.forms.editState)
    const [options, setOptions] = useState([]);
    const [dropdownText, setDropdownText] = useState("Start typing")
    let formInput = context ? form && form.formDataFrench : form && form.formDataEnglish 
    const [value, setValue] = useState(formInput && _.get(formInput, name));
    const classes = useStyles({ disabled: editState.isDisabled });

    const initialState = _.get(formInput, name) ?? {}

    useEffect(() => {
        setOptions([])
        setValue(formInput && _.get(formInput, name))
    }, [context, formInput, name])

    const getCodes = useCallback((searchTerm, optionLoad) => {
        axios.get(`/api/forms/codes?searchTerm=${searchTerm}&code_system=${codeSystem}&context=${context}`)
            .then((response) => {
                optionLoad(response.data)
            })
    }, [codeSystem, context]);

    const fetch = useMemo(
        () => {
            return throttle((request, callback) => {
                getCodes(request.input, callback)
            }, 100)
        },
        [getCodes]
    );

    const genFieldValue = (field) => {
        try {
            return (typeof field.value === "object") ? field.value.codeInfo.displayName : parse((field.value).toString())
        } catch {
            return ""
        }
    }

    const optionLabel = (option) => {
        if (!option || Object.keys(option).length === 0){
            return null
        }

        if (option?.codeInfo){
            if (Object.keys(option?.codeInfo).length === 0){
                return null
            }
            return `${option?.codeInfo?.displayName} - ${option?.codeInfo?.code}`
        }

        let label = context === false ? `${option?.display_name_en} - ${option?.code}` : `${option?.display_name_fr} - ${option?.code}`
        return label ?? null
    }

    return (
        <Box key={name} className={columnStyling ? `form-group col-md-${columnStyling}`: "form-group"} style={{width: width ? width : null}}>
            {showLabel && (
                <label htmlFor={name}>{label}</label>
            )}
            {editState.isComparison ? (
                <Field name={name}>
                    {({ field }) => (
                        <Box className={classes.comparisonField}>
                            {genFieldValue(field)}
                        </Box>
                    )}
                </Field>
            ) : (
                <Autocomplete
                    id={name}
                    name={name}
                    autoComplete
                    noOptionsText={dropdownText}
                    disabled={editState.isDisabled}
                    autoHighlight
                    options={options}
                    value={value}
                    clearOnBlur
                    className={classes.autoComplete}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name={name}
                            variant="outlined"
                        />
                    )}
                    filterOptions={(x) => x}
                    getOptionLabel={option => { return optionLabel(option) }}
                    onChange={(event, newValue) => {
                        if(newValue && newValue.code !== null) {
                            initialState["codeInfo"] = {
                                    code: newValue.code,
                                    displayName: context === false ? newValue.display_name_en : newValue.display_name_fr,
                                    codeSystem: newValue.code_system,
                            }
                        } else {
                            initialState["codeInfo"] = {}
                        }
                        updateFunction(name, initialState);
                        setValue(newValue)
                    }}
                    onInputChange={(event, newValue) => {
                        fetch({ input: newValue }, (results) => {
                            let newOptions = [];
                
                            
                            if (results) {
                                newOptions = [...results];
                            }
                            if (newValue !== ""){
                                setDropdownText("No other results found")
                            } else {
                                setDropdownText("Start typing")
                            }
                            
                            setOptions(newOptions);
                        });
                    }}
                />
            )}
        </Box>
    )
}
