import React from 'react'
import { useSelector } from 'react-redux'

import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, CircularProgress } from '@material-ui/core';

import PMButton from '../common/Button';

import PreviewDownload from './PreviewDownload'

export default function PreviewDialog({ context, isPreviewDialogOpen, setIsPreviewDialogOpen }) {

    const formUpdating = useSelector((state) => state.forms.updating)

    return (
        <Dialog
            open={isPreviewDialogOpen}
            keepMounted
            scroll="paper"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>
                {`Preview Your ${context ? "French": "English"} PM`}
            </DialogTitle>
            <DialogContent dividers={true}>
                {formUpdating ? (
                    <Box textAlign="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <Grid spacing={3} container>
                        <Grid item xs={12}>
                            <PreviewDownload
                                context={context} 
                                isPreviewDialogOpen={isPreviewDialogOpen}
                            />
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <PMButton onClick={() => setIsPreviewDialogOpen(false)}>Close</PMButton>
            </DialogActions>
        </Dialog>
    )
}