export const englishLabels = {
    title: "PRODUCT INFORMATION",
    brandName: "Brand Name*",
    nonProprietaryName: "Non-Proprietary Name*",
    DIN: "Drug Identification Number (DIN)*",
    routeOfAdministration: "Route of Administration",
    dosageForm: "Dosage Form*"
}

export const frenchLabels = {
    title: "INFORMATION PRODUIT",
    brandName: "Marque Nominative*",
    nonProprietaryName: "Nom Non-Propriétaire*",
    DIN: "Numéro D'identification du Médicament (NDM)*",
    routeOfAdministration: "Voie D'administration",
    dosageForm: "Forme Posologique*"
}