import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getArchive, archive } from '../../actions/forms';

import { Box, CircularProgress, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import moment from "moment"

import PMButton from '../common/Button';
import ConfirmationDialog from "./ConfirmationDialog"
import VersionCompare from './VersionCompare';

const useStyles = makeStyles(() => ({
    paper: {
        minWidth: "1000px"
    },
    table: {
        height: "max-content",
        '& > tbody > tr:last-child > *': {
            border: "none"
        }
    },
    pageBar: {
        display: "flex",
        flexDirection: "row",
    },
    icon: {
        height: "15px",
        width: "15px"
    },
    clickable: {
        cursor: "pointer"
    },
    tableBox: {
        height: "70vh"
    },
    tableCellText: {
        fontWeight: 400,
        fontSize: 15
    },
    patientRow: {
        cursor: "pointer",
        transitionDuration: '0.1s',
        "&:hover": {
            backgroundColor: "#d9d9d9",
            color: "black"
        }
    },
    headerCell: {
        borderBottomColor: '#5C71FA'
    },
    emptyRow: {
        paddingTop: "15vh",
        border: "none"
    },
    noSurveys: {
        textAlign: "center",
        fontWeight: 700,
        fontSize: 20,
        color: "#979A9F",
    },
    loadingCell: {
        textAlign: "center"
    }
}));

export default function VersionHistoryDialog({ context, isVersionHistoryDialogOpen, setIsVersionHistoryDialogOpen }) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const form = useSelector((state) => state.forms.form)
    const archivedForms = useSelector((state) => state.forms.archivedForms)
    const formUpdating = useSelector((state) => state.forms.updating)
    const loading = useSelector((state) => state.forms.loading)

    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
    const [majorVersionNumber, setMajorVersionNumber] = useState()
    const [minorVersionNumber, setMinorVersionNumber] = useState()

    useEffect(() => {
        if (isVersionHistoryDialogOpen === true && !formUpdating) {
            dispatch(getArchive(form._id, context))
        }
    }, [isVersionHistoryDialogOpen, formUpdating, form._id, context, dispatch])

    const handleCreateVersion = () => {
        dispatch(archive(form._id, context))
    }

    const setHandleRestoreClick = (majorVersion, minorVersion) => {
        setConfirmationDialogOpen(true)
        setMajorVersionNumber(majorVersion)
        setMinorVersionNumber(minorVersion)
    }

    const handleOpenArchive = (majorVersion, minorVersion) => {
        window.open(`/archive/${form._id}?majorVersionNumber=${majorVersion}&minorVersionNumber=${minorVersion}&isEnglish=${!context}&section=0`, '_blank')
    }

    return (
        <Dialog
            open={isVersionHistoryDialogOpen}
            keepMounted
            scroll="paper"
            maxWidth="lg"
        >
            <DialogTitle>
                <Grid container>
                    <Grid item xs={10}>
                        <Typography variant="h5">
                            {context ? "French" : "English"} Version History
                        </Typography>

                    </Grid>
                    <Grid item xs={2}>
                        <PMButton
                            disabled={loading}
                            onClick={() => { handleCreateVersion() }}
                        >
                            Create Version
                        </PMButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        <Box boxShadow={1} borderRadius={16}>
                            <TableContainer className={classes.tableBox}>
                                <Table className={classes.table}>
                                    <colgroup>
                                        <col style={{ width: '30%' }} />
                                        <col style={{ width: '20%' }} />
                                        <col style={{ width: '10%' }} />
                                        <col style={{ width: '40%' }} />
                                    </colgroup>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.headerCell}>
                                                Archive Date
                                            </TableCell>
                                            <TableCell className={classes.headerCell}>
                                                Document ID
                                            </TableCell>
                                            <TableCell className={classes.headerCell}>
                                                Version
                                            </TableCell>
                                            <TableCell className={classes.headerCell} />
                                        </TableRow>
                                    </TableHead>
                                    {formUpdating ? (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className={classes.loadingCell} colSpan={3}>
                                                    <CircularProgress />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            {archivedForms.map((archivedForm, index) => {
                                                return (
                                                    <TableRow className={classes.patientRow} key={form._id}>
                                                        <TableCell>
                                                            {archivedForm.date ? moment(archivedForm.date).format("LLL") : ""}
                                                        </TableCell>
                                                        <TableCell>
                                                            {archivedForm.documentID}
                                                        </TableCell>
                                                        <TableCell>
                                                            {archivedForm.majorVersionNumber}.{archivedForm?.minorVersionNumber}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6}>
                                                                    <PMButton outlined onClick={() => { handleOpenArchive(archivedForm.majorVersionNumber, archivedForm.minorVersionNumber) }}>
                                                                        View
                                                                    </PMButton>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <PMButton outlined onClick={() => { setHandleRestoreClick(archivedForm.majorVersionNumber, archivedForm.minorVersionNumber) }}>
                                                                        Restore
                                                                    </PMButton>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    )}
                                    <ConfirmationDialog
                                        context={context}
                                        confirmationDialogOpen={confirmationDialogOpen}
                                        setConfirmationDialogOpen={setConfirmationDialogOpen}
                                        majorVersionNumber={majorVersionNumber}
                                        minorVersionNumber={minorVersionNumber}
                                    />
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box p={2}>
                            <VersionCompare
                                context={context}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <PMButton loading={loading} disabled={loading} red onClick={() => setIsVersionHistoryDialogOpen(false)}>Close</PMButton>
            </DialogActions>
        </Dialog>
    )
}
