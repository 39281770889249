import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, CircularProgress } from '@material-ui/core';

import PMButton from '../common/Button';

import { INCREMENT_DEMO_STEP } from '../../actions/types';

import SubmissionDownload from './SubmissionDownload'

export default function DownloadDialog({ context, isDownloadDialogOpen, setIsDownloadDialogOpen }) {
    const dispatch = useDispatch()
    const formUpdating = useSelector((state) => state.forms.updating)

    const handleDownloadDialogClose = (e) => {
        e.preventDefault()
        
        setIsDownloadDialogOpen(false)
        dispatch({
            type: INCREMENT_DEMO_STEP,
        })

    }

    return (
        <Dialog
            open={isDownloadDialogOpen}
            keepMounted
            scroll="paper"
            maxWidth="sm"
        >
            <DialogTitle>
                {`Download Your ${context ? "French": "English"} PM`}
            </DialogTitle>
            <DialogContent dividers={true}>
                {formUpdating ? (
                    <Box textAlign="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <Grid spacing={3} container>
                        <Grid item xs={12}>
                            <SubmissionDownload 
                                context={context} 
                                isDownloadDialogOpen={isDownloadDialogOpen}
                            />
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Box width="100%" className="close-download">
                    <PMButton onClick={(e) => handleDownloadDialogClose(e)}>Close</PMButton>
                </Box>
            </DialogActions>
        </Dialog>
    )
}