import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'

import { Box, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { forgotPassword } from '../../actions/auth';

import PMButton from '../common/Button';

const useStyles = makeStyles(() => ({
    formHeader: {
        float: "left",
        fontWeight: 800,
        fontSize: 36,
        fontStyle: "normal",
        marginBottom: "1%",
    },
    formLabel: {
        float: "left",
        margin: "2% 0% 2% 0",
        fontSize: 18
    },
    forgotPassword: {
        '&:hover': {
            color: "#007bff",
            cursor: "pointer",
            textDecoration: "underline",
        },
    },
}));

const ForgotPassword = ({email, setEmail}) => {

	const classes = useStyles();
    const loading = useSelector((state) => state.auth.loading)
    let history = useHistory()
    const dispatch = useDispatch()


    const handleBackToLogin = () => {
        history.push('/login')
    }

    const handleSubmit = async(event) => {
        event.preventDefault();
        dispatch(forgotPassword(email))
	}

    const forgotPasswordPane = () => {
        return (
            <>
                <Grid align="left" item xs={12}>
                    <Typography>
                        Enter the email associated with your InstaPM account. If the account exists, we will email you a verification code to reset your password.
                    </Typography>
                </Grid>
                <Box mb={2} width="100%">
                    <Grid item xs={12}>
                        <Typography className ={classes.formLabel}>
                            Email
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="username"
                            placeholder="Enter Email Here"
                            name="username"
                            required
                            autoFocus
                            InputProps = {{ className: classes.input }}
                            value={email}
                            onChange = {(e) => {setEmail(e.target.value)}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box mt={2} >
                            <Typography align="right">
                                <span onClick={() => {handleBackToLogin()}} className={classes.forgotPassword}>
                                    Back To Login
                                </span>
                            </Typography>
                        </Box>
                    </Grid>
                </Box>
                <Grid xs={12} item>
                    <Box mt={4}>
                        <PMButton className={classes.btn} loading={loading} disabled={loading} type="submit" onClick={(e) => {handleSubmit(e)}}>
                            Send Reset Email
                        </PMButton>
                    </Box>
                </Grid>
            </>
        )
    }
    
    return (
        <Box display="flex" flexDirection="column">
            <Grid item xs={12}>
                <Typography className ={classes.formHeader}>
                    Forgot Password
                </Typography>
            </Grid>
            {forgotPasswordPane()}
        </Box>
    );
}

export default ForgotPassword;