import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {logout} from '../../actions/auth'

import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import AppBar from '@material-ui/core/AppBar';
import { Box, Button, Toolbar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import Logo from '../../assets/InstaPMwText.svg'
import NavBarDemoDialog from './NavBarDemoDialog';

const useStyles = makeStyles((theme) => ({
	appBar: {
        zIndex: theme.zIndex.drawer + 1,
		backgroundColor: "#007bff",
    },
	title: {
		flexGrow: 1,
	},
	linkText: {
		color: "white",
		textTransform: "capitalize",
	},
	iconButton: {
		width: '40px', // Define your desired width here
		height: '40px', // Define your desired height here
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	icon: {
		width: '100px', // Define your desired width here
		height: '100px', // Define your desired height here
	},
	toolbar: {
		justifyContent: 'space-between',
	}
}));

export default function Navbar(){
    const dispatch = useDispatch()
	const classes = useStyles()
	const [isOpen, setIsOpen] = useState(false)
	const loading = useSelector((state) => state.auth.loading)
	const isDemo = useSelector((state) => state.demo.isDemo)
	const isDemoSignupPage = useSelector((state) => state.demo.isDemoSignupPage)
	const isDemoFormPage = useSelector((state) => state.demo.isDemoFormPage)
	let location = useLocation().pathname
	const navbarHiddenPages = ["/login", '/forgot-password', '/reset-password']
    let history = useHistory()

	const handleNavClick = (path) => {
		if(path === "/logout") {
			dispatch(logout())
		}
		if (location !== path) {
			history.push(path)
		}
	}

	const handleNavLogoClick = () => {
		if (isDemoFormPage) {
			setIsOpen(true)
		} else {
			handleNavClick('/dashboard')
			setIsOpen(false)
		}
	}

	const handleClose = () => {
		setIsOpen(false)
	}

	if (navbarHiddenPages.includes(location) || isDemoSignupPage){
		return <></>
	}

	if (loading) {
		return (
			<AppBar position='static'>
				<Toolbar>
				</Toolbar>
			</AppBar>
		)
	}

	return (
		<AppBar position="fixed" className={classes.appBar}>
			<Toolbar className={classes.toolbar}>
				<Box 
					width="125px"
				>
					<Button onClick={handleNavLogoClick}>
						<img src={Logo} alt="Logo" />
					</Button>
					<NavBarDemoDialog
						open={isOpen}
						handleClose={handleClose}
					/>
				</Box>
				<Box display="flex">
					{!isDemo && (
						<>
							<Box mr={2}>
								<Button 
									onClick={() => {handleNavClick('/dashboard')}}
									startIcon={<DashboardIcon  className={classes.linkText}/>}
								>
									<Typography className={classes.linkText}>
										Dashboard
									</Typography>
								</Button>
							</Box>
							<Box mr={2}>
								<Button 
									onClick={() => {handleNavClick('/profile')}}
									startIcon={<AccountBoxIcon  className={classes.linkText}/>}
								>
									<Typography className={classes.linkText}>
										Profile Settings
									</Typography>
								</Button>
							</Box>
							<Box>
								<Button 
									onClick={() => {handleNavClick('/logout')}}
									startIcon={<ExitToAppIcon  className={classes.linkText}/>}
								>
									<Typography className={classes.linkText}>
										Logout
									</Typography>
								</Button>
							</Box>
						</>
					)}
				</Box>
			</Toolbar>
		</AppBar>
	)
}
