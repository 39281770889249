import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { archiveRestore } from '../../actions/forms';
import PMButton from '../common/Button';

import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { FORM_LOADED } from '../../actions/types';

export default function VersionHistoryDialog({ context, confirmationDialogOpen, setConfirmationDialogOpen, majorVersionNumber, minorVersionNumber }) {
    const dispatch = useDispatch()
    const form = useSelector((state) => state.forms.form)
    const loading = useSelector((state) => state.forms.loading)

    const handleRestore = () => {
        dispatch(archiveRestore(form._id, majorVersionNumber, minorVersionNumber, context))
        setConfirmationDialogOpen(false)
        setTimeout(() => {
            dispatch({
                type: FORM_LOADED,
                payload: false
            })
            window.location.reload();
        }, 4000)
    }

    return(
        <Dialog
            open={confirmationDialogOpen}
            keepMounted
            scroll="paper"
            maxWidth="sm"
        >
            <DialogTitle>
                Confirm PM Restore
            </DialogTitle>
            <DialogContent dividers={true}>
                <Box>
                    <Typography>
                        Restoring to this point will overwrite your current Product Monograph data with the data in this version. Are you sure you want to proceed?
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <PMButton disabled={loading} red onClick={() => setConfirmationDialogOpen(false)}>Cancel</PMButton>
                <PMButton disabled={loading} loading={loading} onClick={() => handleRestore()}>Restore</PMButton>
            </DialogActions>
        </Dialog>
    )
}
