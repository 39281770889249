export const englishLabels = {
    title: "PACKAGING*",
    code: "Code*",
    packageIdentifier: "Package Identifier*",
    noPackagingMessage: "No Packaging",
    dateOfApproval: "Date of Approval*",
    dateOfCancellation: "Date of Cancellation",
    numeratorValue: "Numerator*",
    numeratorUnit: "Units*",
    denominatorValue: "Denominator",
    denominatorType: "Package Type",
    denominatorExamples: "Box, Blister"
}

export const frenchLabels = {
    title: "EMBALLAGE*",
    code: "Code*",
    packageIdentifier: "Identifiant Du Package*",
    noPackagingMessage: "Pas D'emballage",
    dateOfApproval: "Date D'approbation*",
    dateOfCancellation: "Date D'annulation",
    numeratorValue: "Numérateur*",
    numeratorUnit: "Unités*",
    denominatorValue: "Dénominateur",
    denominatorType: "Type D'emballage",
    denominatorExamples: "Boîte, Blister"
}