import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import WysiwygEditor from '../formComponents/WysiwygEditor'
import {englishLabels, frenchLabels} from "../../formLabels/BiosimilarBiologicDrugFormLabels"

import { makeStyles} from '@material-ui/core/styles';
import { Box, Paper } from '@material-ui/core';

const useStyles = makeStyles({
    veritcalText: {
        alignSelf: "center"
    },
    paper: {
        padding: "2rem",
        margin: "2rem 0rem",
        borderRadius: "25px"
    }
});

export default function BiosimilarBiologicDrug({ context }){

    const form = useSelector((state) => state.forms.form)
    const classes = useStyles()
    const [labels, setLabels] = useState({});
    let formInput = context ? form && form.formDataFrench : form && form.formDataEnglish 

    useEffect(() => {
        setLabels(context ? frenchLabels : englishLabels)
    }, [context])

    return(
        <Box mt={4}>
            <Paper className={classes.paper} elevation={4}>
                <Box key="biosimilarBiologicDrug">
                    <h6>{labels.biosimilarBiologicDrug}</h6>
                    <WysiwygEditor 
                        language={context} 
                        initialValue = {formInput.biosimilarBiologicDrug} 
                        name = "biosimilarBiologicDrug" 
                    />
                </Box>
            </Paper>
        </Box>
    )
}
