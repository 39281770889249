import React from 'react'
import { useSelector } from 'react-redux'

import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PMButton from '../components/common/Button';

const useStyles = makeStyles( () => ({
}));

export default function AppVersionDialog() {
    const classes = useStyles();
    const versionUpdateRequired = useSelector((state) => state.auth.versionUpdateRequired)

    const onUpdateVersion = async() => {
        window.location.reload();
    }

    return (
        <Dialog
            open={versionUpdateRequired}
            PaperProps={{
                style: {
                    width: '50vw',
                    borderRadius: '25px',
                    padding: '20px',
                },
            }}
        >
            <DialogTitle className={classes.dialogTitle}>
                <Box alignItems="center" display="flex" justifyContent="space-between">
                    <Typography variant="h5">
                        Your Version of InstaPM Is Outdated!
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box>
                    It looks like the version of InstaPM you are using is outdated. We are constantly making platform improvements for better performance and a smoother user experience. Click the button below to update to the latest version.
                </Box>
            </DialogContent>
            <DialogActions>
                <Box mt={2}>
                    <PMButton onClick={() => {onUpdateVersion()}}>
                        Update Version
                    </PMButton>
                </Box>
            </DialogActions>
        </Dialog>
    )
}
