import { nanoid } from 'nanoid'

const addPlugin = (editor, name, form, language, handleChange) => {

	const namedAnchorSelector = 'a:not([href]),div';

	const formData = language === false ? form.formDataEnglish : form.formDataFrench;

	let existing_ids = []
	if (formData.anchors){
		existing_ids = Object.values(formData.anchors).map((anchor) => {
			return anchor.title
		})
	}
	existing_ids = new Set(existing_ids)

	const getIdFromAnchor = (elm) => {
		const id = elm.getAttribute('id') || elm.getAttribute('name');
		return id || '';
	};

	const getId = () => {
		const anchor = getNamedAnchor();
		if (anchor) {
			return getIdFromAnchor(anchor);
		} else {
			return '';
		}
	};

	const isValidId = (id) => {
		return /^[A-Za-z][A-Za-z0-9\-:._]*$/.test(id);
	}

	const getNamedAnchor = () => {
		const elm = editor.dom.getParent(editor.selection.getStart(), namedAnchorSelector);
		if(elm !== null && elm.nodeName === "DIV"){
			return elm.firstChild
		}
		return elm
	}

	const checkExistingAnchor = (id, node, existingAnchors) => {
		if(existingAnchors.includes(id)){
			editor.windowManager.alert(
				'A linkable anchor with that name/ID already exists. Please choose a different name/ID.'
			);
			return true;
		}
		if(node && node.previousSibling !== null && node.previousSibling.nodeName === "DIV") {
			editor.windowManager.alert(
				'A linkable anchor already exists for this element (table, list, etc.). Please use the existing anchor or remove it.'
			);
			return true;
		}
		return false;
	}

	const updateAnchor = (key, id, anchorElement) => {

		const anchorExists = checkExistingAnchor(id, null, Object.values(formData.anchors).map((anchor) => {return anchor.title}))
		if (anchorExists === true) {
			return false;
		}

		anchorElement.removeAttribute('name');
		let anchorParent = anchorElement.parentNode
		
		let textNode = anchorParent.childNodes[1]
		textNode.textContent = `#${id}`
		formData.anchors[key] = {
			title: id,
			value: `#${key}`
		}
		handleChange("anchors", formData.anchors)
	}

	const highLevelElements = new Set(["TABLE","OL","UL", "STRONG", "EM", "IMG"]);

	const createAnchor = (id) => {

		let currentNode = editor.selection.getNode()

		const anchorExists = checkExistingAnchor(id, currentNode, Object.values(formData.anchors).map((anchor) => {return anchor.title}))
		if (anchorExists === true) {
			return false;
		}

		const key = `AN_${nanoid(10)}`

		// check if tag nested in a higher level element
		let traverseNode = currentNode
		while(traverseNode !== null){
			const nodeName = traverseNode.nodeName
			if(highLevelElements.has(nodeName)){
				currentNode = traverseNode
				break;
			}
			traverseNode = traverseNode.parentNode
		}

		const parent = currentNode.parentNode
		const anchorNode = editor.dom.create('a', { name: "anchor", id: key })
		const divNode = editor.dom.create('div', { class: "mceNonEditable" }, `\t#${id}`)
		divNode.insertBefore(anchorNode, divNode.childNodes[0])
		parent.insertBefore(divNode, currentNode)

		if(formData.anchors) {
			formData.anchors[key] = {
				title: id,
				value: `#${key}`
			}
		} else {
			formData["anchors"] = {}
			formData["anchors"][key] = {
				title: id,
				value: `#${key}`
			}
		}
		handleChange("anchors", formData.anchors);
		return true;
	}

	const addAnchorPopup = () => {
		const key = getId();

    	return editor.windowManager.open({
        	title: 'Create/Edit a Bookmark',
        	body: {
          		type: 'panel',
          		items: [
            		{
              			type: 'input',
              			name: 'id',
              			label: 'ID (must be unique)'
            		}
          		]
        	},
        	buttons: [
				{
					type: 'cancel',
					text: 'Close'
				},
				{
					type: 'submit',
					text: 'Add',
					primary: true
				}
       		],
			initialData: {
				id: formData.anchors ? formData.anchors[key]?.title ?? "" : ""
			},
        	onSubmit: function (api) {
				var id = api.getData().id;

				if (!isValidId(id)) {
					editor.windowManager.alert(
					  'ID should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.'
					);
					return false;
				}

				if (existing_ids.has(`#${id}`)) {
					editor.windowManager.alert(
						'ID should be unique. The ID you provided already exists.'
					  );
					  return false;
				}

				const anchor = getNamedAnchor();

				if (anchor) {
					updateAnchor(key, id, anchor);
				} else {
				  	createAnchor(id);
				}
				editor.focus();
				editor.addVisual()
				editor.nodeChanged()
				editor.save()
				editor.setContent(editor.getContent())
				handleChange(name, editor.getContent())
				api.close()
        	}
      	});
	}

	var onAction = function () {
		addAnchorPopup()
    };

	editor.ui.registry.addToggleButton('instapm_anchor', {
		icon: 'bookmark',
		tooltip: 'Create/Edit Bookmark',
		onAction,
		onSetup: (buttonApi) => editor.selection.selectorChangedWithUnbind(namedAnchorSelector, buttonApi.setActive).unbind
	});

};
  
export default addPlugin;