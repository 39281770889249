import React, { useState } from 'react'
import { connect } from 'react-redux'
import { login } from '../../actions/auth'
import PropTypes from 'prop-types'

import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from '@material-ui/core';

import PMButton from '../common/Button'

const CreateFormDialog = ({ loading, open, handleFormCreate, handleClose, createError, setCreateError}) => {

    const [formName, setFormName] = useState("")

    const handleTextFieldChange = (e) => {
        setFormName(e.target.value)
        setCreateError("")
    }
    
    return(
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Create a Product Monograph</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter the name of the new Product Monograph you want to create
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    fullWidth
                    variant="standard"
                    onChange={handleTextFieldChange}
                />
                {createError !== "" && (
                    <Box color="red">
                        <Typography>
                            {createError}
                        </Typography>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <PMButton disabled={loading} red onClick={handleClose}>Cancel</PMButton>
                <PMButton loading={loading} onClick={() => {handleFormCreate(formName)}}>Create</PMButton>
            </DialogActions>
      </Dialog>
    )
}

CreateFormDialog.propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps, {login})(CreateFormDialog)