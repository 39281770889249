import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateFormField } from '../../../actions/forms';

import { Box } from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';

// Tiny Editor
import { Editor } from '@tinymce/tinymce-react';

import addPlugin from './WysiwygPlugins/customAnchor';
import useInterval from '../../../utils/Interval';

const useStyles = makeStyles((theme) => ({
    editorBox: (props) => ({
		"& .tox-edit-area__iframe": {
			backgroundColor: props.disabled ? "#e9ecef !important" : "",
		}
    }),
}));

const BASE_PLUGINS = [
	'noneditable', 'paste', 'pastetext', 'autoresize'
]
const BASE_TOOLBAR = 'undo redo | subscript superscript'

const EXPERIMENTAL_PLUGINS = [
	'advcode'
]
const EXPERIMENTAL_TOOLBAR = 'code'

const PLUGINS = process.env.REACT_APP_ENVMNT === 'production' ? BASE_PLUGINS : [...BASE_PLUGINS, ...EXPERIMENTAL_PLUGINS]
const TOOLBAR = process.env.REACT_APP_ENVMNT === 'production' ? BASE_TOOLBAR : `${BASE_TOOLBAR} | ${EXPERIMENTAL_TOOLBAR}`

export default function RMLCEditor({ language, initialValue, name }){
	const editorRef = useRef(null);
	const dispatch = useDispatch()
	const form = useSelector((state) => state.forms.form)
	const editState = useSelector((state) => state.forms.editState)
	const [initValue, setInitValue] = useState(initialValue)
	const [editorInFocus, setEditorInFocus] = useState(false)
	const classes = useStyles({ disabled: editState.isDisabled });

	useEffect(() => {
		setInitValue(initialValue)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language, name])

	useEffect(() => {
		if(editState.isDisabled) {
			setInitValue(initialValue)
		}
	}, [editState, initialValue])

	const handleChange = (name, content) => {
		dispatch(updateFormField(name, content, form._id, language))
	}

	useEffect(() => {
		return () => {
			if(editorRef.current && editorRef.current.isDirty()) {
				const content = editorRef.current.getContent();
				handleChange(name, content)
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name])

	useInterval(() => {
		handleChange(name, editorRef.current.getContent())
	}, editorInFocus ? 5000 : null)

	const handleOnBlur = () => {
		setEditorInFocus(false)
	}

	const setup = (editor) => {
		addPlugin(editor, name, form, language, handleChange)

		// TODO:
		// Removing the is dirty setting logic as it wasn't detecting changes and not making API calls to save
		// Need to limit calls in the future for cost saving but commenting out now to ensure data integrity.
		editor.on('blur', (e) => {
			// if(editor.isDirty()){
				handleChange(name, editor.getContent())
				// editor.setDirty(false)
			// }
		})

		editor.on('focus', (e) => {
			setEditorInFocus(true)
		})

	};

  	return (
		<Box className={classes.editorBox} id={name} key={name}>
			<Editor
				initialValue={initValue}
				disabled={editState.isDisabled}
				apiKey="mt66tjvcqygmtm4me8rh2fci5hwi11rkxmwhfx67czmje03w"
				init={{
					setup: setup,
					height: 100,
					max_height: 100,
					min_height: 100,
					menubar: false,
					statusbar: false,
					branding: false,
					contextmenu: false,
					convert_urls: false,
					paste_as_text: true,
					plugins: PLUGINS,
					toolbar: TOOLBAR,
				}}
				onInit={(evt, editor) => editorRef.current = editor}
				onBlur={handleOnBlur}
			/>
		</Box>
  	)
}