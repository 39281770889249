import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';

import { checkDocumentLock, setIsReportViewOpen, setIsUploadDialogOpen } from '../../actions/forms';

import { Box, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Typography, CircularProgress, Slide, Tooltip, Switch, IconButton } from '@material-ui/core';

import { v4 } from "uuid"
import axios from "../../utils/customAxios"
import PMButton from '../common/Button';
import { FORM_LOADED, FORM_LOADING } from '../../actions/types';
import UploadReport from './UploadReport';
import CancelIcon from '@material-ui/icons/Cancel';

import { genTooltipTitle } from "../../utils/downloadUtilFunctions"

const useStyles = makeStyles({
    header: {
        alignSelf: "center"
    },
});

export default function UploadDialog({ context }) {
    const dispatch = useDispatch()
    const classes = useStyles();

    const form = useSelector((state) => state.forms.form)
    const isDemo = useSelector((state) => state.demo.isDemo)
    const loading = useSelector((state) => state.forms.loading)
    const isUploadDialogOpen = useSelector((state) => state.forms.isUploadDialogOpen)
    const isUploadReportOpen = useSelector((state) => state.forms.isUploadReportOpen)
    const formUpdating = useSelector((state) => state.forms.formUpdating)
    const hiddenFileInput = React.useRef(null);
    const [report, setReport] = useState(null)

    useEffect(() => {
        const docx_upload_operation_status = form.docx_upload_operation_status
        const status = context ? docx_upload_operation_status.FRENCH : docx_upload_operation_status.ENGLISH
        setReport(status.report)
    }, [form])

    const handleClick = event => {
        if (isDemo){
            return;
        }

        hiddenFileInput.current.click();
    };

    function uploadChunk(fileId, chunkIndex, chunk, totalChunks) {
        const formData = new FormData();
        formData.append('fileId', fileId);
        formData.append('chunkIndex', chunkIndex);
        formData.append('chunk', chunk);
        formData.append('totalChunks', totalChunks);
        formData.append('isEnglish', !context)
        formData.append('formId', form._id)

        return axios.post('api/forms/uploadDocxChunk', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    const handleDocxFileUpload = async(event) => {
        event.preventDefault();

        dispatch({
            type: FORM_LOADING,
            payload: true,
        })

        const file = event.target?.files?.length ? event.target?.files[0] : null;
        if (!file){
            return;
        }
        const fileName = `${v4()}_${file.name}`
        const chunkSize = 1024 * 1024 * 30; // 30MB
        const chunks = [];

        const reader = new FileReader();
        reader.onload = function () {
            let offset = 0;
            while (offset < file.size) {
                const chunk = file.slice(offset, offset + chunkSize);
                chunks.push(chunk);
                offset += chunkSize;
            }

            let uploadPromises = [];
            for (let i = 0; i < chunks.length; i++) {
                uploadPromises.push(uploadChunk(fileName, i, chunks[i], chunks.length));
            }

            Promise.all(uploadPromises)
                .then((response) => {
                    dispatch(checkDocumentLock(form._id, context))
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setIsUploadDialogOpen(false))
                    dispatch({
                        type: FORM_LOADED,
                        payload: true,
                    })
                })
        };

        reader.readAsArrayBuffer(file);
        // Clear the input field
        event.target.value = '';
    }

    const uploadWordButton = () => {
        return (
            <div>
                <Tooltip placement="top" title={isDemo && "Uploading a Microsoft Word Document is not available in Demo Mode. Please reach out to info@instapm.ca"}>
                    <PMButton 
                        onClick={handleClick}
                        loading={loading}
                        isNotAllowed={isDemo}
                    >
                        Upload
                    </PMButton>
                </Tooltip>
                <input type="file"
                    ref={hiddenFileInput}
                    onChange={handleDocxFileUpload}
                    style={{display:'none'}}
                    accept=".docx"
                /> 
            </div>
        )
    }

    return (
        <Dialog
            open={isUploadDialogOpen}
            keepMounted
            scroll="paper"
            maxWidth="sm"
        >
            <DialogTitle>
                <Box display="flex" justifyContent="space-between">
                    <Box className={classes.header}>
                        <Typography variant="h6">
                            Upload Microsoft Word Document (Early Preview)
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={() => {dispatch(setIsUploadDialogOpen(false))}} color='secondary'>
                            <CancelIcon/>
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid spacing={3} container>
                    {!isUploadReportOpen && (
                        <Slide  direction="right" in={!isUploadReportOpen} mountOnEnter unmountOnExit>
                            <Grid item xs={12}>
                                <Box>
                                    <Typography>
                                        <strong>This feature is currently experimental and should be used cautiously.</strong>
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography>
                                        As an added safety measure, the current version of this Product Monograph will automatically be archived in Version History before its content is replaced using the Word document.
                                    </Typography>
                                </Box>
                                <Box pt={4} marginBottom={"40px"}>
                                    <Typography>
                                    <strong>Uploaded documents must be in the <a target='_blank' href="https://www.canada.ca/en/health-canada/services/drugs-health-products/drug-products/applications-submissions/guidance-documents/product-monograph/master-template.html">latest 2020 Master Template</a></strong>.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Slide>
                    )}
                    {isUploadReportOpen && (
                        <Slide direction="left" in={isUploadReportOpen} mountOnEnter unmountOnExit>
                            <Grid item xs={12}>
                                <UploadReport
                                    report={report}
                                    context={context}
                                />
                            </Grid>
                        </Slide>
                    )}
                </Grid>
            </DialogContent>
            {!isUploadReportOpen && (
                <DialogActions>
                    <Box width="100%" display="flex">
                        <Grid container spacing={2}>
                            {report && (
                                <Grid item xs={6}>
                                    <PMButton outlined disabled={loading} onClick={()=> {dispatch(setIsReportViewOpen(!isUploadReportOpen))}}>
                                        View Recent Upload Report
                                    </PMButton>
                                </Grid>
                            )}
                            <Grid item xs={report ? 6 : 12}>
                                {formUpdating ? (
                                    <Box textAlign="center">
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <Box>
                                        {uploadWordButton()}
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </DialogActions>
            )}
        </Dialog>
    )
}