import React from 'react'
import { connect } from 'react-redux'
import { IconButton, Popover } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    iconbutton: {
        color: "#007bff",
        padding: 0,
        minHeight: 0,
        minWidth: 0,
    },
    infoicon: {
        width: 25,
        height: 25,
        padding: 0,
        minHeight: 0,
        minWidth: 0,

    },
    typography: {
        fontSize: 18,
    },
    popover: {
        backgroundColor: "rgb(192,192,192,0.94)",
        maxWidth: "600px",
        maxHeight: "700px",
        padding: "18px",
    },
});


const TooltipComponent = ({ label }) => {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <span>
            <IconButton className={classes.iconbutton} onClick={handleClick}>
                <InfoOutlinedIcon className={classes.infoicon}/>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                classes={{ paper: classes.popover }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}

            >
                <Typography className={classes.typography}>{label}</Typography>
            </Popover>
        </span>

    )

}


export default connect(null, [])(TooltipComponent)