import { Box } from '@material-ui/core';

export const englishTooltipLabels = {
    readThisForSafeAndEffectiveUseOfYourMedicine:
        <Box>
            Read this carefully before you start taking <b>[Brand name]</b> and each time you get a refill. This leaflet is a summary and will not tell you everything about this drug. Talk to your healthcare professional about your medical condition and treatment and ask if there is any new information about <b>[Brand name]</b>.
            <br />
            <br />
            For biosimilars include the following statement:
            <ul>
                <li>
                    <i>
                        <b>[Brand name]</b> is a biosimilar biologic drug (biosimilar) to the reference biologic drug [Reference biologic drug brand name]. A biosimilar is authorized based on its similarity to a reference biologic drug that was already authorized for sale.
                    </i>
                </li>
            </ul>
            <b>Serious warnings and precautions</b>
            <ul>
                <li>
                    - [text]
                </li>
                <li>
                    - [text]
                </li>
            </ul>

            The box should contain a plain language version of the information provided in 3 Serious warnings and precautions box. Delete this box if there are no serious warnings and precautions.
        </Box>,
    whatIsBrandNameUsedFor:
        <Box>
            <ul>
                <li>
                    - [text]
                </li>
                <li>
                    - [text]
                </li>
            </ul>
            [For products that have been authorized under the Notice of Compliance with Conditions (NOC/c) policy, include the following boxed statement:]
            <ul>
                <li>
                    <i>
                        “For the following indication(s) [Brand name] has been approved with conditions (NOC/c). This means it has passed Health Canada’s review and can be bought and sold in Canada, but the manufacturer has agreed to complete more studies to make sure the drug works the way it should. For more information, talk to your healthcare professional.”
                    </i>
                </li>
            </ul>

            [Provide a bullet listing of the indications from Part I.]
            <br />
            <ul>
                <li>
                    - [text]
                </li>
            </ul>

            [If the Indications section includes lifestyle recommendations as part of the therapy, they should be included here.]
            <br />
            <br />
            <ul>
                <li>
                    <i>
                        “For the following indication(s) [Brand name] has been approved without conditions. This means it has passed Health Canada’s review and can be bought and sold in Canada.”

                    </i>
                </li>
            </ul>

            [Provide a bullet listing of the indications from Part I.]
            <br />
            <ul>
                <li>
                    - [text]
                </li>
            </ul>

            [If the Indications section includes lifestyle recommendations as part of the therapy, they should be included here.]
            <br />
            <br />
            For products that have been authorized under the NOC/c policy, the following text must be included:
            <br />
            <br />
            <ul>
                <li>
                    <i>
                        <b>What is a Notice of Compliance with Conditions (NOC/c)?</b>
                        <br />
                        A Notice of Compliance with Conditions (NOC/c) is a type of approval to sell a drug in Canada.
                        <br />
                        Health Canada only gives an NOC/c to a drug that treats, prevents, or helps identify a serious or life-threatening illness. The drug must show promising proof that it works well, is of high quality, and is reasonably safe. Also, the drug must either respond to a serious medical need in Canada, or be much safer than existing treatments.
                        <br />
                        Drug makers must agree in writing to clearly state on the label that the drug was given an NOC/c, to complete more testing to make sure the drug works the way it should, to actively monitor the drug’s performance after it has been sold, and to report their findings to Health Canada.
                    </i>
                </li>
            </ul>
        </Box>,
    howDoesBrandNameWork:
        <Box>
            [text]
        </Box>,
    whatAreTheIngredientsInBrandName:
        <Box>
            Medicinal ingredients:[List all medicinal ingredients]
            <br />
            <br />
            Non-medicinal ingredients: [List all non-medicinal ingredients in alphabetical order.]
        </Box>,
    brandNameDosageForms:
        <Box>
            <ul>
                <li>
                    - [dosage form(s) and strength(s)]
                </li>
            </ul>
            To maintain brevity, this is the only information required in this section.
        </Box>,
    doNotUseBrandNameIf:
        <Box>
            <ul>
                <li>
                    [text]
                </li>
                <li>
                    [text]
                </li>
            </ul>
            Enter one point for each contraindication from 2 Contraindications.
        </Box>,
    healthConditionsOrProblems:
        <Box>
            <ul>
                <li>
                    [text]
                </li>
                <li>
                    [text]
                </li>
            </ul>
            Enter one point for each item listed in 7 Warnings and precautions.
        </Box>,
    otherWarningsYouShouldKnowAbout:
        <Box>
            [text]
            <br />
            <br />

            Enter general information that would not appear in the Serious Warnings and Precautions Box or other existing headings. Otherwise this heading is not required.
            <br />
            <br />
            <b>
                Tell your healthcare professional about all the medicines you take, including any drugs, vitamins, minerals, natural supplements or alternative medicines.
            </b>
        </Box>,
    theFollowingMayInteractWithBrandName:
        <Box>
            The following may interact with [Brand name]:
            <ul>
                <li>
                    - [list]
                </li>
            </ul>

            Include information from 9 Drug interactions. If no relevant interactions are known, add a statement to reflect this.
        </Box>,
    howToTakeBrandName:
        <Box>
            <ul>
                <li>
                    - [text]
                </li>
            </ul>
            Consider the following or similar statements as required:
            <ul>
                <li>
                    <i>
                        Do not use this medication if it looks cloudy or is leaking.
                    </i>
                </li>
                or
                <li>
                    <i>
                        [Brand name] will be given to you by a healthcare professional in a healthcare setting.
                    </i>
                </li>
                or
                <li>
                    <i>
                        [for radiopharmaceuticals:] [product] will be given to you by a healthcare professional who is experienced in the use of radiopharmaceuticals.
                    </i>
                </li>
            </ul>
        </Box>,
    usualDose:
        <Box>
            [text]
        </Box>,
    overDose:
        <Box>
            [text]
            <br />
            <br />
            If you think you, or a person you are caring for, have taken too much [Brand name], contact a healthcare professional, hospital emergency department, or regional poison control centre immediately, even if there are no symptoms.
            <br />
            <br />
            From 5 Overdosage, provide information on what to do if the individual has taken too much medication. The boxed message may be modified to provide the most appropriate advice according to current standards of care for this drug product.
        </Box>,
    missedDosePMI:
        <Box>
            [text]
        </Box>,
    possibleSideEffects:
        <Box>
            These are not all the possible side effects you may have when taking [Brand name]. If you experience any side effects not listed here, tell your healthcare professional.
            <br />
            <br />

            [text]
            <br />
            <br />

            Self-limiting side effects should be described in the text section only. Those listed in 3 Serious warnings and precautions box must be listed in the serious side effects table. Each side effect should appear only once, in text or in the table to avoid duplication.
            <br />
            <br />

            <b>Serious side effects and what to do about them</b>
            <table >
                <thead>
                    <tr>
                        <th rowspan="2" scope="col">
                            Symptom / effect
                        </th>
                        <th colspan="2" scope="colgroup">
                            Talk to your healthcare professional
                        </th>
                        <th rowspan="2" scope="col">
                            Stop taking drug and get immediate medical help
                        </th>
                    </tr>
                    <tr>
                        <th scope="col">Only if severe</th>
                        <th scope="col">In all cases</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> <b>Very common</b> </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> [Condition: symptom / effect] </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> <b> Common</b> </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> [Condition: symptom / effect] </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> [Condition: symptom / effect] </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> <b>Rare</b> </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> [Condition: symptom / effect] </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> [Condition: symptom / effect] </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                </tbody>
            </table>
            If you have a troublesome symptom or side effect that is not listed here or becomes bad enough to interfere with your daily activities, tell your healthcare professional.
            <br />
            <br />
            <b>Reporting side effects</b>
            <br />
            <br />

            You can report any suspected side effects associated with the use of health products to Health Canada by:
            <ul>
                <li>
                    - Visiting the Web page on Adverse Reaction Reporting for information on how to report online, by mail or by fax; or

                </li>
                or
                <li>
                    - Calling toll-free at 1-866-234-2345.

                </li>
            </ul>
            Note: Contact your health professional if you need information about how to manage your side effects. The Canada Vigilance Program does not provide medical advice.
            <br />
            <br />
            <b>Reporting suspected side effects for vaccines</b>
            <br />
            <br />
            <b>For the general public: </b>Should you experience a side effect following immunization, please report it to your healthcare professional.
            <br />
            <br />
            Should you require information related to the management of the side effect, please contact your healthcare professional. The Public Health Agency of Canada, Health Canada and [Sponsor Name] cannot provide medical advice.
            <br />
            <br />
            <b>For healthcare professionals: </b>If a patient experiences a side effect following immunization, please complete the Adverse Events Following Immunization (AEFI) Form appropriate for your province/territory and send it to your local Health Unit.
            <br />
            <br />
            Please choose the reporting box option that is most appropriate for this product.

        </Box>,
    storage:
        <Box>
            [text]
            <br />

            Keep out of reach and sight of children.
        </Box>,
    moreInformation:
        <Box>
            <ul>
                <li>
                    - Talk to your healthcare professional
                </li>
                <li>
                    - Find the full product monograph that is prepared for healthcare professionals and includes this Patient Medication Information by visiting the Drug Product Database; the manufacturer’s website [website], or by calling 1-800-[phone number].
                </li>
            </ul>
        </Box>,
}

export const frenchTooltipLabels = {
    readThisForSafeAndEffectiveUseOfYourMedicine:
        <Box>
            Lisez ce qui suit attentivement avant de prendre <b>[Marque nominative]</b> et lors de chaque renouvellement de prescription. L’information présentée ici est un résumé et ne couvre pas tout ce qui a trait à ce médicament. Discutez de votre état de santé et de votre traitement avec votre professionnel de la santé et demandez-lui s’il possède de nouveaux renseignements au sujet de <b>[Marque nominative]</b>.

            <br />
            <br />
            Pour les biosimilaires, inclure l’énoncé suivant :

            <ul>
                <li>
                    <i>
                        <b>[Marque nominative]</b> est un médicament biologique biosimilaire (biosimilaire) du médicament biologique de référence [Marque nominative du médicament biologique de référence]. Un biosimilaire est autorisé en fonction de sa similarité à un médicament biologique de référence dont la vente est déjà autorisée.
                    </i>
                </li>
            </ul>
            <b>Mises en garde et précautions importantes</b>
            <ul>
                <li>
                    - [texte]
                </li>
                <li>
                    - [texte]
                </li>
            </ul>
            L’encadré doit contenir une version en langage simple des renseignements fournis dans « Encadré mises en garde et précautions importantes » de la section 3. Supprimer cet encadré s’il n’y a pas de mises en garde et de précautions importantes.
        </Box>,
    whatIsBrandNameUsedFor:
        <Box>
            <ul>
                <li>
                    - [texte]
                </li>
                <li>
                    - [texte]
                </li>
            </ul>
            [Pour les produits autorisés en vertu de la politique sur les avis de conformité avec conditions (AC/C), inclure l’énoncé encadré suivant :]    <ul>
                <li>
                    <i>
                        « Pour la (les) indication(s) suivante(s), [Marque nominative] a été approuvé avec conditions (AC-C). Cela signifie qu’il a réussi l’examen de Santé Canada et qu’il peut être acheté et utilisé au Canada, mais que le fabricant a accepté d’effectuer d’autres études pour confirmer que le médicament fonctionne bien comme prévu. Consultez votre professionnel de la santé pour obtenir de plus amples renseignements. »
                    </i>
                </li>
            </ul>

            [Fournir une liste à puces des indications de la Partie I.]

            <br />
            <ul>
                <li>
                    - [texte]
                </li>
            </ul>

            [Si la section « Indications » inclut des recommandations relatives au style de vie dans le cadre du traitement, ces recommandations doivent être indiquées ici.]

            <br />
            <br />
            <ul>
                <li>
                    <i>
                        « Pour la (les) indication(s) suivante(s), [Marque nominative] a été approuvé sans conditions. Cela signifie qu’il a réussi l’examen de Santé Canada et peut être acheté et vendu au Canada. »

                    </i>
                </li>
            </ul>

            [Fournir une liste à puces des indications de la Partie I.]

            <br />
            <ul>
                <li>
                    - [texte]
                </li>
            </ul>

            [Si la section « Indications » inclut des recommandations relatives au style de vie dans le cadre du traitement, ces recommandations doivent être indiquées ici.]

            <br />
            <br />
            Pour les produits qui ont été autorisés en vertu de la politique d’AC-C, le texte suivant doit être inclus :

            <br />
            <br />
            <ul>
                <li>
                    <i>
                        <b>Qu’est-ce qu’un avis de conformité avec conditions (AC-C)?</b>

                        <br />
                        Un avis de conformité avec conditions (AC-C) est un type d’autorisation de commercialisation d’un médicament au Canada.

                        <br />
                        Santé Canada délivrera un AC-C uniquement à des produits qui permettent de traiter, de prévenir ou de diagnostiquer une maladie grave ou mettant la vie en danger. Ces produits doivent avoir démontré un bénéfice prometteur, être de grande qualité et être raisonnablement sûrs. De même, ils doivent répondre à un besoin médical important au Canada ou être considérablement plus sûrs que tout autre traitement existant.

                        <br />
                        Les fabricants de drogue doivent convenir par écrit d’indiquer sur l’étiquette que le médicament a obtenu un AC-C, d’effectuer d’autres essais pour vérifier que le médicament fonctionne comme il se doit, de suivre activement le rendement du médicament après sa vente et de signaler leurs conclusions à Santé Canada.

                    </i>
                </li>
            </ul>
        </Box>,
    howDoesBrandNameWork:
        <Box>
            [texte]
        </Box>,
    whatAreTheIngredientsInBrandName:
        <Box>
            Ingrédients médicinaux :[Énumérer tous les ingrédients médicinaux]
            <br />
            <br />
            Ingrédients non médicinaux : [Énumérer tous les ingrédients non médicinaux dans l’ordre alphabétique].
        </Box>,
    brandNameDosageForms:
        <Box>
            <ul>
                <li>
                    - [Formes posologiques et concentrations]

                </li>
            </ul>
            Par souci de concision, il s’agit des seuls renseignements requis dans cette section.
        </Box>,
    doNotUseBrandNameIf:
        <Box>
            <ul>
                <li>
                    [texte]
                </li>
                <li>
                    [texte]
                </li>
            </ul>
            Inscrire un point pour chaque contre-indication d’après la section 2 « Contre-indications ».
        </Box>,
    healthConditionsOrProblems:
        <Box>
            <ul>
                <li>
                    [texte]
                </li>
                <li>
                    [texte]
                </li>
            </ul>
            Inscrire chaque mise en garde ou précaution mentionnée à la section 7, « Mises en garde et précautions ».
        </Box>,
    otherWarningsYouShouldKnowAbout:
        <Box>
            [texte]
            <br />
            <br />

            Inscrire les renseignements généraux qui n’apparaîtraient pas dans l’encadré « Mises en garde et précautions importantes » ou dans d’autres titres existants. Sinon, ce titre n’est pas nécessaire.

            <br />
            <br />
            <b>
                Mentionnez à votre professionnel de la santé toute la médication que vous prenez, y compris les médicaments, les vitamines, les minéraux, les suppléments naturels ou les produits les médicaments alternatifs.

            </b>
        </Box>,
    theFollowingMayInteractWithBrandName:
        <Box>
            Les produits qui suivent pourraient être associés à des interactions médicamenteuses avec [Marque nominative] :
            <ul>
                <li>
                    - [liste]
                </li>
            </ul>
            Fournir les informations provenant de la section 9, « Interactions médicamenteuses ». Si aucune interaction pertinente n’est connue, ajouter un énoncé pour le préciser.
        </Box>,
    howToTakeBrandName:
        <Box>
            <ul>
                <li>
                    - [texte]
                </li>
            </ul>
            Si nécessaire, envisager d’inclure l’énoncé suivant ou un énoncé similaire :
            <ul>
                <li>
                    <i>
                        N’utilisez pas ce médicament si la solution a une apparence trouble ou si le contenant fuit.
                    </i>
                </li>
                ou
                <li>
                    <i>
                        [Marque nominative] vous sera remis par un professionnel de la santé dans un établissement de santé.
                    </i>
                </li>
                ou, pour les produits radiopharmaceutiques :
                <li>
                    <i>
                        [produit] vous sera administré par un professionnel de la santé expérimenté en ce qui concerne l’utilisation de substances radiopharmaceutiques.            </i>
                </li>
            </ul>
        </Box>,
    usualDose:
        <Box>
            [texte]
        </Box>,
    overDose:
        <Box>
            [texte]
            <br />
            <br />
            Si vous pensez que vous ou une personne dont vous vous occupez avez pris trop de [Marque nominative], contactez immédiatement un professionnel de la santé, le service des urgences d’un hôpital ou votre centre antipoison régional, même en l’absence de symptômes.

            <br />
            <br />
            Donner des renseignements figurant à la section 5, « Surdosage », sur ce qu’il faut faire si la posologie a été dépassée. L’énoncé encadré peut être modifié pour inclure les recommandations les plus appropriées en fonction des normes de soins actuelles pour ce produit pharmaceutique.

        </Box>,
    missedDosePMI:
        <Box>
            [texte]
        </Box>,
    possibleSideEffects:
        <Box>
            Voici certains des effets secondaires possibles que vous pourriez ressentir lorsque vous prenez [Marque nominative]. Si vous ressentez des effets secondaires qui ne font pas partie de cette liste, avisez votre professionnel de la santé.

            <br />
            <br />

            [texte]
            <br />
            <br />

            Les effets secondaires spontanément résolutifs doivent être décrits dans la partie réservée au texte uniquement. Ceux qui figurent dans l’encadré « Mises en garde et précautions importantes » de la section 3 doivent être mentionnés dans le tableau des effets secondaires graves. Chaque effet secondaire ne doit apparaître qu’une seule fois, dans le texte ou dans le tableau, pour éviter les doublons.

            <br />
            <br />

            <b>Effets secondaires graves et mesures à prendre</b>
            <table >
                <thead>
                    <tr>
                        <th rowspan="2" scope="col">
                            Symptôme / effet
                        </th>
                        <th colspan="2" scope="colgroup">
                            Consultez votre professionnel de la santé.
                        </th>
                        <th rowspan="2" scope="col">
                            Cessez de prendre des médicaments et obtenez de l’aide médicale immédiatement
                        </th>
                    </tr>
                    <tr>
                        <th scope="col">Seulement si l’effet est grave</th>
                        <th scope="col">Dans tous les cas</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> <b>Très courant</b> </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> [Condition : symptôme / effet] </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> <b> Courant</b> </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> [Condition : symptôme / effet] </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> [Condition : symptôme / effet] </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> <b>Rare</b> </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> [Condition : symptôme / effet] </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> [Condition : symptôme / effet] </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                </tbody>
            </table>
            En cas de symptôme ou d’effet secondaire gênant non mentionné dans le présent document ou d’aggravation d’un symptôme ou d’effet secondaire vous empêchant de vaquer à vos occupations quotidiennes, parlez-en à votre professionnel de la santé.

            <br />
            <br />
            <b>Déclaration des effets secondaires
            </b>
            <br />
            <br />

            Vous pouvez déclarer des effets secondaires soupçonnés d’être associés à l’utilisation d’un produit à Santé Canada en

            <ul>
                <li>
                    - Visitant le site Web des déclarations des effets indésirables pour vous informer sur comment faire une déclaration en ligne, par courriel, ou par télécopieur ;

                </li>
                ou
                <li>
                    - Téléphonant sans frais 1-866-234-2345.


                </li>
            </ul>
            Remarque : Consultez votre professionnel de la santé si vous avez besoin de renseignements sur le traitement des effets secondaires. Le Programme Canada Vigilance ne donne pas de conseils médicaux.

            <br />
            <br />
            <b>Déclaration des effets secondaires soupçonnés des vaccins</b>
            <br />
            <br />
            <b>Pour le grand public : </b>Si vous ressentez un effet secondaire à la suite d’une vaccination, veuillez le signaler à votre professionnel de la santé.

            <br />
            <br />
            Si vous souhaitez obtenir des informations sur l’atténuation des effets secondaires, veuillez contacter votre professionnel de la santé. L’Agence de la santé publique du Canada (ASPC), Santé Canada et [nom du promoteur] ne peuvent pas fournir de conseils médicaux.

            <br />
            <br />
            <b>Pour les professionnels de la santé : </b>
            Si un patient ressent un effet secondaire à la suite d’une vaccination, veuillez remplir le formulaire approprié de rapport des effets secondaires suivant l’immunisation (ESSI) selon votre province ou territoire et l’envoyer à votre unité de santé locale.            <br />
            <br />
            Veuillez choisir l’option de la case de déclaration la plus appropriée pour le produit en question.

        </Box>,
    storage:
        <Box>
            [texte]
            <br />
            Garder hors de la portée et de la vue des enfants.
        </Box>,
    moreInformation:
        <Box>
            <ul>
                <li>
                    - Communiquer avec votre professionnel de la santé.

                </li>
                <li>
                    - Lire la monographie de produit intégrale rédigée à l’intention des professionnels de la santé, qui renferme également les Renseignements sur le médicament pour le patient. Ce document est disponible sur la Base de données sur les produits pharmaceutiques, le site Web du fabricant [www.siteweb.document], ou peut être obtenu en téléphonant au 1-800-[numéro de téléphone].

                </li>
            </ul>
        </Box>,
}