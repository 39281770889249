import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { generatePreview, downloadDOCX, getPreviewDownloadStatus } from '../../actions/forms';

import { Box, Grid, Paper, Stepper, Step, StepLabel, Typography, Tooltip } from '@material-ui/core';

import AutorenewIcon from '@material-ui/icons/Autorenew';
import { makeStyles } from '@material-ui/core/styles';
import PMButton from '../common/Button';
import moment from 'moment';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';
import useInterval from '../../utils/Interval';

import { onClickHTML, hasFailed, isLoading, isDownloadable, genTooltipTitle } from "../../utils/downloadUtilFunctions"

const useStyles = makeStyles(() => ({
    paper: {
        borderRadius: "25px",
        padding: 15
    },
    disabledPaper: {
        backgroundColor: "lightgray",
        borderRadius: "25px",
        padding: 15
    },
    iconButton: {
    },
    icon: {
        marginRight: "0.5rem"
    },
    iconGrid: {
        display: "flex",
        justifyContent: "flex-end",
        alignSelf: "center",
        "& .MuiButton-startIcon": {
            margin: 0,
            padding: 0,
        }
    },
    itemGrid: {
        display: "flex",
        alignItems: "center",
    },
    spinIcon: {
        animation: "spin 4s linear infinite"
    },
    errorBox: {
        borderRadius: "25px",
        backgroundColor: "#F68D8E",
        padding: "1rem",
    },
    radio: {
        '&$checked': {
            color: '#007bff'
        }
    },
    checked: {}
}));

const submissionSteps = ['Generating Word Document', 'Validating User Input', 'Generating Quick Preview'];

export default function PreviewDownload({ context, isPreviewDialogOpen }) {
    const dispatch = useDispatch()
    const classes = useStyles();
    const language = context === false ? 'ENGLISH' : 'FRENCH';
    
    const isDemo = useSelector((state) => state.demo.isDemo)

    const form = useSelector((state) => state.forms.form)
    const formUpdating = useSelector((state) => state.forms.updating)
    const downloadEnglishStatus = useSelector((state) => state.forms.previewDownloadEnglishStatus)
    const downloadFrenchStatus = useSelector((state) => state.forms.previewDownloadFrenchStatus)

    const [xmlUrl, setXmlUrl] = useState("")
    const [docxUrl, setDocxUrl] = useState("")
    const [htmlUrl, setHtmlUrl] = useState("")
    const [lastGenDate, setLastGenDate] = useState(null)
    const [pollDownload, setPollDownload] = useState(false)

    const [activeStep, setActiveStep] = useState(-1)
    const [errorStep, setErrorStep] = useState(-1)
    const [blockGen, setBlockGen] = useState(false)

    const handleDocxDownload = () => {
        if (isDemo) {
            return
        }
        dispatch(downloadDOCX(form._id, form.name, language, "PREVIEW"))
    }

    useInterval(() => {
        dispatch(getPreviewDownloadStatus(form._id, "ENGLISH"))
        dispatch(getPreviewDownloadStatus(form._id, "FRENCH"))
    }, pollDownload ? 2000 : null)

    const setData = () => {
        var downloadStatus = {}
        if(language === "ENGLISH") {
            downloadStatus = {
                xml: downloadEnglishStatus?.xml?.ENGLISH.url,
                docx: downloadEnglishStatus?.docx?.ENGLISH.url,
                html: downloadEnglishStatus?.html?.ENGLISH.url,
                lastGenDate: downloadEnglishStatus?.docx?.ENGLISH.lastModified,
                createdDate: downloadEnglishStatus?.createdDate,
            }
        } else {
            downloadStatus = {
                xml: downloadFrenchStatus?.xml?.FRENCH.url,
                docx: downloadFrenchStatus?.docx?.FRENCH.url,
                html: downloadFrenchStatus?.html?.FRENCH.url,
                lastGenDate: downloadFrenchStatus?.docx?.FRENCH.lastModified,
                createdDate: downloadFrenchStatus?.createdDate,
            }
        }

        setXmlUrl(downloadStatus.xml)
        setDocxUrl(downloadStatus.docx)
        setHtmlUrl(downloadStatus.html)
        setLastGenDate(downloadStatus.lastGenDate)
        
        genStep(downloadStatus.xml, downloadStatus.docx, downloadStatus.html)
        refreshGenStatus(downloadStatus.xml, downloadStatus.docx, downloadStatus.html, downloadStatus.createdDate)
    }

    // Poll for download status
    useEffect(() => {
        if (isPreviewDialogOpen) {
            dispatch(getPreviewDownloadStatus(form._id, "ENGLISH"))
            dispatch(getPreviewDownloadStatus(form._id, "FRENCH"))
            setPollDownload(true)
        } else {
            setPollDownload(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPreviewDialogOpen, dispatch, form._id, language])

    useEffect(() => {
        setData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [downloadEnglishStatus, downloadFrenchStatus, isPreviewDialogOpen, language])


    const genStep = (XML, DOCX, HTML) => {
        if(!isDownloadable(DOCX)) {
            if (hasFailed(DOCX)) {
                return setStep(0, 0)
            }
            if (isLoading(DOCX)) {
                return setStep(0, -1)
            }
        }

        if (isDownloadable(DOCX) && !isDownloadable(XML)) {
            if(hasFailed(XML)){
                return setStep(1, 1)
            }

            if(isLoading(XML)){
                return setStep(1, -1)
            }
        }

        if (isDownloadable(DOCX) && isDownloadable(XML) && !isDownloadable(HTML)) {
            if(hasFailed(HTML)){
                return setStep(2, 2)
            }
            if(isLoading(HTML)){
                return setStep(2, -1)
            }    
        }

        if (isDownloadable(DOCX) && isDownloadable(XML) && isDownloadable(HTML)){
            return setStep(3,-1)
        }

        return setStep(3, 3)
    }

    const setStep = (activeStep, errorStep) => {
        setActiveStep(activeStep)

        if (errorStep >= 0) {
            setErrorStep(errorStep)
        } else {
            setErrorStep(-1)
        }

        if (errorStep > activeStep && activeStep !== -1) {
            setErrorStep(-1)
        }
    }

    const refreshGenStatus = (xml, docx, html, cDate) => {
        // If any of them are loading and none of them failed
        if ((isLoading(xml) || isLoading(docx) || (isLoading(html)) && (!hasFailed(xml) || !hasFailed(docx) || !hasFailed(html)))) {
            const currentTime = moment();
            const createdDate = moment(cDate);
            const difference = currentTime.diff(createdDate, 'minutes');

            if (difference < 3) {
                setBlockGen(true)
                return
            }
        }
        setBlockGen(false)
    }

    const handleGenerate = () => {        
        try {
            setActiveStep(1)
            setErrorStep(-1)
            setBlockGen(true)
            dispatch(generatePreview(form._id, language))
        } catch (err) {
            console.log(err)
            setBlockGen(false)
            setActiveStep(0)
            setErrorStep(0)
        }
    }

    const genSubmissionButtons = () => {
        return (
            <Box display="flex">
                <Tooltip title={genTooltipTitle(htmlUrl)}>
                    <Box mr={2}>
                        <PMButton
                            outlined
                            onClick={() => onClickHTML(htmlUrl)}
                            disabled={!isDownloadable(htmlUrl)}
                            startIcon={
                                (isLoading(htmlUrl) || isLoading(docxUrl)) ? (
                                    <>
                                        <AutorenewIcon className={classes.spinIcon} />
                                        <style>{`
                                                    @keyframes spin {
                                                        0% { transform: rotate(0deg); }
                                                        100% { transform: rotate(360deg); }
                                                    }
                                                `}</style>
                                    </>
                                ) : (
                                    <VisibilityIcon className={classes.iconButton} />
                                )
                            }
                        />
                    </Box>
                </Tooltip>
                <Tooltip title={genTooltipTitle(docxUrl, isDemo)}>
                    <Box>
                        <PMButton
                            outlined
                            onClick={() => handleDocxDownload()}
                            disabled={!isDownloadable(docxUrl)}
                            isNotAllowed={isDemo}
                            startIcon={
                                isLoading(docxUrl) && !(hasFailed(docxUrl)) ? (
                                    <>
                                        <AutorenewIcon className={classes.spinIcon} />
                                        <style>{`
                                            @keyframes spin {
                                                0% { transform: rotate(0deg); }
                                                100% { transform: rotate(360deg); }
                                            }
                                        `}</style>
                                    </>
                                ) : (
                                    <DescriptionIcon className={classes.iconButton} />
                                )
                            }
                        />
                    </Box>
                </Tooltip>
            </Box>
        )
    }
    const stepper = (steps) => {
        return (
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel error={errorStep === index}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        )
    }

    const genGenerateTooltipTitle = () => {
        if (blockGen) {
            return "Export is still loading, come back shortly after to generate again"
        }
        return "Click to generate"
    }

    return (
        <Paper variant="outlined" className={classes.paper}>
            <Grid container>
                <Grid item xs={5}>
                    <Tooltip title={genGenerateTooltipTitle()} >
                        <Box>
                            <PMButton
                                onClick={() => handleGenerate()}
                                disabled={blockGen}
                            >
                                Generate Preview
                            </PMButton>
                        </Box>
                    </Tooltip>
                </Grid>
                <Grid className={classes.iconGrid} item xs={7}>
                    <Box display="flex">
                        {genSubmissionButtons()}
                    </Box>
                </Grid>
                <Grid className={classes.itemGrid} item xs={12}>
                    {isDownloadable(docxUrl) && (
                        <Box mt={2} ml={1}>
                            <Typography variant='caption'>
                                {`Last Generated: ${moment(lastGenDate).fromNow()}`}
                            </Typography>
                        </Box>
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {stepper(submissionSteps)}
                </Grid>
            </Grid>
        </Paper>
    )
}
