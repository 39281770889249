import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TextField from '../formComponents/TextField'
import {englishLabels, frenchLabels} from "../../formLabels/PackagingFormLabels"
import AutocompleteField from '../formComponents/AutoComplete'

import { updateFormField } from "../../../actions/forms";
import {packaging } from '../../../models/Packaging'

import MuiTreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';

import { Box, Grid, IconButton } from "@material-ui/core"
import { makeStyles, withStyles} from '@material-ui/core/styles';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DeleteIcon from '@material-ui/icons/Delete';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import _ from "lodash"
import PMButton from "../../common/Button"
import { DATE_MASK } from '../../../utils/utilFunctions'

const useStyles = makeStyles({
    "@global": {
        ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label": {
          backgroundColor: "transparent"
        },
        ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label": {
          backgroundColor: "transparent"
        }
    },
    veritcalText: {
        alignSelf: "center"
    },
    paper: {
        padding: "2rem",
        margin: "2rem 0rem",
        borderRadius: "25px"
    },
    scrollBox: {
        overflowY: 'scroll',
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'block',
            width: '30px', // Adjust width for vertical scrollbar
            height: '10px', // Adjust height for horizontal scrollbar
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
        },
    },
});

const TreeItem = withStyles({
    selected: {
        backgroundColor: "transparent !important"
    }
  })(MuiTreeItem);

export default function PackagingStack({ context, currentProductIndex }){

    const dispatch = useDispatch()
    const form = useSelector((state) => state.forms.form)
    const updating = useSelector((state) => state.forms.updating)
    const editState = useSelector((state) => state.forms.editState)
    const classes = useStyles()
    const [labels, setLabels] = useState({});
    let formInput = context ? form && form.formDataFrench : form && form.formDataEnglish 

    let nodes = []

    useEffect(() => {
        setLabels(context ? frenchLabels : englishLabels)
    }, [context])

    const updatePackage = (name, value) => {
        _.set(formInput, name, value)
        dispatch(updateFormField(name, value, form._id, context))
    }

    const createPackage = (name, children, outermost=false) => {
        let parent= _.get(formInput, `${name}`)
        let packagingTemplate = packaging(outermost, parent.denominatorUnit)
        _.set(formInput, `${name}.packagings`, [...children, packagingTemplate])
        dispatch(updateFormField("products", formInput.products, form._id, context))
    }

    const removePackage = (name) => {
        let packages = _.get(formInput, name.substring(0, name.length-3))
        const removeIndex = parseInt(name.split("[").pop())
        packages.splice(removeIndex, 1)
        _.set(formInput, name.substring(0, name.length-3), packages)
        dispatch(updateFormField("products", formInput.products, form._id, context))
    }

    const packagingNode = (index, initialName, packagingChildren) => {
        var name = `${initialName}.packagings[${index}]`;
        nodes.push(name)
        return (
            <TreeItem
                nodeId={name}
                key={name}
                icon={
                    <Box>
                        <IconButton
                            color="primary"
                            disabled={updating || editState.isDisabled}
                            onClick={(e) => {removePackage(name)}}
                        >
                            <DeleteIcon/>
                        </IconButton>
                        <IconButton
                            color="primary"
                            disabled={updating || editState.isDisabled}
                            onClick={(e) => {createPackage(name, packagingChildren, false)}}
                        >
                            <SubdirectoryArrowRightIcon/>
                        </IconButton>
                    </Box>
                }
                label={
                    <Box width="100%" display="flex">
                        <TextField 
                            width="20rem" 
                            updateFunction={updatePackage} 
                            index={index}  
                            columnStyling = {2} 
                            label = {labels.code} 
                            placeholder = {labels.code} 
                            name ={`${name}.code`}
                        /> 
                        <AutocompleteField 
                            context={context} 
                            codeSystem="2.16.840.1.113883.2.20.6.32" 
                            updateFunction={updatePackage} 
                            index={index} 
                            columnStyling = {2} 
                            label = {labels.packageIdentifier} 
                            placeholder = {labels.packageIdentifier} 
                            name ={`${name}.packageIdentifier`}
                        /> 
                        <TextField 
                            width="12rem" 
                            updateFunction={updatePackage} 
                            index={index} 
                            columnStyling = {2} 
                            label = {labels.dateOfApproval} 
                            placeholder = {"MM/DD/YYYY"} 
                            name ={`${name}.dateOfApproval`}
                            inputMask={DATE_MASK}
                        /> 
                        <TextField 
                            width="12rem" 
                            updateFunction={updatePackage} 
                            index={index} 
                            columnStyling = {2} 
                            label = {labels.dateOfCancellation} 
                            placeholder = {"MM/DD/YYYY"} 
                            name ={`${name}.dateOfCancellation`}
                            inputMask={DATE_MASK}
                        /> 
                        <TextField 
                            updateFunction={updatePackage} 
                            index={index}  
                            columnStyling = {1} 
                            label = {labels.numeratorValue} 
                            placeholder = {"ex. 1"} 
                            name = {`${name}.numeratorValue`}
                            type={"number"}
                        /> 
                        <AutocompleteField 
                            width="12rem" 
                            context={context} 
                            codeSystem="2.16.840.1.113883.2.20.6.15" 
                            updateFunction={updatePackage} 
                            index={index}  
                            columnStyling = {2} 
                            label = {labels.numeratorUnit} 
                            placeholder = {'mg'} 
                            name = {`${name}.numeratorUnit`}
                        /> 
                        <TextField 
                            updateFunction={updatePackage} 
                            index={index}  
                            columnStyling = {1} 
                            label = {labels.denominatorValue} 
                            placeholder = {"ex. 1"} 
                            name = {`${name}.denominatorValue`}
                            type={"number"}
                        /> 
                        <AutocompleteField 
                            width="12rem" 
                            context={context} 
                            codeSystem="2.16.840.1.113883.2.20.6.15" 
                            updateFunction={updatePackage} 
                            index={index}  
                            columnStyling = {2} 
                            label = {labels.numeratorUnit} 
                            placeholder = {'mg'} 
                            name = {`${name}.denominatorUnit`}
                        /> 
                    </Box>
                }
            >
                {
                    packagingChildren.length > 0 && packagingChildren.map((packagingChild, subIndex) => {
                        return packagingNode(subIndex, name, packagingChild.packagings)
                    })
                }    
            </TreeItem>
        )
    }

    return (
        <Fragment>
            <Grid container>
                <Grid xs={10} item className={classes.veritcalText}>
                    <Box width="100%" alignSelf="center">
                        <h6>{labels.title}</h6>
                    </Box>
                </Grid>
                <Grid xs={2} item>
                    <Box display="flex">
                        <Box width="100%" pr={2}>
                            <PMButton isFormButton={true} disabled={updating} onClick={(e) => {createPackage(`products[${currentProductIndex}]`, formInput.products[currentProductIndex].packagings, true)}}>
                                Add
                            </PMButton>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            {formInput.products[currentProductIndex].packagings.length > 0 ? (
               <Box width="100%" mt={4} className={classes.scrollBox}>
               {
                   <TreeView
                       defaultCollapseIcon={<ExpandMoreIcon />}
                       defaultExpandIcon={<ChevronRightIcon />}
                       expanded={nodes}
                   >
                       {
                           formInput.products[currentProductIndex].packagings.map((packaging, index) => {
                               return packagingNode(index, `products[${currentProductIndex}]`, packaging.packagings)
                           })
                       }
                   </TreeView>
               }
                </Box>
            ) : (
                <Box>
                    No packagings for this product currently exist.
                </Box>
            )}
        </Fragment>
    )
}
