import {
	CHAT_LOADING,
	CHAT_STREAMING,
} from "../actions/types";

const initialState = {
	chatHistory: [],
};

export default function chatbotReducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CHAT_LOADING:
			return {
				...state,
				chatHistory: [
					...state.chatHistory,
					{
						human: payload.question
					}
				],
			};
		case CHAT_STREAMING:
			let updatedChatHistory = [...state.chatHistory];
			let lastMessageIndex = updatedChatHistory.length - 1;
			let currentAnswer = updatedChatHistory[lastMessageIndex]['ai']

			if (updatedChatHistory[lastMessageIndex]) {
				updatedChatHistory[lastMessageIndex]['ai'] = currentAnswer ? currentAnswer + payload.answer : payload.answer;
			}
	
			return {
				...state,
				chatHistory: updatedChatHistory,
			};
		default:
			return state;
	}
}

