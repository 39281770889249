import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'

import { Grid, Paper, Typography, Avatar } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(5),
        textAlign: 'center',
        borderRadius: "20px",
        width: "90%",
        margin: "0 auto",
        padding: "5% 5% 0 5%"
    },
    username: {
        padding: theme.spacing(3),
        fontSize : theme.spacing(3)
    },
    avatar: {
        margin: "0 auto",
        width: theme.spacing(17),
        height: theme.spacing(17),
        fontSize : theme.spacing(10),
        borderRadius: "0"
    },
}));

const roleLevels = ["READER", "AUTHOR", "ADMIN"]

export default function UserInfo() {
    const classes = useStyles();
    const user = useSelector((state) => state.auth.user)

    const [username, setUsername] = useState("");
    const [name, setName] = useState("")
    const [company, setCompany] = useState("")
    const [accessLevel, setAccessLevel] = useState("")

    useEffect(() => {
        if (user){
            setName(user.name)
            setCompany(user.company)
            setAccessLevel(user.accessLevel)
            setUsername(user.email)
        }
    }, [user])

    return (
        <Grid container>
            <Paper className={classes.paper}>
                <div>
                    <Avatar className={classes.avatar} alt={"User_Icon"} src="https://upload.wikimedia.org/wikipedia/commons/f/f9/OOjs_UI_icon_userAvatar-constructive.svg" />
                    <div className={classes.username}>
                        <Typography align = "center" className = {classes.subHeader} variant="h4" gutterBottom>
                            {name}
                        </Typography>
                        <Typography align = "center" className = {classes.subHeader} variant="h5" gutterBottom>
                            {username}
                        </Typography>
                        <Typography align = "center" className = {classes.subHeader} variant="h5" gutterBottom>
                            {company.toUpperCase()} |  {roleLevels[accessLevel - 1]}
                        </Typography>
                    </div>
                </div>
            </Paper>
        </Grid>
    )
}
