import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Grid, Select, MenuItem, FormControl, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ImportExportIcon from '@material-ui/icons/ImportExport';

import { getVersionNumbers } from '../../actions/forms';
import PMButton from '../common/Button';

const useStyles = makeStyles( (theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    centerAlign: {
        textAlign: "center"
    }
}));

export default function VersionCompare({ context }) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const form = useSelector((state) => state.forms.form)
    const versionNumbers = useSelector((state) => state.forms.versionNumbers)
    const formUpdating = useSelector((state) => state.forms.updating)
    const loading = useSelector((state) => state.forms.loading)

    const [versionNumberA, setVersionNumberA] = useState()
    const [versionNumberB, setVersionNumberB] = useState()

    useEffect(() => {
        if(!formUpdating) {
            dispatch(getVersionNumbers(form._id, context))
        }
    }, [formUpdating, form._id, context, dispatch])


    const handleCompare = () => {
        const [majorVersionNumberA, minorVersionNumberA] = versionNumberA.split(".")
        const [majorVersionNumberB, minorVersionNumberB] = versionNumberB.split(".")
        window.open(`/compare/${form._id}?majorVersionNumberA=${majorVersionNumberA}&minorVersionNumberA=${minorVersionNumberA}&majorVersionNumberB=${majorVersionNumberB}&minorVersionNumberB=${minorVersionNumberB}&isEnglish=${!context}&section=0`, '_blank')
    }

    return(
        <Grid direction="column" spacing={3} container>
            <Grid item xs={12}>
                <Box>
                    <Typography variant='h6'>
                        Compare Versions (Beta)
                    </Typography>
                    Choose two different versions to compare changes
                </Box>
            </Grid>
            <Grid className={classes.centerAlign} item xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                        value={versionNumberA}
                        onChange={(e) => {setVersionNumberA(e.target.value)}}
                    >
                        {versionNumbers.map((version) => {
                            return (
                                <MenuItem value={`${version.majorVersionNumber}.${version.minorVersionNumber}`}>
                                    {`${version.majorVersionNumber}.${version.minorVersionNumber}`}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid className={classes.centerAlign} item xs={12}>
                <Box>
                    <ImportExportIcon />
                </Box>
            </Grid>
            <Grid className={classes.centerAlign} item xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                        value={versionNumberB}
                        onChange={(e) => {setVersionNumberB(e.target.value)}}
                    >
                        {versionNumbers.map((version) => {
                            return (
                                <MenuItem value={`${version.majorVersionNumber}.${version.minorVersionNumber}`}>
                                    {`${version.majorVersionNumber}.${version.minorVersionNumber}`}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Box>
                    <PMButton outlined loading={loading} disabled={loading || (versionNumberA === "" || versionNumberB === "")} onClick={handleCompare}>Compare</PMButton>
                </Box>
            </Grid>
        </Grid>
    )
}