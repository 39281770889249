import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import WysiwygEditor from '../formComponents/WysiwygEditor';
import { englishLabels, frenchLabels } from "../../formLabels/PartOneFormLabels"

import { makeStyles} from '@material-ui/core/styles';
import { Box, Paper, Typography } from '@material-ui/core';
import { SECTION_INDEXES } from '../formUtils/SectionIndexes';
import { englishTooltipLabels, frenchTooltipLabels } from "../../tooltipLabels/PartOneTooltipLabels"
import TooltipComponent from '../formComponents/TooltipComponent'

const useStyles = makeStyles({
    button: {
        width: "50%",
    },
    veritcalText: {
        alignSelf: "center"
    },
    paper: {
        padding: "2rem",
        margin: "2rem 0rem",
        borderRadius: "25px"
    }
});

export default function PartOne({ context }){

    const form = useSelector((state) => state.forms.form)
    const currentSectionIndex = useSelector((state) => state.forms.currentSectionIndex)
    const [labels, setLabels] = useState({})
    const classes = useStyles();
    const [tooltipLabels, setTooltipLabels] = useState({});


    let formInput = context ? form && form.formDataFrench : form && form.formDataEnglish 

    useEffect(() => {
        setLabels(context ? frenchLabels : englishLabels)
        setTooltipLabels(context ? frenchTooltipLabels : englishTooltipLabels)
    }, [context])

    const getSectionContent = () => {
        switch (currentSectionIndex) {
            case SECTION_INDEXES.PART_ONE:
                return null
            case SECTION_INDEXES.INDICATIONS:
                return (
                    <Box key={`indications-${context}`}>
                        <h6>
                            <Box>
                                {labels.indications}
                                <TooltipComponent label={tooltipLabels.indications} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.indications} 
                            name = "indications" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.PEDIATRICS:
                return (
                    <Box key={`pediatrics-${context}`}>
                        <h6>
                            <Box>
                                {labels.pediatrics}
                                <TooltipComponent label={tooltipLabels.pediatrics} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.pediatrics} 
                            name = "pediatrics" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.GERIATRICS:
                return (
                    <Box key={`geriatrics-${context}`}>
                        <h6>
                            <Box>
                                {labels.geriatrics}
                                <TooltipComponent label={tooltipLabels.geriatrics} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.geriatrics} 
                            name = "geriatrics" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.CONTRAINDICATIONS:
                return (
                    <Box key={`contraindications-${context}`}>
                        <h6>
                            <Box>
                                {labels.contraindications}
                                <TooltipComponent label={tooltipLabels.contraindications} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.contraindications} 
                            name = "contraindications" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.SERIOUS_WARNINGS_AND_PRECAUTIONS_BOX:
                return (
                    <Box key={`seriousWarningsAndPrecautionsBox-${context}`}>
                        <h6>
                            <Box>
                                {labels.seriousWarningsAndPrecautionsBox}
                                <TooltipComponent label={tooltipLabels.seriousWarningsAndPrecautionsBox} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.seriousWarningsAndPrecautionsBox} 
                            name = "seriousWarningsAndPrecautionsBox" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.DOSAGE_AND_ADMINISTRATION:         
                // TODO: Tool tip here maybe?
                // dosageAndAdministration
                return null
            case SECTION_INDEXES.DOSING_CONSIDERATIONS:
                return (
                    <Box key={`dosingConsiderations-${context}`}>
                        <h6>
                            <Box>
                                {labels.dosingConsiderations}
                                <TooltipComponent label={tooltipLabels.dosingConsiderations} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.dosingConsiderations} 
                            name = "dosingConsiderations" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.RECOMMENDED_DOSE_AND_DOSAGE_ADJUSTMENTS:
                return (
                    <Box key={`recommendedDoseAndDosageAdjustments-${context}`}>
                        <h6>
                            <Box>
                                {labels.recommendedDoseAndDosageAdjustments}
                                <TooltipComponent label={tooltipLabels.recommendedDoseAndDosageAdjustments} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.recommendedDoseAndDosageAdjustments} 
                            name = "recommendedDoseAndDosageAdjustments" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.RECONSTITUITION:
                return (
                    <Box key={`reconstituition-${context}`}>
                        <h6>
                            <Box>
                                {labels.reconstituition}
                                <TooltipComponent label={tooltipLabels.reconstituition} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.reconstituition} 
                            name = "reconstituition" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.ADMINISTRATION:
                return (
                    <Box key={`administration-${context}`}>
                        <h6>
                            <Box>
                                {labels.administration}
                                <TooltipComponent label={tooltipLabels.administration} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.administration} 
                            name = "administration" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.MISSED_DOSE:
                return (
                    <Box key={`missedDose-${context}`}>
                        <h6>
                            <Box>
                                {labels.missedDose}
                                <TooltipComponent label={tooltipLabels.missedDose} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.missedDose} 
                            name = "missedDose" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.IMAGE_ACQUISITION_AND_INTERPRETATION:
                return (
                    <Box key={`imageAcquisitionAndInterpretation-${context}`}>
                        <h6>
                            <Box>
                                {labels.imageAcquisitionAndInterpretation}
                                <TooltipComponent label={tooltipLabels.imageAcquisitionAndInterpretation} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.imageAcquisitionAndInterpretation} 
                            name = "imageAcquisitionAndInterpretation" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.INSTRUCTIONS_FOR_PREPARATION_AND_USE:
                return (
                    <Box key={`instructionsForPreparationAndUse-${context}`}>
                        <h6>
                            <Box>
                                {labels.instructionsForPreparationAndUse}
                                <TooltipComponent label={tooltipLabels.instructionsForPreparationAndUse} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.instructionsForPreparationAndUse} 
                            name = "instructionsForPreparationAndUse" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.RADIATION_DOSIMETRY:
                return (
                    <Box key={`radiationDosimetry-${context}`}>
                        <h6>
                            <Box>
                                {labels.radiationDosimetry}
                                <TooltipComponent label={tooltipLabels.radiationDosimetry} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.radiationDosimetry} 
                            name = "radiationDosimetry" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.OVERDOSAGE:
                return (
                    <Box key={`overdosage-${context}`}>
                        <h6>
                            <Box>
                                {labels.overdosage}
                                <TooltipComponent label={tooltipLabels.overdosage} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.overdosage} 
                            name = "overdosage" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.DOSAGE_FORMS_STRENGTHS_COMPOSITION_AND_PACKAGING:
                return (
                    <Box key={`dosageFormsStrengthsCompositionAndPackaging-${context}`}>
                        <h6>
                            <Box>
                                {labels.dosageFormsStrengthsCompositionAndPackaging}
                                <TooltipComponent label={tooltipLabels.dosageFormsStrengthsCompositionAndPackaging} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.dosageFormsStrengthsCompositionAndPackaging} 
                            name = "dosageFormsStrengthsCompositionAndPackaging" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.PHYSICAL_CHARACTERISTICS:
                return (
                    <Box key={`physicalCharacteristics-${context}`}>
                        <h6>
                            <Box>
                                {labels.physicalCharacteristics}
                                <TooltipComponent label={tooltipLabels.physicalCharacteristics} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.physicalCharacteristics} 
                            name = "physicalCharacteristics" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.EXTERNAL_RADIATION:
                return (
                    <Box key={`externalRadiation-${context}`}>
                        <h6>
                            <Box>
                                {labels.externalRadiation}
                                <TooltipComponent label={tooltipLabels.externalRadiation} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.externalRadiation} 
                            name = "externalRadiation" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.WARNINGS_AND_PRECAUTIONS:
                return (
                    <Box key={`warningsAndPrecautions-${context}`}>
                        <h6>
                            <Box>
                                {labels.warningsAndPrecautions}
                                <TooltipComponent label={tooltipLabels.warningsAndPrecautions} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.warningsAndPrecautions} 
                            name = "warningsAndPrecautions" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.SPECIAL_POPULATIONS:
                return null
            case SECTION_INDEXES.PREGNANT_WOMEN:
                return (
                    <Box key={`pregnantWomen-${context}`}>
                        <h6>
                            <Box>
                                {labels.pregnantWomen}
                                <TooltipComponent label={tooltipLabels.pregnantWomen} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.pregnantWomen} 
                            name = "pregnantWomen" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.BREAST_FEEDING:
                return (
                    <Box key={`breastFeeding-${context}`}>
                        <h6>
                            <Box>
                                {labels.breastFeeding}
                                <TooltipComponent label={tooltipLabels.breastFeeding} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.breastFeeding} 
                            name = "breastFeeding" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.PEDIATRICS_WARNINGS_AND_PRECAUTIONS:
                return (
                    <Box key={`pediatricsWarningsAndPrecautions-${context}`}>
                        <h6>
                            <Box>
                                {labels.pediatricsWarningsAndPrecautions}
                                <TooltipComponent label={tooltipLabels.pediatricsWarningsAndPrecautions} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.pediatricsWarningsAndPrecautions} 
                            name = "pediatricsWarningsAndPrecautions" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.GERIATRICS_WARNINGS_AND_PRECAUTIONS:
                return (
                    <Box key={`geriatricsWarningsAndPrecautions-${context}`}>
                        <h6>
                            <Box>
                                {labels.geriatricsWarningsAndPrecautions}
                                <TooltipComponent label={tooltipLabels.geriatricsWarningsAndPrecautions} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.geriatricsWarningsAndPrecautions} 
                            name = "geriatricsWarningsAndPrecautions" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.ADVERSE_REACTIONS:
                return null
            case SECTION_INDEXES.ADVERSE_REACTIONS_OVERVIEW:
                return (
                    <Box key={`adverseReactionsOverview-${context}`}>
                        <h6>
                            <Box>
                                {labels.adverseReactionsOverview}
                                <TooltipComponent label={tooltipLabels.adverseReactionsOverview} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.adverseReactionsOverview} 
                            name = "adverseReactionsOverview" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.CLINICAL_TRIAL_ADVERSE_REACTIONS:
                return (
                    <Box key={`clinicalTrialAdverseReactions-${context}`}>
                        <h6>
                            <Box>
                                {labels.clinicalTrialAdverseReactions}
                                <TooltipComponent label={tooltipLabels.clinicalTrialAdverseReactions} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.clinicalTrialAdverseReactions} 
                            name = "clinicalTrialAdverseReactions" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.CLINICAL_TRIAL_ADVERSE_REACTIONS_PEDIATRICS:
                return (
                    <Box key={`clinicalTrialAdverseReactionsPediatrics-${context}`}>
                        <h6>
                            <Box>
                                {labels.clinicalTrialAdverseReactionsPediatrics}
                                <TooltipComponent label={tooltipLabels.clinicalTrialAdverseReactionsPediatrics} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.clinicalTrialAdverseReactionsPediatrics} 
                            name = "clinicalTrialAdverseReactionsPediatrics" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.LESS_COMMON_CLINICAL_TRIAL_ADVERSE_REACTIONS:
                return (
                    <Box key={`lessCommonClinicalTrialAdverseReactions-${context}`}>
                        <h6>
                            <Box>
                                {labels.lessCommonClinicalTrialAdverseReactions}
                                <TooltipComponent label={tooltipLabels.lessCommonClinicalTrialAdverseReactions} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.lessCommonClinicalTrialAdverseReactions} 
                            name = "lessCommonClinicalTrialAdverseReactions" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.LESS_COMMON_CLINICAL_TRIAL_ADVERSE_REACTIONS_PEDIATRICS:
                return (
                    <Box key={`lessCommonClinicalTrialAdverseReactionsPediatrics-${context}`}>
                        <h6>
                            <Box>
                                {labels.lessCommonClinicalTrialAdverseReactionsPediatrics}
                                <TooltipComponent label={tooltipLabels.lessCommonClinicalTrialAdverseReactionsPediatrics} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.lessCommonClinicalTrialAdverseReactionsPediatrics} 
                            name = "lessCommonClinicalTrialAdverseReactionsPediatrics" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.ABNORMAL_LABORATORY_FINDINGS_HEMATOLOGIC:
                return (
                    <Box key={`abnormalLaboratoryFindingsHematologic-${context}`}>
                        <h6>
                            <Box>
                                {labels.abnormalLaboratoryFindingsHematologic}
                                <TooltipComponent label={tooltipLabels.abnormalLaboratoryFindingsHematologic} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.abnormalLaboratoryFindingsHematologic} 
                            name = "abnormalLaboratoryFindingsHematologic" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.POST_MARKET_ADVERSE_REACTIONS:
                return (
                    <Box key={`postMarketAdverseReactions-${context}`}>
                        <h6>
                            <Box>
                                {labels.postMarketAdverseReactions}
                                <TooltipComponent label={tooltipLabels.postMarketAdverseReactions} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.postMarketAdverseReactions} 
                            name = "postMarketAdverseReactions" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.DRUG_INTERACTIONS:
                return null
            case SECTION_INDEXES.SERIOUS_DRUG_INTERACTIONS:
                return (
                    <Box key={`seriousDrugInteractions-${context}`}>
                        <h6>
                            <Box>
                                {labels.seriousDrugInteractions}
                                <TooltipComponent label={tooltipLabels.seriousDrugInteractions} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.seriousDrugInteractions} 
                            name = "seriousDrugInteractions" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.DRUG_INTERACTIONS_OVERVIEW:
                return (
                    <Box key={`drugInteractionsOverview-${context}`}>
                        <h6>
                            <Box>
                                {labels.drugInteractionsOverview}
                                <TooltipComponent label={tooltipLabels.drugInteractionsOverview} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.drugInteractionsOverview} 
                            name = "drugInteractionsOverview" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.DRUG_BEHAVIOURAL_INTERACTIONS:
                return (
                    <Box key={`drugBehaviouralInteractions-${context}`}>
                        <h6>
                            <Box>
                                {labels.drugBehaviouralInteractions}
                                <TooltipComponent label={tooltipLabels.drugBehaviouralInteractions} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.drugBehaviouralInteractions} 
                            name = "drugBehaviouralInteractions" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.DRUG_DRUG_INTERACTIONS:
                return (
                    <Box key={`drugDrugInteractions-${context}`}>
                        <h6>
                            <Box>
                                {labels.drugDrugInteractions}
                                <TooltipComponent label={tooltipLabels.drugDrugInteractions} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.drugDrugInteractions} 
                            name = "drugDrugInteractions" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.DRUG_FOOD_INTERACTIONS:
                return (
                    <Box key={`drugFoodInteractions-${context}`}>
                        <h6>
                            <Box>
                                {labels.drugFoodInteractions}
                                <TooltipComponent label={tooltipLabels.drugFoodInteractions} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.drugFoodInteractions} 
                            name = "drugFoodInteractions" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.DRUG_HERB_INTERACTIONS:
                return (
                    <Box key={`drugHerbInteractions-${context}`}>
                        <h6>
                            <Box>
                                {labels.drugHerbInteractions}
                                <TooltipComponent label={tooltipLabels.drugHerbInteractions} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.drugHerbInteractions} 
                            name = "drugHerbInteractions" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.DRUG_LABORATORY_TEST_INTERACTIONS:
                return (
                    <Box key={`drugLaboratoryTestInteractions-${context}`}>
                        <h6>
                            <Box>
                                {labels.drugLaboratoryTestInteractions}
                                <TooltipComponent label={tooltipLabels.drugLaboratoryTestInteractions} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.drugLaboratoryTestInteractions} 
                            name = "drugLaboratoryTestInteractions" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.ACTION_AND_CLINICAL_PHARMACOLOGY:
                return null
            case SECTION_INDEXES.MECHANISM_OF_ACTION:
                return (
                    <Box key={`mechanismOfAction-${context}`}>
                        <h6>
                            <Box>
                                {labels.mechanismOfAction}
                                <TooltipComponent label={tooltipLabels.mechanismOfAction} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.mechanismOfAction} 
                            name = "mechanismOfAction" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.PHARMACODYNAMICS:
                return (
                    <Box key={`pharmacodynamics-${context}`}>
                        <h6>
                            <Box>
                                {labels.pharmacodynamics}
                                <TooltipComponent label={tooltipLabels.pharmacodynamics} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.pharmacodynamics} 
                            name = "pharmacodynamics" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.PHARMACOKINETICS:
                return (
                    <Box key={`pharmacokinetics-${context}`}>
                        <h6>
                            <Box>
                                {labels.pharmacokinetics}
                                <TooltipComponent label={tooltipLabels.pharmacokinetics} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.pharmacokinetics} 
                            name = "pharmacokinetics" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.STORAGE_STABILITY_AND_DISPOSAL:
                return (
                    <Box key={`storageStabilityAndDisposal-${context}`}>
                        <h6>
                            <Box>
                                {labels.storageStabilityAndDisposal}
                                <TooltipComponent label={tooltipLabels.storageStabilityAndDisposal} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.storageStabilityAndDisposal} 
                            name = "storageStabilityAndDisposal" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.SPECIAL_HANDLING_INSTRUCTIONS:
                return (
                    <Box key={`specialHandlingInstructions-${context}`}>
                        <h6>
                            <Box>
                                {labels.specialHandlingInstructions}
                                <TooltipComponent label={tooltipLabels.specialHandlingInstructions} />
                            </Box>
                        </h6>
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.specialHandlingInstructions} 
                            name = "specialHandlingInstructions" 
                        />
                    </Box>
                )
            default:
                return null
        }
    }

    const Section = getSectionContent()

    return (
        <Box mt={4}>
            <Paper className={classes.paper} elevation={4}>
                {Section ?? (
                    <Typography>
                        This section does not need to be filled out.
                    </Typography>
                )}
            </Paper>
        </Box>
    )
}
