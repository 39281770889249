import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { Box } from '@material-ui/core';
import AppVersionDialog from './AppVersionDialog';
import { APP_VERSION_NUMBER } from '../actions/types';

export default function AppVersionProvider({ children }){
    const dispatch = useDispatch()

    // Set the current version on initial render
	useEffect(() => {
	    dispatch({
			type: APP_VERSION_NUMBER,
			payload: process.env.REACT_APP_VERSION,
		})
	}, [])

    return (
        <Box>
            <AppVersionDialog />
            {children}
        </Box>
    )
};