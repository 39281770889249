import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Dialog, TextField, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';

import { updateFormField } from "../../../actions/forms"
import { makeStyles } from '@material-ui/core/styles';
import { nanoid } from 'nanoid'

import PMButton from '../../common/Button';

const useStyles = makeStyles(() => ({
    textField: {
        width: "100%",
    }
}));

export default function LinkingDialog({ isOpen, setIsOpen, context }) {

    const dispatch = useDispatch()
    const form = useSelector((state) => state.forms.form)
    const [footnote, setFootnote] = useState("");
    const classes = useStyles();

    const handleChange = (e) => {
        setFootnote(e.target.value)
    }

    const handleClose = () => {
        setFootnote("")
        setIsOpen(false)
    }

    const handleCreate = () => {
        let formData = context === false ? form.formDataEnglish : form.formDataFrench
        const title = `FN_${nanoid(5)}`

        formData.footnotes.push(
            {
                title: title,
                value: `#${title}`,
                footnote_text: footnote
            }
        )
        dispatch(updateFormField("footnotes", formData.footnotes, form._id, context))
        setFootnote("")
        setIsOpen(false)
    }

    return(
        <Dialog
            open={isOpen}
            keepMounted
            scroll="paper"
            maxWidth="sm"
        >
            <DialogTitle>
                Add a Footnote
            </DialogTitle>
            <DialogContent dividers={true}>
                <Typography>
                    Enter the text of your footnote below. We will automatically create a footnote display name for you that you can referene in your product monograph.
                </Typography>
                <Box mt={2} display="flex" width="100%">
                    <TextField
                        className={classes.textField}
                        value={footnote}
                        placeholder="Enter footnote text here"
                        onChange={handleChange}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <PMButton red onClick={handleClose}>Close</PMButton>
                <PMButton onClick={handleCreate}>Create</PMButton>
            </DialogActions>
        </Dialog>
    )
}
