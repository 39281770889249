import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WysiwygEditor from '../formComponents/WysiwygEditor';
import TextField from '../formComponents/TextField'
import { englishLabels, frenchLabels } from "../../formLabels/PartThreeFormLabels"

import { makeStyles} from '@material-ui/core/styles';
import { Box, Button, Paper, Typography } from '@material-ui/core';

import { updateFormField, getPMISectionScore } from '../../../actions/forms';
import { SECTION_INDEXES } from '../formUtils/SectionIndexes';
import { englishTooltipLabels, frenchTooltipLabels } from "../../tooltipLabels/PartThreeTooltipLabels"
import TooltipComponent from '../formComponents/TooltipComponent'

import _ from "lodash"
import PartThreeSuggestionPopup from './PartThreeSuggestionPopup';

const useStyles = makeStyles({
    button: {
        width: "50%",
    },
    veritcalText: {
        alignSelf: "center"
    },
    paper: {
        padding: "2rem",
        margin: "2rem 0rem",
        borderRadius: "25px"
    }
});

export default function PartThree({ context }){

    const dispatch = useDispatch()
    const form = useSelector((state) => state.forms.form)
    const currentSectionIndex = useSelector((state) => state.forms.currentSectionIndex)
    const [labels, setLabels] = useState({})
    const [tooltipLabels, setTooltipLabels] = useState({});
    const classes = useStyles();

    let formInput = context ? form && form.formDataFrench : form && form.formDataEnglish 

    useEffect(() => {
        setLabels(context ? frenchLabels : englishLabels)
        setTooltipLabels(context ? frenchTooltipLabels : englishTooltipLabels)
    }, [context])

    useEffect(() => {
        var editorContent = ""
        switch(currentSectionIndex) {
            case SECTION_INDEXES.READ_THIS_FOR_SAFE_AND_EFFECTIVE_USE_OF_YOUR_MEDICINE:
                editorContent = formInput.readThisForSafeAndEffectiveUseOfYourMedicine
                break;
            case SECTION_INDEXES.WHAT_IS_BRAND_NAME_USED_FOR:
                editorContent = formInput.whatIsBrandNameUsedFor
                break;
            case SECTION_INDEXES.HOW_DOES_BRAND_NAME_WORK:
                editorContent = formInput.howDoesBrandNameWork
                break;
            case SECTION_INDEXES.WHAT_ARE_THE_INGREDIENTS_IN_BRAND_NAME:
                editorContent = formInput.whatAreTheIngredientsInBrandName
                break;
            case SECTION_INDEXES.BRAND_NAME_DOSAGE_FORMS:
                editorContent = formInput.brandNameDosageForms
                break;
            case SECTION_INDEXES.DO_NOT_USE_BRAND_NAME_IF:
                editorContent = formInput.doNotUseBrandNameIf
                break;
            case SECTION_INDEXES.HEALTH_CONDITIONS_OR_PROBLEMS:
                editorContent = formInput.healthConditionsOrProblems
                break;
            case SECTION_INDEXES.OTHER_WARNINGS_YOU_SHOULD_KNOW_ABOUT:
                editorContent = formInput.otherWarningsYouShouldKnowAbout
                break;
            case SECTION_INDEXES.THE_FOLLOWING_MAY_INTERACT_WITH_BRAND_NAME:
                editorContent = formInput.theFollowingMayInteractWithBrandName
                break;
            case SECTION_INDEXES.HOW_TO_TAKE_BRAND_NAME:
                editorContent = formInput.howToTakeBrandName
                break;
            case SECTION_INDEXES.USUAL_DOSE:
                editorContent = formInput.usualDose
                break;
            case SECTION_INDEXES.OVER_DOSE:
                editorContent = formInput.overDose
                break;
            case SECTION_INDEXES.MISSED_DOSE_PMI:
                editorContent = formInput.missedDosePMI
                break;
            case SECTION_INDEXES.POSSIBLE_SIDE_EFFECTS:
                editorContent = formInput.possibleSideEffects
                break;
            case SECTION_INDEXES.STORAGE:
                editorContent = formInput.storage
                break;
            case SECTION_INDEXES.MORE_INFORMATION:
                editorContent = formInput.moreInformation
                break;
            default:
                editorContent = ""
        }
        dispatch(getPMISectionScore(editorContent))
    }, [currentSectionIndex, formInput, context])

    const handleUpdateField = (name, content) => {
        _.set(formInput, name, content)
        dispatch(updateFormField(name, content, form._id, context))
    }

    const getSectionScore = (editorContent) => {
        return (
            editorContent === "" ? (
                <></>
            ) : (
                <PartThreeSuggestionPopup
                    editorContent={editorContent}
                    isEnglish={!context}
                />
            )
        );
    }

    const buildSectionQuestionWithScore = (label, tooltip, editorContent) => {
        return (
            <Box display="flex" justifyContent="space-between">
                <Box>
                    <h6>
                        <Box>
                            {label}
                            <TooltipComponent label={tooltip} />
                        </Box>
                    </h6>
                </Box>
                <Box>
                    {getSectionScore(editorContent)}
                </Box>
            </Box>
        )
    }

    const getSectionContent = () => {
        switch(currentSectionIndex){
            case SECTION_INDEXES.PART_THREE:
                return null
            case SECTION_INDEXES.BRAND_NAME:
                return (
                    <Box>
                        <h6>{labels.brandName}</h6>
                        <TextField 
                            showLabel={false} 
                            updateFunction = {handleUpdateField} 
                            label = {labels.brandName} 
                            placeholder = {labels.brandName} 
                            name = {"brandNamePMI"}
                        />
                    </Box>
                )
            case SECTION_INDEXES.PROPER_NAME:
                return (
                    <Box>
                        <h6>{labels.properName}</h6>
                        <TextField 
                            showLabel={false} 
                            updateFunction = {handleUpdateField} 
                            label = {labels.properName} 
                            placeholder = {labels.properName} 
                            name = {"properName"}
                        />
                    </Box>
                )
            case SECTION_INDEXES.READ_THIS_FOR_SAFE_AND_EFFECTIVE_USE_OF_YOUR_MEDICINE:
                return (
                    <Box key={`readThisForSafeAndEffectiveUseOfYourMedicine-${context}`}>
                        {buildSectionQuestionWithScore(
                            labels.readThisForSafeAndEffectiveUseOfYourMedicine,
                            tooltipLabels.readThisForSafeAndEffectiveUseOfYourMedicine,
                            formInput.readThisForSafeAndEffectiveUseOfYourMedicine
                        )}
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.readThisForSafeAndEffectiveUseOfYourMedicine} 
                            name = "readThisForSafeAndEffectiveUseOfYourMedicine" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.WHAT_IS_BRAND_NAME_USED_FOR:
                return (
                    <Box key={`whatIsBrandNameUsedFor-${context}`}>
                        {buildSectionQuestionWithScore(
                            labels.whatIsBrandNameUsedFor,
                            tooltipLabels.whatIsBrandNameUsedFor,
                            formInput.whatIsBrandNameUsedFor
                        )}
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.whatIsBrandNameUsedFor} 
                            name = "whatIsBrandNameUsedFor" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.HOW_DOES_BRAND_NAME_WORK:
                return (
                    <Box key={`howDoesBrandNameWork"-${context}`}>
                        {buildSectionQuestionWithScore(
                            labels.howDoesBrandNameWork,
                            tooltipLabels.howDoesBrandNameWork,
                            formInput.howDoesBrandNameWork
                        )}
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.howDoesBrandNameWork} 
                            name = "howDoesBrandNameWork" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.WHAT_ARE_THE_INGREDIENTS_IN_BRAND_NAME:
                return (
                    <Box key={`whatAreTheIngredientsInBrandName-${context}`}>
                        {buildSectionQuestionWithScore(
                            labels.whatAreTheIngredientsInBrandName,
                            tooltipLabels.whatAreTheIngredientsInBrandName,
                            formInput.whatAreTheIngredientsInBrandName
                        )}
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.whatAreTheIngredientsInBrandName} 
                            name = "whatAreTheIngredientsInBrandName" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.BRAND_NAME_DOSAGE_FORMS:
                return (
                    <Box key={`brandNameDosageForms-${context}`}>
                        {buildSectionQuestionWithScore(
                            labels.brandNameDosageForms,
                            tooltipLabels.brandNameDosageForms,
                            formInput.brandNameDosageForms
                        )}
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.brandNameDosageForms} 
                            name = "brandNameDosageForms" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.DO_NOT_USE_BRAND_NAME_IF:
                return (
                    <Box key={`doNotUseBrandNameIf-${context}`}>
                        {buildSectionQuestionWithScore(
                            labels.doNotUseBrandNameIf,
                            tooltipLabels.doNotUseBrandNameIf,
                            formInput.doNotUseBrandNameIf
                        )}
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.doNotUseBrandNameIf} 
                            name = "doNotUseBrandNameIf" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.HEALTH_CONDITIONS_OR_PROBLEMS:
                return (
                    <Box key={`healthConditionsOrProblems-${context}`}>
                        {buildSectionQuestionWithScore(
                            labels.healthConditionsOrProblems,
                            tooltipLabels.healthConditionsOrProblems,
                            formInput.healthConditionsOrProblems
                        )}
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.healthConditionsOrProblems} 
                            name = "healthConditionsOrProblems" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.OTHER_WARNINGS_YOU_SHOULD_KNOW_ABOUT:
                return (
                    <Box key={`otherWarningsYouShouldKnowAbout"-${context}`}>
                        {buildSectionQuestionWithScore(
                            labels.otherWarningsYouShouldKnowAbout,
                            tooltipLabels.otherWarningsYouShouldKnowAbout,
                            formInput.otherWarningsYouShouldKnowAbout
                        )}
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.otherWarningsYouShouldKnowAbout} 
                            name = "otherWarningsYouShouldKnowAbout" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.THE_FOLLOWING_MAY_INTERACT_WITH_BRAND_NAME:
                return (
                    <Box key={`theFollowingMayInteractWithBrandName-${context}`}>
                        {buildSectionQuestionWithScore(
                            labels.theFollowingMayInteractWithBrandName,
                            tooltipLabels.theFollowingMayInteractWithBrandName,
                            formInput.theFollowingMayInteractWithBrandName
                        )}
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.theFollowingMayInteractWithBrandName} 
                            name = "theFollowingMayInteractWithBrandName" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.HOW_TO_TAKE_BRAND_NAME:
                return (
                    <Box key={`howToTakeBrandName-${context}`}>
                        {buildSectionQuestionWithScore(
                            labels.howToTakeBrandName,
                            tooltipLabels.howToTakeBrandName,
                            formInput.howToTakeBrandName
                        )}
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.howToTakeBrandName} 
                            name = "howToTakeBrandName" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.USUAL_DOSE:
                return (
                    <Box key={`usualDose-${context}`}>
                        {buildSectionQuestionWithScore(
                            labels.usualDose,
                            tooltipLabels.usualDose,
                            formInput.usualDose
                        )}
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.usualDose} 
                            name = "usualDose" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.OVER_DOSE:
                return (
                    <Box key={`overDose-${context}`}>
                        {buildSectionQuestionWithScore(
                            labels.overDose,
                            tooltipLabels.overDose,
                            formInput.overDose
                        )}
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.overDose} 
                            name = "overDose" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.MISSED_DOSE_PMI:
                return (
                    <Box key={`missedDosePMI-${context}`}>
                        {buildSectionQuestionWithScore(
                            labels.missedDosePMI,
                            tooltipLabels.missedDosePMI,
                            formInput.missedDosePMI
                        )}
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.missedDosePMI} 
                            name = "missedDosePMI" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.POSSIBLE_SIDE_EFFECTS:
                return (
                    <Box key={`possibleSideEffects-${context}`}>
                        {buildSectionQuestionWithScore(
                            labels.possibleSideEffects,
                            tooltipLabels.possibleSideEffects,
                            formInput.possibleSideEffects
                        )}
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.possibleSideEffects} 
                            name = "possibleSideEffects" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.STORAGE:
                return (
                    <Box key={`storage-${context}`}>
                        {buildSectionQuestionWithScore(
                            labels.storage,
                            tooltipLabels.storage,
                            formInput.storage
                        )}
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.storage} 
                            name = "storage" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.MORE_INFORMATION:
                return (
                    <Box key={`moreInformation-${context}`}>
                        {buildSectionQuestionWithScore(
                            labels.moreInformation,
                            tooltipLabels.moreInformation,
                            formInput.moreInformation
                        )}
                        <WysiwygEditor 
                            language={context} 
                            initialValue = {formInput.moreInformation} 
                            name = "moreInformation" 
                        />
                    </Box>
                )
            default:
                return null
        }
    }

    const Section = getSectionContent()

    return(
        <Box mt={4}>
            <Paper className={classes.paper} elevation={4}>
                {Section ?? (
                    <Typography>
                        This section does not need to be filled out.
                    </Typography>
                )}
            </Paper>
        </Box>
    )
}
