import { Box } from '@material-ui/core';

export const englishTooltipLabels = {
    documentTitle:
    <Box>
    The title should include the following information in the following sequence as text: <br/>
    Scheduling Symbol BRAND NAME (common name), Dosage Form(s), Strength(s) and Route(s) of Administration, Pharmaceutical Standard (if applicable), Therapeutic Classification. <br/>
    </Box>,
    title:
        <Box>
            [Scheduling Symbol] [BRAND NAME] <br />
            [Proper name in final dosage form] <br />
            [Dosage Form(s), Strength(s) and Route(s) of Administration] <br />
            [Pharmaceutical Standard (if applicable)] <br />
            [Therapeutic Classification]
        </Box>,
    controlNumber:
        <Box>
            The control number should be provided when known, and left blank if unknown. This is the 6-digit control number under which this manufactured product was first authorized for use in Canada. This control number will remain the same throughout the life cycle of the manufactured product, even if there is a change of ownership.
        </Box>,
    postalCode:
        <Box>Canadian postal codes must be in "A1A 1A1" format with a single white space separating the third and fourth characters.</Box>,
    footer: <Box>The title page footer is optional. If included, it should be inline with the PM Guidance.</Box>

}

export const frenchTooltipLabels = {
    title:
        <Box>
            [Symbole de classification] [MARQUE NOMINATIVE] <br />
            [Nom propre] <br />
            [Forme(s) posologique(s), concentration(s) et voie(s) d’administration] <br />
            [Norme pharmaceutique (s’il y a lieu)] <br />
            [Classification thérapeutique] <br />
        </Box>,
    controlNumber:
        <Box>
            Le numéro de contrôle doit être fourni lorsqu'il est connu et laissé vide s'il est inconnu. Il s'agit du numéro de contrôle à 6 chiffres sous lequel ce produit manufacturé a été autorisé pour la première fois au Canada. Ce numéro de contrôle restera le même tout au long du cycle de vie du produit fabriqué, même en cas de changement de propriétaire.
        </Box>,
    postalCode:
        <Box>Les codes postaux canadiens doivent être au format "A1A 1A1" avec un seul espace blanc séparant les troisième et quatrième caractères.</Box>,
    documentTitle:
        <Box>
        Le titre doit inclure les informations suivantes dans l'ordre suivant sous forme de texte : <br/>
        Symbole de calendrier MARQUE (nom usuel), Forme(s) posologique(s), Dosage(s) et Voie(s) d'administration, Norme pharmaceutique (le cas échéant), Classification thérapeutique. <br/>
        </Box>,
    footer: <Box>Le pied de page de titre est facultatif. S'il est inclus, il doit être conforme aux directives PM.</Box>
}