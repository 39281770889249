import React, { useEffect } from 'react'

import { Box, Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

import UserInfo from './UserInfo'
import ChangePassword from './ChangePassword'

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        paddingBottom: "5%"
    },
}));

export default function Profile() {
    const classes = useStyles();

    useEffect(() =>{
        document.title = "Profile Settings"
    }, [])

    return (
        <Box>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <UserInfo />
                    </Grid>
                    <Grid item xs={6}>
                        <ChangePassword />
                    </Grid>
                </Grid>
            </div>
        </Box>
    );

}
