import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import {
    Chip, InputLabel, TextField, MenuItem, FormControl, Select, Box, CircularProgress, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PMButton from '../common/Button';
import { updateFormPermissionsForUser, updateFormField, archiveForm } from '../../actions/forms';
import TooltipComponent from './formComponents/TooltipComponent';
import { englishTooltipLabels, frenchTooltipLabels } from "../tooltipLabels/FormManagementTooltipLabels"
import { FORM_ACCESS } from '../../utils/utilFunctions';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(() => ({
    paper: {
        minWidth: "1000px"
    },
    table: {
        minWidth: "100%",
        '& > tbody > tr:last-child > *': {
            border: "none"
        }
    },
    pageBar: {
        display: "flex",
        flexDirection: "row",
    },
    icon: {
        height: "15px",
        width: "15px"
    },
    clickable: {
        cursor: "pointer"
    },
    tableBox: {
        minHeight: "46vh"
    },
    tableCellText: {
        fontWeight: 400,
        fontSize: 15
    },
    patientRow: {
        cursor: "pointer",
        transitionDuration: '0.1s',
        "&:hover": {
            backgroundColor: "#d9d9d9",
            color: "black"
        }
    },
    headerCell: {
        borderBottomColor: '#5C71FA'
    },
    emptyRow: {
        paddingTop: "15vh",
        border: "none"
    },
    noSurveys: {
        textAlign: "center",
        fontWeight: 700,
        fontSize: 20,
        color: "#979A9F",
    },
    loadingCell: {
        textAlign: "center"
    }
}));

export const InProgressChip = <Chip style={{ width: "100%", backgroundColor: "#89cff0", color: "white" }} label="In Progress" />
export const UnderReviewChip = <Chip style={{ width: "100%", backgroundColor: "#ffe200" }} label="Under Review" />
export const ApprovedChip = <Chip style={{ width: "100%", backgroundColor: "#3cb371", color: "white" }} label="Approved" />

export const getStatusChip = (status) => {
    switch (status) {
        case 0:
            return InProgressChip
        case 1:
            return UnderReviewChip
        case 2:
            return ApprovedChip
        default:
            return InProgressChip
    }
}

export default function FormManagementDialog({ context, isFormManagementDialogOpen, setIsFormManagementDialogOpen }) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const form = useSelector((state) => state.forms.form)
    const formActiveStatusLoading = useSelector((state) => state.forms.formActiveStatusLoading)
    const formUpdating = useSelector((state) => state.forms.updating)
    const formLock = useSelector((state) => state.forms.editState.isDisabled)

    const loading = useSelector((state) => state.forms.loading)

    const usersInUserCompanyInfo = useSelector((state) => state.forms.usersInUserCompanyInfo)
    const formAccessControlInfo = useSelector((state) => state.forms.formAccessControlInfo)

    const [tooltipLabels, setTooltipLabels] = useState({});
    const [editableName, setEditableName] = useState(form.name);
    const [isEditing, setIsEditing] = useState(false);


    const handleControlAccessChange = (formId, userId, newAccessLevel) => {
        dispatch(updateFormPermissionsForUser(formId, userId, newAccessLevel))
    };

    const handleStatusChange = (e) => {
        dispatch(updateFormField("status", e.target.value, form._id, context, false))
    }

    const getAccessLevelUsername = (username) => {
        return username in formAccessControlInfo ? formAccessControlInfo[username] : FORM_ACCESS.VIEWER
    };

    const [openDialog, setOpenDialog] = useState(false);

    let history = useHistory()

    useEffect(() => {
        setTooltipLabels(context ? frenchTooltipLabels : englishTooltipLabels)
    }, [context])

    const handleEditableNameChange = (event) => {
        const newName = event.target.value;
        setEditableName(newName);
    };

    const handleDeleteProductMonograph = async (id) => {
        setOpenDialog(true);

    }

    const handleConfirmDelete = () => {
        dispatch(archiveForm(form._id, history))
        setOpenDialog(false);
    };

    const handleUpdateName = () => {
        dispatch(updateFormField("name", editableName, form._id, context, false))
    }

    const handleCancelDelete = () => {
        setOpenDialog(false);
    };

    return (
        <Dialog
            open={isFormManagementDialogOpen}
            keepMounted
            scroll="paper"
            maxWidth="lg"
        >
            <DialogTitle>
                <Box display="flex" justifyContent="space-between">
                    <Box>
                        <Typography variant="h5">
                            PM Settings
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={() => setIsFormManagementDialogOpen(false)} color='secondary'>
                            <CancelIcon/>
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <Dialog open={openDialog} onClose={handleCancelDelete}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to delete this item?</p>
                </DialogContent>
                <DialogActions>
                    <PMButton outlined disabled={formActiveStatusLoading} onClick={handleCancelDelete} color="primary">
                        Cancel
                    </PMButton>
                    <PMButton disabled={formActiveStatusLoading} red onClick={handleConfirmDelete} color="primary">
                        {formActiveStatusLoading ? 'Deleting...' : 'Delete'}
                    </PMButton>
                </DialogActions>
            </Dialog>
            <DialogContent dividers={true}>
                {isEditing ? (
                    <Box display="flex" justifyContent="space-between">
                        <Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                value={editableName}
                                onChange={handleEditableNameChange}
                                autoFocus
                            />
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <PMButton
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: '8px' }}
                                onClick={() => {
                                    handleUpdateName();
                                    setIsEditing(false);
                                }}
                            >
                                Save
                            </PMButton>
                            <PMButton
                                red
                                variant="contained"
                                color="secondary"
                                style={{ marginLeft: '8px' }}
                                onClick={() => {
                                    // Reset changes
                                    setEditableName(form.name);
                                    setIsEditing(false);
                                }}
                            >
                                Cancel
                            </PMButton>
                        </Box>
                    </Box>
                ) : (
                    <Box display="flex" justifyContent="space-between">
                        <Box>
                            <Typography
                                variant="body1"
                                style={{ fontWeight: 'bold', fontSize: '22px' }}
                            >
                                {form.name}
                            </Typography>
                        </Box>
                        <Box>
                            <PMButton
                                variant="contained"
                                color="primary"
                                disabled={formLock}
                                style={{ marginLeft: '8px' }}
                                onClick={() => {
                                    setIsEditing(true);
                                }}
                            >
                                Edit
                            </PMButton>
                        </Box>
                    </Box>
                )}
                <Box>
                    <Typography variant="subtitle1">
                        Approval Status
                    </Typography>
                    <Box borderRadius={16} mb={5}>
                        <FormControl variant="standard">
                            <Select
                                value={form.status}
                                label="Approval Status"
                                onChange={handleStatusChange}
                                disabled={formLock}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                disableUnderline
                            >
                                <MenuItem value={0}>
                                    {InProgressChip}
                                </MenuItem>
                                <MenuItem value={1}>
                                    {UnderReviewChip}
                                </MenuItem>
                                <MenuItem value={2}>
                                    {ApprovedChip}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                <Box>
                    <Typography variant="subtitle1">
                        Access Control <TooltipComponent label={tooltipLabels.accessControl}></TooltipComponent>
                    </Typography>
                    <Box boxShadow={1} borderRadius={16}>
                        <TableContainer className={classes.tableBox}>
                            <Table className={classes.table}>
                                <colgroup>
                                    <col style={{ width: '20%' }} />
                                    <col style={{ width: '20%' }} />
                                    <col style={{ width: '20%' }} />
                                    <col style={{ width: '20%' }} />

                                </colgroup>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.headerCell}>
                                            Name
                                        </TableCell>
                                        <TableCell className={classes.headerCell}>
                                            Surname
                                        </TableCell>
                                        <TableCell className={classes.headerCell}>
                                            Email
                                        </TableCell>
                                        <TableCell className={classes.headerCell}>
                                            Access
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {formUpdating ? (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={classes.loadingCell} colSpan={3}>
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        {usersInUserCompanyInfo.users && usersInUserCompanyInfo?.users.map((user, index) => {
                                            const userAccess = getAccessLevelUsername(user?.username)

                                            return (
                                                <TableRow className={classes.patientRow} key={`${index}-${form._id}`}>
                                                    <TableCell>
                                                        {user?.given_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {user?.family_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {user?.email}
                                                    </TableCell>
                                                    <TableCell>
                                                        <FormControl sx={{ m: 2, minWidth: 520 }}>
                                                            <InputLabel id="controlAccess"></InputLabel>
                                                            <Select
                                                                value={userAccess.code}
                                                                label="access"
                                                                onChange={(event) => handleControlAccessChange(form._id, user.username, event.target.value)}
                                                            >
                                                                <MenuItem value={1}>Viewer</MenuItem>
                                                                <MenuItem value={2}>Editor</MenuItem>
                                                                <MenuItem value={3}>Manager</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                )}

                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </DialogContent>

            <DialogActions style={{ justifyContent: 'flex-start', flexDirection: 'column' }}>
                <PMButton
                    loading={loading}
                    disabled={loading}
                    red
                    onClick={() => {
                        handleDeleteProductMonograph(form?._id)
                    }}

                >
                    Delete
                </PMButton>
            </DialogActions>
        </Dialog>
    )
}