import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateFormField } from '../../../actions/forms';

import { Box } from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';

// Tiny Editor
import { Editor } from '@tinymce/tinymce-react';
import axios from '../../../utils/customAxios'

import addPlugin from './WysiwygPlugins/customAnchor';
import useInterval from '../../../utils/Interval';

const useStyles = makeStyles((theme) => ({
    editorBox: (props) => ({
		"& .tox-edit-area__iframe": {
			backgroundColor: props.disabled ? "#e9ecef !important" : "",
		}
    }),
}));

const BASE_PLUGINS = [
	'lists', 'link', 'image', 'anchor', 'media', 'table', 'noneditable', 'instapm_anchor', 'powerpaste', 'charmap', 'autoresize', 'searchreplace', 'nonbreaking',
]
const BASE_TOOLBAR = 'undo redo | bold italic bullist numlist subscript superscript blockquote | nonbreaking charmap | link instapm_anchor image table | searchreplace'

const EXPERIMENTAL_PLUGINS = [
	'advcode'
]
const EXPERIMENTAL_TOOLBAR = 'code'

const PLUGINS = process.env.REACT_APP_ENVMNT === 'production' ? BASE_PLUGINS : [...BASE_PLUGINS, ...EXPERIMENTAL_PLUGINS]
const TOOLBAR = process.env.REACT_APP_ENVMNT === 'production' ? BASE_TOOLBAR : `${BASE_TOOLBAR} | ${EXPERIMENTAL_TOOLBAR}`

export default function WysiwygEditor({ language, initialValue, name }){
	const editorRef = useRef(null);
	const dispatch = useDispatch();
	const form = useSelector((state) => state.forms.form)
	const editState = useSelector((state) => state.forms.editState)
	const [initValue, setInitValue] = useState(initialValue)
	const [editorInFocus, setEditorInFocus] = useState(false)
	const classes = useStyles({ disabled: editState.isDisabled });

	useEffect(() => {
		setInitValue(initialValue)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language, name])

	useEffect(() => {
		if(editState.isDisabled) {
			setInitValue(initialValue)
		}
	}, [editState, initialValue])

	const handleChange = (name, content) => {
		dispatch(updateFormField(name, content, form._id, language))
	}

	useEffect(() => {
		return () => {
			if(editorRef.current && editorRef.current.isDirty()) {
				const content = editorRef.current.getContent();
				handleChange(name, content)
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name])

	useInterval(() => {
		handleChange(name, editorRef.current.getContent())
	}, editorInFocus ? 5000 : null)

	const imageUploader = (blobInfo) => new Promise((resolve, reject) => {
        let data = new FormData();
        data.append('file', blobInfo.blob());
		axios.post(`/api/forms/image_upload`, data, { headers: {
			'Content-Type': `multipart/form-data`,
		}})
			.then(function (res) {
				resolve(res.data.location);
			})
			.catch(function (err) {
				reject('Upload Error: ' + err.response.data.message);
			});
	});

	const handleOnBlur = () => {
		setEditorInFocus(false)
	}

	const setup = (editor) => {
		addPlugin(editor, name, form, language, handleChange)

		// TODO:
		// Removing the is dirty setting logic as it wasn't detecting changes and not making API calls to save
		// Need to limit calls in the future for cost saving but commenting out now to ensure data integrity.
		editor.on('blur', (e) => {
			// if(editor.isDirty()){
				handleChange(name, editor.getContent())
				// editor.setDirty(false)
			// }
		})

		editor.on('focus', (e) => {
			setEditorInFocus(true)
		})
	};

  	return (
		<Box className={classes.editorBox} id={name} key={name}>
			<Editor
				initialValue={initValue}
				apiKey="mt66tjvcqygmtm4me8rh2fci5hwi11rkxmwhfx67czmje03w"
				disabled={editState.isDisabled}
				init={{
					setup: setup,
					content_style:`
						ins { 
							color: green;
							text-decoration: none;
							background-color: rgba(46,160,67,0.4);
						}
						del {
							color: red;
							text-decoration: none;
							background-color: rgba(248,81,73,0.4);
						}
					`,
					height: 500,
					max_height: 700,
					min_height: 200,
					menubar: false,
					statusbar: false,
					branding: false,
					images_upload_handler: imageUploader,
					link_title: false,
					allow_html_in_named_anchor: true,
					convert_urls: false,
					link_list: (success) => {
						let formData = language === false ? form.formDataEnglish : form.formDataFrench
						success([
							{
								title: 'Sections',
								menu: Object.values(formData.sectionUUIDs) ?? []
							},
							{
								title: 'Bookmarks',
								menu: Object.values(formData.anchors ?? []) ?? []
							},
							{
								title: 'Footnotes',
								menu: formData.footnotes ?? []
							},
						])
					},
					target_list: [
						{title: 'None', value: ''},
					],
					powerpaste_allow_local_images: false,
					powerpaste_word_import: 'merge',
					plugins: PLUGINS,
					toolbar:TOOLBAR,
						
				}}
				onInit={(evt, editor) => editorRef.current = editor}
				onBlur={handleOnBlur}
			/>
		</Box>
  	)
}