import axios from "../utils/customAxios";
import { customFetch } from "../utils/customFetch";
import { CHAT_LOADING, CHAT_STREAMING } from './types';

export const queryChatbot = (question, chatHistory) => async (dispatch) => {

	dispatch({
		type: CHAT_LOADING,
		payload: {
			question: question,
		},
	});

  	try {
		const res = await customFetch(
			'/api/ai/query',
			'POST',
			{},
			{
				question: question,
				history: chatHistory || [],
			}
		)

		const reader = res.body.getReader()

		while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            const text = new TextDecoder("utf-8").decode(value);
			dispatch({
				type: CHAT_STREAMING,
				payload: {
					answer: text,
				},
			});
        }
	} catch (err) {
		console.log(err)
	}
};
