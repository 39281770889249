import { Grid, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import _ from "lodash"

const PasswordValidator = ({ conditions, fields=null }) => {
    const passwordConditions = fields ? _.pickBy(conditions, (condition) => {return _.difference(condition.field, fields).length === 0}) : conditions
    return (
        <Grid direction="column" container>
            <List>
                {Object.keys(passwordConditions).map((conditionName) => {
                    return (
                        <ListItem key={conditions[conditionName].key}>
                            <ListItemIcon>
                                {conditions[conditionName].state ? <CheckCircleIcon color="primary"/> : <HighlightOffIcon color="secondary" />}
                            </ListItemIcon>
                            <ListItemText primary={conditions[conditionName].message} />
                        </ListItem>
                    )
                })}
            </List>
        </Grid>
    );
}

export default PasswordValidator;
