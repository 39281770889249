export const productModel = {
    // Product Information
    brandName: '',
    nonProprietaryName: '',
    DIN: '',
    substanceAdministrations: [],
    dosageForm: {},
    isMultiPart: '',

    // Product Characteristics
    productType: {},
    colours: [],
    shapes: [],
    ////// deprecated, use sizes instead
    size: {
        value: "",
        unit: {}
    },
    ///////
    sizes: [],
    score: {},
    imprint: '',
    flavours: [],
    pharmaceuticalStandards: [],
    schedules: [],
    therapeuticClasses: [],

    // Regulatory Status
    regulatoryActivityType: {},
    regulatoryControlNumber: '',
    dateOfApproval: '',
    dateOfCancellation: '',

    denominatorValue: "",
    denominatorUnit: {},

    // Ingredients
    ingredients: [],

    // Packagaing
    packagings: [],

    // parts
    parts: []
}

export const part = {
    // Part Quantity
    numeratorValue: '',
    numeratorUnit: {},
    denominatorValue: '',
    denominatorUnit: {}, 

    // Product Information
    brandName: '',
    nonProprietaryName: '',
    DIN: '',
    substanceAdministrations: [],
    dosageForm: {},

    // Product Characteristics
    productType: {},
    colours: [],
    shapes: [],
    size: {
        value: "",
        unit: {}
    },
    score: {},
    imprint: '',
    flavours: [],
    pharmaceuticalStandards: [],
    schedules: [],
    therapeuticClasses: [],

    // Regulatory Status
    regulatoryActivityType: {},
    regulatoryControlNumber: '',
    dateOfApproval: '',
    dateOfCancellation: '',

    // Ingredients
    ingredients: [],
    ingredientDenominatorValue: "",
    ingredientDenominatorUnit: {},

    // Packagaing
    packagings: [],
}