import React, { Component } from 'react'

// Import additional components
import Robot from '../../assets/not-found-robot.png'

class NotFound extends Component {
  render() {
    return (
      // Not Found
      <table align="center" className="not-found-table">
        <tr>
          <td align = "center" className = "not-found-text-column">
            <div className ="not-found-text">
              <b>404.</b> That's an error. 
              <br></br>
              Basically, it means nothing was found.
              <br></br><br></br>
              The requested URL was not found on this server.
              <br></br>
              That's literally all I know.
              <br></br><br></br>
              Don't worry though. While the team looks into this, click <a href = "/dashboard">here</a> to go back to your dashboard.
            </div>
          </td>
          <td className = "not-found-image-column">
            <img alt="Not found robot." src={Robot} />
          </td>
        </tr>
      </table>

    )
  }
}

export default NotFound;