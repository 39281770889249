import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';

import { TourProvider } from '@reactour/tour'

import PMButton from '../components/common/Button';

import { INCREMENT_DEMO_STEP, DECREMENT_DEMO_STEP, UPDATE_DEMO_STATE, LOGOUT } from '../actions/types';

const DEMO_FORM_PAGE_PATHNAME_REGEX = /^\/demo\/.+/;

const useStyles = makeStyles(() => ({
    tourPopup: {
        borderRadius: "25px"
    }
}))

export default function DemoProvider({ children }){
    const classes = useStyles();
    const dispatch = useDispatch()

    const isDemoUser = useSelector((state) => state.auth.isDemoUser)
    const isDemoFormPage = useSelector((state) => state.demo.isDemoFormPage)
    const currentDemoStep = useSelector((state) => state.demo.currentDemoStep)
    const isDemoComplete = useSelector((state) => state.demo.isDemoComplete)

    const nextButtonHidden = [4, 7, 9, 10, 11, 13].includes(currentDemoStep)
    const disableInteraction = [2, 6, 8].includes(currentDemoStep)
    const showCloseButton = currentDemoStep === 0

    const pathname = useLocation().pathname

    useEffect(() => {
        const _isDemoSignupPage = (pathname) === "/demo/" || (pathname) === "/demo"
        const _isDemoFormPage = DEMO_FORM_PAGE_PATHNAME_REGEX.test(pathname)
        const _isDemo = _isDemoSignupPage || _isDemoFormPage

        dispatch({
            type: UPDATE_DEMO_STATE,
            payload: {
                _isDemoSignupPage,
                _isDemoFormPage,
                _isDemo
            }
        })
    }, [pathname])

    useEffect(() => {
        if(isDemoUser && !(pathname.includes('demo'))) {
            dispatch({
                type: LOGOUT
            })
        }
    }, [pathname, isDemoUser])

    const handleOnNext = (e) => {
        e.preventDefault()

        dispatch({
            type: INCREMENT_DEMO_STEP,
        })
    }

    const steps = [
        {
            selector: '.intro',
            content: 'Welcome to InstaPM\'s interactive demo! Take a look around and experiment with what InstaPM has to offer. Want a personalized demo or have questions? Reach out to us at info@instapm.ca!',
            title: 'InstaPM Interactive Demo',
            position: "center",
        },
        {
            selector: '.author-view',
            content: 'We\'ve created this PM just for you! Let\'s navigate through the various sections to take a closer look'
        },
        {
            selector: '.sidebar',
            content: 'This is your sidebar, where you can navigate through the various sections of your product monograph',
            position: "top",
        },
        {
            selector: '.section-content',
            content: 'Here is where each section\'s content would live. Sections are made up of a variety of textboxes, controlled vocabulary drop-downs, and rich editors that you can fill in.',
            position: "top",
        },
        {
            selector: '.edit-button',
            content: 'Let\'s make a small change to this PM. To start editing. \n Click the Edit PM button.',
            position: "top",
        },
        {
            selector: '.document-title',
            content: 'Try updating the Title content using our custom rich editor',
            position: "top",
        },
        {
            selector: '.action-buttons',
            content: 'Here we have the action buttons to Upload, Manage Versions, Validate, Preview and Export your PM',
            position: "top",
        },
        {
            selector: '.validate-button',
            content: 'Try clicking on the Validate button to see the results',
            position: "top",
        },
        {
            selector: '.validation-report',
            content: 'Here is the validation report, it provides real time validation results on the content in your PM!',
            position: "top"
        },
        {
            selector: '.close-validation',
            content: 'Please close the report to continue',
            position: "top",
        },
        {
            selector: '.click-export-menu-button',
            content: 'Finally, lets generate an export of the PM. Click on the Export button to continue',
            position: "top",
        },
        {
            selector: '.click-generate-button',
            content: 'Hit the Generate button to start the process',
            position: "top",
        },
        {
            selector: '.click-dhpp-button',
            content: 'Once its loaded, click here to view the DHPP stylised version of the PM',
            position: "top",
        },
        {
            selector: '.close-download',
            content: 'Close the popup to finish your demo.',
            position: "top",
        },
        {
            selector: '.conclusion',
            content: 'Thank you for trying our interactive demo! Reach out to us at info@instapm.ca to learn more!',
            position: "center",
        },
    ];

    if (!isDemoFormPage || isDemoComplete) {
        return (
            <>
                {children}
            </>
        )
    }

    return (
        <TourProvider 
            className={classes.tourPopup}
            defaultOpen={isDemoFormPage}
            disableDotsNavigation={true}
            showDots={false}
            showCloseButton={showCloseButton}
            steps={steps}
            currentStep={currentDemoStep}
            nextButton={() => {
                return (
                    <PMButton hidden={nextButtonHidden} onClick={handleOnNext}>
                        {currentDemoStep === 14 ? "End Demo" : "Next"}
                    </PMButton>

                )
            }}
            prevButton={() => {
                return <></>
            }}
            disableInteraction={disableInteraction}
            onClickMask={() => {}}
        >
            {children}
        </TourProvider>
    )
};