import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TextField from '../formComponents/TextField'
import AutocompleteField from "../formComponents/AutoComplete"
import {englishLabels, frenchLabels} from "../../formLabels/IngredientFormLabels"
import Switch from "react-switch";
import { makeStyles} from '@material-ui/core/styles';
import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Paper, Box, Grid } from "@material-ui/core"
import PMButton from "../../common/Button";
import { updateFormField } from '../../../actions/forms'
import { ingredient} from "../../../models/Ingredient"

const useStyles = makeStyles({
    button: {
        width: "50%",
    },
    veritcalText: {
        alignSelf: "center"
    },
    paper: {
        padding: "2rem",
        margin: "2rem 0rem",
        borderRadius: "25px"
    }
});

export default function Ingredients({ handleUpdateField, context, currentProductIndex }){
    const dispatch = useDispatch()
    const form = useSelector((state) => state.forms.form)
    const editState = useSelector((state) => state.forms.editState)
    const updating = useSelector((state) => state.forms.updating)
    const classes = useStyles();
    const [labels, setLabels] = useState({});
    let formInput = context ? form && form.formDataFrench : form && form.formDataEnglish 
    let ingredients = formInput.products[currentProductIndex].ingredients

    useEffect(() => {
        setLabels(context ? frenchLabels : englishLabels)
    }, [context])

    const getIngredientCode = (index) => {
        try {
            return parseInt(formInput.products[currentProductIndex].ingredients[index].classCode.codeInfo.code)
        } catch {   
            return null
        }
    }

    const getIsRange = (index) => {
        try {
            return formInput.products[currentProductIndex].ingredients[index].numerator.isRange
        } catch {
            return false
        }
    }

    const handleCreateIngredient = () => {
        formInput.products[currentProductIndex].ingredients.push(ingredient)
        dispatch(updateFormField("products", formInput.products, form._id, context))
    }

    const handleRemoveIngredient = () => {
        formInput.products[currentProductIndex].ingredients.pop()
        dispatch(updateFormField("products", formInput.products, form._id, context))
    }

    return (
        <Paper className={classes.paper} elevation={4}>
            <div className = "inactive-ingredient-container">
                <Grid container>
                    <Grid xs={10} item className={classes.veritcalText}>
                        <Box>
                            <h6>{labels.title}</h6>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box display="flex">
                            <Box width="100%" mr={2}>
                                <PMButton isFormButton={true} disabled={updating} onClick={handleCreateIngredient}>
                                    Add
                                </PMButton>
                            </Box>
                            <Box width="100%">
                                <PMButton isFormButton={true} disabled={updating} onClick={handleRemoveIngredient} red>
                                    Delete
                                </PMButton>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Box display="flex">
                    <TextField 
                        updateFunction={handleUpdateField} 
                        columnStyling = {3} 
                        label = {labels.denominatorValue} 
                        placeholder = {"ex. 1"} 
                        name = {`products[${currentProductIndex}].denominatorValue`}
                        type={"number"}
                    /> 
                    <AutocompleteField 
                        context={context} 
                        codeSystem="2.16.840.1.113883.2.20.6.15" 
                        updateFunction={handleUpdateField} 
                        columnStyling = {3} 
                        label = {labels.denominatorUnit} 
                        placeholder = {"mL"} 
                        name = {`products[${currentProductIndex}].denominatorUnit`}
                    /> 
                </Box>
                <TableContainer>
                    <Table>
                        <colgroup>
                            <col style={{width:'10%'}}/>{/* Type */}
                            <col style={{width:'20%'}}/>{/* Name*/}
                            <col style={{width:'10%'}}/>{/* Quantity */}
                            <col style={{width:'10%'}}/>{/* Quantity Range */}
                            <col style={{width:'10%'}}/>{/* Unit */}
                            <col style={{width:'20%'}}/>{/* Active Moiety */}
                            <col style={{width:'20%'}}/>{/* Equivalent Substance */}
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Quantity Range</TableCell>
                                <TableCell>Unit</TableCell>
                                <TableCell>Active Moiety</TableCell>
                                <TableCell>Equivalent Substance</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ingredients.length > 0 ? (
                                ingredients.map((ingredient, index) => (
                                    <TableRow key={index}>
                                        <TableCell >
                                            <AutocompleteField 
                                                showLabel={false} 
                                                context={context} 
                                                codeSystem="2.16.840.1.113883.2.20.6.39" 
                                                updateFunction={handleUpdateField} 
                                                index={index} 
                                                label = {labels.code} 
                                                placeholder = {labels.code} 
                                                name ={`products[${currentProductIndex}].ingredients[${index}].classCode`}
                                            /> 
                                        </TableCell>
                                        <TableCell>
                                            <AutocompleteField 
                                                showLabel={false} 
                                                context={context} 
                                                codeSystem="2.16.840.1.113883.2.20.6.14" 
                                                updateFunction={handleUpdateField} 
                                                index={index} 
                                                label = {labels.name} 
                                                placeholder = {labels.name} 
                                                name ={`products[${currentProductIndex}].ingredients[${index}].ingredientName`}
                                            /> 
                                        </TableCell>
                                        <TableCell>
                                            { getIsRange(index) ? (
                                                <>
                                                    <TextField 
                                                        showLabel={false} 
                                                        updateFunction={handleUpdateField} 
                                                        index={index} 
                                                        label = {labels.numeratorValue} 
                                                        placeholder = {"ex. 10"} 
                                                        name = {`products[${currentProductIndex}].ingredients[${index}].numerator.lowValue`}
                                                        type={"number"}
                                                    /> 
                                                    <TextField 
                                                        showLabel={false} 
                                                        updateFunction={handleUpdateField} 
                                                        index={index} 
                                                        label = {labels.numeratorValue} 
                                                        placeholder = {"ex. 10"} 
                                                        name = {`products[${currentProductIndex}].ingredients[${index}].numerator.highValue`}
                                                        type={"number"}
                                                    /> 
                                                </>
                                            ):(
                                                <>
                                                    <TextField 
                                                        showLabel={false} 
                                                        updateFunction={handleUpdateField} 
                                                        index={index} 
                                                        label = {labels.numeratorValue} 
                                                        placeholder = {"ex. 10"} 
                                                        name = {`products[${currentProductIndex}].ingredients[${index}].numerator.value`}
                                                        type={"number"}
                                                    /> 
                                                </>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Switch 
                                                width={70} 
                                                height={35} 
                                                onChange={() => {handleUpdateField(`products[${currentProductIndex}].ingredients[${index}].numerator.isRange`, !getIsRange(index))}} 
                                                checked={getIsRange(index)} 
                                                disabled={editState.isDisabled}
                                                uncheckedIcon={
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            height: "100%",
                                                            fontSize: 15,
                                                            color: "white",
                                                        }}
                                                    >
                                                        No
                                                    </div>
                                                }
                                                checkedIcon={
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            height: "100%",
                                                            fontSize: 15,
                                                            color: "white",
                                                        }}
                                                    >
                                                        Yes
                                                    </div>
                                                }
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <AutocompleteField 
                                                showLabel={false} 
                                                context={context} 
                                                codeSystem="2.16.840.1.113883.2.20.6.15" 
                                                updateFunction={handleUpdateField} 
                                                index={index} 
                                                label = {labels.numeratorUnit} 
                                                placeholder = {'mg'} 
                                                name = {`products[${currentProductIndex}].ingredients[${index}].numerator.unit`}
                                            />     
                                        </TableCell>
                                        <TableCell>
                                            {getIngredientCode(index) !== 3 ? (
                                                <AutocompleteField 
                                                    showLabel={false} 
                                                    context={context} 
                                                    codeSystem="2.16.840.1.113883.2.20.6.14" 
                                                    updateFunction={handleUpdateField} 
                                                    index={index} 
                                                    label = {labels.activeMoiety} 
                                                    placeholder = {labels.activeMoiety} 
                                                    name ={`products[${currentProductIndex}].ingredients[${index}].activeMoiety`}
                                                />
                                            ): (
                                                <Box>Not applicable to this ingredient type.</Box>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {getIngredientCode(index) === 6 ? (
                                                <AutocompleteField 
                                                    showLabel={false} 
                                                    context={context} 
                                                    codeSystem="2.16.840.1.113883.2.20.6.14" 
                                                    updateFunction={handleUpdateField} 
                                                    index={index} 
                                                    label = {labels.equivalentSubstance} 
                                                    placeholder = {labels.equivalentSubstance} 
                                                    name ={`products[${currentProductIndex}].ingredients[${index}].asEquivalentSubstance`}
                                                /> 
                                            ):(
                                                <Box>Not applicable to this ingredient type.</Box>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={7}>
                                       No ingredients to show.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Paper>
    )
}
