import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PrivateRoute from '../routing/PrivateRoute';
import PublicRoute from '../routing/PublicRoute';
import NotFound from "../layout/NotFound"
import Logout from "../auth/Logout"
import Profile from "../profile/Profile"
import Admin from "../admin/Admin"
import Demo from '../demo/Demo';
import Dashboard from '../forms/Dashboard';
import Form from "../form/Form"
import LoginRouter from '../auth/LoginRouter';
import { VIEW_TYPE_STATES } from '../../utils/utilFunctions';
import AdminRoute from './AdminRoute';
const Routes = () => {
    return (
        <Switch>
            <Redirect exact from="/" to="/login" />
            <Route exact path='/login' component={LoginRouter} />
            <Route exact path="/forgot-password" component={LoginRouter} />
            <Route exact path="/reset-password" component={LoginRouter} />
            <Route exact path='/demo' component={Demo} viewType={VIEW_TYPE_STATES.EDITOR} showChatBot={true} />
            <div style={{ paddingTop: "2rem" }}>
                <Switch>
                    <PrivateRoute exact path='/dashboard' component={Dashboard} showChatBot={true} />
                    <PublicRoute exact path='/demo/:id' component={Form} viewType={VIEW_TYPE_STATES.EDITOR} showChatBot={true} />
                    <PrivateRoute exact path='/form/:id' component={Form} viewType={VIEW_TYPE_STATES.EDITOR} showChatBot={true} />
                    <PrivateRoute exact path='/archive/:id' component={Form} viewType={VIEW_TYPE_STATES.ARCHIVE} showChatBot={true} />
                    <PrivateRoute exact path='/compare/:id' component={Form} viewType={VIEW_TYPE_STATES.COMPARISON} showChatBot={true} />
                    <PrivateRoute exact path='/profile' component={Profile} showChatBot={true} />
                    <AdminRoute exact path='/admin' component={Admin} />
                    <PrivateRoute exact path='/logout' component={Logout} />
                    <Route component={NotFound} />
                </Switch>
            </div>
        </Switch>
    )
}

export default Routes;
