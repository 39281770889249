export const packaging = (outermost, parentDenominatorUnit) => {
    return {
        code: '',
        packageIdentifier: {},
        dateOfApproval: '',
        dateOfCancellation: '',
        numeratorValue: '',
        numeratorUnit: outermost ? {} : parentDenominatorUnit,
        denominatorValue: outermost ? '1' : '',
        denominatorUnit: outermost ? 
            {
                codeInfo: {
                    code: "1",
                    displayName: "1",
                    codeSystem: "2.16.840.1.113883.2.20.6.15",
                }
            }
        : 
            {},
        packagings : [],
    }
}