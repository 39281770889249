
import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChatBot from '../chatbot/index';
const useStyles = makeStyles((theme) => ({
    page: {
        margin: "4%"
    },
    formPage: {
        margin: "4% 4% 0% 2%"
    }
}));

const PublicRoute = ({ component: Component, viewType, showChatBot, ...rest }) => {
    const classes = useStyles()

    const isFormPage = rest.path === "/form/:id" || rest.path === "/demo/:id"
    
    return (
        <Box className={isFormPage ? classes.formPage : classes.page}>
            <Route
                {...rest}
                render={ props => (
                    <>
                        <Component {...props} viewType={viewType} />
                        { showChatBot && <ChatBot /> }
                    </>
                )}
            />
        </Box>
    )
};

PublicRoute.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PublicRoute);