import React from 'react'
import { useHistory } from 'react-router-dom';

import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from 'moment';
import { getStatusChip } from "../form/FormManagementDialog";

const useStyles = makeStyles(() => ({
    table: {
        minWidth: "100%",
        '& > tbody > tr:last-child > *': {
            border: "none"
        }
    },
    pageBar: {
        display: "flex",
        flexDirection: "row",
    },
    icon: {
        height: "15px",
        width: "15px"
    },
    clickable: {
        cursor: "pointer"
    },
    tableBox: {
        minHeight: "46vh"
    },
    tableCellText: {
        fontWeight: 400,
        fontSize: 15
    },
    patientRow: {
        cursor: "pointer",
        transitionDuration: '0.1s',
        "&:hover": {
            backgroundColor: "#d9d9d9",
            color: "black"
        }
    },
    headerCell: {
        borderBottomColor: '#5C71FA'
    },
    emptyRow: {
        paddingTop: "15vh",
        border: "none"
    },
    noSurveys: {
        textAlign: "center",
        fontWeight: 700,
        fontSize: 20,
        color: "#979A9F",
    },
}));

const tableHeaders = [
    {
        header: 'Name',
        field: 'name',
        sortable: true,
        width: '30%'
    },
    {
        header: 'Created Date',
        field: 'createdDate',
        sortable: true,
        width: '30%'
    },
    {
        header: 'Last Modified Date',
        field: 'lastModifiedDate',
        sortable: true,
        width: '30%'
    },
    {
        header: 'Status',
        field: 'status',
        sortable: false,
        width: '10%'
    },
];

const loadingArray = [1, 1, 1, 1, 1, 1, 1];

export default function PatientManagementTable({ loading, forms, order, setOrder, orderBy, setOrderBy, searchLoading }) {

    const classes = useStyles();
    let history = useHistory();
    const handleFormClick = (formId) => {
        history.push(`/form/${formId}?section=title-page&isEnglish=true`)
    }


    const handleSortClick = (column) => {

        if (orderBy === column) {
            setOrder(order === '1' ? '-1' : '1');
        } else {
            setOrderBy(column)
            setOrder('1');
        }
    };

    const getDirection = (order) => {
        if (order === '1') {
            return 'asc'
        }
        return 'desc'
    }

    return (      
        <Box>
            <Box boxShadow={1} borderRadius={16}>
                {

                    <TableContainer className={classes.tableBox}>
                        <Table className={classes.table} id="patientTable">
                            <colgroup>
                                {tableHeaders.map((th) => { return <col style={{ width: th.width }} /> })}
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    {tableHeaders.map((item, index) => {
                                        if (item.sortable) {
                                            return (
                                                <TableCell key={index} align="left" className={classes.headerCell}>
                                                    <TableSortLabel
                                                        active={orderBy === item.field}
                                                        direction={orderBy === item.field ? getDirection(order) : 'asc'}
                                                        onClick={() => handleSortClick(item.field)}
                                                    >
                                                        {item.header}
                                                    </TableSortLabel>
                                                </TableCell>
                                            )
                                        } else {
                                            return (
                                                <TableCell key={index} className={classes.headerCell}>
                                                    <Box>
                                                        <Typography>
                                                            {item.header}
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                            )
                                        }
                                    })}
                                    <TableCell className={classes.headerCell}></TableCell>
                                </TableRow>
                            </TableHead>
                            {searchLoading ? (
                                <TableBody>
                                    {loadingArray.map((item, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell align="center">
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Skeleton animation="wave" />
                                                </TableCell>

                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            ) : (
                                forms.length > 0 ? (
                                    <TableBody>
                                        {forms.map((form) => (
                                            <TableRow onClick={() => { handleFormClick(form._id) }} className={classes.patientRow} key={form._id}>
                                                <TableCell align="left">
                                                    <Typography className={classes.tableCellText}>
                                                        {form.name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className={classes.tableCellText}>
                                                        {moment(form.createdDate).format('L')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className={classes.tableCellText}>
                                                        {moment(form.lastModifiedDate).format('L')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    {getStatusChip(form.status)}
                                                </TableCell>
                                                <TableCell align="right"><ArrowForwardIosIcon color="primary" className={classes.icon} /></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        <TableRow >
                                            <TableCell className={classes.emptyRow} colSpan={6}>
                                                <Box className={classes.noSurveys}>
                                                    <PeopleAltIcon fontSize="large" />
                                                    <Typography className={classes.noSurveysMessage}>
                                                        No form matching that search were found.
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )
                            )}
                        </Table>
                    </TableContainer>

                }
            </Box>
        </Box>
    );
}
