import React from 'react'
import PackagingStack from './PackagingStack'

import { makeStyles} from '@material-ui/core/styles';
import { Paper} from '@material-ui/core';

const useStyles = makeStyles({
    paper: {
        padding: "2rem",
        margin: "2rem 0rem",
        borderRadius: "25px"
    }
});

export default function Packaging({ context, currentProductIndex }){
    const classes = useStyles()

    return (
        <Paper className={classes.paper} elevation={4}>
            <PackagingStack
                currentProductIndex={currentProductIndex}
                context={context}
            />
        </Paper>
    )
}
