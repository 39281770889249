export const englishLabels = {
    title: "COMPANY DETAILS",
    marketAuthorizationHolder: "MARKET AUTHORIZATION HOLDER",
    canadianImporterDistributor: "CANADIAN IMPORTER/DISTRIBUTOR",
    name: "Name*",
    streetAddress: "Street Address*",
    city: "City*",
    state: "State*",
    province: "Province*",
    postalCode: "Postal Code*",
    country: "Country*",
    telephone: "Telephone*",
    email: "Email*",
    website: "Website*",
    missingSubtitle: "No Products Exist"
}

export const frenchLabels = {
    title: "DÉTAILS DE L'ENTERPRISE",
    marketAuthorizationHolder: "TITULAIRE DE L'AUTORISATION DE MARCHÉ",
    canadianImporterDistributor: "IMPORTATEUR / DISTRIBUTEUR CANADIEN",
    name: "Nom*",
    streetAddress: "Adresse De Rue*",
    city: "Ville*",
    state: "Etat*",
    province: "Province*",
    postalCode: "Code Postal*",
    country: "Pays*",
    telephone: "Téléphone*",
    email: "Email*",
    website: "Site Internet*",
    missingSubtitle: "Aucun produit n'existe"
}