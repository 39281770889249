import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { accountSetup } from '../../actions/auth'
import PMButton from '../common/Button'

import { Box, Grid, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import PasswordValidator from '../common/PasswordValidator';
import { getPasswordFieldCoditions, passwordConditions, updatePasswordFieldConditions } from "../../utils/utilFunctions";
import { warning } from '../../utils/SnackBar';

const useStyles = makeStyles(() => ({
    button: {
        width: "100%"
    },
    textField: {
        width: "100%"
    },
    formLabel: {
        float: "left",
        margin: "2% 0% 2% 0",
        fontSize: 18
    },
    forgotPassword: {
        '&:hover': {
            color: "#007bff",
            cursor: "pointer",
            textDecoration: "underline",
        },
    },
    formHeader: {
        float: "left",
        fontWeight: 800,
        fontSize: 36,
        fontStyle: "normal",
        marginBottom: "1%",
    },
}));


export default function MFA({email}) {
    const dispatch = useDispatch()
    const loading = useSelector((state) => state.auth.loading)
    const classes = useStyles()

    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [conditions, setConditions] = useState(getPasswordFieldCoditions(passwordConditions, ["NEW_PASSWORD", "CONFIRM_PASSWORD"]))

    const handleSetPassword = (e) => {
        setConditions(updatePasswordFieldConditions(conditions, "NEW_PASSWORD", e.target.value, confirmPassword, null))
        setPassword(e.target.value)
    }

    const handleSetConfirmPassword = (e) => {
        setConditions(updatePasswordFieldConditions(conditions, "CONFIRM_PASSWORD", password, e.target.value, null))
        setConfirmPassword(e.target.value)
    }

    const onSubmit = async e => {
        e.preventDefault();
        if(Object.values(conditions).every((condition) => condition.state)) {
            dispatch(accountSetup( email, password, confirmPassword))
        } else {
            warning("All conditions must be met before resetting password.")
        }
    }

    return(
        <Box>
            <form>
                <Box display="flex" flexDirection="column">
                    <Typography className ={classes.formHeader}>
                        Account Setup
                    </Typography>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box>
                                <Typography className ={classes.formLabel}>
                                    Looks like this is your first time setting up your account. Enter your new password below. This will be the password you use to login to your InstaPM account, so make sure it's secure.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid item xs={12}>
                                <Box my={2}>                                
                                    <TextField
                                        type = 'password'
                                        variant='outlined'
                                        placeholder = 'Enter New Password'
                                        name = 'password'
                                        value = {password}
                                        onChange = {e => handleSetPassword(e)}
                                        minLength = '12'
                                        className={classes.textField}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box my={2}>
                                    <TextField
                                        type = 'password'
                                        variant='outlined'
                                        placeholder = 'Confirm New Password'
                                        name = 'confirmPassword'
                                        value = {confirmPassword}
                                        onChange = {e => handleSetConfirmPassword(e)}
                                        minLength = '12'
                                        className={classes.textField}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid item xs={12}>
                                <PasswordValidator
                                    conditions={conditions}
                                    fields={['NEW_PASSWORD', "CONFIRM_PASSWORD"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={3}>
                    <PMButton
                        type="submit"
                        loading={loading}
                        disabled={loading}
                        onClick={(e) => {onSubmit(e)}}
                        className={classes.button}
                    >
                        Finish Account Setup
                    </PMButton>
                </Box>
            </form>
        </Box>
    )
}
