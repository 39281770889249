  
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	page: {
        margin: "4%"
	},
	formPage: {
        margin: "4% 4% 0% 2%"
	}
}));

const AdminRoute = ({ component: Component, auth: { isAuthenticated, accessLevel }, ...rest }) => {
    const classes = useStyles()
    return (
        <Box className={classes.page}>
            <Route
                {...rest}
                render={ props => (isAuthenticated && accessLevel >= 4) ? <Component {...props} /> : <Redirect to="/login" /> }
            />
        </Box>
    )
};

AdminRoute.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(AdminRoute);
