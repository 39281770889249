import React from 'react'

import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import PMButton from '../common/Button';

export default function NavBarDemoDialog({open, handleClose}) {
    return(
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    width: '50vw',
                    borderRadius: '25px',
                    padding: '20px',
                },
            }}
        >
            <DialogTitle>
                <Box alignItems="center" display="flex" justifyContent="space-between">
                    <Typography variant="h4">
                        InstaPM Interactive Demo
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box>
                    Welcome to InstaPM's interactive demo! Take a look around and experiment with what InstaPM has to offer. Want a personalized demo or have questions? Reach out to us at info@instapm.ca!
                </Box>
            </DialogContent>
            <DialogActions>
                <PMButton
                    onClick={handleClose}
                >
                    Close
                </PMButton>
            </DialogActions>
        </Dialog>
    )
}
