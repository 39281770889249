import React from 'react'
import { connect } from 'react-redux'
import AutocompleteField from './AutoComplete'
import ProvinceDropdown from './ProvinceDropdown'
import TextField from './TextField'


// Field Types
// 0 -> TextField
// 1 -> AutoComplete
// 2 -> ProvinceDropdown
const TextFieldArray = ({ context, updateFunction, columnStyling, label, name, placeholder, inputMask = null, fieldTypes=null, codeSystems=null }) => {

    return(
        <div className = "row">
            {label.map( (singleLabel, index) => {

                if (fieldTypes) {
                    if (fieldTypes[index] === 1) {
                        return (
                            <AutocompleteField 
                                context={context} 
                                key ={index} 
                                columnStyling = {columnStyling} 
                                updateFunction = {updateFunction} 
                                label = {singleLabel} 
                                placeholder = {placeholder[index]} 
                                name = {name[index]}
                                codeSystem={codeSystems[index]}
                            />
                        )
                    } else if (fieldTypes[index] === 2) {
                        return (
                            <ProvinceDropdown
                                key={index} 
                                columnStyling={columnStyling} 
                                updateFunction={updateFunction} 
                                label={singleLabel} 
                                placeholder={placeholder[index]} 
                                name={name[index]}
                                inputMask={inputMask && inputMask[index]}
                            />
                        )   
                    }
                }
                return (
                    <TextField 
                        key={index} 
                        columnStyling={columnStyling} 
                        updateFunction={updateFunction} 
                        label={singleLabel} 
                        placeholder={placeholder[index]} 
                        name={name[index]}
                        inputMask={inputMask && inputMask[index]}
                    /> 
                )
            })}
        </div>
    )
}

export default connect(null, [])(TextFieldArray)