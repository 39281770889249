import {combineReducers} from 'redux'

import auth from "./auth"
import forms from './forms'
import session from './session'
import chatbot from './chatbot'
import demo from './demo'
export default combineReducers({
    auth,
    forms,
    session,
    chatbot,
    demo,
})