import axios from "../utils/customAxios";
import { SET_DEMO, DEMO_LOADING, LOGIN_SUCCESS, INCREMENT_DEMO_STEP } from './types';
import { error, warning } from '../utils/SnackBar'

export const incrementDemoStepWithDelay = () => async (dispatch) => {

    await new Promise(r => setTimeout(r, 1000));

    dispatch({
        type: INCREMENT_DEMO_STEP,
    })
}

export const loadDemo = (email, history, isMobile) => async (dispatch) => {

	dispatch({
		type: DEMO_LOADING,
		payload: true,
	});

  	try {
        const body = {
            email,
        }

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const res = await axios.post( `/api/demo/startDemo`, body, config)

        if (isMobile) {
            warning("InstaPM Demo is only available on a desktop or laptop device.")
            return
        }

        // Set User Data in Redux
        dispatch({
            type: LOGIN_SUCCESS,
            payload: {
                token: res.data.token,
                refreshToken: res.data.refreshToken,
                getAccessLevel: res.data.accessLevel,
                isDemoUser: true,
            },
        })

        dispatch({
            type: SET_DEMO,
        })

        history.push(`/demo/${res.data.formId}?section=title-page&isEnglish=true`);
	} catch (err) {
        error(err.response.data)
	} finally { 
		dispatch({
			type: DEMO_LOADING,
			payload: false,
		});
	}
};
