import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"
import { Box, Drawer, Toolbar, ListItem, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { FormSections, getSectionUrlName } from './formUtils/FormSections';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { EXPAND_TREES } from './formUtils/SectionIndexes';
import { setCurrentSectionIndex } from '../../actions/forms';

const drawerWidth = "25%";

const useStyles = makeStyles((theme) => ({
    root: {
        height: 216,
        flexGrow: 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    selectedItem: {
        backgroundColor: "#007bff",
        color: "white"
    },
    item: {
        padding: 10
    }
}));

export default function FormDrawer({ context, setContext }) {
    const dispatch = useDispatch()
    const classes = useStyles();
    const form = useSelector((state) => state.forms.form)
    const currentSectionIndex = useSelector((state) => state.forms.currentSectionIndex)
    const editState = useSelector((state) => state.forms.editState)
    const history = useHistory()
   

    const handleItemClick = (index) => {
        const params = new URLSearchParams(window.location.search)

        const findSection = (sections, targetIndex) => {
            for (const item of sections) {
                if (item.index === targetIndex) {
                    return item;
                } else if (item.children && item.children.length > 0) {
                    const sectionFromChildren = findSection(item.children, targetIndex)
                    if (sectionFromChildren) return sectionFromChildren;
                }
            }
            return null;
        };

        const section = findSection(FormSections, index)
        const formattedSectionForUrl = getSectionUrlName(section.englishName)
        params.set("section", formattedSectionForUrl)
        history.push({ search: params.toString() })
        dispatch(setCurrentSectionIndex(index))
    }



    const handleContextSwitch = (e, value) => {
        const params = new URLSearchParams(window.location.search)
        params.set("isEnglish", value === "false")
        history.push({ search: params.toString() })
        setContext(value === "true")
    }

    const genTreeItem = (sectionObject) => {
        return (
            <TreeItem
                onClick={() => { handleItemClick(sectionObject.index) }}
                nodeId={`${sectionObject.index}`}
                key={sectionObject.index}
                label={
                    <ListItem className={currentSectionIndex === sectionObject.index ? classes.selectedItem : classes.item} button key={sectionObject.name}>
                        <ListItemText primary={context === false ? sectionObject.englishName : sectionObject.frenchName} />
                    </ListItem>
                }
            >
                {sectionObject.children.map((child) => {
                    return genTreeItem(child)
                })}
            </TreeItem>
        )
    }

    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <Toolbar />
            <Box height="100%" className='sidebar'>
                <Box textAlign="center" padding={4}>
                    <ToggleButtonGroup
                        value={context.toString()}
                        exclusive
                        onChange={handleContextSwitch}
                    >
                        {form.formDataEnglish && (
                            <ToggleButton disabled={editState.isArchive || editState.isComparison} value="false">
                                English
                            </ToggleButton>
                        )}
                        {form.formDataFrench && (
                            <ToggleButton disabled={editState.isArchive || editState.isComparison} value="true">
                                French
                            </ToggleButton>
                        )}
                    </ToggleButtonGroup>
                </Box>
                <TreeView
                    key={currentSectionIndex}
                    className={`${classes.root}`}
                    defaultExpanded={EXPAND_TREES[currentSectionIndex]}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                >
                    {FormSections.map((sectionObject) => {
                        return (
                            genTreeItem(sectionObject)
                        )
                    })}
                </TreeView>
            </Box>
        </Drawer>
    )
}
