import React, {Fragment, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Routes from "./components/routing/Routes"

// Import Layout Components
import Navbar from "./components/layout/Navbar"

// Actions
import { loadUser } from "./actions/auth"

// Providers
import AppVersionProvider from './utils/AppVersionProvider';
import DemoProvider from './utils/DemoProvider';
import SessionProvider from './utils/SessionProvider';
import LogoutProvider from './utils/LogoutProvider';

export default function InstaPMApp() {
    const dispatch = useDispatch()
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
    const sessionLoading = useSelector((state) => state.auth.sessionLoading)

	useEffect( () => {
		if(sessionLoading === false && isAuthenticated === true){
			dispatch(loadUser())
		}
  	}, [sessionLoading, isAuthenticated])

  	return (
        <SessionProvider>
            <AppVersionProvider>
				<Router>
					<DemoProvider>
						<LogoutProvider>
							<Fragment>
								<Navbar/>
								<Switch>
									<Route component = {Routes} />
								</Switch>
							</Fragment>
						</LogoutProvider>
					</DemoProvider>
				</Router>
            </AppVersionProvider>
        </SessionProvider>
	)
}
