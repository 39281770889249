import axios from '../utils/customAxios'
import {
    GET_FORMS,
    GET_FORM,
    GET_VERSION_NUMBERS,
    UPDATE_FORM,
    FORM_LOADED,
    CREATE_FORM,
    FORM_CREATED,
    FORM_ERROR,
    DOWNLOAD_FORM,
    FILTER_FORMS,
    ARCHIVE_FORMS,
    RESTORE_FORM,
    FORM_LOADING,
    FORM_UPDATING,
    GET_FORM_VALIDATION,
    UPDATE_FORM_LAST_MODIFIED_INFO,
    UPDATE_FORM_FIELD,
    PMI_SCORE,
    PMI_SCORE_FAILURE,
    PMI_SUGGESTION_COMPLETE,
    PMI_SUGGESTION_LOADING,
    PMI_SUGGESTION_STREAMING,
    PMI_SUGGESTION_ERROR,
    PREVIEW_DOWNLOAD_ENGLISH_STATUS,
    PREVIEW_DOWNLOAD_FRENCH_STATUS,
    SET_CURRENT_SECTION_INDEX,
    DOWNLOAD_ENGLISH_STATUS,
    DOWNLOAD_FRENCH_STATUS,
    GET_ACCESS_LEVEL,
    GET_USERS_USER_COMPANY_INFO,
    GET_FORM_ACCESS_CONTROL,
    CHECK_DOCUMENT_LOCK,
    ARCHIVE_FORM,
    SET_UPLOAD_DIALOG_OPEN_STATUS,
    SET_UPLOAD_REPORT_OPEN_STATUS,
    INCREMENT_DEMO_STEP,
} from './types'
import { formModel } from '../models/Form'
import { error, success } from '../utils/SnackBar';
import store from '../store'
import { customFetch } from '../utils/customFetch';

export const setCurrentSectionIndex = (sectionID) => async dispatch => {
    dispatch({
        type: SET_CURRENT_SECTION_INDEX,
        payload: sectionID,
    })
}

export const getPMISectionSuggestions = (text, isEnglish) => async dispatch => {
    dispatch({
        type: PMI_SUGGESTION_LOADING,
    })

    try {
        const res = await customFetch(
            '/api/ai/pmiSuggestions',
            'POST',
            {},
            {
                text: text,
                isEnglish: isEnglish
            },
        )

        const reader = res.body.getReader()

        while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            const text = new TextDecoder("utf-8").decode(value);

            dispatch({
                type: PMI_SUGGESTION_STREAMING,
                payload: text,
            })
        }

        dispatch({
            type: PMI_SUGGESTION_COMPLETE,
            payload: false,
        })
    } catch (err) {
        error("Could not load suggestions for this section. Please try again later")
        dispatch({
            type: PMI_SUGGESTION_ERROR
        })
    }
}
export const getPMISectionScore = (text) => async dispatch => {
    try {
        const body = {
            text: text,
        }

        const config = {
            headers:
            {
                'Content-Type': 'application/json'
            }
        }

        const res = await axios.post(`/api/ai/readability`, body, config)

        dispatch({
            type: PMI_SCORE,
            payload: res.data.score,
        })
    } catch (error) {
        dispatch({
            type: PMI_SCORE_FAILURE,
        })
    }
}


export const archiveForm = (id, history) => async dispatch => {
    dispatch({
        type: ARCHIVE_FORM,
        payload: true,
    })
    try {
        const config = {
            headers:
            {
                'Content-Type': 'application/json'
            }
        }

        await axios.put(`/api/forms/delete/${id}`, config)

        dispatch({
            type: ARCHIVE_FORM,
            payload: false,
        })

        history.push('/dashboard');

        success("Monograph deleted successfully")
    } catch (error) {

        error("Could not delete the PM. Please try again later")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })

    }
}

export const createForm = (name) => async dispatch => {
    dispatch({
        type: CREATE_FORM,
    })
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = {
            name: name,
            data: formModel
        }

        const res = await axios.post(`/api/forms`, body, config)

        dispatch({
            type: FORM_CREATED,
            payload: res.data
        })

        return true

    } catch (err) {
        error("There was an error while creating your Product Monograph")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })

        return false;
    }
}


// Get All Forms
export const getForms = (page, orderBy, order, nameParam) => async dispatch => {

    try {
        const name = nameParam ? `&name=${encodeURIComponent(nameParam)}` : '';

        const res = await axios.get(`/api/forms?page=${page}&sort=${orderBy}_${order}${name}`)
        dispatch({
            type: GET_FORMS,
            payload: res.data
        })

    } catch (err) {
        error("There was an error while loading your Product Monographs")

        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}



// Get A Form
export const getForm = id => async dispatch => {

    try {
        if (id) {
            const res = await axios.get(`/api/forms/${id}`)

            dispatch({
                type: GET_FORM,
                payload: res.data
            })
        }

    } catch (err) {
        error("There was an error while loading your Product Monograph")

        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}

// Get Comparison Form
export const getCompareForm = (id, majorVersionNumberA, minorVersionNumberA, majorVersionNumberB, minorVersionNumberB, isEnglish) => async dispatch => {
    try {
        const res = await axios.get(`/api/forms/compare/${id}?majorVersionNumberA=${majorVersionNumberA}&minorVersionNumberA=${minorVersionNumberA}&majorVersionNumberB=${majorVersionNumberB}&minorVersionNumberB=${minorVersionNumberB}&isEnglish=${isEnglish}`)

        dispatch({
            type: RESTORE_FORM,
            payload: res.data
        })
        dispatch({
            type: UPDATE_FORM_LAST_MODIFIED_INFO,
            payload: {
                lastModifiedBy: res.data.lastModifiedBy,
                lastModifiedDate: res.data.lastModifiedDate,
            }
        })
    } catch (err) {
        dispatch({
            type: FORM_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Get An Archived Form
export const getArchivedForm = (id, majorVersionNumber, minorVersionNumber, isEnglish) => async dispatch => {
    try {
        const res = await axios.get(`/api/forms/archive/${id}?majorVersionNumber=${majorVersionNumber}&minorVersionNumber=${minorVersionNumber}&isEnglish=${isEnglish}`)
        dispatch({
            type: GET_FORM,
            payload: res.data,
        })
        dispatch({
            type: UPDATE_FORM_LAST_MODIFIED_INFO,
            payload: {
                lastModifiedBy: res.data.lastModifiedBy,
                lastModifiedDate: res.data.lastModifiedDate,
            }
        })
    } catch (err) {
        error("There was an error while loading that Product Monograph version")

        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}

// Update a Form
export const updateForm = (formData, id, language) => async dispatch => {
    dispatch({
        type: FORM_UPDATING
    })
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = {
            formData: formData,
            language: language
        }

        const res = await axios.put(`/api/forms/${id}`, body, config)

        if ((window.location.pathname).includes("form") || (window.location.pathname).includes("demo")) {
            dispatch({
                type: UPDATE_FORM,
                payload: res.data
            })
            dispatch({
                type: UPDATE_FORM_LAST_MODIFIED_INFO,
                payload: {
                    lastModifiedBy: res.data.lastModifiedBy,
                    lastModifiedDate: res.data.lastModifiedDate,
                }
            })
        }
    } catch (err) {
        error("There was an error while updating your Product Monograph")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}

// Update Form Field
export const updateFormField = (field, content, id, language, isFormData = true) => async dispatch => {

    const { forms } = store.getState()
    if (forms.editState.isDisabled) {
        return;
    }

    dispatch({
        type: FORM_UPDATING
    })

    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = {
            field,
            content,
            language,
            isFormData,
        }
       
        const res = await axios.put(`/api/forms/field/${id}`, body, config)

        if (field === "status") {
            success("Approval status updated successfully")
        }
        if (field === "name") {
            success("Monograph name updated successfully")
        }

        if ((window.location.pathname).includes("form") || (window.location.pathname).includes("demo")) {
            dispatch({
                type: UPDATE_FORM_FIELD,
                payload: {
                    field: res.data.field,
                    content: res.data.content,
                }
            })
            dispatch({
                type: UPDATE_FORM_LAST_MODIFIED_INFO,
                payload: {
                    lastModifiedBy: res.data.lastModifiedBy,
                    lastModifiedDate: res.data.lastModifiedDate,
                }
            })
        }
    } catch (err) {
        //TODO: Add the name of the field here
        error(err.response.data || "There was an error while updating your Product Monograph")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}

export const getPreviewDownloadStatus = (id, language) => async dispatch => {
    try {
        const res = await axios.get(`/api/download/previewDownloadStatus/${id}?language=${language}`)
        if (language === "ENGLISH") {
            dispatch({
                type: PREVIEW_DOWNLOAD_ENGLISH_STATUS,
                payload: res.data,
            })
        } else {
            dispatch({
                type: PREVIEW_DOWNLOAD_FRENCH_STATUS,
                payload: res.data,
            })
        }
    } catch (err) {
        error("There was an error while retrieving the Product Monograph's download progress")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}

export const getDownloadStatus = (id, language) => async dispatch => {
    try {
        const res = await axios.get(`/api/download/downloadStatus/${id}?language=${language}`)
        if (language === "ENGLISH") {
            dispatch({
                type: DOWNLOAD_ENGLISH_STATUS,
                payload: res.data,
            })
        } else {
            dispatch({
                type: DOWNLOAD_FRENCH_STATUS,
                payload: res.data,
            })
        }
    } catch (err) {
        error("There was an error while retrieving the Product Monograph's download progress")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}

// Download a Form
export const downloadSubmission = (id, name, language) => async dispatch => {

    try {

        const res = await axios({ url: `/api/download/submission?id=${id}&language=${language}`, method: 'GET', "responseType": 'blob' })

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name.replace(" ", "_").trim()}_${language}.zip`);
        document.body.appendChild(link);
        link.click();

    } catch (err) {
        error("There was an error while downloading the submission ZIP folder")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}

// Download a Form
export const downloadXML = (id, name, language) => async dispatch => {

    try {

        const res = await axios({ url: `/api/download/xml?id=${id}&language=${language}`, method: 'GET', "responseType": 'blob' })

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name.replace(" ", "_").trim()}_${language}.xml`);
        document.body.appendChild(link);
        link.click();

    } catch (err) {
        error("There was an error while downloading the XML file")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}

export const downloadDOCX = (id, name, language, xportType = "SUBMISSION") => async dispatch => {

    try {

        const res = await axios({ url: `/api/download/docx?id=${id}&language=${language}&xportType=${xportType}`, method: 'GET', "responseType": 'blob' })

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name.replace(" ", "_").trim()}_${language}.docx`);
        document.body.appendChild(link);
        link.click();

        dispatch({
            type: DOWNLOAD_FORM,
            payload: res.data
        })

    } catch (err) {
        error("There was an error while downloading the DOCX file")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}

export const generatePreview = (id, language) => async dispatch => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const res = await axios.post(`/api/download/preview/${id}?language=${language}`, config)

        if (language === "ENGLISH") {
            dispatch({
                type: PREVIEW_DOWNLOAD_ENGLISH_STATUS,
                payload: res.data,
            })
        } else {
            dispatch({
                type: PREVIEW_DOWNLOAD_FRENCH_STATUS,
                payload: res.data,
            })
        }

    } catch (err) {
        error("There was an error while generating the preview files for this Product Monograph")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}

export const generate = (id, language, isForHealthCanada) => async dispatch => {
    try {

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = {
            isForHealthCanada,
        }

        const res = await axios.post(`/api/download/${id}?language=${language}`, body, config)
        axios.get(`/api/download/validateXMLSchema/${id}?language=${language}`)
            .then((res) => {
            })

        dispatch({
            type: DOWNLOAD_FORM,
            payload: res.data
        })

        dispatch({
            type: INCREMENT_DEMO_STEP,
        })

    } catch (err) {
        error("There was an error while generating the submission files for this Product Monograph")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}

// Filter Forms
export const filterForms = (filter) => async dispatch => {
    try {
        // Build Filter String
        let filterString = ''
        let keys = Object.keys(filter)
        let values = Object.values(filter)
        for (const index in keys) {
            if (values[index] !== '') {
                filterString += `${keys[index]}=${values[index]}&`
            }
        }
        const res = await axios.get(`/api/forms?${filterString}`)
        dispatch({
            type: FILTER_FORMS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}

// Get the version numbers for a form
export const getVersionNumbers = (id, context) => async dispatch => {
    dispatch({
        type: FORM_LOADING,
        payload: true
    })

    try {
        const res = await axios.get(`/api/forms/versions/${id}?isEnglish=${!context}`)
        dispatch({
            type: GET_VERSION_NUMBERS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: FORM_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Archive Form
export const archive = (id, context) => async dispatch => {
    dispatch({
        type: FORM_LOADING,
        payload: true
    })

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        isEnglish: !context
    }

    try {
        const res = await axios.put(`/api/forms/archive/${id}`, body, config)
        success("Product monograph version history commit was successful")
        dispatch(getVersionNumbers(id, context))
        dispatch({
            type: ARCHIVE_FORMS,
            payload: res.data
        })
    } catch (err) {
        error("There was an error when trying to back up this version. Please try again later")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}

// Get Archive
export const getArchive = (id, context) => async dispatch => {
    dispatch({
        type: FORM_LOADING,
        payload: true
    })
    try {
        const res = await axios.get(`/api/forms/archive-list/${id}?isEnglish=${!context}`)
        dispatch({
            type: ARCHIVE_FORMS,
            payload: res.data
        })
    } catch (err) {
        error("There was an error while loading this Product Monograph's version history")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}

export const archiveRestore = (id, majorVersionNumber, minorVersionNumber, context) => async dispatch => {
    dispatch({
        type: FORM_LOADING,
        payload: true
    })

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = {
        id,
        majorVersionNumber,
        minorVersionNumber,
        isEnglish: !context,
    }
    try {
        const res = await axios.put(`/api/forms/archive/restore`, body, config)
        success("Form restored successfully. Please wait while your page is refeshed")
        dispatch({
            type: RESTORE_FORM,
            payload: res.data
        })
    } catch (err) {
        error("There was an error while restoring your form")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}

export const validateForm = (id, context) => async dispatch => {
    try {
        const res = await axios.get(`/api/forms/validate?form_id=${id}&context=${context}`)
        dispatch({
            type: GET_FORM_VALIDATION,
            payload: res.data
        })
    } catch (err) {
        error("There was an error while trying to validate your Product Monograph.")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}

export const getLastModifiedInfo = (id) => async dispatch => {
    try {
        const res = await axios.get(`/api/forms/lastModified?id=${id}`)
        dispatch({
            type: UPDATE_FORM_LAST_MODIFIED_INFO,
            payload: res.data
        })
    } catch (err) {
        error("There was an error trying to load Product Monograph's last edited info")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}

export const getAccessLevel = (form_id) => async dispatch => {
    try {
        const res = await axios.get(`/api/forms/permissions?form_id=${form_id}&scope=user`)
        dispatch({
            type: GET_ACCESS_LEVEL,
            payload: res.data
        })
    } catch (err) {
        error("There was an error trying to load Product Monograph's permissions for a user")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}

export const getFormAccessControlInfo = (form_id) => async dispatch => {
    try {
        const res = await axios.get(`/api/forms/permissions?form_id=${form_id}&scope=form`)
        dispatch({
            type: GET_FORM_ACCESS_CONTROL,
            payload: res.data
        })
    } catch (err) {
        error("There was an error trying to load Product Monograph's access control info")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}


export const checkDocumentLock = (id, context) => async dispatch => {
    try {
        const res = await axios.get(`/api/forms/checkDocLock?formID=${id}&isEnglish=${!context}`)
        dispatch({
            type: CHECK_DOCUMENT_LOCK,
            payload: {
                documentLock: res.data
            }
        })
    } catch (err) {
        error("There was an error trying to check Product Monograph's document lock for user")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}


export const getDocumentLock = (id, context) => async dispatch => {
    try {

        dispatch({
            type: FORM_LOADING,
            payload: true,
        })

        // Get latest for form data before obtaining lock
        const formData = await axios.get(`/api/forms/${id}`)

        // Get latest last modified data before obtaining lock
        const lastModifiedData = await axios.get(`/api/forms/lastModified?id=${id}`)

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = {
            formId: id,
            isEnglish: !context,
        }

        const res = await axios.post('/api/forms/tryGetDocLock', body, config)
        dispatch({
            type: CHECK_DOCUMENT_LOCK,
            payload: {
                documentLock: res.data,
                lastModifiedInfo: lastModifiedData.data,
                form: formData.data,
            }
        })
        dispatch({
            type: INCREMENT_DEMO_STEP,
        })
    } catch (err) {
        error("There was an error trying to get Product Monograph's document lock for user")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}


export const releaseDocumentLock = (id, context) => async dispatch => {
    try {

        dispatch({
            type: FORM_LOADING,
            payload: true,
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = {
            formId: id,
            isEnglish: !context,
        }
        const res = await axios.post('/api/forms/tryReleaseDoc', body, config)
        dispatch({
            type: CHECK_DOCUMENT_LOCK,
            payload: {
                documentLock: res.data,
            }
        })
    } catch (err) {
        error("There was an error trying to release Product Monograph's document lock for user")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}


export const releaseAllDocumentLocks = () => {
    try {

    } catch (err) {

    }
}


export const getUsersInUserCompanyInfo = (id) => async dispatch => {
    try {
        const res = await axios.get(`/api/forms/usersInCompany`)
        dispatch({
            type: GET_USERS_USER_COMPANY_INFO,
            payload: res.data
        })
    } catch (err) {
        error("There was an error loading the access control list")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}


export const setIsUploadDialogOpen = (isOpen) => async dispatch => {
    try {
        dispatch({
            type: SET_UPLOAD_DIALOG_OPEN_STATUS,
            payload: isOpen,
        })
    } catch (err) {

    }
}


export const setIsReportViewOpen = (isOpen) => async dispatch => {
    try {
        dispatch({
            type: SET_UPLOAD_REPORT_OPEN_STATUS,
            payload: isOpen,
        })
    } catch (err) {

    }
}


export const getUploadDocxStatus = (formID, context) => async dispatch => {
    try {
        const res = await axios.get(`/api/forms/uploadDocxStatus?formID=${formID}&isEnglish=${!context}`)

        if (res.data.state === "DONE" && res.data.errorMessage === "") {
            success("Your Word document was uploaded successfully")
            dispatch({
                type: SET_UPLOAD_DIALOG_OPEN_STATUS,
                payload: true
            })
            dispatch({
                type: SET_UPLOAD_REPORT_OPEN_STATUS,
                payload: true
            })
        } else {
            error("There was an issue uploading your Word document. Please try again.")
        }
    } catch (err) {
        error("There was an error loading the upload status for the form")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}


export const getFormMetaData = (formId, context) => async dispatch => {
    try {
        dispatch({
            type: FORM_LOADING,
            payload: true,
        })
        const metadataPromises = [
            axios.get(`/api/forms/permissions?form_id=${formId}&scope=user`),
            axios.get(`/api/forms/checkDocLock?formID=${formId}&isEnglish=${!context}`),
            axios.get(`/api/forms/lastModified?id=${formId}`),
            axios.get(`/api/forms/permissions?form_id=${formId}&scope=form`)
        ]

        Promise.all(metadataPromises)
            .then((responses) => {
                const [accessLevelRes, docLockRes, lastModifiedRes, permissionsRes] = responses
                dispatch({
                    type: GET_ACCESS_LEVEL,
                    payload: accessLevelRes.data
                })

                dispatch({
                    type: CHECK_DOCUMENT_LOCK,
                    payload: {
                        documentLock: docLockRes.data
                    }
                })

                dispatch({
                    type: UPDATE_FORM_LAST_MODIFIED_INFO,
                    payload: lastModifiedRes.data
                })

                dispatch({
                    type: GET_FORM_ACCESS_CONTROL,
                    payload: permissionsRes.data
                })
            })
            .catch((error) => {
                error("There was an error loading the metadata for the form")
                dispatch({
                    type: FORM_ERROR,
                    payload: { message: "Form Error" }
                })
            })
            .finally(() => {
                dispatch({
                    type: FORM_LOADED,
                    payload: false,
                })
            })
    } catch (err) {
        error("There was an error loading the metadata for the form")
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}

// Update Form Permissions
export const updateFormPermissionsForUser = (formId, toChangeUserId, newAccessLevel) => async dispatch => {
    dispatch({
        type: FORM_UPDATING
    })
    try {

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = {
            formId,
            toChangeUserId,
            newAccessLevel,
        }

        const res = await axios.put(`/api/forms/permissions`, body, config)
        if (!res.data.success) {
            error(res.data.message)
            dispatch({
                type: FORM_LOADED,
                payload: false
            })
            return;
        }

        success("User access updated successfully")
        if ((window.location.pathname).includes("form") || (window.location.pathname).includes("demo")) {
            dispatch({
                type: UPDATE_FORM_FIELD,
                payload: {
                    field: res.data.field,
                    content: res.data.content,
                }
            })
            dispatch({
                type: UPDATE_FORM_LAST_MODIFIED_INFO,
                payload: {
                    lastModifiedBy: res.data.lastModifiedBy,
                    lastModifiedDate: res.data.lastModifiedDate,
                }
            })

        }
    } catch (err) {
        dispatch({
            type: FORM_ERROR,
            payload: { message: "Form Error" }
        })
    }
}
