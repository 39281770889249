import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getArchivedForm, getCompareForm, getForm, getFormMetaData, releaseDocumentLock, setCurrentSectionIndex } from '../../actions/forms'
import { Formik, Form } from 'formik';

import FormHeader from './FormHeader';
import FormDrawer from './FormDrawer';
import { FormSectionRouter } from './formUtils/FormSectionRouter';
import { CLEAR_FORM, EDIT_STATE } from '../../actions/types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import { VIEW_TYPE_STATES } from '../../utils/utilFunctions';
import useInterval from '../../utils/Interval';
import { warning } from '../../utils/SnackBar';
import { getUploadDocxStatus } from '../../actions/forms';
import { FormSections, getSectionUrlName } from './formUtils/FormSections';

const useStyles = makeStyles({
    paper: {
        padding: "2rem",
        margin: "2rem 0rem",
        borderRadius: "25px"
    }
});

export default function FormPage({ match, viewType }) {
    const dispatch = useDispatch()
    const classes = useStyles();
    const form = useSelector((state) => state.forms.form)
    const currentSectionIndex = useSelector((state) => state.forms.currentSectionIndex)
    const user = useSelector((state) => state.auth.user)
    const editState = useSelector((state) => state.forms.editState)
    const documentLock = useSelector((state) => state.forms.documentLock)
    const accessLevel = useSelector((state) => state.forms.accessLevel)
    const isSaving = useSelector((state) => state.forms.saving)
    const loading = useSelector((state) => state.forms.loading)
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    const editStateRef = useRef(editState)

    const [context, setContext] = useState(params.isEnglish ? params.isEnglish === "false" : false)
    const [formDataPolling, setFormDataPolling] = useState(false)


    let formInput = context ? form && form.formDataFrench : form && form.formDataEnglish

    useEffect(() => {
        if (parseInt(params.section) >= 0) {
            dispatch(setCurrentSectionIndex(parseInt(params.section)))
        }
        if (params.isEnglish === "true" || params.isEnglish === "false") {
            setContext(params.isEnglish === "false")
        }
    }, [params.isEnglish, params.section])

    useEffect(() => {
        if (viewType === VIEW_TYPE_STATES.ARCHIVE) {
            dispatch(getArchivedForm(match.params.id, params.majorVersionNumber, params.minorVersionNumber, params.isEnglish))
        } else if (viewType === VIEW_TYPE_STATES.COMPARISON) {
            dispatch(getCompareForm(match.params.id, params.majorVersionNumberA, params.minorVersionNumberA, params.majorVersionNumberB, params.minorVersionNumberB, params.isEnglish))
        } else {
            dispatch(getForm(match.params.id))
        }
    }, [dispatch, viewType, match.params.id, params.isEnglish, params.majorVersionNumber, params.minorVersionNumber, params.majorVersionNumberA, params.minorVersionNumberA, params.majorVersionNumberB, params.minorVersionNumberB]);

    useInterval(() => {
        dispatch(getForm(match.params.id))
    }, formDataPolling ? 5000 : null)

    useEffect(() => {

        if (editStateRef.current.operationName === "Upload Word" && editState.isDocumentUnlocked) {
            dispatch(getUploadDocxStatus(match.params.id, context))
        }

        editStateRef.current = editState
        if (editState && editState.isDisabled && editState.isEditor) {
            setFormDataPolling(true)
        } else {
            setFormDataPolling(false)
        }
    }, [editState, dispatch, context, match.params.id])

    const keepOnPage = (e) => {
        if (editStateRef.current.isEditable) {
            var message = 'Warning!\n\nPlease disable editing before leaving the web page. Leaving without disabling will lock the PM for 30 seconds';
            e.returnValue = message;
            warning("Please disable editing before leaving the web page. Leaving without disabling will lock the PM for 30 seconds")
            return message;
        }
    }

    useEffect(() => {
        if (form) {
            dispatch({
                type: EDIT_STATE,
                payload: {
                    accessLevel: accessLevel,
                    documentLock: documentLock,
                    viewType: viewType,
                    userID: user?.username,
                },
            })
            if (viewType === VIEW_TYPE_STATES.ARCHIVE) {
                document.title = `${form.name} - v${params.majorVersionNumber}.${params.minorVersionNumber}`;
            } else if (viewType === VIEW_TYPE_STATES.COMPARISON) {
                document.title = `${form.name} - v${params.majorVersionNumberA}.${params.minorVersionNumberA}...v${params.majorVersionNumberB}.${params.minorVersionNumberB}`
            } else {
                document.title = `${form.name}`
            }
        }
    }, [dispatch, viewType, accessLevel, documentLock, form, user?.username, params.isEnglish, params.majorVersionNumber, params.minorVersionNumber, params.majorVersionNumberA, params.minorVersionNumberA, params.majorVersionNumberB, params.minorVersionNumberB])

    useEffect(() => {
        dispatch(getFormMetaData(match.params.id, context))
        window.addEventListener('beforeunload', keepOnPage);
        return () => {
            if (editStateRef.current.isEditable) {
                dispatch(releaseDocumentLock(match.params.id, context))
            }
            dispatch({
                type: CLEAR_FORM
            })
            window.removeEventListener('beforeunload', keepOnPage);
        }
    }, [dispatch, context, match.params.id])

    const Section = FormSectionRouter(currentSectionIndex)

    const findIndexRecursive = (sections, targetSectionName) => {
        for (let i = 0; i < sections.length; i++) {
            const transformedName = getSectionUrlName(sections[i].englishName);

            if (transformedName === targetSectionName) {
                return sections[i].index;
            }

            if (sections[i].children && sections[i].children.length > 0) {
                const sectionFromChildren = findIndexRecursive(sections[i].children, targetSectionName);
                if (sectionFromChildren) return sectionFromChildren;
            }
        }
        return null;
    };

    useEffect(() => {
        const targetSectionName = params.section;
        const index = findIndexRecursive(FormSections, targetSectionName);
        dispatch(setCurrentSectionIndex(index));
    }, [params.isEnglish, params.section]);




    return (loading && !form) || (!form) ? (
        <Box alignItems="center" height="80vh" display="flex" flexDirection="column" justifyContent="center">
            <CircularProgress />
            <Box mt={2}>
                <Typography variant='h4'>
                    InstaPM
                </Typography>
            </Box>
        </Box>
    ) : (
        <Grid container>
            <Grid item xs={3}>
                <FormDrawer
                    context={context}
                    setContext={setContext}
                />
            </Grid>
            <Grid item xs={9}>
                <Box ml={2}>
                    <FormHeader
                        context={context}
                    />
                    <Formik
                        enableReinitialize
                        initialValues={{ ...formInput, ...formInput.products[0] }}
                    >
                        {() => (
                            <Form>
                                {Section && !isSaving ? (
                                    <Section
                                        context={context}
                                    />
                                ) : (
                                    <Box mt={4}>
                                        <Paper className={classes.paper} elevation={4}>
                                            <Typography>
                                                This section does not need to be filled out.
                                            </Typography>
                                        </Paper>
                                    </Box>
                                )}
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Grid>
        </Grid>
    )
}
