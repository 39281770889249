export const englishLabels = {
    title: "RECENT MAJOR LABEL CHANGES",
    missingMessage: "No Recent Major Label Changes",
    name: "Name",
    date: "Date",
    datePlaceholder: "MM/YYYY"
}

export const frenchLabels = {
    title: "RÉCENTES MODIFICATIONS IMPORTANTES DE L'ÉTIQUETTE",
    missingMessage: "Non Récentes modifications importantes de l'étiquette",
    name: "Nom",
    date: "Date",
    datePlaceholder: "MM/YYYY"
}
