import {USER_LOADED, AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, UPDATE_USER, AUTH_INITIATED, AUTH_LOADING, AUTH_RESET, AUTH_LOADED, REFRESH_TOKEN_SUCCESS, REFRESH_LOADING, APP_VERSION_NUMBER, VERSION_UPDATE_REQUIRED, SESSION_CREATION, RESET_LOGOUT, SESSION_TIMEOUT, USER_ONBOARDING} from '../actions/types'

const invalidatePreviousDemoSession = localStorage.getItem('isDemoSession') === "true" && !(window.location.pathname.includes('demo'))
const invalidatePreviousLiveSession = localStorage.getItem('isDemoSession') === "false" && window.location.pathname.includes('demo')

const initialState = {
    token: null,
    sessionID: null,
    isAuthenticated: null,
    accessLevel: null,
    loading: false,
    refreshLoading: false,
    user: null,
    isDemoUser: null,
    authenticationStep: null,
    appVersionNumber: null,
    versionUpdateRequired: false,
    logout: false,
    sessionLoading: true,
    userOnboarding: false,
}

// Invalidate the previous session (if switching between normal and Demo sessions)
if (invalidatePreviousDemoSession || invalidatePreviousLiveSession) {
    localStorage.clear()
    sessionStorage.clear()
} else {
    initialState.token = localStorage.getItem('token')
    initialState.sessionID = sessionStorage.getItem('sessionID')
    initialState.isAuthenticated = localStorage.getItem('token') !== null
    initialState.accessLevel = parseInt(localStorage.getItem('accessLevel'))
}

export default function authReducer(state = initialState, action) {
    
    const {type, payload} = action
    
    switch(type) {
        case USER_ONBOARDING:
            return {
                ...state,
                userOnboarding: payload,
            }
        case SESSION_CREATION:
            sessionStorage.setItem("sessionID", payload)
            return {
                ...state,
                loading: false,
                sessionLoading: false,
                sessionID: payload
            }
        case APP_VERSION_NUMBER:
            return {
                ...state,
                appVersionNumber: payload,
            }
        case VERSION_UPDATE_REQUIRED:
            return {
                ...state,
                versionUpdateRequired: payload,
            }
        case AUTH_LOADING:
            return {
                ...state,
                loading: payload
            }
        case AUTH_LOADED:
            return {
                ...state,
                loading: payload
            }
        case USER_LOADED:
        case UPDATE_USER:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: payload.user,
                isDemoUser: payload.isDemoUser,
            }
        case AUTH_INITIATED:
            return {
                ...state,
                loading: false,
                authenticationStep: payload
            }
        case LOGIN_SUCCESS:
            localStorage.setItem('token', payload.token)
            localStorage.setItem('refreshToken', payload.refreshToken)
            localStorage.setItem('accessLevel', parseInt(payload.accessLevel))
            localStorage.setItem('isDemoSession', payload.isDemoUser ?? false)
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                authenticationStep: null,
                loading: false,
                isDemoUser: payload.isDemoUser ?? false
            }
        case REFRESH_LOADING:
            return {
                ...state,
                refreshLoading: true,
            }
        case REFRESH_TOKEN_SUCCESS:
            localStorage.setItem('token', payload.token)
            sessionStorage.setItem("sessionID", payload.sessionID)
            return {
                ...state,
                refreshLoading: false,
            }
        case SESSION_TIMEOUT:
            sessionStorage.removeItem('sessionID')
            return {
                ...state,
                token: null,
                accessLevel: null,
                sessionID: null,
                isAuthenticated: false,
                loading: false,
                user: null,
                refreshLoading: false,
                authenticationStep: null,
                sessionLoading: true,
            }
        case AUTH_RESET:
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT:
            localStorage.removeItem('token')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('accessLevel')
            sessionStorage.removeItem('sessionID')
            return {
                ...state,
                token: null,
                sessionID: null,
                accessLevel: null,
                isAuthenticated: false,
                loading: false,
                user: null,
                refreshLoading: false,
                authenticationStep: null,
                logout: true,
                sessionLoading: true,
            }
        case RESET_LOGOUT:
            return {
                ...state,
                logout: payload
            }
        default:
            return state
    }
}