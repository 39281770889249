export const onClickHTML = (url) => {
    window.open(url);
}

export const isEmpty = (url) => {
    return url === "" || url === undefined
}

export const hasFailedValidation = (url) => {
    return url === "FAILED_TO_VALIDATE"
}
export const hasFailed = (url) => {
    return url != null &&
        url !== "" &&
        url.toLowerCase().includes("fail")
}

export const isLoading = (url) => {
    return url === "LOADING"
}

export const isDownloadable = (url) => {
    return !isLoading(url) && !hasFailed(url) && !isEmpty(url)
}

export const genTooltipTitle = (url, isDemo=false) => {
    if (isDemo) {
        return "Downloading DOCX is not available in Demo Mode. Please reach out for more at info@instapm.ca"
    }
    if (url === "LOADING") {
        return "This file is currently being generated. Check again soon."
    }
    if (hasFailed(url)) {
        return "There was an issue generating file. Try to generate the file again."
    }
    if (url === "" || url === undefined || url == null) {
        return "No files, please generate"
    }
    if (url.includes(".html")) {
        return "Quick Preview (DHPP Format)"
    }
    if (url.includes(".zip")) {
        return "Download full submission"
    }
    if (url.includes(".docx")) {
        return "Download the Word Document only"
    }
    return ""
}

export const genSubmissionTooltipTitle = (url, xmlUrl, docxUrl, isDemo=false) => {
    if (isDemo) {
        return "Downloading XML Submission is not available in Demo Mode. Please reach out for more at info@instapm.ca"
    }

    if (hasFailed(xmlUrl) || hasFailed(docxUrl)) {
        return "Generation failed"
    }
    return genTooltipTitle(url)
}