const BASE_URL = process.env.REACT_APP_ENVMNT === 'production' ? 'https://api.instapm.ca' : process.env.REACT_APP_ENVMNT === 'staging' ? '' :  'http://localhost:5000';

export const customFetch = (url, method, customHeaders, body=null) => {
    const config = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            ...customHeaders,
        }
    }

    if(localStorage.token) {
        config.headers['x-auth-token'] = localStorage.token;
    } else {
        config.headers['x-auth-token'] = null;
    }
    
    if (sessionStorage.sessionID){
        config.headers['x-sessionID'] = sessionStorage.sessionID;
    } else {
        config.headers['x-sessionID'] = null;
    }

    if (body) {
        config.body = JSON.stringify(body)
    }

    return fetch(
        `${BASE_URL}${url}`,
        config
    )
}
