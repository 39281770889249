export const englishLabels = {
    title: "PART II: SCIENTIFIC INFORMATION",
    pharmaceuticalInformation: '13 | PHARMACEUTICAL INFORMATION',
    clinicalTrials: '14 | CLINICAL TRIALS',
    clinicalTrialsByIndications: '14.1 | CLINICAL TRIALS BY INDICATION',
    comparativeBioavailabiltyStudies: '14.2 | COMPARATIVE BIOAVAILABILITY STUDIES',
    immunogenicity: '14.3 | IMMUNOGENICITY',
    clinicalTrialsReferenceBiologicDrug: '14.4 | CLINICAL TRIALS - REFERENCE BIOLOGIC DRUG',
    microbiology: '15 | MICROBIOLOGY',
    nonClinicalToxicology: '16 | NON-CLINICAL TOXICOLOGY',
    comparativeNonClinicalPharmacologyAndToxicology: '16.1 | COMPARATIVE NON-CLINICAL PHARMACOLOGY AND TOXICOLOGY',
    comparativeNonClinicalPharmacodynamics: '16.1.1 | COMPARATIVE NON-CLINICAL PHARMACODYNAMICS',
    comparativeToxicology: '16.1.2 | COMPARATIVE TOXICOLOGY',
    supportingProductMonographs: '17 | SUPPORTING PRODUCT MONOGRAPHS',
}

export const frenchLabels = {
    title: "PARTIE II: RENSEIGNEMENTS SCIENTIFIQUES",
    pharmaceuticalInformation: '13 | INFORMATION PHARMACEUTIQUES',
    clinicalTrials: '14 | ÉTUDES CLINIQUES',
    clinicalTrialsByIndications: '14.1 | ÉTUDES CLINIQUES PAR INDICATION',
    comparativeBioavailabiltyStudies: '14.2 | ÉTUDES DE BIODISPONIBILITÉ COMPARATIVES',
    immunogenicity: '14.3 | IMMUNOGÉNICITÉ',
    clinicalTrialsReferenceBiologicDrug: '14.4 | ESSAIS CLINIQUES : MÉDICAMENT BIOLOGIQUE DE RÉFÉRENCE',
    microbiology: '15 | MICROBIOLOGIE',
    nonClinicalToxicology: '16 | TOXICOLOGIE NON CLINIQUE',
    comparativeNonClinicalPharmacologyAndToxicology: '16.1 | PHARMACOLOGIE ET TOXICOLOGIE NON CLINIQUES COMPARATIVES',
    comparativeNonClinicalPharmacodynamics: '16.1.1 | PHARMACODYNAMIE NON CLINIQUE COMPARATIVE',
    comparativeToxicology: '16.1.2 | TOXICOLOGIE COMPARATIVE',
    supportingProductMonographs: '17 | MONOGRAPHIES DE RÉFÉRENCE',
}