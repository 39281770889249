import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateFormField } from '../../../actions/forms';

import { Box } from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';

// Tiny Editor
import { Editor } from '@tinymce/tinymce-react';

import addPlugin from './WysiwygPlugins/customAnchor';
import { warning } from '../../../utils/SnackBar';
import useInterval from '../../../utils/Interval';

const useStyles = makeStyles((theme) => ({
    editorBox: (props) => ({
		"& .tox-edit-area__iframe": {
			backgroundColor: props.disabled ? "#e9ecef !important" : "",
		}
    }),
}));

const BASE_PLUGINS = [
	'link', 'table', 'noneditable', 'paste', 'pastetext', 'autoresize',
]
const BASE_TOOLBAR = 'undo redo | link | insert_row_after delete_row'

const EXPERIMENTAL_PLUGINS = [
	'advcode'
]
const EXPERIMENTAL_TOOLBAR = 'code'

const PLUGINS = process.env.REACT_APP_ENVMNT === 'production' ? BASE_PLUGINS : [...BASE_PLUGINS, ...EXPERIMENTAL_PLUGINS]
const TOOLBAR = process.env.REACT_APP_ENVMNT === 'production' ? BASE_TOOLBAR : `${BASE_TOOLBAR} | ${EXPERIMENTAL_TOOLBAR}`


export default function RMLCEditor({ language, initialValue, name }){
	const editorRef = useRef(null);
	const dispatch = useDispatch()
	const form = useSelector((state) => state.forms.form)
	const editState = useSelector((state) => state.forms.editState)
	const [initValue, setInitValue] = useState(initialValue)
	const [editorInFocus, setEditorInFocus] = useState(false)
	const classes = useStyles({ disabled: editState.isDisabled });

	useEffect(() => {
		setInitValue(initialValue)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language, name])

	useEffect(() => {
		if(editState.isDisabled) {
			setInitValue(initialValue)
		}
	}, [editState, initialValue])

	const handleChange = (name, content) => {
		dispatch(updateFormField(name, content, form._id, language))
	}

	useEffect(() => {
		return () => {
			if(editorRef.current && editorRef.current.isDirty()) {
				const content = editorRef.current.getContent();
				handleChange(name, content)
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name])

	useInterval(() => {
		handleChange(name, editorRef.current.getContent())
	}, editorInFocus ? 5000 : null)

	const handleOnBlur = () => {
		setEditorInFocus(false)
	}

	const setup = (editor) => {
		addPlugin(editor, name, form, language, handleChange)

		// TODO:
		// Removing the is dirty setting logic as it wasn't detecting changes and not making API calls to save
		// Need to limit calls in the future for cost saving but commenting out now to ensure data integrity.
		editor.on('blur', (e) => {
			// if(editor.isDirty()){
				handleChange(name, editor.getContent())
				// editor.setDirty(false)
			// }
		})

		editor.on('focus', (e) => {
			setEditorInFocus(true)
		})

		editor.on('keydown', (e) => {
			const encapsulatingNodeName = e.target.nodeName
			if(encapsulatingNodeName !== "BODY"){
				e.preventDefault();
				return false;
			}
		})

		editor.ui.registry.addButton('insert_row_after', {
			text: 'Add Row After',
			onAction: () => {
				if(editor.queryCommandValue("mceTableRowType") !== "body") {
					warning("Click into a table row to add/delete rows.")
					return;
				}
				editor.execCommand('mceTableInsertRowAfter', false);
			}
		});

		editor.ui.registry.addButton('delete_row', {
			text: 'Delete Current Row',
			onAction: () => {
				if(editor.queryCommandValue("mceTableRowType") !== "body") {
					warning("Click into a table row to add/delete rows.")
					return;
				}

				const [tableBody] = editor.getContent({format: "tree"}).getAll("tbody")

				if(!tableBody){
					// TODO: Add handling here for missing table body
					return;
				}

				const RMLC_row_count = tableBody.getAll("tr").length

				if (RMLC_row_count >= 2) {
					editor.execCommand('mceTableDeleteRow');
				} else {
					warning("If no Recent Major Label Changes exist, click the 'Remove RMLC Section' button.")
				}
			}
		});
	};

  	return (
		<Box className={classes.editorBox} id={name} key={name}>
			<Editor
				initialValue={initValue}
				disabled={editState.isDisabled}
				apiKey="mt66tjvcqygmtm4me8rh2fci5hwi11rkxmwhfx67czmje03w"
				init={{
					setup: setup,
					height: 500,
					max_height: 700,
					min_height: 200,
					menubar: false,
					statusbar: false,
					branding: false,
					link_title: false,
					contextmenu: false,
					allow_html_in_named_anchor: true,
					convert_urls: false,
					paste_as_text: true,
					object_resizing: false,
					link_list: (success) => {
						let formData = language === false ? form.formDataEnglish : form.formDataFrench
						success([
							{
								title: 'Sections',
								menu: Object.values(formData.sectionUUIDs) ?? []
							},
							{
								title: 'Bookmarks',
								menu: Object.values(formData.anchors ?? []) ?? []
							},
						])
					},
					target_list: [
						{title: 'None', value: ''},
					],
					plugins: PLUGINS,
					toolbar: TOOLBAR,
					table_toolbar: '',
					table_appearance_options: false,
					table_use_colgroups: false,
				}}
				onInit={(evt, editor) => editorRef.current = editor}
				onBlur={handleOnBlur}
			/>
		</Box>
  	)
}