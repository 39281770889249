import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TextField from '../formComponents/TextField'
import AutocompleteField from '../formComponents/AutoComplete'
import PMButton from "../../common/Button"
import { makeStyles} from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core'
import { updateFormField } from '../../../actions/forms'

const useStyles = makeStyles({
    veritcalText: {
        alignSelf: "center"
    },
    paper: {
        padding: "2rem",
        margin: "2rem 0rem",
        borderRadius: "25px"
    }
});
export default function ProductCharacteristicsStack({ currentProductIndex, isOriginalText, codeSystem, context, label, placeholder, name, updateFunction, isValueUnitPair = false}){

    const dispatch = useDispatch()
    const form = useSelector((state) => state.forms.form)
    const updating = useSelector((state) => state.forms.updating)
    const classes = useStyles();
    
    let formInput = context ? form && form.formDataFrench : form && form.formDataEnglish 
    const characteristicList = formInput.products[currentProductIndex][name] ?? []

    const handleCreateProductCharacteristic = (name) => {
        formInput.products[currentProductIndex][name].push({})
        dispatch(updateFormField("products", formInput.products, form._id, context))
    }

    const handleRemoveProductCharacteristic = (name) => {
        formInput.products[currentProductIndex][name].pop()
        dispatch(updateFormField("products", formInput.products, form._id, context))
    }

    return(
        <Box pt={3} pb={3}>
            <Grid container>
                <Grid xs={10} item className={classes.veritcalText}>
                    <Box width="100%" alignSelf="center">
                        <h6>{label}</h6>
                    </Box>
                </Grid>
                <Grid xs={2} item>
                    <Box display="flex">
                        <Box width="100%" pr={2}>
                            <PMButton isFormButton={true} disabled={updating} onClick={() => {handleCreateProductCharacteristic(name)}}>
                                Add
                            </PMButton>
                        </Box>
                        <Box width="100%">
                            <PMButton isFormButton={true} disabled={updating} red onClick={() => {handleRemoveProductCharacteristic(name)}}>
                                Delete
                            </PMButton>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Box mt={4}>
                {characteristicList.length === 0 ? (
                    <Box>
                        There are currently no items for this characteristic.
                    </Box>
                ) : (!isValueUnitPair ? (
                    characteristicList.map((characteristic, index) => {
                        return (
                            <Box key={index}>
                                <AutocompleteField 
                                    showLabel={false} 
                                    context={context} 
                                    codeSystem={codeSystem} 
                                    updateFunction={updateFunction} 
                                    label = {label} 
                                    placeholder = {placeholder} 
                                    name = {`products[${currentProductIndex}].${name}[${index}]`}
                                />
                                {isOriginalText && (
                                    <TextField 
                                        updateFunction={updateFunction} 
                                        label = "Additional Text" 
                                        placeholder = {placeholder}
                                        name = {`products[${currentProductIndex}].${name}[${index}].originalText`}
                                    />
                                )}
                            </Box>
                        )   
                    })
                ) : (
                    characteristicList.map((characteristic, index) => {
                        return (
                            <Grid spacing={3} container>
                                <Grid item xs={6}>
                                    <TextField
                                        updateFunction={updateFunction}
                                        label= { context === false ? "Value" : "Valeur"}
                                        placeholder={placeholder}
                                        name = {`products[${currentProductIndex}].${name}[${index}].value`}
                                        type={"number"}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <AutocompleteField
                                        context={context}
                                        codeSystem={codeSystem}
                                        updateFunction={updateFunction}
                                        label={ context === false ? "Unit" : "Unité"}
                                        placeholder={placeholder}
                                        name = {`products[${currentProductIndex}].${name}[${index}].unit`}
                                    />
                                </Grid>
                            </Grid>
                        )   
                    })
                ))}
            </Box>
        </Box>
    )
}
