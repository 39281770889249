import React, { useEffect, useState } from 'react'
import TextField from '../formComponents/TextField'
import {englishLabels, frenchLabels} from "../../formLabels/ProductInformationFormLabels"
import AutocompleteField from '../formComponents/AutoComplete'
import ProductCharacteristicStack from './productCharacteristicStack'

import { makeStyles} from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { DIN_MASK } from '../../../utils/utilFunctions'

const useStyles = makeStyles({
    button: {
        width: "50%",
    },
    veritcalText: {
        alignSelf: "center"
    },
    paper: {
        padding: "2rem",
        margin: "2rem 0rem",
        borderRadius: "25px"
    }
});

export default function ProductInformation({ currentProductIndex, context, handleUpdateField }){

    const [labels, setLabels] = useState({})
    const classes = useStyles();

    useEffect(() => {
        setLabels(context ? frenchLabels : englishLabels)
    }, [context])

    return(
        <Paper className={classes.paper} elevation={4}>
            <h6>{labels.title}</h6>
                <TextField 
                    updateFunction={handleUpdateField} 
                    label = {labels.brandName} 
                    placeholder = {labels.brandName} 
                    name = {`products[${currentProductIndex}].brandName`}
                />
                <TextField 
                    updateFunction={handleUpdateField} 
                    label = {labels.nonProprietaryName} 
                    placeholder = {labels.nonProprietaryName} 
                    name = {`products[${currentProductIndex}].nonProprietaryName`}
                />
                <TextField 
                    updateFunction={handleUpdateField} 
                    label = {labels.DIN} 
                    placeholder = {labels.DIN} 
                    name = {`products[${currentProductIndex}].DIN`}
                    inputMask={DIN_MASK}
                />
                <AutocompleteField 
                    context={context} 
                    codeSystem="2.16.840.1.113883.2.20.6.3" 
                    updateFunction={handleUpdateField} 
                    label = {labels.dosageForm} 
                    placeholder = {labels.dosageForm} 
                    name = {`products[${currentProductIndex}].dosageForm`}
                />
                <Paper className={classes.paper} elevation={4}>
                    <ProductCharacteristicStack
                        updateFunction={handleUpdateField} 
                        currentProductIndex={currentProductIndex}
                        isOriginalText={false}
                        context={context}
                        codeSystem="2.16.840.1.113883.2.20.6.7"
                        label = {labels.routeOfAdministration}
                        placeholder = {labels.routeOfAdministration}
                        name="substanceAdministrations"
                    />
                </Paper>
        </Paper>
    )
}
