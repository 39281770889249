export const englishLabels = {
    title: "INGREDIENTS",
    missingSubtitle: "No Ingredients",
    name: "Name",
    code: "Code",
    numeratorValue: "Numerator",
    numeratorUnit: "Units",
    denominatorValue: "Denominator*",
    denominatorUnit: "Units*",
    activeMoiety: "Active Moiety",
    equivalentSubstance: "Equivalent Substance"
}

export const frenchLabels = {
    title: "INGRÉDIENTS",
    missingSubtitle: "Aucun Ingrédient",
    name: "Nom",
    code: "Code",
    numeratorValue: "Numérateur",
    numeratorUnit: "Unités",
    denominatorValue: "Dénominateur*",
    denominatorUnit: "Unités*",
    activeMoiety: "Fraction active",
    equivalentSubstance: "Substance équivalente"
}
