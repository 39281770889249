import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import PMButton from '../common/Button'

import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { AUTH_RESET } from '../../actions/types';

const useStyles = makeStyles(() => ({
    button: {
        width: "100%"
    },
    textField: {
        width: "100%"
    },
    formLabel: {
        float: "left",
        margin: "2% 0% 2% 0",
        fontSize: 18
    },
    forgotPassword: {
        '&:hover': {
            color: "#007bff",
            cursor: "pointer",
            textDecoration: "underline",
        },
    },
    formHeader: {
        float: "left",
        fontWeight: 800,
        fontSize: 36,
        fontStyle: "normal",
        marginBottom: "1%",
    },
}));


export default function PasswordReset({email}) {
    const dispatch = useDispatch()
    const loading = useSelector((state) => state.auth.loading)
    const classes = useStyles()
    let history = useHistory()


    const handleClick = async e => {
        dispatch({
            type: AUTH_RESET,
            payload: null
        })
        history.push('/login')
    }

    return(
        <Box>
            <Box display="flex" flexDirection="column">
                <Typography className ={classes.formHeader}>
                    Password Reset Successfully
                </Typography>
                <Grid container>
                    <Grid item xs={12}>
                        <Box>
                            <Typography className ={classes.formLabel}>
                                Your password was reset successfully. Click below to login using your updated credentials.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={3}>
                <PMButton
                    type="submit"
                    loading={loading}
                    disabled={loading}
                    onClick={(e) => {handleClick(e)}}
                    className={classes.button}
                >
                    Back to Login
                </PMButton>
            </Box>
        </Box>
    )
}
