import { Box } from '@material-ui/core';


export const englishTooltipLabels = {
    indications:
        <Box>
            [Brand name] (proper name in final dosage form) is indicated for: <br />
            <ul>
                <li> -[text]</li>
                <li> -[text]</li>
                <li> -[text]</li>
            </ul>

            For biosimilars, the wording of each indication authorized for the biosimilar should be identical to the reference biologic drug product monograph, and the following statement should be made: <br /><br />

            <i>Indications have been granted on the basis of similarity between [Biosimilar brand name] and the reference biologic drug [Reference biologic drug brand name].</i><br /><br />
            For NOC/c indications: include a brief statement regarding the uncertainties and/or limitations of the indications.
        </Box>,
    pediatrics:
        <Box>
            One of the following or similar statements should be used:
            <ul>
                <li> - <i>Pediatrics (age range): Based on the data submitted and reviewed by Health Canada, the safety and efficacy of [Brand name] in pediatric patients has been established. Therefore, Health Canada has authorized an indication for pediatric use. [Include cross-reference to relevant sections.]
                </i></li> or
                <li> -<i>Pediatrics (age range): No data are available to Health Canada; therefore, Health Canada has not authorized an indication for pediatric use.
                </i></li> or
                <li> -<i>Pediatrics (age range): Based on the data submitted and reviewed by Health Canada, the safety and efficacy of [Brand name] in pediatric patients has not been established; therefore, Health Canada has not authorized an indication for pediatric use. [Include cross-reference to relevant sections.]
                </i></li>
            </ul>

        </Box>,
    geriatrics:
        <Box>
            One of the following or similar statements may be used:
            <ul>
                <li> -<i>Geriatrics: No data are available to Health Canada; therefore, Health Canada has not authorized an indication for geriatric use.
                </i></li> or
                <li> -<i>Geriatrics: Evidence from clinical studies and experience suggests that use in the geriatric population is associated with differences in safety or effectiveness.
                </i></li>
            </ul>
        </Box>,


    contraindications:
        <Box>
            <ul>
                <li> -[text]</li>
                Describe absolute contraindications, meaning those situations in which the drug should not be used because the risk outweighs any potential therapeutic benefit. For example: <br /><br />
                <li> -<i>[Proper name] is contraindicated with co-administration of [Drug X] as it may result in increased concentrations of [Drug X] due to inhibition of CYP3A, which may lead to QT interval prolongation and torsades de pointes. See 7 Warnings and precautions and 9 Drug interactions.
                </i></li> <br />
                For hypersensitivity reactions, the following or similar statement should be used: <br /><br />

                <li> -<i>[Proper name] is contraindicated in patients who are hypersensitive to this drug or to any ingredient in the formulation, including any non-medicinal ingredient, or component of the container. For a complete listing, see 6 Dosage forms, strengths, composition and packaging.
                </i></li>

            </ul>
        </Box>,
    seriousWarningsAndPrecautionsBox:
        <Box>
            <b>Serious warnings and precautions</b>
            <ul>
                <li> -[text]</li>
                <li> -[text]</li>
            </ul>

            Clinically significant or serious (e.g., life-threatening) safety hazards should be placed in this box, with a cross reference to the relevant section(s) for more detailed information. Generally, this text should not exceed 20 lines.<br /><br />

            For all radiopharmaceuticals the Serious Warnings and Precautions Box should contain the following or similar statement:<br /><br />

            <i>Radiopharmaceuticals should be used only by those health professionals who are appropriately qualified in the use of radioactive prescribed substances in or on humans.</i><br /><br />
            In the absence of a serious warning or precaution identified at the time of authorization, this box is omitted, along with the heading 3 Serious warnings and precautions box.
        </Box>,
    dosageAndAdministration:
        <Box>
            Biosimilar specific properties should be considered, such as potentially allergenic product container materials or differences in product presentation that require biosimilar-specific storage and administration directions.
        </Box>,
    dosingConsiderations:
        <Box>
            <ul>
                <li> -[text]</li>
            </ul>
            Briefly list all safety issues to consider that may affect dosing of the drug (e.g., renal or hepatic disease, concomitant therapy, changing from intravenous to oral therapy, lab values prior to infusion, rule out pregnancy prior to administration, pre-medication is required, duration of effect, imaging time post-injection).
        </Box>,
    recommendedDoseAndDosageAdjustments:
        <Box>
            <ul>
                <li> -[text]</li>
            </ul>

            Include detailed dosage information for each indication, route of administration and/or dosage form, dosage schedules, booster doses, initial dose, titration of dose, dosage range, maximum daily dose, maintenance dosage, duration of treatment and drug discontinuance, considerations for special populations.
            <br /><br />
            In the absence of a Health Canada authorized pediatric indication, the following or similar statement should be used, with a cross-reference to relevant sections, if applicable:
            <br /><br />
            <ul>
                <li> -<i>Health Canada has not authorized an indication for pediatric use.</i></li>
            </ul>
        </Box>,
    reconstituition:
        <Box>
            <b>Oral solutions:</b><br />
            <ul>
                <li> -[text and/or table]</li>
            </ul>
            List all recommended diluents for reconstitution. Directions should include the volume and type of diluents to be added and the approximate volume and concentration of the resulting product.
            <br />
            <br />
            Recommended storage period and conditions should be stated and include cross-reference to 11 Storage, stability and disposal .
            <br />
            <br />

            <b>Parenteral products:</b>
            <ul>
                <li> -[text and table]</li>
            </ul>

            For intravenous use, information should be separately described for direct intravenous injection, intermittent infusion, and continuous infusion; use of in-line filters etc.
            <br /><br />
            Include any specific precautions, storage periods and incompatibilities, and include cross-reference to 11 Storage, stability and disposal .
            <br />
            <br />

            <b>Table: Reconstitution</b>
            <table >
                <thead>
                    <tr>
                        <th>HVial Size</th>
                        <th>Volume of Diluent to be Added to Vial</th>
                        <th>Approximate Available Volume</th>
                        <th>Concentration per mL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                </tbody>
            </table>
        </Box>,
    administration:
        <Box>
            [text and/or table] <br /><br />

            Include details concerning methods of administration. Specify any special considerations (e.g., do not crush, do not split if not scored, capsule contents can be sprinkled).
            <br /><br />
            For radiopharmaceuticals, if applicable, include the following or similar statement:
            <br />
            <br />
            <ul>
                <li> -<i>The patient dose should be measured by a suitable radioactivity calibration system prior to administration.</i></li>
            </ul>

        </Box>,
    missedDose:
        <Box>
            [text] <br /><br />

            Include actions to be taken in the event that a patient misses a dose.

        </Box>,
    imageAcquisitionAndInterpretation:
        <Box>
            [text] <br /><br />
            For radiopharmaceuticals only. Include specific requirements for image acquisition and interpretation such as type of equipment and calibration scanning or imaging time post injection, location of views, and frequency of images.
        </Box>,
    instructionsForPreparationAndUse:
        <Box>
            <ul>
                <li> -[text]</li>
                For radiopharmaceuticals only. The following or similar statement should be included:
                <br />
                <li> -
                    <i>
                        The components of the reagent vial are sterile and nonpyrogenic. It is essential that the user follows the directions carefully and adheres to strict aseptic technique.
                    </i>
                </li>
                or
                <li> -
                    <i>
                        Use aseptic technique and wear waterproof gloves throughout the entire preparation procedure.

                    </i>
                </li>
                or
                <li> -
                    <i>
                        Make all transfers of radioactive solutions with an adequately shielded syringe and maintain adequate shielding around the vial during the useful life of the radioactive product.
                    </i>
                </li>
            </ul>
        </Box>,
    radiationDosimetry:
        <Box>
            [For radiopharmaceuticals only. This is an example of acceptable presentation of Dose Estimate Data:]<br /><br />
            <b>Final Dose Estimates: [The model and method of calculation should be specified.]</b>
            <table >
                <thead>
                    <tr>
                        <th>Organ</th>
                        <th>mGy/MBq	</th>
                        <th>rad/mCi</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> Adrenals	 </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> ... </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> Uterus </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>

                </tbody>
            </table>
            Effective Dose Equivalent (mSv/MBq) (rem/mCi)
            <br />
            Effective Dose (mSv/MBq) (rem/mCi)

        </Box>,
    overdosage:
        <Box>
            [text] <br />

            Include the following information:<br />
            <ul>
                <li> -a description of the acute and/or long-term signs and symptoms of overdose,</li>
                <li> -potential sequelae/complications which may occur with the drug e.g. organ toxicity,
                </li>
                <li> -current recommended management of overdosage (e.g., monitoring, use of agonist/antagonist/antidotes, method to increase elimination and/or other clinical interventions), and
                </li>
                <li> -procedures that, by experience with this or similar type drugs, are known or reasonably expected to be unnecessary or unsuitable (e.g., those that may be hazardous to the patient).
                </li>
            </ul>
            <br />

            For management of a suspected drug overdose, contact your regional poison control centre.
        </Box>,
    dosageFormsStrengthsCompositionAndPackaging:
        <Box>
            To help ensure the traceability of biologic products, including biosimilars, health professionals should recognise the importance of recording both the brand name and the non-proprietary (active ingredient) name as well as other product-specific identifiers such as the Drug Identification Number (DIN) and the batch/lot number of the product supplied.
            <br /><br />
            To help ensure the traceability of vaccines for patient immunization record-keeping as well as safety monitoring, health professionals should record the time and date of administration, quantity of administered dose (if applicable), anatomical site and route of administration, brand name and generic name of the vaccine, the product lot number and expiry date.
            <br /><br />
            <b>Table: Dosage forms, strengths, composition and packaging</b>
            <table >
                <thead>
                    <tr>
                        <th>Route of Administration</th>
                        <th>Dosage Form / Strength/Composition</th>
                        <th>Non-medicinal Ingredients</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> [oral]</td>
                        <td> [tablet 5 mg, 10 mg] </td>
                        <td> [List all non-medicinal ingredients in alphabetical order] </td>
                    </tr>

                </tbody>
            </table>
            [text]<br /><br />

            <b>Description</b>
            <br />
            [text]
            <br />
            <br />
            For biosimilars only. Include a narrative description of the biosimilar biologic drug that is similar to the narrative in the reference biologic drug monograph. Incorporate changes as necessary where there are descriptive differences between the biosimilar and the reference biologic drug due to, for example, differences in formulation.
        </Box>,
    physicalCharacteristics:
        <Box>
            [table]<br /><br />
            For radiopharmaceuticals only. Include physical half-life, principle radiation emission data, physical decay chart (tabular format), parent and daughter radionuclides data.
        </Box>,
    externalRadiation:
        <Box>
            [table] <br /><br />
            For radiopharmaceuticals only. Include specific gamma ray constant for the radioisotope, radiation attenuation by lead shielding (tabular format), parent and daughter radionuclides data.
        </Box>,
    warningsAndPrecautions:
        <Box>
            If applicable, include one of the following statements:
            <br />
            <ul>
                <li> -
                    Please see 3 Serious warnings and precautions box.

                </li>
            </ul>

            For blood products:
            <br />
            <ul>
                <li> -
                    <i>
                        This product is prepared from large pools of human plasma. Thus, there is a possibility it may contain causative agents of viral or other undetermined diseases.
                    </i>
                </li>
            </ul>
            <br />
            For all radiopharmaceuticals:
            <ul>
                <li> -
                    <i>
                        The product should be administered under the supervision of a health professional who is experienced in the use of radiopharmaceuticals. Appropriate management of therapy and complications is only possible when adequate diagnostic and treatment facilities are readily available.
                    </i>
                </li>
                <li> -
                    <i>
                        The radiopharmaceutical product may be received, used and administered only by authorized persons in designated clinical settings. Its receipt, storage, use, transfer and disposal are subject to the regulations and/or appropriate licenses of local competent official organizations.

                    </i>
                </li>
                <li> -
                    <i>
                        As in the use of any other radioactive material, care should be taken to minimize radiation exposure to patients consistent with proper patient management, and to minimize radiation exposure to occupational workers.

                    </i>
                </li>
            </ul>
            <br />
            [Subheadings to be included as applicable, in alphabetical order:]
            <br />
            <br />
            <b>General</b>
            <br />
            Include information that does not fall under the subheadings listed below.
            <br />
            For products derived from plasma, explain the inherent risks when products have been derived from plasma.
            <br />
            [text]
            <br />
            <br />
            <b>Carcinogenesis and mutagenesis</b>
            <br />
            Include only human data where there is evidence that the drug is carcinogenic or mutagenic. Where there is only animal data, a cross-reference to the animal data in 16 Non-clinical toxicology should be provided.
            <br />
            [text]
            <br />
            <br />
            <b>Cardiovascular</b>
            <br />
            Includes QTc prolongation (cross reference to 10.2 Pharmacodynamics as required.)
            <br />
            [text]
            <br />
            <br />
            <b>Contamination</b>
            <br />
            For radiopharmaceuticals, include practical information for the patient to minimize the contamination potential after receiving the drug. This information must also appear in the Patient Medication Information.
            <br />
            [text]
            <br />
            <br />
            <b>Dependence/tolerance</b>
            <br />
            Include effects for both physical and psychological dependence. Treatment of the effects of the dependence should be provided.
            <br />
            [text]
            <br />
            <br />
            <b>Driving and operating machinery</b>
            <br />
            If applicable, this subheading should include the following or similar statement:
            <br />
            <ul>
                <li> -
                    <i>
                        Exercise caution when driving or operating a vehicle or potentially dangerous machinery.
                    </i>
                </li>
            </ul>
            [text]
            <br />
            <br />
            <b>Ear/nose/throat</b>
            <br />
            [text]
            <br />
            <br />
            <b>Endocrine and metabolism</b>
            <br />
            This subheading should specify genetic polymorphism where applicable.
            <br />
            [text]
            <br />
            <br />
            <b>Gastrointestinal</b>
            <br />
            [text]
            <br />
            <br />
            <b>Genitourinary</b>
            <br />
            [text]
            <br />
            <br />
            <b>Hematologic</b>
            <br />
            [text]
            <br />
            <br />
            <b>Hepatic/biliary/pancreatic</b>
            <br />
            When possible, idiopathic versus metabolic liver failure should be described.
            <br />
            [text]
            <br />
            <br />
            <b>Immune</b>
            <br />
            [text]
            <br />
            <br />
            <b>Monitoring and laboratory tests</b>
            <br />
            [text]
            <br />
            <br />
            <b>Musculoskeletal</b>
            <br />
            [text]
            <br />
            <br />
            <b>Neurologic</b>
            <br />
            [text]
            <br />
            <br />
            <b>Ophthalmologic</b>
            <br />
            [text]
            <br />
            <br />
            <b>Peri-operative considerations</b>
            <br />
            Include information on management before, during and after surgery.
            <br />
            [text]
            <br />
            <br />
            <b>Psychiatric</b>
            <br />
            Behavioural changes, or potential (e.g., suicidal ideation) should be stated.
            <br />
            [text]
            <br />
            <br />
            <b>Renal</b>
            <br />
            [text]
            <br />
            <br />
            <b>Reproductive health: Female and male potential</b>
            <br />
            Cross-reference to other relevant sections (e.g. 2 Contraindications, 7.1.1 Pregnant women); consider contraception for both females and males.
            <ul>
                <li> -
                    <b>Fertility</b><br />
                    Include a summary of relevant information of effects of the drug on fertility from animal or human exposure. In the absence of information, clearly state that no data exist.
                    <br />
                    [text]
                </li>
                <li> -
                    <b>Function</b><br />
                    Include effects on sexual desire, erection, orgasm and ejaculation.
                    <br />
                    [text]

                </li>
                <li> -
                    <b>Teratogenic risk</b><br />
                    Teratogenic and nonteratogenic effects on the fetus should be included. If contraindicated in pregnancy, this should be stated in 2 Contraindications.
                    <br />
                    [text]

                </li>

            </ul>
            <br />

            <b>Respiratory</b>
            <br />
            [text]
            <br />
            <br />

            <b>Sensitivity/resistance</b>
            <br />
            [text]
            <br />
            <br />
            <b>Skin</b>
            <br />
            <ul>
                <li> -
                    Include information on local reactions to vaccination, human photosensitivity where applicable, etc.
                </li>
            </ul>
            <br />
            [text]
        </Box>,
    pregnantWomen:
        <Box>
            Include information related to Pregnancy Registries. The availability of a pregnancy exposure registry should also be included in the Patient Medication Information. If information on birth defects and miscarriage is available for the patient population for whom the drug is labelled, it must also be included, along with the following, when available:

            <ul>
                <li> -
                    Disease-associated maternal and/or fetal risk
                </li>
                <li> -
                    Maternal adverse reactions
                </li>
                <li> -
                    Embryo/Fetal/Neonatal adverse reactions
                </li>
                <li> -
                    Labour and/or delivery
                </li>
            </ul>
            The extent of exposure in pregnancy during clinical trials should be included:
            <br />
            <ul>
                <li> -
                    Wide: &gt; 1,000 pregnancies
                </li>
                <li> -
                    Limited: &lt; 1,000 pregnancies
                </li>
                <li> -
                    Very Limited: individual cases only
                </li>
                <li> -
                    No experience.
                </li>
            </ul>
            For radiopharmaceuticals the following or similar statement may be included:
            <br /><br />
            <i>
                Ideally, examinations using radiopharmaceuticals, especially those elective in nature of women of childbearing capability, should be performed during the first ten days following the onset of menses, or after ensuring the woman is not pregnant. The benefit of using a diagnostic radiopharmaceutical should be weighed against the possible risk to an embryo or a fetus.
            </i>
            <br />
            [text]


        </Box>,
    breastFeeding:
        <Box>
            If studies have shown that the drug is not excreted in human breast milk, it should be stated. In the absence of human data, pertinent animal data should be included along with the following or similar statement:
            <br />
            <ul>
                <li>
                    <i>
                        - It is unknown if [Brand name] [product] is excreted in human milk. Precaution should be exercised because many drugs can be excreted in human milk.
                    </i>
                </li>
            </ul>

            For radiopharmaceuticals, the following or similar statement should be included:
            <br />
            <ul>
                <li>
                    <i>
                        - Where an assessment of the risk to benefit ratio suggests the use of this product in nursing mothers, formula feeding should be substituted for breast feeding.
                    </i>
                </li>
            </ul>
            [text]

        </Box>,
    pediatricsWarningsAndPrecautions:
        <Box>
            In the absence of a Health Canada authorized pediatric indication, the following statement should be used:
            <br />
            <ul>
                <li>
                    <i>
                        - <b>Pediatrics (age range):</b> No data are available to Health Canada; therefore, Health Canada has not authorized an indication for pediatric use.
                    </i>
                </li>
                or
                <li>
                    <i>
                        - <b> Pediatrics (age range):</b> Based on the data submitted and reviewed by Health Canada, the safety and efficacy of [Brand name] in pediatric patients has not been established; therefore, Health Canada has not authorized an indication for pediatric use. [Include cross-reference to relevant sections.]
                    </i>
                </li>
            </ul>
            [text]
        </Box>,
    geriatricsWarningsAndPrecautions:
        <Box>
            [text]
        </Box>,
    adverseReactions:
        <Box>
            For biosimilars, include the following or similar statement:
            <ui>
                <li>
                    <i>
                        - The adverse drug reaction profiles reported in clinical studies that compared [Biosimilar brand name] to the reference biologic drug were comparable. The description of adverse reactions in this section is based on clinical experience with the reference biologic drug.
                    </i>
                </li>
            </ui>

        </Box>,
    adverseReactionsOverview:
        <Box>
            [text]
            <br /><br />
            Provide information on the most serious and/or most frequently occurring adverse reactions, or those where there have been reports of particularly severe cases. Frequencies to be stated as accurately as possible. It should not be a summary of the safety database.
        </Box>,
    clinicalTrialAdverseReactions:
        <Box>
            Clinical trials are conducted under very specific conditions. The adverse reaction rates observed in the clinical trials; therefore, may not reflect the rates observed in practice and should not be compared to the rates in the clinical trials of another drug. Adverse reaction information from clinical trials may be useful in identifying and approximating rates of adverse drug reactions in real-world use.
            <br />
            <br />
            [Include a brief description of data sources.]
            <br />
            [text]
            <br />
            <b>Table [#] [Title of Table]</b>
            <table >
                <thead>
                    <tr>
                        <th></th>
                        <th>[drug name] nd = [#] (%)</th>
                        <th>[placebo] n = [#] (%)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> [use MedDRA terms for headings, as applicable] <br />
                            <b>Cardiovascular</b> <br />
                            [text] </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> <b>Gastrointestinal</b><br />
                            [text] </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                </tbody>
            </table>
            <br />
            [text]
            <br />
            <br />

            A brief narrative should follow the table to explain or supplement the information provided in the table where applicable. Separate tables may be required for different indications (e.g., oncology and a non-oncology indication) or different formulations (e.g., oral, intravenous) or different drug combinations.
            <br />
            <br />


            Adverse reactions may also be related to genetically determined product metabolism. Subjects or patients deficient in the specific enzyme may experience a different rate or severity of adverse reactions. This should be mentioned where relevant, and correlated with data from clinical trials.
        </Box>,
    clinicalTrialAdverseReactionsPediatrics:
        <Box>
            [text] <br /><br />
            From pediatric studies include: age characteristics, any clinically relevant differences (i.e., seriousness or reversibility of adverse reaction) between safety profiles in adult and pediatric populations, or any relevant age groups, uncertainties due to limited experience. If the observed safety profile is consistent in children and adults this could be stated.
        </Box>,
    lessCommonClinicalTrialAdverseReactions:
        <Box>
            [text] <br /><br />
            Present as a list, categorized by System Organ Class, alphabetically: e.g.,
            <br />
            Cardiovascular: [text]
            <br />
            Gastrointestinal: [text]
        </Box>,
    lessCommonClinicalTrialAdverseReactionsPediatrics:
        <Box>
            [text] <br /><br />
            From pediatric studies include: age characteristics, any clinically relevant differences (i.e., seriousness or reversibility of adverse reaction) between safety profiles in adult and pediatric populations, or any relevant age groups, uncertainties due to limited experience. If the observed safety profile is consistent in children and adults this could be stated.
        </Box>,
    abnormalLaboratoryFindingsHematologic:
        <Box>
            <b>Clinical trial findings</b>
            <br />
            [table]
            <br />
            <br />

            Outline any differences between adults, geriatrics and pediatrics as necessary with regard to abnormal laboratory findings.
            <br />
            <br />
            <b>Post-market findings</b>
            <br />
            [table]
            <br />
            <br />
            Outline any differences between adults, geriatrics and pediatrics as necessary with regard to post-market abnormal laboratory findings.

        </Box>,
    postMarketAdverseReactions:
        <Box>
            [text and/or table]<br /><br />
            Include Canadian and international post-market adverse reactions including serious and/or unexpected adverse reactions that are reported through post-market surveillance and/or identified in Phase IV clinical trials. Adverse reactions already listed in the Clinical Trial Adverse Reactions section should not be repeated in this section unless there are changes in severity, frequency or character.
        </Box>,
    seriousDrugInteractions:
        <Box>
            <b>Serious drug interactions</b>
            <br />
            [Serious (e.g., life-threatening) drug interactions should be highlighted in this box, with a cross-reference to detailed information in 9.4 Drug-Drug Interactions. Not to exceed 20 lines.]
            <br />
            <ul>
                <li>[text]</li>
                <li>[text]</li>
            </ul>
            If there are no serious drug interactions at the time of authorization, this box is omitted.
        </Box>,
    drugInteractionsOverview:
        <Box>
            [text]
        </Box>,
    drugBehaviouralInteractions:
        <Box>
            [text]<br />
            Briefly present potential interactions in terms of individual behavioural risks including, but not limited to, alcohol consumption, sexual activity, and smoking, which may result in unfavourable adverse events or treatment outcomes.
        </Box>,
    drugDrugInteractions:
        <Box>
            The following or similar statement should precede the table:
            <ul>
                <li>
                    <i> The drugs listed in this table are based on either drug interaction case reports or studies, or potential interactions due to the expected magnitude and seriousness of the interaction (i.e., those identified as contraindicated).
                    </i>
                </li>
            </ul>
            Where no interaction data is known the following or similar statement should be included:
            <ul>
                <li>
                    <i>
                        - Interactions with other drugs have not been established.
                    </i>
                </li>

            </ul>
            <b>Table [#] - Established or potential drug-drug interactions</b>
            <table >
                <thead>
                    <tr>
                        <th>[Proper/Common name]</th>
                        <th>Source of Evidence</th>
                        <th>Effect</th>
                        <th>Clinical comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> [drug A] </td>
                        <td> [level/source of evidence, see legend] </td>
                        <td> [drug A] conc </td>
                        <td> [Caution is warranted and therapeutic concentration monitoring is recommended]</td>
                    </tr>
                    <tr>Legend: C = Case Study; CT = Clinical Trial; T = Theoretical</tr>
                </tbody>
            </table>
        </Box>,
    drugFoodInteractions:
        <Box>
            [text]<br />

            Briefly present known or potential interactions with food or beverages (e.g., grapefruit, caffeine). Cross-referencing to 4 Dosage and administration may be required when the timing of food consumption should be considered.
            <br /><br />

            Where no interaction data is known, the following or similar statement should be included:
            <ul>
                <li>
                    <i>Interactions with food have not been established.
                    </i>
                </li>
            </ul>
        </Box>,
    drugHerbInteractions:
        <Box>
            [text]<br />

            Briefly present known or potential interactions with herbal products and practical guidance for the health professional.
            <br /><br />

            Where no interaction data is known, the following or similar statement should be included:
            <ul>
                <li>
                    <i>Interactions with herbal products have not been established.
                    </i>
                </li>
            </ul>
        </Box>,
    drugLaboratoryTestInteractions:
        <Box>
            [text]<br />

            Briefly present laboratory tests affected by the presence of the drug, such as interfering with the accuracy of the test results or methods (e.g., antihistamines diminish the positive reactions to dermal reactivity indicators).
            <br /><br />
            Where no interaction data is known, the following or similar statement should be included:
            <ul>
                <li>
                    <i>Interactions with laboratory tests have not been established.
                    </i>
                </li>
            </ul>
        </Box>,
    actionAndClinicalPharmacology: "10 | CLINCIAL PHARMACOLOGY",
    mechanismOfAction:
        <Box>
            [text]
        </Box>,
    pharmacodynamics:
        <Box>
            [text]
        </Box>,
    pharmacokinetics:
        <Box>
            <b>Table [#] - Summary of [proper name] Pharmacokinetic Parameters in [specific patient population]
            </b>
            <table >
                <thead>
                    <tr>
                        <th></th>
                        <th>Cmax</th>
                        <th>Tmax</th>
                        <th>t½ (h)</th>
                        <th>AUC0-∞</th>
                        <th>CL</th>
                        <th>Vd</th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> Single dose mean </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>

                    </tr>
                </tbody>
            </table>
            <b>Absorption:</b>
            <br />
            [text]
            <br />
            <br />

            <b>Distribution:</b>
            <br />
            [text]
            <br />
            <br />

            <b>Metabolism:</b>
            <br />
            [text]
            <br />
            <br />

            <b>Elimination:</b>
            <br />
            [text]
            <br />
            <br />

            <b>Duration of effect:</b>
            <br />
            This subsection applies specifically to vaccines and should describe the duration of effect of the recommended dose (e.g., duration of detectable levels of antibodies and/or conferred immunity status).
            <br />
            [text]
            <br />
            <br />
            <b>Special populations and conditions</b>
            <br />
            [text]
            <br />
            <ul>
                <li>
                    - <b>Pediatrics</b> [text]
                </li>
                <li>
                    - <b>Geriatrics</b> [text]
                </li>
                <li>
                    - <b>Sex</b> [text]
                </li>
                <li>
                    - <b>Pregnancy and Breast-feeding</b> [text]
                </li>
                <li>
                    - <b>Genetic Polymorphism</b> [text]
                </li>
                <li>
                    - <b>Ethnic Origin</b> [text]
                </li>
                <li>
                    - <b>Hepatic Insufficiency</b> [text]
                </li>
                <li>
                    - <b>Renal Insufficiency</b> [text]
                </li>
                <li>
                    - <b>Obesity</b> [text]
                </li>
            </ul>

        </Box>,
    storageStabilityAndDisposal:
        <Box>
            [text]
            <br />
            <br />
            Include recommended storage conditions for each dosage form as supported by stability studies.
            <br />
            <br />
            For reconstituted products, including parenterals, the recommended storage period and conditions should be stated.
            <br />
            <br />
            Disposal instructions should be included for all drug products. Include a cross-reference to more detailed safe disposal instructions under 12 Special handling instructions where appropriate.
            <br />
            <br />
            For radiopharmaceutical kits include the following or similar statement:
            <ul>
                <li>
                    <i>
                        Do not use the kit beyond the expiration date stamped on the box. After preparation [product] should be stored at room temperature until administration, within [x] hours of radiolabelling.
                    </i>
                </li>
            </ul>
        </Box>,
    specialHandlingInstructions:
        <Box>
            [text]
        </Box>,
}

export const frenchTooltipLabels = {
    indications:
        <Box>
            [Marque nominative] (nom propre) est indiqué pour: <br />
            <ul>
                <li> -[texte]</li>
                <li> -[texte]</li>
                <li> -[texte]</li>
            </ul>

            Pour les biosimilaires : La formulation de chaque indication autorisée pour le produit biosimilaire doit être identique à celle de la monographie du médicament biologique de référence, et l’énoncé suivant doit être inclus :
            <br /><br />

            <i>Les indications ont été autorisées sur la base de la similarité entre [Marque nominative du produit biosimilaire] et le médicament biologique de référence [Marque nominative du médicament biologique de référence].</i><br /><br />

            Pour les indications assujetties à un AC/C : inclure un court énoncé concernant les incertitudes et/ou les limites des indications.
        </Box>,

    pediatrics:
        <Box>
            OL’un des énoncés suivants ou un énoncé semblable doit être utilisé :
            <ul>
                <li> - <i>Enfants (tranche d’âge) : D’après les données examinées par Santé Canada, l’innocuité et l’efficacité de [Marque nominative] dans la population pédiatrique ont été démontrées. Par conséquent, Santé Canada a autorisé une indication d’utilisation dans la population pédiatrique. [Inclure un renvoi aux sections pertinentes.]</i></li> ou
                <li> -<i>Enfants (tranche d’âge) : Santé Canada ne dispose d’aucune donnée; par conséquent, l’indication d’utilisation dans la population pédiatrique n’est pas autorisée par Santé Canada.</i></li> ou
                <li> -<i>Enfants (tranche d’âge) : D’après les données examinées par Santé Canada, l’innocuité et l’efficacité de [Marque nominative] dans la population pédiatrique n’ont pas été démontrées; par conséquent, l’indication d’utilisation chez ces patients n’est pas autorisée par Santé Canada. [Inclure un renvoi aux sections pertinentes.]</i></li>
            </ul>

        </Box>,
    geriatrics:
        <Box>
            L’un des énoncés suivants ou un énoncé semblable peut être utilisé :
            <ul>
                <li> -<i>Personnes âgées : Santé Canada ne dispose d’aucune donnée; par conséquent, l’indication d’utilisation dans la population gériatrique n’est pas autorisée par Santé Canada.</i></li> ou
                <li> -<i>Personnes âgées : Les données tirées des études cliniques et de l’expérience laissent entendre que l’utilisation du produit au sein de la population gériatrique entraîne des différences en matière d’innocuité ou d’efficacité.
                </i></li>
            </ul>
        </Box>,
    contraindications:
        <Box>
            <ul>
                <li> -[texte]</li>
                Décrire les contre-indications absolues, c’est-à-dire les situations dans lesquelles le médicament ne doit pas être utilisé parce que le risque l’emporte sur tout bienfait thérapeutique potentiel. Exemples : <br /><br />
                <li> -<i>[Nom propre] est contre-indiqué avec l’administration concomitante de [Produit X], car cela peut faire augmenter les concentrations de [Produit X] en raison de l’inhibition du CYP3A, ce qui peut entraîner un allongement de l’intervalle QT et des torsades de pointes. Voir les sections 7, « Mises en garde et précautions », et 9, « Interactions médicamenteuses ».</i></li> <br />
                En ce qui a trait aux réactions d’hypersensibilité, l’énoncé suivant ou un énoncé semblable doit être utilisé : <br /><br />

                <li> -<i>[Nom propre] est contre-indiqué chez les patients qui présentent une hypersensibilité au produit, à un ingrédient de la formulation, y compris à un ingrédient non médicinal, ou à un composant du contenant. Pour obtenir la liste complète des ingrédients, veuillez consulter la section 6 « Formes posologiques, concentrations, composition et emballage ».</i></li>

            </ul>
        </Box>,
    seriousWarningsAndPrecautionsBox:
        <Box>
            <b>Mises en garde et précautions importantes</b>
            <ul>
                <li> -[texte]</li>
                <li> -[texte]</li>
            </ul>

            Les risques pour la sécurité cliniquement significatifs ou graves (p. ex. mettant la vie en danger) doivent figurer dans cet encadré, avec un renvoi vers la ou les sections correspondantes qui contiennent de plus amples détails. En règle générale, ce texte ne doit pas dépasser 20 lignes.<br /><br />

            Pour tous les produits radiopharmaceutiques, l’encadré « Mises en garde et précautions importantes » doit comprendre l’énoncé suivant ou un énoncé semblable :<br /><br />

            <i>Les produits radiopharmaceutiques ne doivent être utilisés que par des professionnels de la santé adéquatement qualifiés en ce qui a trait au recours à des substances réglementées radioactives chez l’homme.</i><br /><br />
            En l’absence d’avertissement sérieux ou de mesures à prendre au moment de l’autorisation, cet encadré est omis, ainsi que l’encadré « Mises en garde et précautions importantes » de la section 3.
        </Box>,
    dosageAndAdministration:
        <Box>
            Les propriétés biosimilaires spécifiques doivent être prises en compte, telles que les matériaux de contenant du produit potentiellement allergènes ou les différences de présentation du produit qui nécessitent des instructions d’administration et des conditions d’entreposage propres au produit biosimilaire.

        </Box>,
    dosingConsiderations:
        <Box>
            <ul>
                <li> -[texte]</li>
            </ul>
            Énumérer brièvement toutes les questions d’innocuité pouvant avoir un effet sur la posologie du médicament et dont il faut tenir compte (p. ex. maladie rénale ou hépatique, traitement concomitant, passage d’un traitement intraveineux à un traitement oral, valeurs de laboratoire avant la perfusion, exclusion d’une grossesse avant l’administration, traitements antérieurs nécessaires, durée de l’effet, temps d’imagerie après l’injection).
        </Box>,
    recommendedDoseAndDosageAdjustments:
        <Box>
            <ul>
                <li> -[texte]</li>
            </ul>

            Inclure des renseignements détaillés sur les doses pour chaque indication, la voie d’administration et/ou la forme posologique, les posologies, les doses de rappel, la dose initiale, la modification posologique, la gamme posologique, la dose quotidienne maximale, la posologie d’entretien, la durée et la cessation du traitement, les considérations relatives aux populations particulières.
            <br /><br />
            En l’absence d’une indication pédiatrique autorisée par Santé Canada, l’énoncé suivant ou un énoncé similaire doit être utilisé, avec un renvoi aux sections pertinentes, le cas échéant :            <br /><br />
            <ul>
                <li> -<i>Santé Canada n’a pas autorisé d’indication d’utilisation dans la population pédiatrique.</i></li>
            </ul>
        </Box>,
    reconstituition:
        <Box>
            <b>Solutions orales</b><br />
            <ul>
                <li> -[texte et/ou tableau]</li>
            </ul>
            Veuillez fournir la liste complète des diluants recommandés pour la reconstitution. Les directives doivent comprendre le volume et le type de diluants à utiliser, ainsi que la concentration et le volume approximatifs du produit final.

            <br />
            <br />
            Les conditions et la période d’entreposage recommandées doivent être mentionnées et inclure un renvoi à la section 11, « Entreposage, stabilité et traitement ».            <br />
            <br />

            <b>Produits parentéraux :</b>
            <ul>
                <li> -[tableau et texte]</li>
            </ul>

            Pour une utilisation par voie intraveineuse, les renseignements doivent être décrits séparément pour l’injection intraveineuse directe, la perfusion intermittente et la perfusion continue; l’utilisation de filtres intégrés, etc.  <br /><br />
            Inclure toutes les précautions spécifiques, les périodes de stockage et les incompatibilités, et inclure une référence croisée à la section 11, « Entreposage, stabilité et traitement ». <br />
            <br />

            <b>Tableau : Reconstitution</b>
            <table >
                <thead>
                    <tr>
                        <th>Taille du flacon</th>
                        <th>Volume de diluant à ajouter au flacon</th>
                        <th>Volume disponible approximatif</th>
                        <th>Concentration par mL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                </tbody>
            </table>
        </Box>,
    administration:
        <Box>
            [texte et/ou tableau] <br /><br />

            Inclure des détails au sujet des modes d’administration Indiquer toute considération particulière (p. ex. ne pas écraser, ne pas diviser si noté, le contenu de la capsule peut être saupoudré).<br /><br />
            Pour les produits radiopharmaceutiques, le cas échéant, veuillez inclure l’énoncé suivant ou un énoncé similaire :
            <br />
            <br />
            <ul>
                <li> -<i>On doit mesurer la dose destinée au patient à l’aide d’un système de calibrage pour la radioactivité approprié avant de la lui administrer.</i></li>
            </ul>
        </Box>,
    missedDose:
        <Box>
            [texte] <br /><br />

            Fournir des directives sur les mesures à prendre lorsqu’un patient oublie de prendre une dose.

        </Box>,
    imageAcquisitionAndInterpretation:
        <Box>
            [texte] <br /><br />
            Pour les produits radiopharmaceutiques uniquement. Indiquer les exigences précises pour l’acquisition d’image et l’interprétation, telles que celles qui s’appliquent au type d’équipement, au balayage d’étalonnage ou au temps d’imagerie après l’injection, à l’emplacement des vues et à la fréquence des images.
        </Box>,
    instructionsForPreparationAndUse:
        <Box>
            <ul>
                <li> -[texte]</li>
                Pour les produits radiopharmaceutiques uniquement. L’énoncé suivant ou un énoncé semblable doit être inclus :
                <br />
                <li> -
                    <i>
                        Les composants du flacon de réactif sont stériles et apyrogènes. Il est primordial de suivre les directives à la lettre et de se conformer à des mesures d’asepsie rigoureuses.                    </i>
                </li>
                ou
                <li> -
                    <i>
                        Avoir recours à une technique aseptique et porter des gants imperméables tout au long de la procédure de préparation.
                    </i>
                </li>
                ou
                <li> -
                    <i>
                        Effectuer tous les transferts de solutions radioactives à l’aide d’une seringue adéquatement blindée et assurer un blindage adéquat autour du flacon au cours de la durée de vie utile du produit radioactif.                    </i>
                </li>
            </ul>
        </Box>,
    radiationDosimetry:
        <Box>
            [Pour les produits radiopharmaceutiques uniquement. Voici un exemple de présentation acceptable des données d’estimation de dose :]            <br /><br />
            <b>Dose finale estimée [le modèle et la méthode de calcul doivent être indiqués].</b>
            <table >
                <thead>
                    <tr>
                        <th>Organe</th>
                        <th>mGy/MBq</th>
                        <th>rad/mCi</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> Glandes surrénales</td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> ... </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> Utérus </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                </tbody>
            </table>
            Effective Dose Equivalent (mSv/MBq) (rem/mCi)
            <br />
            Effective Dose (mSv/MBq) (rem/mCi)

        </Box>,
    overdosage:
        <Box>
            [texte] <br />

            Inclure les renseignements suivants :<br />
            <ul>
                <li> -une description des signes et symptômes aigus et/ou à long terme de surdose;</li>
                <li> -les séquelles ou complications potentielles qui peuvent se produire avec le médicament, p. ex. toxicité pour les organes;
                </li>
                <li> -le traitement actuellement recommandé en cas de surdosage (p. ex. surveillance, utilisation d’agonistes/antagonistes/antidotes, méthode pour augmenter l’élimination et/ou autres interventions cliniques);</li>
                <li> -les procédures qui, en raison de l’expérience avec ce médicament particulier ou un autre similaire, sont connues comme étant superflues ou inappropriées ou pourraient vraisemblablement l’être (p. ex. celles qui peuvent être dangereuses pour le patient).</li>
            </ul>
            <br />

            Pour traiter une surdose présumée, communiquez avec le centre antipoison de votre région.
        </Box>,
    dosageFormsStrengthsCompositionAndPackaging:
        <Box>
            Pour assurer la traçabilité des produits biologiques, y compris les biosimilaires, les professionnels de la santé doivent reconnaître l’importance de consigner à la fois la marque nominative et le nom non exclusif (principe actif) ainsi que d’autres identificateurs propres au produit, tels que le numéro d’identification numérique de drogue (DIN) et le numéro de lot du produit fourni.<br /><br />
            Pour assurer la traçabilité des vaccins pour la tenue des dossiers de vaccination des patients ainsi que le contrôle de la sécurité, les professionnels de la santé doivent enregistrer l’heure et la date d’administration, la quantité de dose administrée (le cas échéant), le site anatomique et la voie d’administration, la marque et le nom générique du vaccin, le numéro de lot du produit et la date d’expiration.
            <br /><br />
            <b>Tableau : Formes posologiques, concentrations, composition et emballage</b>
            <table >
                <thead>
                    <tr>
                        <th>Voie d’administration</th>
                        <th>Forme posologique / concentration / composition</th>
                        <th>Ingrédients non médicinaux</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> [orale]</td>
                        <td> [comprimé de 5 mg, 10 mg]</td>
                        <td> [Veuillez fournir la liste complète des ingrédients non médicinaux, en ordre alphabétique.] </td>
                    </tr>

                </tbody>
            </table>
            [texte]<br /><br />

            <b>Description</b>
            <br />
            [texte]
            <br />
            <br />
            Pour les biosimilaires uniquement. Inclure une description narrative du médicament biologique biosimilaire qui est similaire à celle de la monographie du médicament biologique de référence. Intégrer des modifications si nécessaire lorsqu’il existe des différences descriptives entre le médicament biosimilaire et le médicament biologique de référence en raison, par exemple, de différences de formulation.        </Box>,
    physicalCharacteristics:
        <Box>
            [tableau]<br /><br />
            Pour les produits radiopharmaceutiques uniquement. Inclure période physique, principales données sur l’émission de radiations, données sur la désintégration physique (sous forme de tableau), données sur les radionucléides pour l’élément mère et pour l’élément de filiation.</Box>,
    externalRadiation:
        <Box>
            [tableau] <br /><br />
            Pour les produits radiopharmaceutiques uniquement. Inclure la constante spécifique du rayon gamma en ce qui concerne le radio-isotope, ainsi que l’atténuation de la radiation par un écran de plomb (sous forme de tableau) et les données sur les radionucléides pour l’élément mère et pour l’élément de filiation.</Box>,
    warningsAndPrecautions:
        <Box>
            Si l’un des énoncés suivants s’applique, veuillez l’inclure :
            <br />
            <ul>
                <li> -
                    Veuillez consulter « Encadré mises en garde et précautions importantes » de la section 3

                </li>
            </ul>

            Pour les produits sanguins :
            <br />
            <ul>
                <li> -
                    <i>
                        Ce produit est préparé à partir de grands bassins de plasma humain. Ainsi, il est possible qu’il contienne des agents causatifs de maladies virales ou d’autres maladies indéterminées.                    </i>
                </li>
            </ul>
            <br />
            Pour tous les produits radiopharmaceutiques :
            <ul>
                <li> -
                    <i>
                        Le produit doit être administré sous la supervision d’un professionnel de la santé expérimenté en ce qui concerne l’utilisation de produits radiopharmaceutiques. La gestion appropriée de la thérapie et des complications n’est possible que lorsque des installations adéquates de diagnostic et de traitement sont rapidement utilisables.                    </i>
                </li>
                <li> -
                    <i>
                        Le produit radiopharmaceutique ne peut être reçu, utilisé et administré que par des personnes autorisées dans un environnement clinique autorisé. Sa réception, son entreposage, son utilisation, son transport et son élimination sont soumis aux règlements ou aux autorisations appropriées des organismes officiels locaux compétents.
                    </i>
                </li>
                <li> -
                    <i>
                        Comme pour l’utilisation de tout autre produit radioactif, la prudence s’impose afin que le patient ne soit exposé qu’à l’irradiation nécessaire pour évaluer son état, ce qui permet également de protéger le personnel œuvrant dans ce domaine.

                    </i>
                </li>
            </ul>
            <br />
            [Sous-titres à ajouter, le cas échéant, par ordre alphabétique :]<br />
            <br />
            <b>Généralités</b>
            <br />
            Comprendre des renseignements qui ne correspondent à aucun des sous-titres apparaissant ci-dessous.<br />
            Pour les produits dérivés du plasma, expliquer les risques inhérents lorsque les produits ont été dérivés du plasma.<br />
            [texte]
            <br />
            <br />
            <b>Cancérogenèse et mutagenèse</b>
            <br />
            N’inclure que des données chez l’humain lorsqu’il existe des preuves que le produit est cancérigène ou mutagène. Lorsque l’on ne dispose que de données tirées d’essais chez les animaux, un renvoi aux données sur les animaux de la section 16, « Toxicologie non clinique », doit être fourni.
            <br />
            [texte]
            <br />
            <br />
            <b>Cardiovasculaire</b>
            <br />
            Comprend l’allongement de l’intervalle Qtc (avec un renvoi à la section 10.2, « Pharmacodynamie », au besoin).<br />
            [texte]
            <br />
            <br />
            <b>Conduite de véhicules et utilisation de machines</b>
            <br />
            Si ce sous-titre s’applique, il devrait inclure l’énoncé suivant ou un énoncé similaire :
            <br />
            <ul>
                <li> -
                    <i>
                        Faire preuve de prudence lors de la conduite d’un véhicule motorisé ou lors de l’opération d’une machine dangereuse.                    </i>
                </li>
            </ul>
            [texte]
            <br />
            <br />
            <b>Considérations péri-opératoires</b>
            <br />
            Inclure des renseignements sur la prise en charge avant, pendant et après l’intervention.<br />
            [texte]
            <br />
            <br />
            <b>Contamination</b>
            <br />
            Pour les produits radiopharmaceutiques, inclure des renseignements pratiques destinés au patient et visant à réduire au minimum le risque de contamination à la suite de l’administration du produit. Ces renseignements doivent également figurer dans les « Renseignements pour les patients sur les médicaments ».<br />
            [texte]
            <br />
            <br />
            <b>Dépendance/tolérance</b>
            <br />
            Inclure les effets résultant de la dépendance physique et psychologique. Les moyens de traiter les effets de la dépendance doivent être indiqués.
            <br />
            [texte]
            <br />
            <br />
            <b>Endocrinien/métabolisme</b>
            <br />
            Ce sous-titre doit indiquer le polymorphisme génétique, le cas échéant.<br />
            [texte]
            <br />
            <br />
            <b>Gastro-intestinal</b>
            <br />
            [texte]
            <br />
            <br />
            <b>Génito-urinaire</b>
            <br />
            [texte]
            <br />
            <br />
            <b>Hématologique</b>
            <br />
            [texte]
            <br />
            <br />
            <b>Hépatique/biliaire/pancréatique</b>
            <br />
            Dans la mesure du possible, il faut faire mention, dans la description, du caractère idiopathique par rapport au caractère métabolique de l’insuffisance hépatique.
            <br />
            [texte]
            <br />
            <br />
            <b>Immunitaire</b>
            <br />
            [texte]
            <br />
            <br />
            <b>Musculo-squelettique</b>
            <br />
            [texte]
            <br />
            <br />
            <b>Neurologique</b>
            <br />
            [texte]
            <br />
            <br />
            <b>Ophtalmologique</b>
            <br />
            [texte]
            <br />
            <br />
            <b>Oreille/nez/gorge</b>
            <br />
            [texte]
            <br />
            <br />
            <b>Peau</b>
            <br />
            <ul>
                <li> -
                    Inclure les renseignements sur les réactions locales à la vaccination, la photosensibilité humaine, le cas échéant, etc.
                </li>
            </ul>
            [texte]
            <br />
            <br />
            <b>Psychiatrique</b>
            <br />
            Les changements de comportement, ou les comportements potentiels (p. ex. idéations suicidaires) doivent être indiqués.<br />
            [texte]
            <br />
            <br />
            <b>Rénal</b>
            <br />
            [texte]
            <br />
            <br />
            <b>Respiratoire</b>
            <br />
            [texte]
            <br />
            <br />
            <b>Santé reproductive : Potentiel des femmes et des hommes</b>
            <br />
            Renvoi à d’autres sections pertinentes (p. ex. sections 2, « Contre-indications », et 7.1.1, » Femmes enceintes »); inclure des renseignements sur la contraception pour les femmes et les hommes.            <ul>
                <li> -
                    <b>Fertilité</b><br />
                    Inclure un résumé des renseignements pertinents sur les effets du médicament sur la fertilité à la suite d’une exposition chez les animaux ou chez les humains. En l’absence de renseignements, il doit être clairement indiqué qu’il n’existe aucune donnée.                    <br />
                    [texte]
                </li>
                <li> -
                    <b>Fonction sexuelle</b><br />
                    Inclure les effets sur le désir sexuel, la fonction érectile, l’orgasme et l’éjaculation.<br />
                    [texte]

                </li>
                <li> -
                    <b>Risque tératogène</b><br />
                    Les effets tératogènes et non tératogènes sur le fœtus doivent être mentionnés. En cas de contre-indication pendant la grossesse, il faut l’indiquer dans la section 2, « Contre-indications  ».
                    <br />
                    [texte]

                </li>
            </ul>
            <br />
            <b>Sensibilité/résistance</b>
            <br />
            [texte]
            <br />
            <br />
            <b>Surveillance et tests de laboratoire</b>
            <br />
            [texte]
        </Box>,
    pregnantWomen:
        <Box>
            Inclure les renseignements relatifs aux registres de grossesse. La disponibilité d’un registre d’exposition pendant la grossesse doit également être incluse dans les renseignements pour les patients sur les médicaments. Si des renseignements sur les malformations congénitales et les fausses couches sont disponibles pour la population de patients pour laquelle le médicament est indiqué, ils doivent également être inclus, de même que les renseignements suivants, s’ils sont disponibles :
            <ul>

                <li> -
                    risque associé à la maladie pour la mère et/ou pour le fœtus;
                </li>
                <li> -
                    effets indésirables chez la mère;
                </li>
                <li> -
                    effets indésirables chez l’embryon, le fœtus ou le nouveau-né;
                </li>
                <li> -
                    travail et/ou accouchement.
                </li>
            </ul>
            Le degré d’exposition lors de la grossesse au cours des essais cliniques doit être mentionné :<br />
            <ul>
                <li> -
                    Élevé : plus de (&gt;) 1 000 grossesses
                </li>
                <li> -
                    Limité : moins de (&lt;) 1000 grossesses
                </li>
                <li> -
                    Très limité : cas particuliers seulement
                </li>
                <li> -
                    Aucune expérience
                </li>
            </ul>
            Pour les produits radiopharmaceutiques, l’énoncé suivant ou un énoncé semblable doit être utilisé :
            <br /><br />
            <i>
                Chez la femme en âge de procréer, il est préférable de faire passer la scintigraphie dans les 10 jours qui suivent le début des menstruations, ou après avoir vérifié que la femme n’est pas enceinte, surtout quand cet examen peut être différé. Il convient d’évaluer le bienfait associé à l’utilisation d’un produit radiopharmaceutique de diagnostic par rapport au risque possible pour un embryon ou un fœtus.            </i>
            <br />
            [texte]


        </Box>,
    breastFeeding:
        <Box>
            Il faut indiquer si des études ont démontré que le produit n’est pas excrété dans le lait maternel. En l’absence de données chez l’humain, des données pertinentes tirées d’études menées chez les animaux doivent être offertes; de plus, l’énoncé suivant ou un énoncé semblable doit être utilisé             <br />
            <ul>
                <li>
                    <i>
                        On ignore si ce [produit] [Marque nominative] est excrété dans le lait maternel chez l’humain. Il convient de prendre des précautions, car de nombreux médicaments peuvent être excrétés dans le lait maternel                    </i>
                </li>
            </ul>

            <b>Pour les médicaments radiopharmaceutiques,</b> l’énoncé suivant ou un énoncé semblable doit être utilisé :
            <br />
            <ul>
                <li>
                    <i>
                        Lorsque l’évaluation des avantages et des risques justifie l’administration de ce produit à des femmes qui allaitent, l’allaitement au sein doit être remplacé par un allaitement artificiel.                    </i>
                </li>
            </ul>
            [texte]

        </Box>,
    pediatricsWarningsAndPrecautions:
        <Box>
            En l’absence d’une indication pédiatrique autorisée par Santé Canada, la déclaration suivante doit être utilisée :
            <br />
            <ul>
                <li>
                    <i>
                        - <b>Enfants (tranche d’âge) :</b> Santé Canada ne dispose d’aucune donnée; par conséquent, l’indication d’utilisation dans la population pédiatrique n’est pas autorisée par Santé Canada.
                    </i>
                </li>
                ou
                <li>
                    <i>
                        - <b> Enfants (tranche d’âge) :</b> D’après les données examinées par Santé Canada, l’innocuité et l’efficacité de [Marque nominative] dans la population pédiatrique n’ont pas été démontrées; par conséquent, l’indication d’utilisation chez ces patients n’est pas autorisée par Santé Canada. [Inclure un renvoi aux sections pertinentes.]
                    </i>
                </li>
            </ul>
            [texte]
        </Box>,
    geriatricsWarningsAndPrecautions:
        <Box>
            [texte]
        </Box>,
    adverseReactions:
        <Box>
            Pour les biosimilaires, inclure l’énoncé suivant ou un énoncé similaire :
            <ui>
                <li>
                    <i>
                        - Les profils des effets indésirables aux médicaments signalés dans les études cliniques qui ont comparé la [Marque nominative du biosimilaire] au médicament biologique de référence se sont révélés comparables. La description des effets indésirables dans cette section repose sur l’expérience clinique obtenue avec le médicament biologique de référence.
                    </i>
                </li>
            </ui>

        </Box>,
    adverseReactionsOverview:
        <Box>
            [texte]
            <br /><br />
            Fournir des informations sur les effets indésirables les plus graves ou les plus fréquentes, ou celles pour lesquelles des cas particulièrement graves ont été signalés. Les fréquences doivent être indiquées aussi précisément que possible. Il ne devrait pas s’agir d’un résumé de la base de données sur l’innocuité.
        </Box>,
    clinicalTrialAdverseReactions:
        <Box>
            Les essais cliniques sont menés dans des conditions très particulières. Les taux d’effets indésirables qui y sont observés ne reflètent pas nécessairement les taux observés en pratique, et ces taux ne doivent pas être comparés aux taux observés dans le cadre d’essais cliniques portant sur un autre médicament. Les informations sur les effets indésirables provenant d’essais cliniques peuvent être utiles pour déterminer et estimer les taux de réactions indésirables aux médicaments lors d’une utilisation réelle.            <br />
            <br />
            [Inclure une courte description des sources de données.]<br />
            [texte]
            <br />
            <b>Tableau [nº] [Titre du tableau]</b>
            <table >
                <thead>
                    <tr>
                        <th></th>
                        <th>[Nom du médicament] n = [nº] (%)</th>
                        <th>[placebo] n = [nº] (%)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> [employer les termes du medDRA pour les titres, selon le cas] <br />
                            <b>cardiovasculaire</b> <br />
                            [texte] </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                    <tr>
                        <td> <b>gastro-intestinal</b><br />
                            [texte] </td>
                        <td> - </td>
                        <td> - </td>
                    </tr>
                </tbody>
            </table>
            <br />
            [texte]
            <br />
            <br />
            Une courte description doit accompagner le tableau pour expliquer ou compléter les informations fournies dans le tableau, au besoin. Des tableaux séparés peuvent être requis pour différentes indications (p. ex. indication oncologique et non oncologique) ou différentes formulations (p. ex. orale, intraveineuse) ou différentes associations de médicaments.            <br />
            <br />
            Les effets indésirables peuvent également être liées au métabolisme des produits génétiquement déterminés. Les sujets ou les patients ayant une déficience d’une enzyme donnée peuvent présenter un taux ou une gravité différente des effets indésirables. Cela doit être mentionné le cas échéant et mis en corrélation avec les données des essais cliniques.        </Box>,
    clinicalTrialAdverseReactionsPediatrics:
        <Box>
            [texte] <br /><br />
            À partir des études pédiatriques, inclure : les caractéristiques de l’âge, toute différence cliniquement pertinente (c’est-à-dire la gravité ou la réversibilité de la réaction indésirable) entre les profils d’innocuité dans les populations adultes et pédiatriques, ou tout groupe d’âge pertinent, les incertitudes dues à une expérience limitée. Si le profil d’innocuité observé est cohérent chez les enfants et les adultes, cela pourrait être indiqué.
        </Box>,
    lessCommonClinicalTrialAdverseReactions:
        <Box>
            [texte] <br /><br />
            À présenter sous forme de liste, par classe de système organique, par ordre alphabétique : par exemple,<br />
            Cardiovasculaire : [texte]<br />
            Gastro-intestinal : [texte]
        </Box>,
    lessCommonClinicalTrialAdverseReactionsPediatrics:
        <Box>
            [texte] <br /><br />
            À présenter sous forme de liste, par classe de système organique, par ordre alphabétique : par exemple,<br />
            Cardiovasculaire : [texte]<br />
            Gastro-intestinal : [texte]
        </Box>,
    abnormalLaboratoryFindingsHematologic:
        <Box>
            <b>Conclusions de l’essai clinique</b>
            <br />
            [tableau]
            <br />
            <br />

            Indiquer les différences entre les populations adultes, gériatriques et pédiatriques, le cas échéant, en ce qui concerne les résultats de laboratoire anormaux.            <br />
            <br />
            <b>Conclusions après la mise sur le marché</b>
            <br />
            [tableau]
            <br />
            <br />
            Décrire les différences entre les adultes, les personnes âgées et les enfants, le cas échéant, en ce qui concerne les résultats de laboratoire anormaux après la mise sur le marché.

        </Box>,
    postMarketAdverseReactions:
        <Box>
            [texte et/ou tableau]<br /><br />
            Inclure les effets indésirables observées après la mise sur le marché au Canada et à l’échelle internationale, y compris les effets indésirables graves ou inattendues qui sont signalées par l’intermédiaire de la surveillance après la mise sur le marché ou décelées dans des essais cliniques de phase IV. Les effets indésirables qui figurent déjà dans la section « Effets indésirables observées dans les essais cliniques » ne doivent pas être répétées dans cette section, sauf s’il y a des différences sur le plan de la gravité, de la fréquence ou du caractère.
        </Box>,
    seriousDrugInteractions:
        <Box>
            <b>Interactions médicamenteuses graves</b>
            <br />
            Les interactions médicamenteuses graves (p. ex. mettant la vie en danger) devraient être mises en évidence dans cet encadré, avec un renvoi à la sous-section pertinente de la section 9.4, « Interactions médicament-médicament », pour les renseignements détaillés. Cette partie ne doit pas dépasser 20 lignes.]            <br />
            <ul>
                <li>[texte]</li>
                <li>[texte]</li>
            </ul>
            S’il n’y a pas d’interactions médicamenteuses graves au moment de l’autorisation, cet encadré est omis
        </Box>,
    drugInteractionsOverview:
        <Box>
            [texte]
        </Box>,
    drugBehaviouralInteractions:
        <Box>
            [texte] <br />
            Présenter brièvement les interactions potentielles en termes de risques comportementaux individuels, y compris, mais sans s’y limiter, la consommation d’alcool, l’activité sexuelle et le tabagisme, qui peuvent entraîner des événements indésirables ou des résultats de traitement défavorables.
        </Box>,
    drugDrugInteractions:
        <Box>
            L’énoncé suivant ou un énoncé semblable doit apparaître avant le tableau :
            <ul>
                <li>
                    <i> Les médicaments apparaissant dans ce tableau sont fondés sur des exposés de cas ou des études sur les interactions médicamenteuses, ou encore sur les interactions potentielles en raison de l’ampleur ou de la gravité anticipée de l’interaction (ceux qui ont été identifiés comme contre-indiqués).
                    </i>
                </li>
            </ul>
            En l’absence de données sur l’interaction, l’énoncé suivant ou un énoncé semblable doit être inclus :
            <ul>
                <li>
                    <i>
                        Aucune interaction avec d’autres médicaments n’a été établie.
                    </i>
                </li>

            </ul>
            <b>Tableau [nº] – Interactions médicament-médicaments établies ou potentielles</b>
            <table >
                <thead>
                    <tr>
                        <th>[Nom propre/nom usuel]</th>
                        <th>Source de preuve</th>
                        <th>Effet</th>
                        <th>Commentaire clinique</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> [médicament A] </td>
                        <td> [source de preuve, voir la légende] </td>
                        <td> [la concentration du médicament A] </td>
                        <td> [Lla prudence s’impose et la surveillance de concentration thérapeutique est recommandée.]</td>
                    </tr>
                    <tr>Légende : É = étude de cas; EC = essai clinique; T = théorique</tr>
                </tbody>
            </table>
        </Box>,
    drugFoodInteractions:
        <Box>
            [texte]<br />
            Présenter brièvement les interactions connues ou potentielles avec certains aliments ou certaines boissons (par exemple; pamplemousse, caféine). Un renvoi à la section 4, « Posologie et administration », peut s’avérer nécessaire lorsque la répartition dans le temps de la consommation d’aliments doit être envisagée.
            <br /><br />
            En l’absence de données sur l’interaction, l’énoncé suivant ou un énoncé semblable doit être inclus :
            <ul>
                <li>
                    <i>Aucune interaction avec les aliments n’a été établie.
                    </i>
                </li>
            </ul>
        </Box>,
    drugHerbInteractions:
        <Box>
            [texte]<br />
            Indiquer brièvement les interactions connues ou potentielles du médicament avec des produits à base de plante médicinales et offrir des directives pratiques destinées aux professionnels de la santé.
            <br /><br />
            En l’absence de données sur l’interaction, l’énoncé suivant ou un énoncé semblable doit être inclus :
            <ul>
                <li>
                    <i>Aucune interaction avec des produits à base de plantes médicinales n’a été établie.
                    </i>
                </li>
            </ul>
        </Box>,
    drugLaboratoryTestInteractions:
        <Box>
            [texte]<br />
            Indiquer brièvement les épreuves de laboratoire qui sont affectés par la présence du médicament; celui-ci peut en effet nuire, par exemple, à la précision des résultats de l’épreuve  ou des méthodes utilisées (comme dans le cas des antihistaminiques qui inhibent les réactions positives aux indicateurs de réactivité cutanée).
            <br /><br />
            En l’absence de données sur l’interaction, l’énoncé suivant ou un énoncé semblable doit être inclus :
            <ul>
                <li>
                    <i>Aucune preuve selon laquelle le médicament nuirait aux épreuves de laboratoire n’a été établie.
                    </i>
                </li>
            </ul>
        </Box>,
    actionAndClinicalPharmacology: "10 | PHARMACOLOGIE CLINIQUE",
    mechanismOfAction:
        <Box>
            [texte]
        </Box>,
    pharmacodynamics:
        <Box>
            [texte]
        </Box>,
    pharmacokinetics:
        <Box>
            <b>
                Tableau [nº] - Résumé des paramètres pharmacocinétiques de [Nom propre] chez [population de patients particulière]
            </b>
            <table >
                <thead>
                    <tr>
                        <th></th>
                        <th>Cmax</th>
                        <th>Tmax</th>
                        <th>t½ (h)</th>
                        <th>ASC0-∞</th>
                        <th>CL</th>
                        <th>Vd</th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> Dose unique moyenne </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>

                    </tr>
                </tbody>
            </table>
            <b>Absorption :</b>
            <br />
            [texte]
            <br />
            <br />

            <b>Distribution :</b>
            <br />
            [texte]
            <br />
            <br />

            <b>Métabolisme :</b>
            <br />
            [texte]
            <br />
            <br />

            <b>Élimination :</b>
            <br />
            [text]
            <br />
            <br />

            <b>Durée de l’effet :</b>
            <br />
            Cette sous-section s’applique particulièrement aux vaccins et doit décrire la durée de l’effet de la dose recommandée (p. ex. durée des niveaux décelables d’anticorps et/ou du statut immunitaire conféré).
            <br />
            [texte]
            <br />
            <br />
            <b>Populations et états pathologiques particuliers</b>
            <br />
            [texte]
            <br />
            <ul>
                <li>
                    - <b>Enfants</b> [texte]
                </li>
                <li>
                    - <b>Personnes âgées</b> [texte]
                </li>
                <li>
                    - <b>Sexe</b> [texte]
                </li>
                <li>
                    - <b>Grossesse et allaitement</b> [texte]
                </li>
                <li>
                    - <b>Polymorphisme génétique</b> [texte]
                </li>
                <li>
                    - <b>Origine ethnique</b> [texte]
                </li>
                <li>
                    - <b>Insuffisance hépatique</b> [texte]
                </li>
                <li>
                    - <b>Insuffisance rénale</b> [texte]
                </li>
                <li>
                    - <b>Obésité</b> [texte]
                </li>
            </ul>
        </Box>,
    storageStabilityAndDisposal:
        <Box>
            [texte]
            <br />
            <br />
            Inclure les conditions d’entreposage recommandées pour chaque forme posologique étayées par les études de stabilité.
            <br />
            <br />
            En ce qui concerne les produits reconstitués, y compris les produits administrés par voie parentérale, il faut indiquer la période d’entreposage recommandée et les conditions requises.
            <br />
            <br />
            Les instructions du traitement doivent être incluses pour tous les produits pharmaceutiques. Inclure un renvoi vers les instructions détaillées sur l’élimination sans risque du médicament dans la section 12, « Instructions particulières de manipulation », au besoin.
            <br />
            <br />
            Pour les trousses radiopharmaceutiques, le cas échéant, veuillez inclure l’énoncé suivant ou un énoncé similaire :

            <ul>
                <li>
                    <i>
                        Ne pas utiliser la trousse au-delà de la date de péremption apparaissant sur la boîte. À la suite de la préparation, le [produit] doit être entreposé à température ambiante jusqu’à l’administration, dans un délai de [x] heures avant le radiomarquage.
                    </i>
                </li>
            </ul>
        </Box>,
    specialHandlingInstructions:
        <Box>
            [texte]
        </Box>,
}