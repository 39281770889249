import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { login } from '../../actions/auth'
import PMButton from '../common/Button'

import { Box, Grid, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    button: {
        width: "100%"
    },
    textField: {
        width: "100%"
    },
    formLabel: {
        float: "left",
        margin: "2% 0% 2% 0",
        fontSize: 18
    },
    forgotPassword: {
        '&:hover': {
            color: "#007bff",
            cursor: "pointer",
            textDecoration: "underline",
        },
    },
    formHeader: {
        float: "left",
        fontWeight: 800,
        fontSize: 36,
        fontStyle: "normal",
        marginBottom: "1%",
        // marginTop: "4%"
    },
}));


export default function Login({ email, setEmail, password, setPassword }) {
    const dispatch = useDispatch()
    const loading = useSelector((state) => state.auth.loading)

    const classes = useStyles()
    let history = useHistory()

    const onSubmit = async e => {
        e.preventDefault();
        dispatch(login( email, password))
    }

    const handleForgotPasswordRedirect = () => {
        history.push('/forgot-password')
    }

    return(
        <Box>
            <form>
                <Box display="flex" flexDirection="column">
                    <Typography className ={classes.formHeader}>
                        {"Login"}
                    </Typography>
                    <Box>
                        <Grid item xs={12}>
                            <Typography className ={classes.formLabel}>
                                Email
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                type = 'text'
                                variant='outlined'
                                placeholder="Enter Email Here"
                                name = 'email'
                                value = {email}
                                onChange = {e => setEmail(e.target.value)}
                                required
                                className={classes.textField}
                            />
                        </Grid>
                    </Box>
                    <Box width="100%" mt={3}>
                        <Grid item xs={12}>
                            <Typography className ={classes.formLabel}>
                                Password
                            </Typography>
                        </Grid>
                        <TextField
                            type = 'password'
                            variant='outlined'
                            placeholder = 'Enter Password Here'
                            name = 'password'
                            value = {password}
                            onChange = {e => setPassword(e.target.value)}
                            minLength = '8'
                            className={classes.textField}
                        />
                    </Box>
                </Box>
                <Grid item xs={12}>
                    <Box mt={2} >
                        <Typography align="right">
                            <span onClick={() => {handleForgotPasswordRedirect()}} className={classes.forgotPassword}>
                                Forgot Password?
                            </span>
                        </Typography>
                    </Box>
                </Grid>
                <Box mt={3}>
                    <PMButton
                        type="submit"
                        loading={loading}
                        disabled={loading}
                        onClick={(e) => {onSubmit(e)}}
                        className={classes.button}
                    >
                        Login
                    </PMButton>
                </Box>
            </form>
        </Box>
    )
}
