import React from 'react'
import { useSelector } from 'react-redux'
import { Field } from 'formik';
import { Box, FormControl, MenuItem, Select } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { PROVINCE_ABBREVIATIONS } from '../../../utils/utilFunctions';
import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    comparisonField: {
        backgroundColor: "#e9ecef",
		padding: "0.75rem",
		minHeight: "3rem",
		borderRadius: "25px"
    },
}));

const FormikSelect = ({ children, field, placeholder, updateFunction, disabled }) => {
    const { name, value } = field;
  
    return (
        <Select
            name={name}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            onChange={e => {
                updateFunction(name, e.target.value);
            }}
        >
            {children}
        </Select>
    );
};

export default function ProvinceDropdown({ width, columnStyling, updateFunction, label, name, placeholder }){
    const classes = useStyles();
	const editState = useSelector((state) => state.forms.editState);

    const genFieldValue = (field) => {
        try {
            return (field.value).toString()
        } catch {
            return ""
        }
    }

    return(
		<Box key={name} className={columnStyling ? `form-group col-md-${columnStyling}`: "form-group"}  style={{display: "flex", flexDirection: "column", width: width ? width : null}}>
			<label htmlFor={name}>{label}</label>
            {editState.isComparison ? (
                <Field name={name}>
                    {({ field }) => (
                        <Box className={classes.comparisonField}>
                            {parse(genFieldValue(field))}
                        </Box>
                    )}
                </Field>
            ) : (
                <FormControl variant="outlined" className={classes.formControl}>
                        <Field 
                            name={name}
                            component={FormikSelect}
                            updateFunction={updateFunction}
                            disabled={editState.isDisabled}
                            placeholder={placeholder}
                        >
                            {PROVINCE_ABBREVIATIONS.map((provinceAbbreviation) => {
                                return (
                                    <MenuItem value={provinceAbbreviation.abbreviation}>
                                        {provinceAbbreviation.province}
                                    </MenuItem>
                                )
                            })}
                        </Field>
                </FormControl>
            )}
		</Box>
    )
}