export const englishLabels = {
    title: "PRODUCT CHARACTERISTICS",
    productType: "Product Type",
    colour: "Colour",
    shape: "Shape",
    size: "Size",
    sizeValue: "Value",
    sizeUnit: "Unit",
    score: "Score",
    imprint: "Imprint",
    flavour: "Flavour",
    combinationProduct: "Combination Product",
    pharmaceuticalStandard: "Pharmaceutical Standard",
    schedule: "Schedule",
    therapeuticClass: "Therapeutic Class"
}

export const frenchLabels = {
    title: "CARACTÉRISTIQUES DU PRODUIT",
    productType: "Type De Produit",
    colour: "Couleur",
    shape: "Forme",
    size: "Taille",
    sizeValue: "Valeur",
    sizeUnit: "Unité",
    score: "Rainure",
    imprint: "Empreinte",
    flavour: "Saveur",
    combinationProduct: "Produit Combiné",
    pharmaceuticalStandard: "Norme Pharmaceutique",
    schedule: "Annexe",
    therapeuticClass: "Classe Thérapeutique"
}