import {
    GET_FORMS,
    GET_FORM,
    GET_VERSION_NUMBERS,
    UPDATE_FORM,
    FORM_ERROR,
    CREATE_FORM,
    FORM_CREATED,
    DOWNLOAD_FORM,
    FILTER_FORMS,
    ARCHIVE_FORMS,
    ARCHIVE_FORM_ID,
    RESTORE_FORM,
    FORM_LOADING,
    FORM_LOADED,
    CLEAR_FORM,
    FORM_UPDATING,
    GET_FORM_VALIDATION,
    UPDATE_FORM_LAST_MODIFIED_INFO,
    UPDATE_FORM_FIELD,
    PMI_SCORE,
    PMI_SCORE_FAILURE,
    PMI_SUGGESTION_COMPLETE,
    PMI_SUGGESTION_LOADING,
    PMI_SUGGESTION_STREAMING,
    PMI_SUGGESTION_ERROR,
    PREVIEW_DOWNLOAD_ENGLISH_STATUS,
    PREVIEW_DOWNLOAD_FRENCH_STATUS,
    SET_CURRENT_SECTION_INDEX,
    DOWNLOAD_ENGLISH_STATUS,
    DOWNLOAD_FRENCH_STATUS,
    EDIT_STATE,
    GET_FORM_ACCESS_CONTROL,
    GET_USERS_USER_COMPANY_INFO,
    GET_ACCESS_LEVEL,
    CHECK_DOCUMENT_LOCK,
    UPLOAD_ENGLISH_STATUS,
    UPLOAD_FRENCH_STATUS,
    SET_UPLOAD_DIALOG_OPEN_STATUS,
    SET_UPLOAD_REPORT_OPEN_STATUS,
    ARCHIVE_FORM,
} from '../actions/types'
import _ from "lodash"
import EditState from "../utils/EditState"
import { DOCUMENT_LOCK_STATES, FORM_ACCESS, VIEW_TYPE_STATES } from '../utils/utilFunctions'

const initialState = {
    forms: [],
    form: null,
    editState: new EditState(
        FORM_ACCESS.VIEWER,
        DOCUMENT_LOCK_STATES.ANOTHER_SESSION_OR_OPERATION,
        VIEW_TYPE_STATES.EDITOR,
        null,
        false,
    ),
    documentLock: {},
    formCount: 0,
    loading: false,
    creationLoading: false,
    formActiveStatusLoading: false,
    updating: true,
    saving: false,
    error: {},
    currentSectionIndex: 0,
    creationID: '',
    searchResults: [],
    archivedForms: [],
    versionNumbers: [],
    originalFormId: '',
    previewDownloadEnglishStatus: {},
    previewDownloadFrenchStatus: {},
    downloadEnglishStatus: {},
    downloadFrenchStatus: {},
    uploadEnglishStatus: {},
    uploadFrenchStatus: {},
    validationResults: {},
    lastModifiedInfo: null,
    accessLevel: FORM_ACCESS.VIEWER,
    formAccessControlInfo: {},
    usersInUserCompanyInfo: {},
    pmiScore: null,
    pmiSuggestion: null,
    pmiSuggestionStreaming: false,
    pmiSuggestionLoading: false,
    isUploadDialogOpen: false,
    isUploadReportOpen: false,
}

export default function formReducer(state = initialState, action) {

    const { type, payload } = action;
    let currentForm = _.cloneDeep(state.form)
    switch (type) {
        case SET_UPLOAD_DIALOG_OPEN_STATUS:
            return {
                ...state,
                isUploadDialogOpen: payload
            }
        case SET_UPLOAD_REPORT_OPEN_STATUS:
            return {
                ...state,
                isUploadReportOpen: payload
            }
        case SET_CURRENT_SECTION_INDEX:
            return {
                ...state,
                pmiSuggestion: null,
                currentSectionIndex: payload,
            }
        case PMI_SUGGESTION_LOADING:
            return {
                ...state,
                pmiSuggestion: null,
                pmiSuggestionLoading: true,
                pmiSuggestionStreaming: false,
            }
        case PMI_SUGGESTION_COMPLETE:
            return {
                ...state,
                pmiSuggestionLoading: false,
                pmiSuggestionStreaming: false,
            }
        case PMI_SUGGESTION_STREAMING:
            return {
                ...state,
                pmiSuggestionLoading: false,
                pmiSuggestionStreaming: true,
                pmiSuggestion: state.pmiSuggestion ? state.pmiSuggestion + payload : payload,
            }
        case PMI_SUGGESTION_ERROR:
            return {
                ...state,
                pmiSuggestionLoading: false,
                pmiSuggestion: null,
            }
        case PMI_SCORE: 
            return {
                ...state,
                pmiScore: payload,
            }
        case PMI_SCORE_FAILURE:
            return {
                ...state,
                pmiScore: null,
            }
        case GET_VERSION_NUMBERS:
            return {
                ...state,
                loading: false,
                updating: false,
                saving: false,
                error: {},
                versionNumbers: payload,
            }
        case EDIT_STATE:
            return {
                ...state,
                editState: new EditState(
                    payload.accessLevel,
                    payload.documentLock,
                    payload.viewType,
                    payload.userID,
                ),
                loading: false,
            }
        case CHECK_DOCUMENT_LOCK:
            return {
                ...state,
                documentLock: payload.documentLock,
                lastModifiedInfo: payload.lastModifiedInfo ?? state.lastModifiedInfo,
                form: payload.form ?? state.form,
                loading: false,
                updating: false,
                saving: false,
                error: {},
            }
        case UPLOAD_ENGLISH_STATUS:
            return {
                ...state,
                uploadEnglishStatus: payload,
            }
        case UPLOAD_FRENCH_STATUS:
            return {
                ...state,
                uploadFrenchStatus: payload,
            }
        case CLEAR_FORM:
            return {
                ...state,
                form: null,
                docxDownloadEnglishStatus: {},
                docxDownloadFrenchStatus: {},
                downloadEnglishStatus: {},
                downloadFrenchStatus: {},
                validationResults: {},
                lastModifiedInfo: null,
                editState: new EditState(
                    FORM_ACCESS.VIEWER,
                    DOCUMENT_LOCK_STATES.ANOTHER_SESSION_OR_OPERATION,
                    VIEW_TYPE_STATES.EDITOR,
                    null,
                    false,
                ),
                documentLock: {},
                accessLevel: FORM_ACCESS.VIEWER,
                formAccessControlInfo: {},
                usersInUserCompanyInfo: {},
            }
        case FORM_UPDATING:
            return {
                ...state,
                updating: true,
            }
        case FORM_LOADING:
            return {
                ...state,
                loading: payload
            }
        case FORM_LOADED:
            return {
                ...state,
                loading: payload,
                updating: false,
            }
        case CREATE_FORM:
            return {
                ...state,
                creationLoading: true,
            }
        case FORM_CREATED:
            return {
                ...state,
                form: null,
                creationID: payload,
                creationLoading: false,
                loading: false,
                updating: false,
                error: {}
            }
        case GET_FORMS:
        case FILTER_FORMS:
            return {
                ...state,
                form: null,
                forms: payload.forms,
                formCount: payload.count,
                loading: false,
                updating: false,
                lastModifiedInfo: null,
                searchResults: [],
                archivedForms: [],
                error: {}
            }
        case GET_FORM:
        case UPDATE_FORM:
            return {
                ...state,
                form: payload,
                loading: false,
                updating: false,
                saving: false,
                error: {},
            }
        case UPDATE_FORM_FIELD:
            _.set(currentForm, payload.field, payload.content)
            return {
                ...state,
                form: currentForm,
                loading: false,
                updating: false,
                saving: false,
                error: {},
            }
        case RESTORE_FORM:
            return {
                ...state,
                form: payload,
                loading: true,
                error: {},
            }
        case FORM_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
                updating: false,
                formActiveStatusLoading: false,
                creationLoading: false,
                validationResults: {},
                lastModifiedInfo: null,
            }
        case DOWNLOAD_FORM:
            return {
                ...state,
                loading: false,
                updating: false,
                error: {}
            }
        case PREVIEW_DOWNLOAD_ENGLISH_STATUS:
            return {
                ...state,
                previewDownloadEnglishStatus: payload,
            }
        case PREVIEW_DOWNLOAD_FRENCH_STATUS:
            return {
                ...state,
                previewDownloadFrenchStatus: payload,
            }
        case DOWNLOAD_ENGLISH_STATUS:
            return {
                ...state,
                downloadEnglishStatus: payload
            }
        case DOWNLOAD_FRENCH_STATUS:
            return {
                ...state,
                downloadFrenchStatus: payload
            }
        case ARCHIVE_FORMS:
            return {
                ...state,
                loading: false,
                updating: false,
                archivedForms: payload,
                error: {}
            }
        case ARCHIVE_FORM_ID:
            return {
                ...state,
                loading: false,
                updating: false,
                originalFormId: payload,
                error: {}
            }
        case GET_FORM_VALIDATION:
            _.set(currentForm, payload.field, payload.anchors)
            return {
                ...state,
                form: currentForm,
                validationResults: payload.results,
            }
        case UPDATE_FORM_LAST_MODIFIED_INFO:
            return {
                ...state,
                lastModifiedInfo: payload,
            }
        case GET_ACCESS_LEVEL:
            return {
                ...state,
                accessLevel: payload,
            }
        case GET_FORM_ACCESS_CONTROL:
            return {
                ...state,
                formAccessControlInfo: payload,
            }
        case GET_USERS_USER_COMPANY_INFO:
            return {
                ...state,
                usersInUserCompanyInfo: payload,
            }
        case ARCHIVE_FORM:
            return {
                ...state,
                formActiveStatusLoading: payload,
            }
        default:
            return state
    }
}